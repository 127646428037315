import React, { memo } from "react";
import PropTypes from "prop-types";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircle from "@mui/icons-material/CheckCircle";
import WarningRounded from "@mui/icons-material/WarningRounded";

// todo check why we can't copy paste from this input
const PhoneInput = ({
  name,
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  value,
  onChange,
  InputLabelProps,
  InputProps,
  inputProps,
  showValidationIcon = true,
  ...rest
}) => {
  const isValid = showValidationIcon ? matchIsValidTel(value ?? "") : null;

  const onChangeByName = (value, data) => {
    value = (value ?? "").replace(/\s/g, "");
    onChange(name, value, data);
  };

  return (
    <MuiTelInput
      defaultCountry="FR"
      preferredCountries={["BE", "FR", "IT", "MC", "ES"]}
      langOfCountryName="fr"
      {...rest}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      value={value ?? ""}
      onChange={onChangeByName}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        endAdornment: isValid != null && (
          <InputAdornment position="end">
            {isValid ? (
              <CheckCircle color="success" />
            ) : (
              <WarningRounded color="error" />
            )}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        maxLength: 22,
        ...inputProps,
      }}
    />
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  showValidationIcon: PropTypes.bool,
};

export default memo(PhoneInput);
