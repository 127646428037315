import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Check from "@mui/icons-material/Check";

// core components
import MuiButton from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DateUtils from "Utils/DateUtils";
import SelectSearch from "components/CustomSelect/SelectSearch";
import Stack from "@mui/material/Stack";
import EvenementIcon from "components/Evenements/EvenementIcon";
import DateTimePicker from "components/DatePicker/DateTimePicker";
import SelectInput from "components/CustomSelect/SelectInput";

class EvenementForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/evenements";

    this.state = {
      types: [],
      id: props.id,
      eventable_id: "",
      eventable_type_readable: "",
      origine_id: "",
      type_evenement_id: "",
      subject: "",
      content: "\n",
      resolu: "",
      date_time: new Date(),
      errors: null,
      collapse: props.collapsable ? props.openCollapse : true,
      loading: false,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
    this.creatableTypes();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.date_time = new Date(result.data?.date_time);
        this.setState(state);
      });
  }

  creatableTypes = () => {
    axiosApiBackend
      .get("/config-type-evenement", {
        params: {
          create_from: this.props.modelType ?? null,
        },
      })
      .then((result) => {
        this.setState({
          types: result.data,
        });
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeValue = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const dataIn = {
      type_evenement_id: this.state.type_evenement_id,
      origine_id: this.state.origine_id,
      subject: this.state.subject,
      content: this.state.content,
      resolu: this.state.resolu,
      date_time: DateUtils.toDBString(this.state.date_time, true),
    };

    if (this.state.id) {
      // modify
      const putDataIn = {
        ...dataIn,
        eventable_id: this.state.eventable_id,
        eventable_type_readable: this.state.eventable_type_readable,
      };

      axiosApiBackend
        .put(this.BACKEND_URL + "/" + this.state.id, putDataIn)
        .then(() => {
          this.props.onSuccess();
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      const postDataIn = {
        ...dataIn,
        eventable_id: this.props.modelId,
        eventable_type_readable: this.props.modelType,
      };

      axiosApiBackend
        .post(this.BACKEND_URL, postDataIn)
        .then(() => {
          this.setState({
            type_evenement_id: "",
            origine_id: "",
            subject: "",
            content: "\n",
            resolu: "",
            date_time: new Date(),
            loading: false,
          });
          this.props.onSuccess();
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    let errors = error.response?.data?.errors ?? error.response?.data ?? {};
    this.setState({
      errors: errors,
      loading: false,
    });
  };

  render() {
    return (
      <>
        {this.props.collapsable && (
          <MuiButton
            sx={{ mb: 2 }}
            onClick={() => this.setState({ collapse: !this.state.collapse })}
          >
            {this.state.collapse
              ? "Réduire le formulaire"
              : "Ajouter un évènement"}
          </MuiButton>
        )}
        <Collapse in={this.state.collapse}>
          <Grid sx={{ mt: 0, mb: 2 }} container spacing={2} alignItems="center">
            {this.state.errors && (
              <Grid item lg={12} xs={12}>
                <Typography color="error">
                  {Object.entries(this.state.errors).length === 0 ? (
                    <>Une erreur est survenue.</>
                  ) : this.state.errors?.message ? (
                    <>{this.state.errors?.message}.</>
                  ) : (
                    <>Merci de corriger les champs en rouge du formulaire.</>
                  )}
                </Typography>
              </Grid>
            )}
            <Grid item lg={4} xs={12}>
              <DateTimePicker
                name="date_time"
                label="Date"
                format="dd/MM/yyyy HH:mm"
                ampm={false}
                value={this.state.date_time}
                onChange={this.handleChangeByName}
                error={Boolean(this.state.errors?.date_time)}
                textFieldProps={{ margin: "none" }}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <SelectInput
                name="type_evenement_id"
                label="Type"
                margin="none"
                value={this.state.type_evenement_id}
                onChange={this.handleChange}
                error={Boolean(this.state.errors?.type_evenement_id)}
              >
                {this.state.types.map((type, key) => {
                  return (
                    <MenuItem key={key} value={type.id}>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <EvenementIcon
                          sx={{
                            marginTop: "-4px",
                            marginBottom: "-4px",
                          }}
                          color={type.color}
                          icon={type.icon}
                          dotSize={30}
                          iconSize={20}
                        />
                        {type.value}
                      </Stack>
                    </MenuItem>
                  );
                })}
              </SelectInput>
            </Grid>
            <Grid item lg={2} xs={12}>
              <SelectSearch
                name="origine_id"
                label="Origine"
                margin="none"
                apiUrl="/config-origine-evenements"
                value={this.state.origine_id}
                onChange={this.handleChangeValue}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(this.state.errors?.origine_id)}
              />
            </Grid>
            <Grid item lg={2} xs={12}>
              <SelectInput
                name="resolu"
                label="Avec suivi"
                margin="none"
                value={this.state.resolu}
                onChange={this.handleChange}
                displayEmpty
                error={Boolean(this.state.errors?.resolu)}
              >
                <MenuItem value="">Non</MenuItem>
                <MenuItem value="0">Oui</MenuItem>
                <MenuItem value="1">Résolu</MenuItem>
              </SelectInput>
            </Grid>
            <Grid item lg={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="subject"
                  label="Titre"
                  variant="outlined"
                  fullWidth
                  onChange={this.handleChange}
                  value={this.state.subject}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 255,
                  }}
                  error={Boolean(this.state.errors?.subject)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={10} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="content"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  onChange={this.handleChange}
                  value={this.state.content}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(this.state.errors?.content)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={2} xs={12}>
              <LoadingButton
                fullWidth
                size="small"
                color="success"
                loading={this.state.loading}
                onClick={this.handleSubmit}
              >
                <Check />
                <Box display={{ xs: "none", md: "inline" }}>
                  {this.state.id ? "Modifier" : "Ajouter"}
                </Box>
              </LoadingButton>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  }
}

EvenementForm.defaultProps = {
  collapsable: false,
  openCollapse: false,
};

EvenementForm.propTypes = {
  id: PropTypes.any,
  modelId: PropTypes.number,
  modelType: PropTypes.string,
  onSuccess: PropTypes.func,
  history: PropTypes.any,
  collapsable: PropTypes.bool,
  openCollapse: PropTypes.bool,
};

export default EvenementForm;
