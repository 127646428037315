import React from "react";
import PropTypes from "prop-types";

import Avatar from "@mui/material/Avatar";
import Icon from "@mui/material/Icon";

class EvenementIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { color, icon, dotSize, iconSize, sx } = this.props;

    return (
      <Avatar
        sx={{
          bgcolor: color,
          width: dotSize,
          height: dotSize,
          ...sx,
        }}
        variant="circular"
      >
        <Icon style={{ fontSize: iconSize }}>{icon ?? "event"}</Icon>
      </Avatar>
    );
  }
}

EvenementIcon.defaultProps = {
  dotSize: 40,
  iconSize: 24,
};

EvenementIcon.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  dotSize: PropTypes.number,
  iconSize: PropTypes.number,
  sx: PropTypes.object,
};

export default EvenementIcon;
