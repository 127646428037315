import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Button from "components/Button/Button.jsx";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import WarningTextField from "components/CustomInput/WarningTextField";
import SelectInput from "components/CustomSelect/SelectInput";
import DatePicker from "components/DatePicker/DatePicker.jsx";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";

import CPUtils from "Utils/CPUtils";
import DateUtils from "Utils/DateUtils";
import Render from "Utils/RenderUtils";

class RegularisationChargePopup extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/contrats-proprietaires";
    this.state = {
      // NESTED DATA

      // DATA FORM
      date_debut_regule: "",
      date_fin_regule: "",
      montant_regule: "",
      charges_taxes: "",

      // END DATA FORM
      open: false,
      openPrevisualisation: false,
      error: false,
      errorMessage: "Erreur.",
      errors: {},
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      openPrevisualisation: false,
    });
  };

  handleDateDebut = (name, value) => {
    this.setState({
      [name]: value,
      openPrevisualisation: false,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      openPrevisualisation: false,
      error: false,
      errorMessage: null,
      errors: {},
      date_debut_regule: "",
      date_fin_regule: "",
      montant_regule: "",
      charges_taxes: "",
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
    });
  };

  validateInputs = () => {
    const {
      date_debut_regule,
      date_fin_regule,
      montant_regule,
      charges_taxes,
    } = this.state;
    const errors = {};

    if (!date_debut_regule) errors.date_debut_regule = true;
    if (!date_fin_regule) errors.date_fin_regule = true;
    if (!montant_regule) errors.montant_regule = true;
    if (!charges_taxes) errors.charges_taxes = true;

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  handlePreview = () => {
    if (!this.validateInputs()) {
      return;
    }

    const { contrat_proprietaire_id } = this.props;

    const dataIn = {
      date_debut: DateUtils.toDBString(this.state.date_debut_regule.setDate(1)),
      date_fin: DateUtils.toDBString(
        DateUtils.lastDayOfMonth(this.state.date_fin_regule),
      ),
      montant_regule: this.state.montant_regule,
      charges_taxes: this.state.charges_taxes,
    };

    axiosApiBackend
      .post(
        this.BACKEND_URL + "/" + contrat_proprietaire_id + "/regularisation",
        dataIn,
      )
      .then((result) => {
        this.setState({
          data: result.data,
          openPrevisualisation: true,
        });
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  handleSubmit = () => {
    if (!this.validateInputs()) {
      return;
    }

    const { contrat_proprietaire_id } = this.props;

    const dataIn = {
      date_debut: DateUtils.toDBString(this.state.date_debut_regule.setDate(1)),
      date_fin: DateUtils.toDBString(
        DateUtils.lastDayOfMonth(this.state.date_fin_regule),
      ),
      montant_regule: this.state.montant_regule,
      charges_taxes: this.state.charges_taxes,
    };

    axiosApiBackend
      .post(
        this.BACKEND_URL +
          "/" +
          contrat_proprietaire_id +
          "/create-regularisation",
        dataIn,
      )
      .then((result) => {
        this.setState({
          data: result.data,
        });
        this.props.onSuccess();
      })
      .catch((err) => {
        this.handleError(err);
      });

    this.handleClose();
  };

  render() {
    const { errors, openPrevisualisation, date_debut_regule, date_fin_regule } =
      this.state;
    const {
      contrat_proprietaire_id,
      type_contrat_proprietaire,
      date_debut_bail,
      date_fin_bail,
    } = this.props;

    const isWarningDebut =
      date_debut_regule !== "" &&
      date_fin_regule !== "" &&
      (date_debut_regule > new Date() || date_debut_regule >= date_fin_regule);

    const helperTextDebut = isWarningDebut
      ? "La date de début ne doit pas être postérieure à la date de fin ou à aujourd'hui."
      : "";

    const isWarningFin =
      date_fin_regule !== "" &&
      date_debut_regule !== "" &&
      (date_fin_regule > new Date() || date_debut_regule >= date_fin_regule);

    const helperTextFin = isWarningFin
      ? "La date de fin ne doit pas être antérieure à la date de début ou à aujourd'hui."
      : "";

    const date_debut_bail_parsed = date_debut_bail
      ? new Date(date_debut_bail)
      : new Date();

    const date_fin_bail_parsed = date_fin_bail ? new Date(date_fin_bail) : null;

    const minDateRegule = DateUtils.subtractYears(
      new Date(date_debut_bail_parsed),
      1,
    );

    const maxDateRegule =
      date_fin_bail_parsed && date_fin_bail_parsed < new Date()
        ? date_fin_bail_parsed
        : DateUtils.subtractMonths(new Date(), 1);

    const isDisabled =
      (this.state.data?.montantRegule === 0 &&
        this.state.data?.contratLocataires?.every(
          (cl) => cl.diffMontantAppeleesReel === 0,
        )) ||
      date_debut_bail === null;

    return (
      <>
        <Button
          size="small"
          onClick={this.handleOpen}
          style={{ float: "right" }}
        >
          Régularisation
        </Button>
        <Dialog open={this.state.open} maxWidth="md" fullWidth={true}>
          <DialogTitle>
            Effectuer une régularisation
            <InfoLightTooltip
              title="HAPY assure une répartition équitable des charges entre les locataires et le propriétaire pour chaque contrat de location, en tenant compte des périodes d'occupation du logement, des dates de bail, des appels de charges réalisés sur la période et du type de contrat."
              placement="top"
            />
          </DialogTitle>
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={6}>
                <DatePicker
                  label="Mois de début de régularisation"
                  name="date_debut_regule"
                  value={date_debut_regule}
                  onChange={this.handleDateDebut}
                  views={["year", "month"]}
                  minDate={minDateRegule}
                  maxDate={maxDateRegule}
                  error={Boolean(errors?.date_debut_regule)}
                  textFieldSlot={WarningTextField}
                  textFieldProps={{
                    isWarning: isWarningDebut,
                    helperText: helperTextDebut,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DatePicker
                  label="Mois de fin de régularisation"
                  name="date_fin_regule"
                  value={this.state.date_fin_regule}
                  onChange={this.handleDateDebut}
                  views={["year", "month"]}
                  minDate={minDateRegule}
                  maxDate={maxDateRegule}
                  error={Boolean(errors?.date_fin_regule)}
                  textFieldSlot={WarningTextField}
                  textFieldProps={{
                    isWarning: isWarningFin,
                    helperText: helperTextFin,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectInput
                  label="Charges/Taxes à régulariser"
                  name="charges_taxes"
                  onChange={this.handleChange}
                  value={this.state.charges_taxes}
                  error={Boolean(errors?.charges_taxes)}
                >
                  <MenuItem value="">
                    <em>Non renseigné</em>
                  </MenuItem>
                  <MenuItem value="Charges">Charges</MenuItem>
                  <MenuItem value="Charges garage">Charges garage</MenuItem>
                  <MenuItem value="TOM">TOM</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CurrencyInput
                  label="Montant"
                  name="montant_regule"
                  value={this.state.montant_regule}
                  onChange={this.handleChange}
                  error={Boolean(errors.montant_regule)}
                />
              </Grid>
              {openPrevisualisation && (
                <Grid item xs={12}>
                  <HeaderTitle label="Modification" />
                  <Typography>
                    <strong>Provisions mensuelles prévues : </strong>
                    {Render.euro(this.state.data.chargeMensuelAppelees)}{" "}
                    {Render.euro(
                      this.state.data.chargeJournaliereAppelees,
                      " (environ ",
                      " par jour)",
                    )}
                  </Typography>
                  <Typography>
                    <strong>Provisions mensuelles réelles : </strong>
                    {Render.euro(this.state.data.chargeMensuelReel)}{" "}
                    {Render.euro(
                      this.state.data.chargeJournaliereReel,
                      " (environ ",
                      " par jour)",
                    )}
                  </Typography>
                  {this.state.data.contratLocataires.length > 0 ? (
                    this.state.data.contratLocataires.map((cl, index) => {
                      return (
                        <div key={index}>
                          <HeaderTitle
                            label={`Contrat Locataire CL${cl.id} - ${cl.referent}`}
                          />
                          <Typography>
                            <strong>Période d&apos;occupation :</strong>
                            {Render.date(cl.dateDebut, " du ", " au ")}
                            {Render.date(cl.dateFin)}
                          </Typography>
                          <Typography>
                            <strong>Somme des provisions appelées : </strong>
                            {cl.sommeProvisionsAppelees !== 0
                              ? Render.euro(
                                  Math.abs(cl.sommeProvisionsAppelees),
                                  "",
                                  `${
                                    cl.sommeProvisionsAppelees < 0
                                      ? " au débit"
                                      : " au crédit"
                                  } sur ces `,
                                )
                              : Render.euro(
                                  Math.abs(cl.sommeProvisionsAppelees),
                                  "",
                                  " sur ces ",
                                )}
                            {`${cl.totalMoisOccupation} mois`}
                          </Typography>
                          <Typography>
                            <strong>Somme des provisions réelles : </strong>
                            {Render.euro(cl.sommeChargeReel)}
                          </Typography>
                          <Typography>
                            <strong>Régularisation : </strong>
                            {Render.euro(cl.sommeChargeReel)}
                            {Render.euro(
                              Math.abs(cl.sommeProvisionsAppelees),
                              " - ",
                              " = ",
                            )}
                            {Render.euro(cl.diffMontantAppeleesReel)}
                          </Typography>
                          <Typography>
                            <strong>Action : </strong>
                            {date_debut_bail !== null
                              ? cl.diffMontantAppeleesReel !== 0
                                ? `Création d'une opération de régulation au ${
                                    cl.diffMontantAppeleesReel > 0
                                      ? "débit"
                                      : "crédit"
                                  }`
                                : "Aucune opération"
                              : "Aucune date de début de bail n'est renseignée sur le contrat propriétaire"}
                          </Typography>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <HeaderTitle label="Contrat Locataire" />
                      <Typography>Aucun contrat locataire trouvé</Typography>
                    </>
                  )}
                  <HeaderTitle
                    label={`Contrat Propriétaire CP${contrat_proprietaire_id} (${type_contrat_proprietaire})`}
                  />
                  <Typography>
                    <strong>Période incluse dans le bail :</strong>
                    {Render.date(this.state.data.dateDebut, " du ", " au ")}
                    {Render.date(this.state.data.dateFin)}
                  </Typography>
                  <Typography>
                    <strong>Somme des provisions payée : </strong>
                    {`${Render.euro(this.state.data.sommeProvisions)} sur ces ${
                      this.state.data.totalMoisBail
                    } mois`}
                  </Typography>
                  <Typography>
                    <strong>Somme des provisions réelles : </strong>
                    {Render.euro(this.state.data.sommeChargeReel)}
                  </Typography>
                  <Typography>
                    <strong>Régularisation : </strong>
                    {type_contrat_proprietaire === CPUtils.TYPES[0] ? (
                      <>
                        {Render.euro(this.state.data.sommeProvisions)}
                        {Render.euro(
                          this.state.data.sommeChargeReel,
                          " - ",
                          " = ",
                        )}
                        {Render.euro(this.state.data.montantRegule)}
                      </>
                    ) : (
                      <>
                        {Render.euro(this.state.data.sommeProvisions)}
                        {Render.euro(
                          this.state.data.sommeClSommeChargeReel,
                          " - ",
                          " = ",
                        )}
                        {Render.euro(this.state.data.montantRegule)}
                      </>
                    )}
                  </Typography>
                  {/* <Typography> TODO voir quelles pertes afficher (Pro periode / Pro Régu / Soli periode / Soli regul)
                    <strong>Perte de charge réelle totale : </strong>
                    {Render.euro(this.state.data.montantPerte)}
                  </Typography> */}
                  <Typography>
                    <strong>Action : </strong>
                    {date_debut_bail !== null
                      ? this.state.data.montantRegule !== 0
                        ? `Création d'une opération de régulation au ${
                            this.state.data.montantRegule > 0
                              ? "débit"
                              : "crédit"
                          }`
                        : "Aucune opération"
                      : "Aucune date de début de bail n'est renseignée sur le contrat propriétaire"}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Annuler</Button>
            {openPrevisualisation && (
              <Button
                onClick={this.handleSubmit}
                color="info"
                disabled={isDisabled}
              >
                Enregistrer
              </Button>
            )}
            {!openPrevisualisation && (
              <Button onClick={this.handlePreview} color="info">
                Prévisualisation
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

RegularisationChargePopup.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  contrat_proprietaire_id: PropTypes.number,
  type_contrat_proprietaire: PropTypes.string,
  date_debut_bail: PropTypes.string,
  date_fin_bail: PropTypes.string,
};

export default RegularisationChargePopup;
