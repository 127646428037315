import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import FilterVintage from "@mui/icons-material/FilterVintage";
import PanTool from "@mui/icons-material/PanTool";
import Assignment from "@mui/icons-material/Assignment";
import Contacts from "@mui/icons-material/Contacts";
import Home from "@mui/icons-material/Home";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import ConfigLogementFinanceur from "components/ConfigComponents/ConfigLogementFinanceur";
import ConfigLogementConventionnement from "components/ConfigComponents/ConfigLogementConventionnement";

// context
import { withUserContext } from "context/UserContext";

class LogementConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/logements";
    this.LABEL = "logement(s)";

    this.state = {};
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<PanTool />}
            title="Raison de refus"
            backendUrl="/config-raison-refus"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<FilterVintage />}
            title="Etat du logement"
            backendUrl="/config-etats-logements"
            label={this.LABEL}
          />
          <ConfigLogementFinanceur />
          <ConfigContainer
            icon={<Home />}
            title="Secteur Géographique"
            backendUrl="/config-logement-secteur"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Assignment />}
            title="Convention"
            backendUrl="config-conventions-logements"
          />
          <ConfigLogementConventionnement />
          <ConfigContainer
            icon={<Contacts />}
            title="Origine contact"
            backendUrl="/config-origine-contact-logements"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigLogement"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
        {this.state.alert}
      </Grid>
    );
  }
}

LogementConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LogementConfig);
