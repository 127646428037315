export default class LogementUtils {
  static STATUT = [
    { name: "Prospection", roles: ["prospection"] },
    { name: "Vacant" },
    { name: "Actif", roles: ["chefgla", "gestionlocative"] },
    { name: "Terminé" },
    { name: "Refusé" },
    { name: "Hors Gestion Immo" },
  ];

  static getInitFilter = (user) => {
    for (const statut of this.STATUT) {
      if (statut.roles && user.isOne(statut.roles)) {
        return [statut.name];
      }
    }
    return [];
  };

  static TYPE = ["Logement", "Garage"];
  static RESULTAT = ["Refusé", "Signé"];
  static SDB = ["Douche", "Baignoire"];
  static STATIONNEMENT = ["Sans", "Parking", "Garage", "Parking et Garage"];
}
