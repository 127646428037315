import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui/icons-material
import ExpandMore from "@mui/icons-material/ExpandMore";

class ShowMoreList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  toggleActive = () => {
    this.setState((prevState) => ({ ...prevState, active: !prevState.active }));
  };

  render() {
    const { data, alwaysDisplay, renderItem, compareFunction } = this.props;
    const { active } = this.state;
    const iconSx = {
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    };
    if (active) {
      iconSx.transform = "rotate(180deg)";
    }

    const sortedData =
      compareFunction != null ? data.sort(compareFunction) : data;

    let alwaysDisplayData = sortedData.filter(
      (item, key) => key < alwaysDisplay,
    );
    let showMoreData = sortedData.filter((item, key) => key >= alwaysDisplay);

    return (
      <Box>
        <Box>{alwaysDisplayData.map((item) => renderItem(item))}</Box>
        {showMoreData.length > 0 && (
          <>
            <Collapse in={active} timeout="auto" unmountOnExit>
              <Box>{showMoreData.map((item) => renderItem(item))}</Box>
            </Collapse>
            <Box sx={{ textAlign: "center" }}>
              <IconButton sx={iconSx} size="small" onClick={this.toggleActive}>
                <ExpandMore />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

ShowMoreList.defaultProps = {
  alwaysDisplay: 2,
  renderItem: (item) => item,
};

ShowMoreList.propTypes = {
  alwaysDisplay: PropTypes.any,
  data: PropTypes.array,
  compareFunction: PropTypes.func,
  renderItem: PropTypes.func,
};

export default ShowMoreList;
