import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import EmojiPeople from "@mui/icons-material/EmojiPeople";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";

import { withUserContext } from "context/UserContext";

class AccompagnementsForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/accompagnements";
    this.FRONT_URL = "/tiers/accompagnements";

    this.state = {
      // DATA FORM
      nom: "",
      // END DATA FORM
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom: this.state.nom,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, errors, nom, loading } = this.state;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <EmojiPeople />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {id ? (
                    <>Modifier l&apos;accompagnement ({id})</>
                  ) : (
                    <>Ajouter un accompagnement</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={loading}
            isEdit={Boolean(id)}
          />
        </Grid>
      </Grid>
    );
  }
}

AccompagnementsForm.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AccompagnementsForm);
