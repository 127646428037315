import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui/material components
import InputAdornment from "@mui/material/InputAdornment";
import WarningTextField from "./WarningTextField";

const CurrencyInput = ({
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  name,
  onChange,
  InputLabelProps,
  InputProps,
  value,
  ...rest
}) => {
  const handleChangeNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/,/g, ".");
    event.target.value = val;
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <WarningTextField
      {...rest}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      onChange={handleChangeNumber}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
        ...InputProps,
      }}
      value={value ?? ""}
    />
  );
};

CurrencyInput.propTypes = {
  fullWidth: PropTypes.any,
  margin: PropTypes.any,
  name: PropTypes.any,
  variant: PropTypes.any,
  onChange: PropTypes.func,
  InputLabelProps: PropTypes.any,
  InputProps: PropTypes.any,
  value: PropTypes.any,
};

export default memo(CurrencyInput);
