import React, { Component } from "react";
import PropTypes from "prop-types";

import EmailSender from "components/EmailSender/EmailSender";

class Courriel extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {}

  render() {
    return <EmailSender section={this.props.section} />;
  }
}

Courriel.propTypes = {
  section: PropTypes.any,
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
};

export default Courriel;
