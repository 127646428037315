import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

// @mui/material components
import styled from "@mui/system/styled";
import Box from "@mui/material/Box";

// @mui/icons-material
import OpenInNew from "@mui/icons-material/OpenInNew";

// core components
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";

const BoxFull = styled(Box)({
  height: "calc(100vh - 70px)",
  overflow: "hidden",
  backgroundColor: "white",
});

class EmbeddedDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iframeUrl: "",
      ressourceUrl: "",
    };
  }

  loadAsyncData() {
    axiosApiBackend
      .get("/embedded-dashboard/" + this.props.backUrl)
      .then((result) => {
        this.setState({
          iframeUrl: result.data.url,
          ressourceUrl: result.data.ressourceUrl,
        });
      });
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  render() {
    const { iframeUrl, ressourceUrl } = this.state;

    return (
      <BoxFull>
        <iframe
          id="my-iframe"
          src={iframeUrl}
          style={{ border: "0" }}
          width="100%"
          height="100%"
        />
        <MenuBottom>
          <a target="_blank" rel="noopener noreferrer" href={ressourceUrl}>
            <Button size="small" square round>
              <OpenInNew />
            </Button>
          </a>
        </MenuBottom>
      </BoxFull>
    );
  }
}

EmbeddedDashboard.propTypes = {
  backUrl: PropTypes.string.isRequired,
};

export default EmbeddedDashboard;
