import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Paper from "@mui/material/Paper";
import styled from "@mui/styles/styled";

const BorderedPaper = styled(Paper)(({ theme }) => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "rgba(58, 53, 65, 0.12)",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

function PaperList(props) {
  const { datas, buildBody, ...rest } = props;

  return (
    <>
      {datas.map((data, key) => (
        <BorderedPaper key={key} elevation={0} {...rest}>
          {buildBody(data, key)}
        </BorderedPaper>
      ))}
    </>
  );
}

PaperList.propTypes = {
  datas: PropTypes.array,
  buildBody: PropTypes.func.isRequired,
};

export default PaperList;
