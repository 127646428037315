import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import AttachMoney from "@mui/icons-material/AttachMoney";

import AlertDialog from "components/AlertDialog/AlertDialog";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import DateUtils from "Utils/DateUtils";
import FileUtils from "Utils/FileUtils";

class GenerateFileWithDatesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      date_debut: "",
      date_fin: DateUtils.toDBString(new Date()),
      // end form
      open: false,
      loading: false,
      dropDownList: [
        { child: "1 mois", value: "1" },
        { child: "2 mois", value: "2" },
        { child: "3 mois", value: "3" },
        { child: "4 mois", value: "4" },
        { child: "5 mois", value: "5" },
        { child: "6 mois", value: "6" },
        { child: "7 mois", value: "7" },
        { child: "8 mois", value: "8" },
        { child: "9 mois", value: "9" },
        { child: "10 mois", value: "10" },
        { child: "11 mois", value: "11" },
        { divider: true },
        { child: "1 an", value: "12" },
        { child: "2 ans", value: "24" },
        { child: "3 ans", value: "36" },
        { divider: true },
        { child: "Choisir une période", value: "XXX" },
      ],
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isNullOrEmpty = (val) => {
    return val == null || val === "";
  };

  getHistorique = (dateStart, dateEnd) => {
    const { baseUrl, documentUrl, idContrat } = this.props;
    if (
      !this.isNullOrEmpty(idContrat) &&
      !this.isNullOrEmpty(dateStart) &&
      !this.isNullOrEmpty(dateEnd)
    ) {
      this.setState({ loading: true });

      const url = `${baseUrl}/${idContrat}/documents/${documentUrl}/${dateStart}/${dateEnd}`;
      FileUtils.downLoadFromUrl(
        axiosApiBackendNoJson,
        url,
        { responseType: "arraybuffer" },
        "historique-comptable.pdf",
        this.clearAlert,
      );
    }
  };

  handleItem = (event) => {
    let monthVal = event.target.dataset.value;
    if (monthVal === "XXX") {
      this.setState({ open: true });
    } else {
      const month = monthVal ? parseInt(monthVal) : 1;
      let date = new Date();
      const dateEnd = DateUtils.toDBString(date);
      date = DateUtils.addMonths(date, -month);
      const dateStart = DateUtils.toDBString(date);

      this.getHistorique(dateStart, dateEnd);
    }
  };

  handleAlert = () => {
    const dateStart = this.state.date_debut;
    const dateEnd = this.state.date_fin;

    this.getHistorique(dateStart, dateEnd);
  };

  clearAlert = () => {
    this.setState({ open: false, loading: false });
  };

  render() {
    const { buttonText, tooltipText } = this.props;
    const { open, dropDownList, loading, errors } = this.state;

    return (
      <>
        <CustomDropdown
          dropup
          tooltipText={tooltipText}
          buttonText={buttonText}
          buttonProps={{
            size: "small",
            round: true,
            color: "primary",
          }}
          buttonIcon={AttachMoney}
          onItemClick={this.handleItem}
          dropdownList={dropDownList}
        />
        <AlertDialog
          open={open}
          loading={loading}
          title="Choisir une période"
          onConfirm={this.handleAlert}
          confirmLabel="Exporter"
          confirmColor="primary"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="gray"
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Date de début"
                name="date_debut"
                type="date"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.date_debut}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.date_debut)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Date de fin"
                name="date_fin"
                type="date"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.date_fin}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.date_fin)}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

GenerateFileWithDatesDropdown.defaultProps = {};

GenerateFileWithDatesDropdown.propTypes = {
  buttonText: PropTypes.any,
  tooltipText: PropTypes.any,
  baseUrl: PropTypes.any.isRequired,
  documentUrl: PropTypes.any.isRequired,
  idContrat: PropTypes.any,
};

export default GenerateFileWithDatesDropdown;
