import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = () => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
});

class CircularLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.props.display && (
          <div className={classes.container}>
            <CircularProgress />
          </div>
        )}
      </>
    );
  }
}

CircularLoading.propTypes = {
  display: PropTypes.bool.isRequired,
  classes: PropTypes.any,
};

export default withStyles(useStyles)(CircularLoading);
