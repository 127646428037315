import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import SettingsBackupRestore from "@mui/icons-material/SettingsBackupRestore";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";
import Button from "components/CustomButtons/Button";
import SelectSearch from "components/CustomSelect/SelectSearch";
import SearchBar from "components/SearchBar/SearchBar";

import SearchBarService from "services/SearchBarService";

import QueryUtils from "Utils/QueryUtils";

class TablePopUpContainer extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = props.backendUrl;
    this.FRONT_URL = props.frontUrl;
    this.getRowStyle = (params) => {
      if (params.data.deleted_at) {
        return { "background-color": "#d1d1d1" };
      }
      return null;
    };
    this.SEARCH_BAR_SERVICE = new SearchBarService(
      this.FRONT_URL + "/" + props.searchStorageKey,
    );

    let buttonsColumnDef = {
      headerName: "",
      field: "actions",
      type: "rightAligned",
      filter: false,
      editable: false,
      sortable: false,
      autoHeight: true,
      cellRenderer: ButtonRenderer,
      cellRendererParams: (params) => {
        if (!params.data.deleted_at) {
          return {
            buttons: [
              ...(props?.customButtons ? props.customButtons(params.data) : []),
              {
                label: <Edit />,
                color: "primary",
                square: true,
                hide:
                  this.props.hideEditButton ||
                  (this.props.hideButton
                    ? this.props.hideButton(params.data)
                    : ""),
                onClick: () => {
                  this.onClickEditButton(params);
                },
              },
              {
                label: <Delete />,
                color: "error",
                square: true,
                hide:
                  this.props.hideDeleteButton ||
                  (this.props.hideButton
                    ? this.props.hideButton(params.data)
                    : ""),
                onClick: () => {
                  this.onClickDeleteButton(params);
                },
              },
            ],
          };
        } else {
          return {
            buttons: [
              {
                label: <SettingsBackupRestore />,
                color: "success",
                square: true,
                hide: this.props.hideButton
                  ? this.props.hideButton(params.data)
                  : "",
                onClick: () => {
                  this.onClickEditButton(params);
                },
              },
            ],
          };
        }
      },
    };

    if (props.hasGroupHeader) {
      buttonsColumnDef = {
        headerName: "",
        children: [buttonsColumnDef],
      };
    }

    this.gridApi = null;
    this.gridColumnApi = null;
    this.state = {
      etatFilter: props.etatFilter,
      select_data: props.searchSelect?.defaultValue ?? "",
      alert: null,
      snackbar: null,
      openModal: false,
      loading: false,
      openDeleteModal: false,
      paramsDeleteModal: null,
      data: null,
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: props.filterColumns,
        resizable: props.resizable,
      },
      columnDefs: [...props.colDef, buttonsColumnDef],
      rowClassRules: props.rowClassRules,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  shouldComponentUpdate() {
    if (this.gridApi && this.props.forceRefreshCells) {
      this.gridApi.refreshCells();
    }

    return true;
  }

  loadAsyncData() {
    this.setState({
      loading: true,
      openModal: false,
    });

    this.props.formSetErrors(null);
    const selectName = this.props.searchSelect?.name ?? null;

    let params = {
      etat: this.state.etatFilter,
      ...this.props.getParams,
    };

    if (selectName !== null) {
      params[selectName] = this.state.select_data;
    }

    axiosApiBackend.get(this.BACKEND_URL, { params }).then((result) => {
      this.toggleOverlay(false);
      if (this.props.onFetchData) {
        this.props.onFetchData(result.data);
      }

      if (this.props.forceLoadAsyncRender) {
        this.setState({ data: [] });
      }

      this.setState({ data: result.data, loading: false }, () => {
        this.autoSizeAll();
      });
    });
  }

  toggleOverlay = (show) => {
    if (this.gridApi !== null) {
      show ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null && this.props.autoSize) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };
  onChangeFilter = (event) => {
    const name = event.target.name;
    const val = event.target.value;

    QueryUtils.replaceSearchParams({ [name]: val });
    this.setState({ [name + "Filter"]: val }, this.loadAsyncData);
  };

  onChangeSelect = (name, value) => {
    this.setState({ select_data: value }, this.loadAsyncData);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  onClickDeleteButton = (params) => {
    this.setState({ openDeleteModal: true, paramsDeleteModal: params });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  closeDeleteModal = () => {
    const { onCloseDeleteModal } = this.props;

    this.setState({
      openDeleteModal: false,
      paramsDeleteModal: null,
    });

    if (onCloseDeleteModal) {
      onCloseDeleteModal();
    }
  };

  onClickEditButton = (params) => {
    this.props.formSetData(params.data);
    this.setState({ openModal: true });
  };

  onClickAddButton = () => {
    this.props.formInitData();
    this.setState({ openModal: true });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeData = () => {
    if (this.props.onChangeData) {
      this.props.onChangeData();
    }
  };

  handleAlert = () => {
    this.autoSizeAll();
    this.setState({
      loading: true,
    });
    this.toggleOverlay(true);
    const data = this.props.formGetData();
    if (data.id && !data.deleted_at) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + data.id, data)
        .then((res) => {
          this.props.formSetErrors(res);
          this.loadAsyncData();
          this.onChangeData();
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else if (data.id && data.deleted_at) {
      // restore
      axiosApiBackend
        .post(this.BACKEND_URL + "/restore/" + data.id)
        .then((res) => {
          this.props.formSetErrors(res);
          this.loadAsyncData();
          this.onChangeData();
          this.setState({
            snackbar: true,
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, data)
        .then((res) => {
          this.props.formSetErrors(res);
          this.loadAsyncData();
          this.onChangeData();
          this.setState({
            snackbar: true,
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    }
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };
  handleError = (error) => {
    let err = error.response?.data?.errors ?? null;
    let status = error.response?.status ?? null;
    this.setState({
      loading: false,
    });
    this.props.formSetErrors(err, status);
    this.toggleOverlay(false);
  };

  handleDelete = (params) => {
    this.setState({
      loading: true,
    });
    this.toggleOverlay(true);
    this.clearAlert();
    const data = params.data;

    // delete
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + data.id, {
        params: this.props.deleteParams,
      })
      .then(() => {
        this.loadAsyncData();
        this.onChangeData();
      })
      .catch((err) => {
        let errorContent = "Erreur";

        if (err.response?.status === 428) {
          const error = err.response?.data;
          errorContent = this.props.getDeleteError(params.data, error);
        }
        this.setState({
          loading: false,
          alert: (
            <AlertDialog
              title={this.props.dialogDeleteTitleFail}
              content={errorContent}
              onCancel={this.clearAlert}
              cancelLabel="Ok"
              cancelColor="info"
            />
          ),
        });
        this.toggleOverlay(false);
      })
      .finally(() => {
        this.closeDeleteModal();
      });
  };

  render() {
    const { openDeleteModal, paramsDeleteModal } = this.state;
    const {
      icon,
      iconColor,
      title,
      searchBar,
      filter,
      labelFilter,
      searchSelect,
      dialogProps,
      hideAddButton,
      dialogDeleteTitle,
      getDeleteContent,
      getDeleteFormContent,
    } = this.props;

    return (
      <Card>
        <CardHeader
          avatar={<CardAvatar sx={{ bgcolor: iconColor }}>{icon}</CardAvatar>}
          title={
            <Typography variant="h6" component="h5">
              {title}
            </Typography>
          }
        />

        <CardContent>
          <Grid container columnSpacing={2} alignItems="center">
            {searchBar && (
              <Grid item>
                <SearchBar
                  label="Rechercher"
                  name="quickFilterText"
                  value={this.state.quickFilterText}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  resetSearchValue={this.resetSearchValue}
                />
              </Grid>
            )}

            {searchSelect && (
              <Grid item sx={{ width: 300 }}>
                <SelectSearch
                  name={searchSelect?.name ?? "search_select"}
                  label={searchSelect.label}
                  apiUrl={searchSelect.apiUrl}
                  onChange={this.onChangeSelect}
                  value={this.state.select_data ?? ""}
                  buildOptionValue={searchSelect.buildOptionValue}
                  buildOptionLabel={searchSelect.buildOptionLabel}
                />
              </Grid>
            )}

            {filter && (
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="Active Filter"
                    name="etat"
                    value={this.state.etatFilter}
                    onChange={this.onChangeFilter}
                  >
                    {labelFilter.map((filtre, key) => (
                      <FormControlLabel
                        key={key}
                        value={filtre.value}
                        control={<Radio color="primary" />}
                        label={filtre.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} className="ag-theme-material">
              <AgGridReact
                getRowStyle={this.getRowStyle}
                overlayNoRowsTemplate="Aucune donnée à afficher."
                enableCellTextSelection={true}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.data}
                onGridReady={this.onGridReady}
                domLayout="autoHeight"
                rowClassRules={this.state.rowClassRules}
                rowSelection="multiple"
                onPaginationChanged={this.autoSizeAll}
                pagination={this.props.pagination}
                paginationPageSize={this.props.paginationPageSize}
                localeText={AG_GRID_LOCALE}
                quickFilterText={this.state.quickFilterText}
                rowHeight={38} // un truc avec qui bug --ag-internal-padded-row-height
              />
            </Grid>
            {!hideAddButton && (
              <Grid item xs={12}>
                <Button
                  size="sm"
                  color="success"
                  onClick={this.onClickAddButton}
                >
                  Ajouter
                </Button>
              </Grid>
            )}
          </Grid>
          <AlertDialog
            open={this.state.openModal}
            title={this.props.formTitle}
            loading={this.state.loading}
            onConfirm={this.handleAlert}
            confirmLabel={this.props.formConfirmLabel}
            confirmColor="success"
            onCancel={this.closeModal}
            cancelLabel="Annuler"
            cancelColor="primary"
            {...dialogProps}
          >
            {this.props.formContent}
          </AlertDialog>
          {this.state.alert}
          {paramsDeleteModal && (
            <AlertDialog
              open={openDeleteModal}
              title={dialogDeleteTitle}
              content={
                getDeleteContent && getDeleteContent(paramsDeleteModal.data)
              }
              onConfirm={() => this.handleDelete(paramsDeleteModal)}
              confirmLabel="Supprimer"
              confirmColor="error"
              onCancel={this.closeDeleteModal}
              cancelLabel="Annuler"
              cancelColor="primary"
              loading={this.state.loading}
            >
              {getDeleteFormContent &&
                getDeleteFormContent(paramsDeleteModal.data)}
            </AlertDialog>
          )}
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={6000}
            onClose={this.closeSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={this.closeSnackbar} severity="success">
              Enregistré avec succès
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    );
  }
}

TablePopUpContainer.defaultProps = {
  autoSize: true,
  pagination: false,
  paginationPageSize: 10,
  filterColumns: false,
  searchStorageKey: "",
  resizable: false,
  iconColor: "config.main",
  dialogDeleteTitle: "Voulez vous supprimer ce champ ?",
  dialogDeleteTitleFail: "Vous ne pouvez pas supprimer ce champ.",
};

TablePopUpContainer.propTypes = {
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  backendUrl: PropTypes.string,
  frontUrl: PropTypes.string,
  label: PropTypes.string,
  hasGroupHeader: PropTypes.bool,
  colDef: PropTypes.any,
  getDeleteContent: PropTypes.func,
  getDeleteFormContent: PropTypes.func,
  getDeleteError: PropTypes.func,
  formGetResponse: PropTypes.func,
  formInitData: PropTypes.func,
  formSetData: PropTypes.func,
  formGetData: PropTypes.func,
  formTitle: PropTypes.any,
  dialogDeleteTitle: PropTypes.string,
  dialogDeleteTitleFail: PropTypes.string,
  formConfirmLabel: PropTypes.any,
  formContent: PropTypes.any,
  formSetErrors: PropTypes.func,
  forceLoadAsyncRender: PropTypes.bool,
  location: PropTypes.any,
  filter: PropTypes.bool,
  etatFilter: PropTypes.any,
  searchBar: PropTypes.bool,
  searchSelect: PropTypes.any,
  searchStorageKey: PropTypes.string,
  labelFilter: PropTypes.array,
  dialogProps: PropTypes.object,
  autoSize: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  filterColumns: PropTypes.bool,
  hideButton: PropTypes.any,
  hideAddButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  resizable: PropTypes.bool,
  getParams: PropTypes.object,
  deleteParams: PropTypes.object,
  rowClassRules: PropTypes.any,
  onFetchData: PropTypes.func,
  onChangeData: PropTypes.func,
  forceRefreshCells: PropTypes.bool,
  customButtons: PropTypes.func,
  onCloseDeleteModal: PropTypes.func,
  onGridReady: PropTypes.func,
};

export default TablePopUpContainer;
