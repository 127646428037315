import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Rating from "@mui/material/Rating";

import Handyman from "@mui/icons-material/Handyman";

import Button from "components/Button/Button.jsx";
import StarsRatingRender from "components/CustomAgEditor/StarsRatingRender";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

import Render from "Utils/RenderUtils";

import { withUiCustomizationContext } from "context/UiCustomizationContext";

class DevisListForm extends Component {
  constructor(props) {
    super(props);

    this.BACKEND_URL = "/devis";
    this.NO_YES = ["Non", "Oui"];
    this.ENTREPRISE_URL = "/entreprises/" + props.section + "/detail";
    const uiCustomization = props.uiCustomization;

    this.state = {
      id: null,
      devisable_id: props.modelId,
      devisable_type: props.modelType,
      entreprise_id: "",
      tel: "",
      email: "",
      reception: "",
      montant_ttc: "",
      montant_ht: "",
      montant_facture_ht: "",
      montant_facture_ttc: "",
      description: "",
      valide: "",
      note: null,
      date_facture: "",
      errors: null,
      isCreating: false,
      dep_code: uiCustomization?.dep_code ?? "",
      commune_siege_id: "",
      nom: "",
      siret: "",
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleEntreprise = () => {
    this.setState((prevState) => ({
      isCreating: !prevState.isCreating,
    }));
  };

  handleChangeDepartement = (name, value) => {
    this.setState({ [name]: value, commune_siege_id: "" });
  };

  render() {
    const { section, canEdit, title, onUpdate } = this.props;
    const {
      id,
      devisable_id,
      devisable_type,
      entreprise_id,
      tel,
      email,
      reception,
      montant_ttc,
      montant_ht,
      montant_facture_ht,
      montant_facture_ttc,
      description,
      valide,
      note,
      date_facture,
      errors,
      isCreating,
      nom,
      siret,
      commune_siege_id,
    } = this.state;

    return (
      <TablePopUpContainer
        icon={<Handyman />}
        iconColor="primary"
        dialogProps={{
          fullWidth: true,
        }}
        title={title}
        backendUrl={this.BACKEND_URL}
        getParams={{
          devisable_id: devisable_id,
          devisable_type: devisable_type,
        }}
        autoSize={false}
        hideAddButton={!canEdit}
        hideEditButton={!canEdit}
        hideDeleteButton={!canEdit}
        resizable
        rowClassRules={{
          pale_green_bg: (params) => Boolean(params.data.valide),
        }}
        hasGroupHeader
        colDef={[
          {
            headerName: "Entreprise",
            children: [
              {
                headerName: "Nom",
                field: "entreprise_id",
                cellRenderer: (params) => {
                  if (!params.data.entreprise_id) {
                    return null;
                  }
                  return (
                    <Link
                      to={`${this.ENTREPRISE_URL}/${params.data.entreprise_id}`}
                    >
                      {params.data.entreprise?.nom}
                    </Link>
                  );
                },
              },

              {
                headerName: "Téléphone",
                field: "entreprise.tel",
                valueFormatter: (params) => Render.shortTelephone(params.value),
              },
              {
                headerName: "Email",
                field: "entreprise.email",
              },
            ],
          },
          {
            headerName: "Devis",
            children: [
              {
                headerName: "Date",
                field: "reception",
                valueFormatter: (params) => Render.date(params.value),
              },
              {
                headerName: "Montant HT",
                field: "montant_ht",
                valueFormatter: (params) => Render.euro(params.value),
              },
              {
                headerName: "Montant TTC",
                field: "montant_ttc",
                valueFormatter: (params) => Render.euro(params.value),
              },
              {
                headerName: "Validé",
                field: "valide",
                valueFormatter: (params) => this.NO_YES[params.value] ?? "",
              },
            ],
          },
          {
            headerName: "Facture",
            children: [
              {
                headerName: "Date",
                field: "date_facture",
                valueFormatter: (params) => Render.date(params.value),
              },
              {
                headerName: "Montant HT",
                field: "montant_facture_ht",
                valueFormatter: (params) => Render.euro(params.value),
              },
              {
                headerName: "Montant TTC",
                field: "montant_facture_ttc",
                valueFormatter: (params) => Render.euro(params.value),
              },
              {
                headerName: "Description",
                field: "description",
              },
            ],
          },
          {
            headerName: "Évaluation",
            children: [
              {
                headerName: "Note",
                field: "note",
                minWidth: 100,
                cellRenderer: StarsRatingRender,
                cellRendererParams: {
                  readOnly: true,
                  precision: 0.5,
                  fontSize: "13px",
                  size: "small",
                },
              },
            ],
          },
        ]}
        onChangeData={onUpdate}
        formInitData={() => {
          this.setState({
            id: null,
            entreprise_id: "",
            tel: "",
            email: "",
            reception: "",
            montant_ttc: "",
            montant_ht: "",
            montant_facture_ht: "",
            montant_facture_ttc: "",
            description: "",
            valide: "",
            note: null,
            date_facture: "",
            errors: null,
            isCreating: false,
            nom: "",
            siret: "",
            commune_siege_id: "",
          });
        }}
        formSetData={(data) => {
          this.setState({
            id: data.id ?? null,
            entreprise_id: data.entreprise_id ?? "",
            tel: data.tel ?? "",
            email: data.email ?? "",
            reception: data.reception ?? "",
            montant_ttc: data.montant_ttc ?? "",
            montant_ht: data.montant_ht ?? "",
            montant_facture_ht: data.montant_facture_ht ?? "",
            montant_facture_ttc: data.montant_facture_ttc ?? "",
            description: data.description ?? "",
            valide: data.valide ?? "",
            note: data.note ?? null,
            date_facture: data.date_facture ?? "",
            errors: null,
            isCreating: false,
            nom: data.nom ?? "",
            siret: data.nom ?? "",
            commune_siege_id: data.commune_siege_id ?? "",
          });
        }}
        formGetData={() => {
          const formData = {
            id: id,
            devisable_id: devisable_id,
            devisable_type: devisable_type,
            tel: tel,
            email: email,
            reception: reception,
            montant_ttc: montant_ttc,
            montant_ht: montant_ht,
            montant_facture_ht: montant_facture_ht,
            montant_facture_ttc: montant_facture_ttc,
            description: description,
            valide: valide,
            note: valide ? note : null,
            date_facture: date_facture,
          };

          if (isCreating) {
            formData.nom = nom;
            formData.siret = siret;
            formData.commune_siege_id = commune_siege_id;
          } else {
            formData.entreprise_id = entreprise_id;
          }

          return formData;
        }}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        getDeleteContent={(data) =>
          data?.entreprise?.nom
            ? data?.entreprise?.nom + " : " + Render.euro(data.montant_ttc)
            : Render.euro(data.montant_ttc)
        }
        dialogDeleteTitle="Voulez vous supprimer ce devis ?"
        dialogDeleteTitleFail="Vous ne pouvez pas supprimer ce devis."
        formTitle={
          <Grid
            container
            columnSpacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>{id ? "Modifier le devis" : "Ajouter un devis"}</Grid>
            <Grid item>
              {!id && (
                <Button size="small" onClick={this.handleEntreprise}>
                  {isCreating
                    ? "Chercher une entreprise"
                    : "Créer une entreprise"}
                </Button>
              )}
            </Grid>
          </Grid>
        }
        formConfirmLabel={id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <HeaderTitle
                label={
                  isCreating
                    ? "Créer une entreprise"
                    : "Séléctionner une entreprise"
                }
              />
            </Grid>
            {!isCreating && (
              <Grid item xs={12}>
                <SelectSearch
                  name="entreprise_id"
                  label="Entreprise"
                  margin="normal"
                  apiUrl={"/entreprises?section=" + section}
                  onChange={this.handleChangeByName}
                  buildOptionLabel={(data) => data?.nom ?? ""}
                  value={entreprise_id}
                  error={Boolean(errors?.entreprise_id)}
                  shrink
                />
              </Grid>
            )}
            {isCreating && (
              <Grid item xs={12} lg={6}>
                <TextInput
                  label="Nom de l'entreprise"
                  name="nom"
                  value={nom}
                  onChange={this.handleChange}
                  error={Boolean(errors?.nom)}
                />
              </Grid>
            )}
            {isCreating && (
              <Grid item xs={12} lg={6}>
                <TextInput
                  label="SIRET de l'entreprise"
                  name="siret"
                  value={siret}
                  onChange={this.handleChange}
                  error={Boolean(errors?.siret)}
                />
              </Grid>
            )}
            {isCreating && (
              <Grid item xs={12} lg={6}>
                <SelectSearch
                  name="dep_code"
                  label="Département du siège social"
                  apiUrl="/departements"
                  onChange={this.handleChangeDepartement}
                  value={this.state.dep_code}
                  buildOptionValue={(data) => data.code}
                  buildOptionLabel={(data) => data.code + " " + data.nom}
                  shrink
                  error={Boolean(errors?.commune_siege_id)}
                />
              </Grid>
            )}
            {isCreating && (
              <Grid item xs={12} lg={6}>
                <SelectSearch
                  name="commune_siege_id"
                  label={
                    "Commune du siège social" +
                    (!this.state.dep_code
                      ? " - Choisissez un département..."
                      : "")
                  }
                  loadOnMount={Boolean(this.state.dep_code)}
                  apiUrl="/villes"
                  filterKey="departement"
                  filter={this.state.dep_code}
                  onChange={this.handleChangeByName}
                  value={this.state.commune_siege_id}
                  buildOptionLabel={(data) => data.cp + " " + data.ville}
                  shrink
                  error={Boolean(errors?.commune_siege_id)}
                  disabled={!this.state.dep_code}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <HeaderTitle label="Devis" />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                type="date"
                label="Date de réception"
                name="reception"
                value={reception}
                onChange={this.handleChange}
                error={Boolean(errors?.reception)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CurrencyInput
                label="Montant HT"
                name="montant_ht"
                value={montant_ht}
                onChange={this.handleChange}
                error={Boolean(errors?.montant_ht)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CurrencyInput
                label="Montant TTC"
                name="montant_ttc"
                value={montant_ttc}
                onChange={this.handleChange}
                error={Boolean(errors?.montant_ttc)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Validé"
                name="valide"
                onChange={this.handleChange}
                value={valide}
                shrink
                error={Boolean(errors?.valide)}
              >
                <MenuItem value="">
                  <em>Non renseigné</em>
                </MenuItem>
                <MenuItem value={0}>Non</MenuItem>
                <MenuItem value={1}>Oui</MenuItem>
              </SelectInput>
            </Grid>

            <Grid item xs={12}>
              <HeaderTitle label="Facture" />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                type="date"
                label="Date de facturation"
                name="date_facture"
                value={date_facture}
                onChange={this.handleChange}
                error={Boolean(errors?.date_facture)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CurrencyInput
                label="Montant HT"
                name="montant_facture_ht"
                value={montant_facture_ht}
                onChange={this.handleChange}
                error={Boolean(errors?.montant_facture_ht)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CurrencyInput
                label="Montant TTC"
                name="montant_facture_ttc"
                value={montant_facture_ttc}
                onChange={this.handleChange}
                error={Boolean(errors?.montant_facture_ttc)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Description"
                name="description"
                multiline
                value={description}
                onChange={this.handleChange}
                error={Boolean(errors?.description)}
              />
            </Grid>

            {Boolean(valide) && (
              <>
                <Grid item xs={12}>
                  <HeaderTitle label="Évaluation" />
                </Grid>

                <Grid item xs={12} container justifyContent="center">
                  <Rating
                    sx={{
                      "& > span > label": { fontSize: "xx-large" },
                    }}
                    name="simple-controlled"
                    value={note}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      this.setState({ note: newValue });
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    );
  }
}

DevisListForm.propTypes = {
  modelId: PropTypes.any.isRequired,
  modelType: PropTypes.any.isRequired,
  section: PropTypes.oneOf(["immobilier", "travaux"]).isRequired,
  onUpdate: PropTypes.func,
  canEdit: PropTypes.any,
  title: PropTypes.string.isRequired,
  uiCustomization: PropTypes.object,
};

export default withUiCustomizationContext(DevisListForm);
