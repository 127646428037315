import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Euro from "@mui/icons-material/Euro";
import HowToReg from "@mui/icons-material/HowToReg";
import WarningRounded from "@mui/icons-material/WarningRounded";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormCollection from "components/Form/FormCollection";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import PhoneInput from "components/PhoneInput/PhoneInput";
import TextIcon from "components/TextIcon/TextIcon";

import CPUtils from "Utils/CPUtils";

import AdresseForm from "./Adresse/AdresseForm";

class ProprietairesForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/proprietaires";
    this.FRONT_URL = "/tiers/proprietaires";
    var currentYear = new Date().getFullYear();
    this.years = new Array(10)
      .fill(currentYear)
      .map((val, index) => val + index);

    this.state = {
      // NESTED DATA
      adresse: null,
      adhesion_cp: null,
      // DATA FORM
      type_id: "",
      societe: "",
      nom: "",
      prenom: "",
      contact: "",
      contact_2: "",
      telephone: "",
      telephone_2: "",
      email: "",
      email_2: "",
      profession: "",
      profession_2: "",
      estime_parc_id: "",
      usufruitier: "",
      infos: "",
      date_naissance: "",
      lieu_naissance: "",
      nationalite: "",
      num_adherent: "",
      adhesion_cp_id: "",
      adhesion_auto_renew: 1,
      adhesions: [],
      current_year: currentYear,
      // END DATA FORM
      config_adhesion_annuelle: "",
      config_adhesion_mensualite_max: "",
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
    this.getAdhesionConfigData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        this.setState(result.data ?? {});
      });
  }

  event = (handleChangeFn) => {
    return (event) => handleChangeFn(event.target.name, event.target.value);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  getAdhesionConfigData = () => {
    axiosApiBackend
      .get("/proprietaires/adhesion-config-data")
      .then((result) => {
        this.setState({
          config_adhesion_annuelle: result.data?.adhesion_annuelle ?? "",
          config_adhesion_mensualite_max:
            result.data?.adhesion_mensualite_max ?? "",
        });
      });
  };

  getDefaultAdhesion = () => {
    return {
      id: "",
      adhesion_annuelle: this.state.config_adhesion_annuelle ?? "",
      adhesion_mensualite_max: this.state.config_adhesion_mensualite_max ?? "",
      year: this.state.current_year ?? "",
    };
  };

  handleAddAdhesion = () => {
    this.setState((prevState) => {
      let adhesions = prevState.adhesions;
      adhesions.push(this.getDefaultAdhesion());
      return { adhesions: adhesions };
    });
  };

  handleChangeAdhesion = (key, name, value) => {
    this.setState((prevState) => {
      let adhesions = prevState.adhesions;
      adhesions[key][name] = value;
      return { adhesions: adhesions };
    });
  };

  handleRemoveAdhesion = (key) => {
    this.setState((prevState) => {
      let adhesions = prevState.adhesions;
      adhesions.splice(key, 1);
      return { adhesions: adhesions };
    });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      type_id: this.state.type_id,
      societe: this.state.societe,
      nom: this.state.nom,
      prenom: this.state.prenom,
      adresse: this.state.adresse,
      contact: this.state.contact,
      contact_2: this.state.contact_2,
      telephone: this.state.telephone,
      telephone_2: this.state.telephone_2,
      email: this.state.email,
      email_2: this.state.email_2,
      profession: this.state.profession,
      profession_2: this.state.profession_2,
      estime_parc_id: this.state.estime_parc_id,
      usufruitier: this.state.usufruitier,
      infos: this.state.infos,
      date_naissance: this.state.date_naissance,
      lieu_naissance: this.state.lieu_naissance,
      nationalite: this.state.nationalite,
      num_adherent: this.state.num_adherent,
      adhesion_cp_id: this.state.adhesion_cp_id,
      adhesion_auto_renew: this.state.adhesion_auto_renew,
      adhesions: this.state.adhesions,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, adresse, errors, adhesions } = this.state;

    const missingAdhesionCp =
      adhesions.length != 0 && !this.state.adhesion_cp_id;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <HowToReg />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le Propriétaire ( {this.state.id} )</>
                  ) : (
                    <>Ajouter un Propriétaire</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Type"
                    name="type_id"
                    apiUrl="/config-type-proprietaires"
                    onChange={this.handleChangeByName}
                    value={this.state.type_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Société"
                    name="societe"
                    value={this.state.societe}
                    onChange={this.handleChange}
                    error={Boolean(errors?.societe)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Prénom"
                    name="prenom"
                    onChange={this.handleChange}
                    value={this.state.prenom}
                    error={Boolean(errors?.prenom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    onChange={this.handleChange}
                    value={this.state.nom}
                    error={Boolean(errors?.nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextInput
                    label="Date de naissance"
                    name="date_naissance"
                    type="date"
                    onChange={this.handleChange}
                    value={this.state.date_naissance}
                    error={Boolean(errors?.date_naissance)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextInput
                    label="Lieu de naissance"
                    name="lieu_naissance"
                    onChange={this.handleChange}
                    value={this.state.lieu_naissance}
                    error={Boolean(errors?.lieu_naissance)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextInput
                    label="Nationalité"
                    name="nationalite"
                    onChange={this.handleChange}
                    value={this.state.nationalite}
                    error={Boolean(errors?.nationalite)}
                  />
                </Grid>
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errors}
                  isEditMode={Boolean(id)}
                  hideRegion
                />
                <Grid item xs={12}>
                  <HeaderTitle label="Coordonnées de Contact" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Contact Info"
                    name="contact"
                    onChange={this.handleChange}
                    value={this.state.contact}
                    error={Boolean(errors?.contact)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Contact Info 2"
                    name="contact_2"
                    onChange={this.handleChange}
                    value={this.state.contact_2}
                    error={Boolean(errors?.contact_2)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="telephone"
                    value={this.state.telephone}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.telephone)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone 2"
                    name="telephone_2"
                    value={this.state.telephone_2}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.telephone_2)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email"
                    name="email"
                    onChange={this.handleChange}
                    value={this.state.email}
                    error={Boolean(errors?.email)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email 2"
                    name="email_2"
                    onChange={this.handleChange}
                    value={this.state.email_2}
                    error={Boolean(errors?.email_2)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Profession"
                    name="profession"
                    onChange={this.handleChange}
                    value={this.state.profession}
                    error={Boolean(errors?.profession)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Profession 2"
                    name="profession_2"
                    onChange={this.handleChange}
                    value={this.state.profession_2}
                    error={Boolean(errors?.profession_2)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectSearch
                    label="Parc de logement éstimé"
                    name="estime_parc_id"
                    apiUrl="/config-estime-parc-proprietaires"
                    onChange={this.handleChangeByName}
                    value={this.state.estime_parc_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.estime_parc_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    label="Usufruitier"
                    name="usufruitier"
                    value={this.state.usufruitier}
                    onChange={this.handleChange}
                    error={Boolean(errors?.usufruitier)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    maxRows="4"
                    onChange={this.handleChange}
                    value={this.state.infos}
                    error={Boolean(errors?.infos)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Adhésion
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={4}>
                  <TextInput
                    label="Numéro adhérent"
                    name="num_adherent"
                    onChange={this.handleChange}
                    value={this.state.num_adherent}
                    error={Boolean(errors?.num_adherent)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SelectSearch
                    label="Choisir un contrat propriétaire à débiter"
                    name="adhesion_cp_id"
                    apiUrl={
                      this.state.id
                        ? "/contrats-proprietaires?proprietaire_id=" +
                          this.state.id
                        : null
                    }
                    value={this.state.adhesion_cp_id}
                    onChange={this.handleChangeByName}
                    // onResponse={this.handleOnResponse}
                    buildOptionLabel={(data) => CPUtils.renderCP(data)}
                    shrink
                    disabled={!this.state.id}
                    error={Boolean(errors?.adhesion_cp_id)}
                    textFieldProps={{
                      helperText: missingAdhesionCp && (
                        <Typography color="warning.main" component="span">
                          <TextIcon Icon={WarningRounded} /> Choisissez le
                          contrat à partir duquel débiter l&apos;adhésion !
                        </Typography>
                      ),
                    }}
                  />
                </Grid>
                {adhesions.length > 0 && (
                  <Grid item xs={12}>
                    <CheckBoxInput
                      label="Renouvellement automatique"
                      name="adhesion_auto_renew"
                      value={this.state.adhesion_auto_renew}
                      onChange={this.handleChangeByName}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormCollection
                    items={adhesions}
                    rowTitle="Adhésion"
                    addRow={this.handleAddAdhesion}
                    removeRow={this.handleRemoveAdhesion}
                    tooltipAdd="Ajouter une adhésion"
                    tooltipDelete="Supprimer l'adhésion"
                    dialogDeleteTitle={(rowNumber) =>
                      "Voulez-vous supprimer l'adhésion n° " + rowNumber + " ?"
                    }
                    formInputs={(adhesion, key) => (
                      <>
                        <Grid item xs={12} lg={4}>
                          <TextInput
                            disabled={adhesion.read_only}
                            label="Montant adhésion annuelle"
                            name="adhesion_annuelle"
                            value={adhesion.adhesion_annuelle}
                            onChange={this.event((name, value) =>
                              this.handleChangeAdhesion(key, name, value),
                            )}
                            error={Boolean(
                              errors?.[
                                "adhesions." + key + ".adhesion_annuelle"
                              ],
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <SelectInput
                            disabled={adhesion.read_only}
                            label="Adhésion mensualités maximum"
                            name="adhesion_mensualite_max"
                            value={adhesion.adhesion_mensualite_max}
                            onChange={this.event((name, value) =>
                              this.handleChangeAdhesion(key, name, value),
                            )}
                            error={Boolean(
                              errors?.[
                                "adhesions." + key + ".adhesion_mensualite_max"
                              ],
                            )}
                          >
                            <MenuItem value={1}>
                              Tout quittancer le premier mois
                            </MenuItem>
                            <MenuItem value={2}>2 mois</MenuItem>
                            <MenuItem value={3}>3 mois</MenuItem>
                            <MenuItem value={4}>4 mois</MenuItem>
                            <MenuItem value={5}>5 mois</MenuItem>
                            <MenuItem value={6}>6 mois</MenuItem>
                            <MenuItem value={7}>7 mois</MenuItem>
                            <MenuItem value={8}>8 mois</MenuItem>
                            <MenuItem value={9}>9 mois</MenuItem>
                            <MenuItem value={10}>10 mois</MenuItem>
                            <MenuItem value={11}>11 mois</MenuItem>
                            <MenuItem value={12}>12 mois</MenuItem>
                          </SelectInput>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <SelectInput
                            disabled={adhesion.read_only}
                            label="Année"
                            name="year"
                            value={adhesion.year}
                            onChange={this.event((name, value) =>
                              this.handleChangeAdhesion(key, name, value),
                            )}
                            error={Boolean(
                              errors?.["adhesions." + key + ".year"],
                            )}
                          >
                            {!adhesion.read_only ? (
                              this.years.map((year, index) => (
                                <MenuItem key={"year" + index} value={year}>
                                  {year}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value={adhesion.year}>
                                {adhesion.year}
                              </MenuItem>
                            )}
                          </SelectInput>
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

ProprietairesForm.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  uiCustomization: PropTypes.object,
};

export default ProprietairesForm;
