import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
// @mui/material
import Typography from "@mui/material/Typography";

import AddBox from "@mui/icons-material/AddBox";
import Description from "@mui/icons-material/Description";
// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import Home from "@mui/icons-material/Home";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import ToggleChip from "components/CustomButtons/ToggleChip";
import MenuBottom from "components/MenuBottom/MenuBottom";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import AbortRequest from "services/AbortRequest";
import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import LogementUtils from "Utils/LogementUtils";
import QueryUtils from "Utils/QueryUtils";
import Render from "Utils/RenderUtils";

import { withUserContext } from "context/UserContext";

class Logements extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/logements";
    this.FRONT_URL = "/tiers/logements";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.ABORT_REQUEST = new AbortRequest();

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      filters:
        this.QUERY_PARAMS.get("filters")?.split(",") ??
        LogementUtils.getInitFilter(this.USER),
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Propriétaire",
          field: "proprietaire",
          valueGetter: (params) => {
            return params.data.proprietaire
              ? (params.data.proprietaire.nom ?? "") +
                  " " +
                  (params.data.proprietaire.prenom ?? "")
              : "";
          },
        },
        {
          headerName: "Type de bien",
          field: "type",
        },
        {
          headerName: "Statut",
          field: "statut",
          valueFormatter: (params) =>
            params.value === "Occupé" ? "Actif" : params.value,
        },
        {
          headerName: "Adresse",
          field: "adresse",
          valueGetter: (params) => Render.address(params.data?.adresse),
        },
        { headerName: "Code postal", field: "adresse.ville.cp" },
        { headerName: "Ville", field: "adresse.ville.ville" },
        { headerName: "Étage", field: "adresse.etage" },
        {
          headerName: "Complément localisation",
          field: "adresse.complement_localisation",
        },
        { headerName: "Numéro logement", field: "adresse.numero_logement" },
        {
          headerName: "Prospecteur",
          field: "prospecteur",
          valueGetter: (params) => Render.fullName(params.data.prospecteur),
        },
        {
          headerName: "Typologie",
          field: "typologie.value",
        },
        {
          headerName: "Surface habitable",
          field: "surface_principale",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "N° Conventionnement ANAH",
          field: "conventionnement_actif.numero_conventionnement",
        },
        {
          headerName: "Date début Conventionnement",
          field: "conventionnement_actif.date_debut_conventionnement",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Durée de conventionnement",
          field: "conventionnement_actif.duree_conventionnement",
        },
        {
          headerName: "Date fin Conventionnement",
          field: "conventionnement_actif.date_fin_conventionnement",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Conventionnement loyer max",
          field: "conventionnement_actif.conventionnement_loyer_max",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Convention",
          field: "convention.value",
        },
        {
          headerName: "date DPE",
          field: "dpe_date",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "date validité DPE",
          field: "dpe_date_validite",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        { headerName: "Etiquette DPE", field: "dpe_etiquette" },
        {
          headerName: "Chances signature",
          field: "chances_signature",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.percent(params.value, "", "", 0),
        },
        { headerName: "Numéro lot", field: "numero_lot" },
        {
          headerName: "Surface terrasse",
          field: "surface_terrasse",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Surface balcon",
          field: "surface_balcon",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Surface cave",
          field: "surface_cave",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Ascenceur",
          field: "ascenseur",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.ascenseur),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Accès PMR",
          field: "acces_pmr",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.acces_pmr),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Cuisine séparée",
          field: "cuisine_separee",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.cuisine_separee),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "WC indépendant",
          field: "wc_independant",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.wc_independant),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        { headerName: "SDB", field: "sdb" },
        {
          headerName: "Chaudière gaz individuel",
          field: "chaudiere_gaz_individuel",
          cellDataType: "boolean",
          valueGetter: (params) =>
            Boolean(params.data.chaudiere_gaz_individuel),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Contrat chaudière estimé",
          field: "chaudiere_contrat_estime",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Contrat assurance estimé",
          field: "assurance_contrat_estime",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        { headerName: "Etat du logement", field: "etat_logement.value" },
        {
          headerName: "Digicode",
          field: "digicode",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.digicode),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        { headerName: "Nom Syndic", field: "syndic_nom" },
        {
          headerName: "Téléphone Syndic",
          field: "syndic_tel",
          valueGetter: (params) => Render.telephone(params.data.syndic_tel),
        },
        { headerName: "Email Syndic", field: "syndic_email" },
        {
          headerName: "Détecteur de fumée",
          field: "detecteur_fumee",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.detecteur_fumee),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Assurance PNO",
          field: "pno",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.pno),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Loyer HC SOLIHA",
          field: "loyer_hc_soliha",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Loyer HC Parc privé",
          field: "loyer_hc_parc_prive",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Loyer HC bail séparé",
          field: "loyer_hc_bail_separe",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Résultat",
          field: "resultat",
        },
        {
          headerName: "Date refus",
          field: "refus_date",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Date disponibilité",
          field: "date_disponibilite",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: {
          filters: this.state.filters,
        },
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      })
      .catch(() => {});
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  toggleFilter = (filter) => {
    let filters = this.state.filters;
    let index = filters.indexOf(filter);

    if (index !== -1) {
      filters.splice(index, 1);
    } else {
      filters.push(filter);
    }

    this.setState({ filters: filters }, () => {
      this.loadAsyncData();
      if (filters.length) {
        QueryUtils.replaceSearchParams({ filters: filters.join(",") });
      } else {
        QueryUtils.deleteSearchParams("filters");
      }
    });
  };

  hasFilter = (filter) => {
    return this.state.filters.includes(filter);
  };

  render() {
    return (
      <Grid container columnSpacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">Gestion des logements</Typography>
              }
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                alignItems="center"
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item>
                  <ToggleChip
                    sx={{ m: 0.5 }}
                    color="primary"
                    label="Tous"
                    on={this.state.filters.length === 0}
                    onClick={() => {
                      this.setState({ filters: [] }, () => {
                        this.loadAsyncData();
                        QueryUtils.deleteSearchParams("filters");
                      });
                    }}
                  />
                  {LogementUtils.STATUT.map((statut) => {
                    return (
                      <ToggleChip
                        sx={{ m: 0.5 }}
                        key={statut.name}
                        color="primary"
                        label={statut.name}
                        on={this.hasFilter(statut.name)}
                        onClick={() => this.toggleFilter(statut.name)}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL + "/ajouter"}>
            <Button size="small" square round>
              <AddBox />
            </Button>
          </Link>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Logement"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

Logements.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(Logements);
