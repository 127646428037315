import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Build from "@mui/icons-material/Build";
import Business from "@mui/icons-material/Business";
import CompareIcon from "@mui/icons-material/Compare";
import Edit from "@mui/icons-material/Edit";
import Handyman from "@mui/icons-material/Handyman";
import Home from "@mui/icons-material/Home";
import Person from "@mui/icons-material/Person";
import PersonAdd from "@mui/icons-material/PersonAdd";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import CheckBoxSelect from "components/CheckBox/CheckBoxSelect";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import NumberInput from "components/CustomInput/NumberInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch.jsx";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple";
import UserSelectSearch from "components/CustomSelect/UserSelectSearch";
import FormCollection from "components/Form/FormCollection";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";

import DateUtils from "Utils/DateUtils";
import LogementTravauxUtils from "Utils/LogementTravauxUtils";
import Render from "Utils/RenderUtils";

import { withUiCustomizationContext } from "context/UiCustomizationContext";
import { withUserContext } from "context/UserContext";

class DossiersTravauxForm extends React.Component {
  constructor(props) {
    super(props);
    this.EDIT_MODE = props.match.params.id ? true : false;
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.LOGEMENT_ID = this.QUERY_PARAMS.get("logement_id");
    this.BACKEND_URL = "/dossiers-travaux";
    this.FRONT_URL = "/dossiers-travaux";
    const uiCustomization = props.uiCustomization;

    this.state = {
      // NESTED DATA
      logement: {},
      listLogements: [],
      travaux: [],
      typesTravaux: [],
      types_prets: [],
      dispositifs: [],
      all_dispositifs: [],

      // DATA FORM
      logement_travaux_id: +this.LOGEMENT_ID || "",
      audit_avant_id: "",
      audit_apres_id: "",
      ouverture: this.EDIT_MODE ? "" : DateUtils.toDBString(new Date()),
      demande_paiement: "", // computed on backend side
      envoi: "", // computed on backend side
      reception: "", // computed on backend side
      debut: "",
      statut: "",
      infos: "",
      carton: "",
      technicien_id: "",
      conseiller_id: "",
      donneur_ordres_id: "",
      ergo_preconise: 0,
      ergo_passe: 0,
      date_fin_travaux: "",
      raison_fin_travaux: "",
      reference_externe: "",
      raison_abandon_id: "",
      date_fin_dossier: "",
      date_visite_avant_travaux: "",
      date_rapport_technicien: "",
      contrat_amo_valide: "",
      raison_attente_id: "",
      beneficiaire_pret_bancaire: false,
      raison_contact_id: "",
      origine_contact_id: "",
      mode_contact_id: "",
      lot: "",
      nb_logement_contact: "",
      nb_logement: "",
      chauffage_avant: "",
      chauffage_apres: "",
      surface_avant: "",
      surface_apres: "",
      potentiel_lhi: false,
      potentiel_al: false,
      dup: false,
      nombre_logement_concerne: "",
      nombre_loyer_libre_avant: "",
      nombre_loyer_libre_apres: "",
      nombre_loyer_conventionne_avant: "",
      nombre_loyer_conventionne_apres: "",
      nombre_loyer_conventionne_tres_social_avant: "",
      nombre_loyer_conventionne_tres_social_apres: "",
      date_relance_primo: "",
      indice_degradation_avant: "",
      indice_degradation_apres: "",
      cotation_insalubre_avant: "",
      cotation_insalubre_apres: "",
      type_loyer_avant_id: "",
      type_loyer_apres_id: "",
      type_gestion_avant_id: "",
      type_gestion_apres_id: "",
      has_audit_apres: 0,
      date_demande_document: "",
      date_validation_document: "",
      date_visite_apres_travaux: "",
      info_fiscale_id: "",
      cotisation_provisionnelle: "",
      cotisation_paye: "",
      date_demande_paiement_cotisation: "",
      date_facturation_amo_sans_subvention: "",
      amo_sans_subvention_prevue: "",
      amo_sans_subvention_payee: "",
      statut_personnalise_id: "",
      reference_personnalise: "",
      code_identification: "",
      has_visite_avant_travaux: true,
      has_visite_apres_travaux: true,
      // END DATA FORM
      audits: [],
      dispositifs_to_sync: {},
      selected_dispositifs: [],
      loading: false,
      errors: null,
      id: props.match.params.id,
      openAlertCancelDt: false,
      openAlertOpenDt: false,
      openReplicateDialog: false,
      alertCancelDtTitle: "Annuler le dossier travaux",
      initialValues: {},
      modifiedFields: [],
      auditFieldsToUpdate: {
        date_visite_avant_travaux: false,
        date_visite_apres_travaux: false,
      },
      affichage_statut_personnalise:
        uiCustomization?.affichage_champ_dt?.statut_personnalise ?? false,
      affichage_reference_personnalise:
        uiCustomization?.affichage_champ_dt?.reference_personnalise ?? false,
    };
  }

  componentDidMount() {
    if (this.EDIT_MODE) {
      this.loadAsyncData();
    } else {
      this.getConfigDispositifs();
    }
    this.getConfigTypeTravaux();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id, {
        params: {
          withAllDispositifs: true,
        },
      })
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.initialValues = {
          date_visite_avant_travaux: state.date_visite_avant_travaux,
          date_visite_apres_travaux: state.date_visite_apres_travaux,
          raison_abandon_id: state.raison_abandon_id,
          date_fin_dossier: state.date_fin_dossier,
        };
        if (!state.date_fin_dossier) {
          state.date_fin_dossier = DateUtils.toDBString(new Date());
        }
        this.setState(state);
        this.updateDispositifsList(
          this.state.dispositifs,
          this.state.all_dispositifs,
        );
      });
  }

  getConfigTypeTravaux = () => {
    axiosApiBackend.get("/config-types-travaux").then((result) => {
      this.setState({
        typesTravaux: result.data,
      });
    });
  };

  getConfigDispositifs = () => {
    axiosApiBackend.get("/config-dispositif").then((result) => {
      this.updateDispositifsList(this.state.dispositifs, result.data);
    });
  };

  updateDispositifsToSync(data) {
    const dispositifs_to_sync = {};

    data.forEach((item) => {
      if (item.checked) {
        dispositifs_to_sync[item.id] = {
          override_operateur_id: item.override_operateur_id
            ? item.override_operateur_id
            : null,
        };
      }
    });

    return dispositifs_to_sync;
  }

  updateDispositifsList(dispositifsDT, dispositifsList) {
    const updatedDispositifs = dispositifsList.map((dispositif) => {
      const { id, value, operateur } = dispositif;
      const operePar = operateur?.value ? " opéré par " + operateur?.value : "";
      let checked = false;
      let override_operateur_id = null;

      const matchDispositif = dispositifsDT.find(
        (dispositifDT) => dispositifDT.id === dispositif.id,
      );

      if (matchDispositif) {
        checked = true;
        override_operateur_id = matchDispositif.pivot.override_operateur_id;
      }

      return {
        id,
        value: value + operePar,
        checked,
        override_operateur_id,
        is_recommended: dispositif.is_recommended,
      };
    });

    this.setState({
      selected_dispositifs: updatedDispositifs,
      dispositifs_to_sync: this.updateDispositifsToSync(updatedDispositifs),
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  // travaux list
  getDefaultTravaux = () => {
    return {
      type_id: "",
      description: "",
    };
  };
  handleAddTravaux = () => {
    let newData = [...this.state.travaux];

    newData.push(this.getDefaultTravaux());
    this.setState({ travaux: newData });
  };
  handleChangeTravaux = (key, event) => {
    this.handleChangeTravauxByName(key, event.target.name, event.target.value);
  };
  handleChangeTravauxByName = (key, name, value) => {
    let newData = this.state.travaux;
    newData[key][name] = value;
    this.setState({ travaux: newData });
  };
  handleRemoveTravaux = (key) => {
    let newData = this.state.travaux;
    newData.splice(key, 1);
    this.setState({ travaux: newData });
  };

  handleChangeTypesTravaux = (event, key) => {
    let newData = this.state.travaux;
    const selectedTypeId = event.target.value;
    var newType = this.state.typesTravaux.find(
      (typeTravaux) => selectedTypeId === typeTravaux.id,
    );

    newData[key][event.target.name] = selectedTypeId;
    newData[key]["type"] = newType;

    this.setState({ travaux: newData });
  };

  handleChangeLogement = (name, value) => {
    this.setState({ [name]: value, audit_avant_id: "", audit_apres_id: "" });
    this.updateInfo(this.state.listLogements, value);
  };

  getLogementTravaux = (datas) => {
    if (!this.EDIT_MODE) {
      // on est en create mode
      if (this.LOGEMENT_ID) {
        // on a un id pre-rempli dans l'URL
        this.updateInfo(datas, this.state.logement_travaux_id);
      }
    }
    this.setState({
      listLogements: datas,
    });
  };

  updateInfo = (data, logementId) => {
    let logement = data.find((logement) => logement.id === logementId);
    if (logement) {
      this.setState({ logement: logement });
    }
  };

  handleSubmit = () => {
    const {
      id,
      audit_avant_id,
      audit_apres_id,
      date_visite_avant_travaux,
      date_visite_apres_travaux,
      initialValues,
    } = this.state;

    const modifiedFields = [];
    const editMode = id ? true : false;

    // Check if date_visite_avant_travaux has changed in edit mode
    // Or if exist in create mode
    if (
      (editMode &&
        audit_avant_id &&
        date_visite_avant_travaux !==
          initialValues?.date_visite_avant_travaux) ||
      (!editMode && audit_avant_id && date_visite_avant_travaux)
    ) {
      modifiedFields.push({
        name: "date_visite_avant_travaux",
        label: "Date de visite avant travaux",
      });
    }

    // Check if date_visite_apres_travaux has changed in edit mode
    // Or if exist in create mode
    if (
      (editMode &&
        audit_apres_id &&
        date_visite_apres_travaux !==
          initialValues?.date_visite_apres_travaux) ||
      (!editMode && audit_apres_id && date_visite_apres_travaux)
    ) {
      modifiedFields.push({
        name: "date_visite_apres_travaux",
        label: "Date de visite après travaux",
      });
    }

    if (modifiedFields.length > 0) {
      this.setState({ openReplicateDialog: true, modifiedFields });
    } else {
      this.submitForm();
    }
  };

  submitForm = (auditUpdate = true) => {
    const { id } = this.state;

    let dataIn = {
      logement_travaux_id: this.state.logement_travaux_id,
      audit_avant_id: this.state.audit_avant_id,
      audit_apres_id: this.state.has_audit_apres
        ? this.state.audit_apres_id
        : null,
      ouverture: this.state.ouverture,
      debut: this.state.debut,
      carton: this.state.carton,
      technicien_id: this.state.technicien_id,
      conseiller_id: this.state.conseiller_id,
      donneur_ordres_id: this.state.donneur_ordres_id,
      ergo_preconise: this.state.ergo_preconise,
      ergo_passe: this.state.ergo_passe,
      infos: this.state.infos,
      date_fin_travaux: this.state.date_fin_travaux,
      raison_fin_travaux: this.state.date_fin_travaux
        ? this.state.raison_fin_travaux
        : null,
      travaux: this.state.travaux,
      reference_externe: this.state.reference_externe,
      raison_abandon_id: this.state.raison_abandon_id,
      date_fin_dossier: this.state.date_fin_dossier,
      has_visite_avant_travaux: this.state.has_visite_avant_travaux,
      date_visite_avant_travaux: this.state.has_visite_avant_travaux
        ? this.state.date_visite_avant_travaux
        : null,
      date_rapport_technicien: this.state.date_rapport_technicien,
      contrat_amo_valide: this.state.contrat_amo_valide,
      raison_attente_id: this.state.raison_attente_id,
      beneficiaire_pret_bancaire: this.state.beneficiaire_pret_bancaire,
      types_prets: this.state.beneficiaire_pret_bancaire
        ? this.state.types_prets.map((type_pret) => type_pret.id)
        : null,
      raison_contact_id: this.state.raison_contact_id,
      origine_contact_id: this.state.origine_contact_id,
      mode_contact_id: this.state.mode_contact_id,
      lot: this.state.lot,
      nb_logement_contact: this.state.nb_logement_contact,
      nb_logement: this.state.nb_logement,
      chauffage_avant: this.state.chauffage_avant,
      chauffage_apres: this.state.chauffage_apres,
      surface_avant: this.state.surface_avant,
      surface_apres: this.state.surface_apres,
      potentiel_lhi: this.state.potentiel_lhi,
      potentiel_al: this.state.potentiel_al,
      dup: this.state.dup,
      nombre_logement_concerne: this.state.nombre_logement_concerne,
      nombre_loyer_libre_avant: this.state.nombre_loyer_libre_avant,
      nombre_loyer_conventionne_avant:
        this.state.nombre_loyer_conventionne_avant,
      nombre_loyer_conventionne_tres_social_avant:
        this.state.nombre_loyer_conventionne_tres_social_avant,
      nombre_loyer_libre_apres: this.state.nombre_loyer_libre_apres,
      nombre_loyer_conventionne_apres:
        this.state.nombre_loyer_conventionne_apres,
      nombre_loyer_conventionne_tres_social_apres:
        this.state.nombre_loyer_conventionne_tres_social_apres,
      date_relance_primo: this.state.date_relance_primo,
      indice_degradation_avant: this.state.indice_degradation_avant,
      indice_degradation_apres: this.state.indice_degradation_apres,
      cotation_insalubre_avant: this.state.cotation_insalubre_avant,
      cotation_insalubre_apres: this.state.cotation_insalubre_apres,
      type_loyer_avant_id: this.state.type_loyer_avant_id,
      type_loyer_apres_id: this.state.type_loyer_apres_id,
      type_gestion_avant_id: this.state.type_gestion_avant_id,
      type_gestion_apres_id: this.state.type_gestion_apres_id,
      dispositifs_to_sync: this.state.dispositifs_to_sync,
      has_audit_apres: this.state.has_audit_apres,
      date_demande_document: this.state.date_demande_document,
      date_validation_document: this.state.date_validation_document,
      has_visite_apres_travaux: this.state.has_visite_apres_travaux,
      date_visite_apres_travaux: this.state.has_visite_apres_travaux
        ? this.state.date_visite_apres_travaux
        : null,
      info_fiscale_id: this.state.info_fiscale_id,
      cotisation_provisionnelle: this.state.cotisation_provisionnelle,
      cotisation_paye: this.state.cotisation_paye,
      date_demande_paiement_cotisation:
        this.state.date_demande_paiement_cotisation,
      date_facturation_amo_sans_subvention:
        this.state.date_facturation_amo_sans_subvention,
      amo_sans_subvention_prevue: this.state.amo_sans_subvention_prevue,
      amo_sans_subvention_payee: this.state.amo_sans_subvention_payee,
      statut_personnalise_id: this.state.statut_personnalise_id,
      reference_personnalise: this.state.reference_personnalise,
    };

    if (auditUpdate) {
      dataIn.auditFieldsToUpdate = this.state.auditFieldsToUpdate;
    }

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
      openReplicateDialog: false,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleOnResponseAudit = (data) => {
    this.setState({
      audits: data,
    });
  };

  //Alert cancel dossiers travaux
  clearAlertCancelDt = () => {
    this.setState({
      openAlertCancelDt: false,
      raison_abandon_id: this.state.initialValues.raison_abandon_id,
      date_fin_dossier:
        this.state.initialValues.date_fin_dossier ||
        DateUtils.toDBString(new Date()),
    });
  };
  handleAlertCancelDt = () => {
    let errors = {
      raison_abandon_id: !this.state.raison_abandon_id,
      date_fin_dossier: !this.state.date_fin_dossier,
    };

    if (errors.raison_abandon_id || errors.date_fin_dossier) {
      this.setState({ errors });
    } else {
      this.handleSubmit();
      this.clearAlertCancelDt();
    }
  };
  onClickCancelDt = () => {
    this.setState({
      openAlertCancelDt: true,
      alertCancelDtTitle: "Annuler le dossier travaux",
    });
  };
  onClickEditAnnulation = () => {
    this.setState({
      openAlertCancelDt: true,
      alertCancelDtTitle: "Modifier l'annulation du dossier travaux",
    });
  };

  //Alert open dossiers travaux
  clearAlertOpenDt = () => {
    this.setState({ openAlertOpenDt: false });
  };
  handleAlertOpenDt = () => {
    this.setState({ raison_abandon_id: null }, () => {
      this.handleSubmit();
    });
    this.clearAlertOpenDt();
  };
  onClickOpenDt = () => {
    this.setState({
      openAlertOpenDt: true,
    });
  };

  // Renvoi true si au moins un des travaux a has_ergo à true
  checkErgo = (travaux) => {
    return travaux.some((t) => t?.type?.has_ergo);
  };

  render() {
    const {
      logement_travaux_id,
      audit_avant_id,
      audit_apres_id,
      ouverture,
      debut,
      statut,
      infos,
      carton,
      technicien_id,
      conseiller_id,
      donneur_ordres_id,
      ergo_preconise,
      ergo_passe,
      date_fin_travaux,
      raison_fin_travaux,
      reference_externe,
      raison_abandon_id,
      date_fin_dossier,
      date_visite_avant_travaux,
      date_rapport_technicien,
      contrat_amo_valide,
      raison_attente_id,
      beneficiaire_pret_bancaire,
      raison_contact_id,
      origine_contact_id,
      mode_contact_id,
      lot,
      nb_logement_contact,
      nb_logement,
      chauffage_avant,
      chauffage_apres,
      surface_avant,
      surface_apres,
      potentiel_lhi,
      potentiel_al,
      dup,
      nombre_logement_concerne,
      nombre_loyer_libre_avant,
      nombre_loyer_libre_apres,
      nombre_loyer_conventionne_avant,
      nombre_loyer_conventionne_apres,
      nombre_loyer_conventionne_tres_social_avant,
      nombre_loyer_conventionne_tres_social_apres,
      date_relance_primo,
      indice_degradation_avant,
      indice_degradation_apres,
      cotation_insalubre_avant,
      cotation_insalubre_apres,
      type_loyer_avant_id,
      type_loyer_apres_id,
      type_gestion_avant_id,
      type_gestion_apres_id,
      has_audit_apres,
      date_demande_document,
      date_validation_document,
      date_visite_apres_travaux,
      info_fiscale_id,
      cotisation_provisionnelle,
      cotisation_paye,
      date_demande_paiement_cotisation,
      date_facturation_amo_sans_subvention,
      amo_sans_subvention_prevue,
      amo_sans_subvention_payee,
      statut_personnalise_id,
      reference_personnalise,
      has_visite_avant_travaux,
      has_visite_apres_travaux,
      audits,
      selected_dispositifs,
      loading,
      errors,
      id,
      openAlertCancelDt,
      openAlertOpenDt,
      openReplicateDialog,
      alertCancelDtTitle,
      initialValues,
      modifiedFields,
      auditFieldsToUpdate,
      affichage_statut_personnalise,
      affichage_reference_personnalise,
      logement,
      typesTravaux,
      code_identification,
      types_prets,
      travaux,
    } = this.state;
    const beneficiaire = logement?.beneficiaire;
    const infosFiscales = logement?.beneficiaire?.infos_fiscales ?? [];

    return (
      <Grid container columnSpacing={2}>
        <AlertDialog
          open={openAlertCancelDt}
          title={alertCancelDtTitle}
          onConfirm={this.handleAlertCancelDt}
          confirmLabel="Oui"
          confirmColor="error"
          onCancel={this.clearAlertCancelDt}
          cancelLabel="Non"
          cancelColor="info"
          fullWidth
        >
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12}>
              <SelectSearch
                label="Raison abandon"
                name="raison_abandon_id"
                apiUrl="/config-raison-abandon-dt"
                onChange={this.handleChangeByName}
                value={raison_abandon_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.raison_abandon_id)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Date"
                name="date_fin_dossier"
                type="date"
                onChange={this.handleChange}
                value={date_fin_dossier}
                error={Boolean(errors?.date_fin_dossier)}
              />
            </Grid>
          </Grid>
        </AlertDialog>
        <AlertDialog
          open={openAlertOpenDt}
          title="Rouvrir le dossier?"
          onConfirm={this.handleAlertOpenDt}
          confirmLabel="Oui"
          confirmColor="error"
          onCancel={this.clearAlertOpenDt}
          cancelLabel="Non"
          cancelColor="info"
          fullWidth
        />
        <AlertDialog
          open={openReplicateDialog}
          title="Mettre à jour les informations de l'audit associé ?"
          content="Veuillez cocher les champs que vous souhaitez aussi mettre à jour dans l'audit."
          confirmLabel="Propager"
          onConfirm={this.submitForm}
          cancelLabel="Aucune"
          onCancel={() => this.submitForm(false)}
        >
          {modifiedFields.map((field) => (
            <Grid item key={field.name} xs={12}>
              <CheckBoxInput
                label={field.label}
                name={field.name}
                value={auditFieldsToUpdate[field.name]}
                onChange={(name, value) => {
                  this.setState((prevState) => ({
                    auditFieldsToUpdate: {
                      ...prevState.auditFieldsToUpdate,
                      [name]: value,
                    },
                  }));
                }}
              />
            </Grid>
          ))}
        </AlertDialog>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar
                  sx={{ bgcolor: beneficiaire?.categorie_ressource?.color_mpr }}
                >
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bénéficiaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prénom : </strong>
                {beneficiaire?.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {beneficiaire?.nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(beneficiaire?.tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(beneficiaire?.mail)}
              </Typography>
              <Typography>
                <strong>Statut de propriété : </strong>
                {beneficiaire?.statut_propriete?.value}
              </Typography>
              <Typography>
                <strong>Catégorie de ressources : </strong>
                {beneficiaire?.categorie_ressource?.value}
              </Typography>
              <SelectInput
                label="Année fiscale de référence"
                name="info_fiscale_id"
                onChange={this.handleChange}
                value={info_fiscale_id}
                error={Boolean(errors?.info_fiscale_id)}
              >
                <MenuItem value="">
                  <em>Non renseigné</em>
                </MenuItem>
                {infosFiscales.map((item, index) => {
                  const year = item.annee ?? "20xx";
                  const rfr = item.revenu_fiscal
                    ? ` (${Render.euro(item.revenu_fiscal)})`
                    : "";

                  return (
                    <MenuItem key={index} value={item.id}>
                      {year + rfr}
                    </MenuItem>
                  );
                })}
              </SelectInput>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <PersonAdd />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Primo Contact
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="raison_contact_id"
                    label="Raison de contact"
                    apiUrl="/config-raison-contact-dt"
                    onChange={this.handleChangeByName}
                    value={raison_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.raison_contact_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="origine_contact_id"
                    label="Origine de contact"
                    apiUrl="/config-origine-contact-dt"
                    onChange={this.handleChangeByName}
                    value={origine_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.origine_contact_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="mode_contact_id"
                    label="Mode de contact"
                    apiUrl="/config-mode-contact-dt"
                    onChange={this.handleChangeByName}
                    value={mode_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.mode_contact_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Nombre de logements au moment du contact"
                    name="nb_logement_contact"
                    value={nb_logement_contact}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nb_logement_contact)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CheckBoxInput
                    label="Potentiel LHI"
                    name="potentiel_lhi"
                    value={potentiel_lhi}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CheckBoxInput
                    label="Potentiel AL"
                    name="potentiel_al"
                    value={potentiel_al}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxInput
                    label="DUP (Déclaration d'Utilité Publique)"
                    name="dup"
                    value={dup}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Date de relance"
                    name="date_relance_primo"
                    type="date"
                    value={date_relance_primo}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_relance_primo)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Business />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Soliha
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <UserSelectSearch
                    label="Conseiller en charge"
                    name="conseiller_id"
                    apiUrl="/user-list/conseillers"
                    onChange={this.handleChangeByName}
                    value={conseiller_id}
                    error={Boolean(errors?.conseiller_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserSelectSearch
                    label="Technicien en charge"
                    name="technicien_id"
                    apiUrl="/user-list/techniciens"
                    onChange={this.handleChangeByName}
                    value={technicien_id}
                    error={Boolean(errors?.technicien_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Référence : </strong>
                    DT{id}
                  </Typography>
                </Grid>
                {Boolean(affichage_reference_personnalise) && (
                  <Grid item xs={12}>
                    <TextInput
                      label="Référence personnalisée"
                      name="reference_personnalise"
                      value={reference_personnalise}
                      onChange={this.handleChange}
                      error={Boolean(errors?.reference_personnalise)}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextInput
                    label="N° carton/archive"
                    name="carton"
                    value={carton}
                    onChange={this.handleChange}
                    error={Boolean(errors?.carton)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="AMO non subventionnée" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Frais AMO prévus"
                    name="amo_sans_subvention_prevue"
                    value={amo_sans_subvention_prevue}
                    onChange={this.handleChange}
                    error={Boolean(errors?.amo_sans_subvention_prevue)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Paiement de l'AMO"
                    name="date_facturation_amo_sans_subvention"
                    type="date"
                    onChange={this.handleChange}
                    value={date_facturation_amo_sans_subvention}
                    error={Boolean(
                      errors?.date_facturation_amo_sans_subvention,
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Frais AMO payés"
                    name="amo_sans_subvention_payee"
                    value={amo_sans_subvention_payee}
                    onChange={this.handleChange}
                    error={Boolean(errors?.amo_sans_subvention_payee)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="cotisation" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Frais cotisation prévus"
                    name="cotisation_provisionnelle"
                    value={cotisation_provisionnelle}
                    onChange={this.handleChange}
                    error={Boolean(errors?.cotisation_provisionnelle)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Paiement de la cotisation"
                    name="date_demande_paiement_cotisation"
                    type="date"
                    onChange={this.handleChange}
                    value={date_demande_paiement_cotisation}
                    error={Boolean(errors?.date_demande_paiement_cotisation)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Frais de cotisation payés"
                    name="cotisation_paye"
                    value={cotisation_paye}
                    onChange={this.handleChange}
                    error={Boolean(errors?.cotisation_paye)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Donneur d&apos;ordres
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <SelectSearch
                    name="donneur_ordres_id"
                    label="Donneur d'ordres"
                    apiUrl="/donneurs-ordres"
                    onChange={this.handleChangeByName}
                    value={donneur_ordres_id}
                    buildOptionLabel={(data) => data.nom}
                    shrink
                    error={Boolean(errors?.donneur_ordres_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Code d&apos;identification : </strong>
                    {code_identification}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Référence externe / BDC"
                    name="reference_externe"
                    value={reference_externe}
                    onChange={this.handleChange}
                    error={Boolean(errors?.reference_externe)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <SelectSearch
                    name="logement_travaux_id"
                    label="Logement"
                    apiUrl={
                      // on envoie pas de requete si on est en edit mode et qu'on a pas d'id
                      this.EDIT_MODE && !beneficiaire?.id
                        ? null
                        : "/logements-travaux"
                    }
                    filterKey={this.EDIT_MODE ? "ofBeneficiaire" : null}
                    filter={this.EDIT_MODE ? beneficiaire?.id : null}
                    sortResponseData={(a, b) => b.id - a.id}
                    onChange={this.handleChangeLogement}
                    onResponse={this.getLogementTravaux}
                    value={logement_travaux_id}
                    buildOptionLabel={LogementTravauxUtils.renderForList}
                    shrink
                    error={Boolean(errors?.logement_travaux_id)}
                  />
                  <Typography>
                    <strong>Adresse : </strong>
                    {Render.address(logement?.adresse)}
                  </Typography>
                  <Typography>
                    <strong>Ville : </strong>
                    {logement?.adresse?.ville?.cp}{" "}
                    {logement?.adresse?.ville?.ville}
                  </Typography>
                  <Typography>
                    <strong>Étage : </strong>
                    {logement?.adresse?.etage}
                  </Typography>
                  <Typography>
                    <strong>Complément de localisation : </strong>
                    {logement?.adresse?.complement_localisation}
                  </Typography>
                  <Typography>
                    <strong>Type : </strong>
                    {logement?.type?.value}
                  </Typography>
                  <Typography>
                    <strong>Typologie : </strong>
                    {logement?.typologie?.value}
                  </Typography>
                  <CheckBoxInput
                    label="Prêt bancaire"
                    name="beneficiaire_pret_bancaire"
                    value={beneficiaire_pret_bancaire}
                    onChange={this.handleChangeByName}
                  />
                  {Boolean(beneficiaire_pret_bancaire) && (
                    <SelectSearchMultiple
                      apiUrl="/config-type-pret"
                      getOptionLabel={(data) => data.value}
                      onChange={(event, newValue) => {
                        this.setState({ types_prets: newValue });
                      }}
                      value={types_prets}
                      label="Types de prêts"
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Build />
                </CardAvatar>
              }
              title={
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6" component="h5">
                      Dossier travaux
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.EDIT_MODE && ouverture && statut != "annule" && (
                      <Button color="error" onClick={this.onClickCancelDt}>
                        Annuler le dossier
                      </Button>
                    )}
                    {this.EDIT_MODE && statut === "annule" && (
                      <>
                        <Button
                          sx={{ mr: 1 }}
                          size="small"
                          color="error"
                          onClick={this.onClickOpenDt}
                        >
                          Rouvrir
                        </Button>
                        <Button
                          size="small"
                          onClick={this.onClickEditAnnulation}
                        >
                          <Edit />
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                {Boolean(affichage_statut_personnalise) && (
                  <Grid item xs={12}>
                    <SelectSearch
                      label="Statut personnalisé"
                      name="statut_personnalise_id"
                      apiUrl="/config-statut-personnalise-dt"
                      onChange={this.handleChangeByName}
                      value={statut_personnalise_id}
                      buildOptionLabel={(data) => data.value}
                      sortResponseData={(a, b) => a.ordre - b.ordre}
                      shrink
                      error={Boolean(errors?.statut_personnalise_id)}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SelectSearch
                    label="Raison d'attente"
                    name="raison_attente_id"
                    apiUrl="/config-raison-attente-dt"
                    onChange={this.handleChangeByName}
                    value={raison_attente_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.raison_attente_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CheckBoxInput
                    label="Visite avant travaux"
                    name="has_visite_avant_travaux"
                    value={has_visite_avant_travaux}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CheckBoxInput
                    label="Visite après travaux"
                    name="has_visite_apres_travaux"
                    value={has_visite_apres_travaux}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="étapes" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Ouverture du dossier"
                    name="ouverture"
                    type="date"
                    value={ouverture}
                    onChange={this.handleChange}
                    error={Boolean(errors?.ouverture)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Demande de documents"
                    name="date_demande_document"
                    type="date"
                    value={date_demande_document}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_demande_document)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Validation de documents"
                    name="date_validation_document"
                    type="date"
                    value={date_validation_document}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_validation_document)}
                  />
                </Grid>
                {Boolean(has_visite_avant_travaux) && (
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="Visite avant travaux"
                      name="date_visite_avant_travaux"
                      type="date"
                      value={date_visite_avant_travaux}
                      onChange={this.handleChange}
                      error={Boolean(errors?.date_visite_avant_travaux)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Contrat AMO validé"
                    name="contrat_amo_valide"
                    type="date"
                    value={contrat_amo_valide}
                    onChange={this.handleChange}
                    error={Boolean(errors?.contrat_amo_valide)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Début des travaux"
                    name="debut"
                    type="date"
                    value={debut}
                    onChange={this.handleChange}
                    error={Boolean(errors?.debut)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Fin des travaux"
                    name="date_fin_travaux"
                    type="date"
                    value={date_fin_travaux}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_fin_travaux)}
                  />
                </Grid>
                {date_fin_travaux && (
                  <Grid item xs={12} lg={6}>
                    <SelectInput
                      label="Raison de fin des travaux"
                      name="raison_fin_travaux"
                      onChange={this.handleChange}
                      value={raison_fin_travaux}
                      error={Boolean(errors?.raison_fin_travaux)}
                    >
                      <MenuItem value="">
                        <em>Non renseigné</em>
                      </MenuItem>
                      <MenuItem value="Clôturé">Clôturé</MenuItem>
                      <MenuItem value="Annulé">Annulé</MenuItem>
                    </SelectInput>
                  </Grid>
                )}
                {Boolean(has_visite_apres_travaux) && (
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="Visite après travaux"
                      name="date_visite_apres_travaux"
                      type="date"
                      value={date_visite_apres_travaux}
                      onChange={this.handleChange}
                      error={Boolean(errors?.date_visite_apres_travaux)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Rapport technicien"
                    name="date_rapport_technicien"
                    type="date"
                    value={date_rapport_technicien}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_rapport_technicien)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>
                {logement_travaux_id && (
                  <>
                    <Grid item xs={12}>
                      <SelectSearch
                        hideIfEmpty
                        name="audit_avant_id"
                        label="Audit avant"
                        apiUrl="/audits"
                        filterKey="logement_id"
                        filter={logement_travaux_id}
                        onChange={this.handleChangeByName}
                        onResponse={this.handleOnResponseAudit}
                        value={audit_avant_id}
                        buildOptionLabel={(data) =>
                          ("AD" + data.id.toString() ?? "") +
                          " " +
                          data.type_audit +
                          " " +
                          (data.resultat ?? "")
                        }
                        shrink
                        error={Boolean(errors?.audit_avant_id)}
                      />
                    </Grid>
                    {audits.length > 0 && (
                      <>
                        <Grid item xs={12}>
                          <CheckBoxInput
                            label="Un audit après a été effectué"
                            name="has_audit_apres"
                            value={has_audit_apres}
                            onChange={this.handleChangeByName}
                          />
                        </Grid>
                        {Boolean(has_audit_apres) && (
                          <Grid item xs={12}>
                            <SelectSearch
                              name="audit_apres_id"
                              label="Audit après"
                              apiUrl="/audits"
                              filterKey="logement_id"
                              filter={logement_travaux_id}
                              onChange={this.handleChangeByName}
                              value={audit_apres_id}
                              buildOptionLabel={(data) =>
                                ("AD" + data.id.toString() ?? "") +
                                " " +
                                data.type_audit +
                                " " +
                                (data.resultat ?? "")
                              }
                              shrink
                              error={Boolean(errors?.audit_apres_id)}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Business />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Dispositifs et programmes
                </Typography>
              }
            />
            <CardContent>
              <TextInput
                label="Lot"
                name="lot"
                value={lot}
                onChange={this.handleChange}
                error={Boolean(errors?.lot)}
              />
              {selected_dispositifs.length > 0 && (
                <CheckBoxSelect
                  items={selected_dispositifs}
                  checkBoxLabelKey="value"
                  checkBoxHintKey="is_recommended"
                  checkBoxHintText="Recommandé"
                  selectLabel="Opérateur de remplacement"
                  selectApiUrl="config-operateur"
                  selectValueKey="override_operateur_id"
                  selectEmptyLabelValue="Par défaut"
                  onChange={(data) => {
                    this.setState({
                      dispositifs_to_sync: this.updateDispositifsToSync(data),
                    });
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Handyman />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Définition
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <FormCollection
                    items={travaux}
                    rowTitle="Typologie de Travaux"
                    addRow={this.handleAddTravaux}
                    removeRow={this.handleRemoveTravaux}
                    tooltipAdd="Ajouter une typologie de travaux"
                    tooltipDelete="Supprimer"
                    hideButtons={Boolean(initialValues.date_fin_dossier)}
                    dialogDeleteTitle={(rowNumber) =>
                      "Voulez-vous supprimer la typologie de travaux n° " +
                      rowNumber +
                      " ?"
                    }
                    formInputs={(travaux, key) => (
                      <>
                        <Grid item xs={12}>
                          <SelectInput
                            label="Typologie"
                            name="type_id"
                            value={travaux?.type_id}
                            onChange={(event) =>
                              this.handleChangeTypesTravaux(event, key)
                            }
                            displayEmpty
                            disabled={Boolean(initialValues.date_fin_dossier)}
                            error={Boolean(errors?.[`travaux.${key}.type_id`])}
                          >
                            {travaux?.type_id ? (
                              <MenuItem
                                key={travaux.type_id}
                                value={travaux.type_id}
                              >
                                {travaux?.type?.value}
                              </MenuItem>
                            ) : (
                              <MenuItem value="">
                                <em>Non renseigné</em>
                              </MenuItem>
                            )}
                            {typesTravaux
                              ?.filter(
                                (item) =>
                                  !Object.values(this.state.travaux)
                                    .map((travaux) => travaux.type_id)
                                    .includes(item.id),
                              )
                              .map((item, key) => (
                                <MenuItem key={key} value={item.id}>
                                  {item.value}
                                </MenuItem>
                              ))}
                          </SelectInput>
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label="Description complémentaire de la typologie de travaux"
                            name="description"
                            multiline
                            value={travaux?.description}
                            onChange={(event) =>
                              this.handleChangeTravaux(key, event)
                            }
                            disabled={Boolean(initialValues.date_fin_dossier)}
                            error={Boolean(
                              errors?.[`travaux.${key}.description`],
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
                {this.checkErgo(travaux) && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label="Ergothérapeute préconisé"
                        name="ergo_preconise"
                        onChange={this.handleChange}
                        value={ergo_preconise}
                        error={Boolean(errors?.ergo_preconise)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        <MenuItem value={0}>Non</MenuItem>
                        <MenuItem value={1}>Oui</MenuItem>
                      </SelectInput>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label="Ergothérapeute passé"
                        name="ergo_passe"
                        onChange={this.handleChange}
                        value={ergo_passe}
                        error={Boolean(errors?.ergo_passe)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        <MenuItem value={0}>Non</MenuItem>
                        <MenuItem value={1}>Oui</MenuItem>
                      </SelectInput>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    minRows={2}
                    maxRows={8}
                    onChange={this.handleChange}
                    value={infos}
                    error={Boolean(errors?.infos)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <CompareIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations avant/après travaux
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <HeaderTitle label="Logements" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nombre de logements avant"
                    name="nombre_logement_concerne"
                    value={nombre_logement_concerne}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_logement_concerne)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Nombre de logements après"
                    name="nb_logement"
                    value={nb_logement}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nb_logement)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Chauffage avant"
                    name="chauffage_avant"
                    value={chauffage_avant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.chauffage_avant)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Chauffage après"
                    name="chauffage_apres"
                    value={chauffage_apres}
                    onChange={this.handleChange}
                    error={Boolean(errors?.chauffage_apres)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Surface avant"
                    name="surface_avant"
                    value={surface_avant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.surface_avant)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Surface après"
                    name="surface_apres"
                    value={surface_apres}
                    onChange={this.handleChange}
                    error={Boolean(errors?.surface_apres)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Type de loyer avant"
                    name="type_loyer_avant_id"
                    apiUrl="/config-type-loyer-dt"
                    onChange={this.handleChangeByName}
                    value={type_loyer_avant_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_loyer_avant_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Type de loyer après"
                    name="type_loyer_apres_id"
                    apiUrl="/config-type-loyer-dt"
                    onChange={this.handleChangeByName}
                    value={type_loyer_apres_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_loyer_apres_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Type de gestion avant"
                    name="type_gestion_avant_id"
                    apiUrl="/config-type-gestion-dt"
                    onChange={this.handleChangeByName}
                    value={type_gestion_avant_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_gestion_avant_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Type de gestion après"
                    name="type_gestion_apres_id"
                    apiUrl="/config-type-gestion-dt"
                    onChange={this.handleChangeByName}
                    value={type_gestion_apres_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_gestion_apres_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Nombre de logements en loyer " />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Loyer libre avant"
                    name="nombre_loyer_libre_avant"
                    value={nombre_loyer_libre_avant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_loyer_libre_avant)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Loyer libre après"
                    name="nombre_loyer_libre_apres"
                    value={nombre_loyer_libre_apres}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_loyer_libre_apres)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Loyer conventionné avant"
                    name="nombre_loyer_conventionne_avant"
                    value={nombre_loyer_conventionne_avant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_loyer_conventionne_avant)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Loyer conventionné après"
                    name="nombre_loyer_conventionne_apres"
                    value={nombre_loyer_conventionne_apres}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nombre_loyer_conventionne_apres)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Loyer conventionné très social avant"
                    name="nombre_loyer_conventionne_tres_social_avant"
                    value={nombre_loyer_conventionne_tres_social_avant}
                    onChange={this.handleChange}
                    error={Boolean(
                      errors?.nombre_loyer_conventionne_tres_social_avant,
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Loyer conventionné très social après"
                    name="nombre_loyer_conventionne_tres_social_apres"
                    value={nombre_loyer_conventionne_tres_social_apres}
                    onChange={this.handleChange}
                    error={Boolean(
                      errors?.nombre_loyer_conventionne_tres_social_apres,
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Insalubrité" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Indice de dégradation avant"
                    name="indice_degradation_avant"
                    value={indice_degradation_avant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.indice_degradation_avant)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Indice de dégradation apres"
                    name="indice_degradation_apres"
                    value={indice_degradation_apres}
                    onChange={this.handleChange}
                    error={Boolean(errors?.indice_degradation_apres)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Cotation insalubrité avant"
                    name="cotation_insalubre_avant"
                    value={cotation_insalubre_avant}
                    onChange={this.handleChange}
                    error={Boolean(errors?.cotation_insalubre_avant)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Cotation insalubrité apres"
                    name="cotation_insalubre_apres"
                    value={cotation_insalubre_apres}
                    onChange={this.handleChange}
                    error={Boolean(errors?.cotation_insalubre_apres)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={loading}
            isEdit={Boolean(id)}
          />
        </Grid>
      </Grid>
    );
  }
}

DossiersTravauxForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  uiCustomization: PropTypes.object,
};
export default withUserContext(withUiCustomizationContext(DossiersTravauxForm));
