import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";

const MultilineTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "lineClamp",
})(({ lineClamp }) => ({
  whiteSpace: "pre-wrap",
  hyphens: "auto",
  overflowWrap: "break-word",
  ...(lineClamp != null && {
    display: "-webkit-box",
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
}));

export default MultilineTypography;
