import LogementTravauxUtils from "Utils/LogementTravauxUtils";
import Render from "Utils/RenderUtils";

export default class BeneficiaireUtils {
  static buildRowsForLinks = (logements) => {
    let rows = [];
    logements.forEach((logement) => {
      let logRows = LogementTravauxUtils.buildRowsForLinks(
        logement.audits,
        logement.dossiers_travaux,
      );

      let logForlink = logement;
      logForlink.span = logRows.length > 0 ? logRows.length : 1;
      logRows.forEach((row) => {
        rows.push({
          logement: logForlink,
          dossier_travaux: row.dossier_travaux,
          audit: row.audit,
        });
        logForlink = null;
      });
      if (logForlink != null) {
        rows.push({
          logement: logForlink,
          dossier_travaux: null,
          audit: null,
        });
      }
    });

    return rows;
  };

  static renderNomPrenom = (beneficiaire) => {
    return `${beneficiaire.prenom} ${beneficiaire.nom}`;
  };

  static getNomComplet = (beneficiaire) => {
    return [beneficiaire.civilite?.value, beneficiaire.prenom, beneficiaire.nom]
      .filter(Boolean)
      .join(" ");
  };

  static findDuplicateBeneficiary = (beneficiaries, nom, prenom, id) => {
    const nomNormalise = Render.normalizeString(nom);
    const prenomNormalise = Render.normalizeString(prenom);

    return beneficiaries.find(
      (b) =>
        Render.normalizeString(b.nom) === nomNormalise &&
        Render.normalizeString(b.prenom) === prenomNormalise &&
        b.id !== id,
    );
  };
}
