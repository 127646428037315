import React from "react";

// @mui/material components
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

class StarsRatingRender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  decimal = (precision) => {
    if (precision != null) {
      return Math.ceil(-Math.log10(precision));
    }
    return 0;
  };

  render() {
    const {
      value,
      readOnly,
      hover,
      precision,
      fontSize,
      size,
      showRating,
      onChange,
      onChangeActive,
    } = this.props;

    let note = hover && hover != -1 ? hover : value;
    note = Number(note).toFixed(this.decimal(precision));

    return (
      <Box
        style={{
          height: "48px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {value || !readOnly ? (
          <>
            <Rating
              precision={precision}
              value={value}
              readOnly={readOnly}
              size={size}
              onChange={onChange}
              onChangeActive={onChangeActive}
            />
            {showRating && (
              <Box
                style={{
                  fontSize: fontSize,
                }}
              >
                {note}
              </Box>
            )}
          </>
        ) : (
          <Typography
            style={{
              fontSize: fontSize,
            }}
          >
            &nbsp;Aucune note
          </Typography>
        )}
      </Box>
    );
  }
}

StarsRatingRender.propTypes = {
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  hover: PropTypes.any,
  precision: PropTypes.any,
  fontSize: PropTypes.any,
  size: PropTypes.any,
  showRating: PropTypes.any,
  onChangeActive: PropTypes.func,
  onChange: PropTypes.func,
};

export default StarsRatingRender;
