import React from "react";
import PropTypes from "prop-types";
import DisableLink from "components/DisableLink/DisableLink";
import styled from "@mui/styles/styled";
import Box from "@mui/material/Box";

const MarkerBox = styled(Box)(() => ({
  border: "1px solid white",
  borderRadius: "40%",
  height: "15px",
  width: "25px",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: "white",
  zIndex: 10,
}));

class CustomMarker extends React.Component {
  render() {
    const { lat, lng, text, disablelink, color } = this.props;

    return (
      <DisableLink to={disablelink} lat={lat} lng={lng}>
        <MarkerBox style={{ backgroundColor: color }}>{text}</MarkerBox>
      </DisableLink>
    );
  }
}

CustomMarker.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  text: PropTypes.string,
  disablelink: PropTypes.string,
  color: PropTypes.string,
};

export default CustomMarker;
