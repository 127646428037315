import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";

const ListTitle = styled(Typography)(({ theme }) => ({
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  marginBottom: "5px",
  marginTop: "10px",
}));

export default ListTitle;
