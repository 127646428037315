import React from "react";
import { PropTypes } from "prop-types";
import MuiTypography from "@mui/material/Typography";
import styled from "@mui/system/styled";

const Typography = styled(MuiTypography)({
  // "& span": {
  //   color: "#766f6f",
  // },
});

function FieldView(props) {
  const { label, value } = props;

  return (
    <Typography>
      <strong>{label} : </strong>
      {value}
    </Typography>
  );
}

FieldView.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
};

export default FieldView;
