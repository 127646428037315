export default class OperationUtils {
  static NOT_COUNTED_COLOR = "#eeeeee";

  static renderDebitCredit = (debit) => {
    return debit ? "Débit" : "Crédit";
  };

  static renderDebit = (operation) => {
    return operation.debit ? operation.montant : null;
  };

  static renderCredit = (operation) => {
    return operation.debit ? null : operation.montant;
  };

  static getMontant = (operation) => {
    return operation.debit ? -operation.montant : operation.montant;
  };
}
