import React from "react";
import PropTypes from "prop-types";

import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";

class LogDetailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  formatProperty = (property) => {
    return property.replace(/_/g, " ");
  };

  renderData = (data) => {
    let detail = JSON.parse(data);
    return (
      <>
        {Object.entries(detail).map(([key, value]) => {
          return (
            <p key={key}>
              <b style={{ textTransform: "capitalize" }}>
                {this.formatProperty(key)}
              </b>
              :{" "}
              {typeof value === "object"
                ? JSON.stringify(value)
                : value.toString()}
            </p>
          );
        })}
      </>
    );
  };

  render() {
    const { params } = this.props;

    return (
      <>
        <Button
          square={params.square}
          color={params.color}
          size="small"
          onClick={this.handleOpen}
        >
          {params.label}
        </Button>
        <AlertDialog
          open={this.state.open}
          outsideClickCancel
          maxWidth="sm"
          fullWidth
          title={<>Détails Action {Render.CRUDAction(params.data.action)}</>}
          onCancel={this.handleClose}
          cancelLabel="Fermer"
          cancelColor="onyx"
        >
          {this.renderData(params.data.data)}
        </AlertDialog>
      </>
    );
  }
}

LogDetailPopup.propTypes = {
  params: PropTypes.any,
};

export default LogDetailPopup;
