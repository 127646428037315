import React from "react";
import PropTypes from "prop-types";
import fr from "date-fns/locale/fr";

// @mui/x-date-pickers components
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DateTimePicker as MuiDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";

// @mui/material components

// @mui/icons-material

// core components
import TextInput from "components/CustomInput/TextInput";

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleChange = (date) => {
    const { onChange, name } = this.props;
    if (onChange) {
      onChange(name, date);
    }
  };

  render() {
    const { textFieldProps, onChange, error, textFieldSlot, ...rest } =
      this.props;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <MuiDateTimePicker
          {...rest}
          onChange={this.handleChange}
          slots={{ textField: textFieldSlot ?? TextInput }}
          slotProps={{
            textField: {
              error: error,
              ...textFieldProps,
            },
          }}
        />
      </LocalizationProvider>
    );
  }
}

DateTimePicker.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.any,
  textFieldSlot: PropTypes.elementType,
  textFieldProps: PropTypes.any,
  error: PropTypes.bool,
};

export default DateTimePicker;
