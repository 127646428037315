export default class ProcedureUtils {
  static STATUTS = [
    { name: "en_cours", label: "En cours" },
    { name: "termine", label: "Terminé" },
    { name: "annule", label: "Annulé" },
  ];

  static TYPES = [
    { name: "avocat", label: "Avocat" },
    { name: "expulsion", label: "Expulsion" },
    { name: "gli", label: "GLI" },
    { name: "intervention", label: "Intervention" },
    { name: "signalement insalubrite", label: "Signalement Insalubrité" },
    { name: "sinistre", label: "Sinistre" },
    { name: "surendettement", label: "Surendettement" },
    { name: "travaux", label: "Travaux" },
  ];

  static getType = (name) => {
    return this.TYPES.find((type) => type.name === name);
  };

  static renderType = (name) => {
    return this.getType(name)?.label ?? "";
  };

  static getStatut = (name) => {
    return this.STATUTS.find((type) => type.name === name);
  };

  static renderStatut = (name) => {
    return this.getStatut(name)?.label ?? "";
  };
}
