import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import Render from "Utils/RenderUtils";
import CustomTimeline from "components/Timeline/CustomTimeline";
import ProcedureUtils from "Utils/ProcedureUtils";

class ProcedureCLView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  onSortProcedures = (a, b) => {
    const statutValues = {
      en_cours: 1,
      termine: 2,
      annule: 3,
    };

    const statutA = statutValues[a.statut];
    const statutB = statutValues[b.statut];

    if (statutA !== statutB) {
      return statutA - statutB;
    }

    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  };

  render() {
    const { procedures } = this.props;
    procedures.sort(this.onSortProcedures);
    const activeId = procedures.length > 0 ? procedures[0].id : null;

    return (
      <>
        {procedures.length <= 0 ? (
          <Typography>
            <strong>Aucune procédure n&apos;a été créée.</strong>
          </Typography>
        ) : (
          <CustomTimeline
            items={procedures}
            visibleItems={2}
            renderDot={(item) => (
              <TimelineDot
                color={activeId === item.id ? "success" : "primary"}
              />
            )}
            renderTo={(item) => "/tiers/procedures/detail/" + item.id}
            renderBody={(item) => {
              return (
                <>
                  <Typography>
                    <strong>Date : </strong>
                    {Render.dateTime(item.date)}
                  </Typography>
                  <Typography>
                    <strong>Statut : </strong>
                    {ProcedureUtils.renderStatut(item.statut)}
                  </Typography>
                  <Typography>
                    <strong>Type : </strong>
                    {ProcedureUtils.renderType(item.type)}
                  </Typography>
                  <Typography>
                    <strong>Causes : </strong>
                    {Render.configList(item.causes)}
                  </Typography>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

ProcedureCLView.propTypes = {
  procedures: PropTypes.array.isRequired,
};

export default ProcedureCLView;
