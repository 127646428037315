import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Build from "@mui/icons-material/Build";
import Business from "@mui/icons-material/Business";
import CompareIcon from "@mui/icons-material/Compare";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import Handyman from "@mui/icons-material/Handyman";
import Home from "@mui/icons-material/Home";
import List from "@mui/icons-material/List";
import Person from "@mui/icons-material/Person";
import PersonAdd from "@mui/icons-material/PersonAdd";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import Today from "@mui/icons-material/Today";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import CheckBoxShow from "components/CheckBoxShow/CheckBoxShow";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Evenements from "components/Evenements/Evenements";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import TravauxLinkCard from "components/LinkCard/TravauxLinkCard";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import DocumentTemplate from "components/Table/DocumentTemplate";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import SimpleTreeView from "components/Tree/SimpleTreeView";

import SearchBarService from "services/SearchBarService";

import AuditUtils from "Utils/AuditUtils";
import DateUtils from "Utils/DateUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import Render from "Utils/RenderUtils";

import Ged from "views/Tables/Ged";

import { withUiCustomizationContext } from "context/UiCustomizationContext";
import { withUserContext } from "context/UserContext";

import DevisListForm from "./DevisListForm";
import DispositifView from "./Dispositif/DispositifView";
import FinancementListForm from "./FinancementListForm";

import withStyles from "@mui/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = (theme) => ({
  ...regularFormsStyle,
  gridWithNoMarginBot: {
    "& > .MuiGrid-item": {
      "& > *": {
        marginBottom: 0,
      },
    },
  },
  verticalMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: "20px",
    color: "#0a4f75",
    marginRight: "5px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

class DossiersTravauxView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.dossiertravaux");
    this.canEditDosserTravaux = this.USER.can("edit.dossiertravaux");
    this.CAN_VIEW_TRAVAUX_EVENTS = this.USER.canOne([
      "view.evenement.travaux",
      "edit.evenement.travaux",
    ]);
    this.CAN_EDIT_TRAVAUX_EVENTS = this.USER.can("edit.evenement.travaux");
    this.CAN_USE_DT = this.USER.can("use.dossiertravaux");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    // Ged DT permissions
    this.GED_DT_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.travaux.dt"),
      CAN_EDIT: this.USER.can("edit.ged.travaux.dt"),
      CAN_DELETE: this.USER.can("delete.ged.travaux.dt"),
    };

    this.BACKEND_URL = "/dossiers-travaux";
    this.FRONT_URL = "/dossiers-travaux";
    const uiCustomization = props.uiCustomization;
    this.NO_YES = ["Non", "Oui"];
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      logement: {},
      technicien: null,
      conseiller: null,
      plan_financement: null,
      travaux: [],
      audit_avant: {},
      audit_apres: {},
      raison_abandon_dt: {},
      donneurordres: null,
      code_identification: null,
      dispositifs: [],
      types_prets: {},
      raison_contact: null,
      origine_contact: null,
      mode_contact: null,
      type_loyer_avant: null,
      type_loyer_apres: null,
      type_gestion_avant: null,
      type_gestion_apres: null,
      info_fiscale: null,
      updated_at: null,
      // DATA FORM
      ouverture: this.EDIT_MODE ? "" : DateUtils.toDBString(new Date()),
      envoi: "",
      reception: "",
      demande_paiement: "",
      debut: "",
      statut: "",
      infos: "",
      carton: "",
      ergo_preconise: 0,
      ergo_passe: 0,
      date_fin_travaux: "",
      raison_fin_travaux: "",
      reference_externe: "",
      date_fin_dossier: "",
      date_visite_avant_travaux: "",
      date_rapport_technicien: "",
      contrat_amo_valide: "",
      beneficiaire_pret_bancaire: false,
      lot: "",
      nb_logement_contact: "",
      nb_logement: "",
      chauffage_avant: "",
      chauffage_apres: "",
      surface_avant: "",
      surface_apres: "",
      potentiel_lhi: "",
      potentiel_al: "",
      dup: "",
      nombre_logement_concerne: "",
      nombre_loyer_libre_avant: "",
      nombre_loyer_libre_apres: "",
      nombre_loyer_conventionne_avant: "",
      nombre_loyer_conventionne_apres: "",
      nombre_loyer_conventionne_tres_social_avant: "",
      nombre_loyer_conventionne_tres_social_apres: "",
      date_relance_primo: "",
      indice_degradation_avant: "",
      indice_degradation_apres: "",
      cotation_insalubre_avant: "",
      cotation_insalubre_apres: "",
      taux_amelioration_dpe: "",
      taux_amelioration_ges: "",
      has_audit_apres: 0,
      date_demande_document: "",
      date_validation_document: "",
      date_visite_apres_travaux: "",
      statut_avant_annulation: "",
      date_demande_paiement_cotisation: "",
      cotisation_provisionnelle: null,
      cotisation_paye: null,
      date_facturation_amo_sans_subvention: "",
      amo_sans_subvention_prevue: null,
      amo_sans_subvention_payee: null,
      reference_personnalise: "",
      has_visite_avant_travaux: true,
      has_visite_apres_travaux: true,
      // END DATA FORM
      dossierTravaux: null,
      id: Number(props.match.params.id),
      alert: null,
      // AFFICHAGE
      affichage_statut_personnalise:
        uiCustomization?.affichage_champ_dt?.statut_personnalise ?? false,
      affichage_reference_personnalise:
        uiCustomization?.affichage_champ_dt?.reference_personnalise ?? false,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData = () => {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  };

  // Renvoi true si au moins un des travaux a has_ergo à true
  checkErgo = (travaux) => {
    return travaux.some((t) => t?.type?.has_ergo);
  };

  //Renvoi un icon selon le type des travaux
  iconTravaux = (travaux) => {
    const { classes } = this.props;
    return (
      <Grid container columnSpacing={2}>
        <Typography className={classes.iconContainer}>
          <Icon className={classes.icon}>{travaux.type?.icon}</Icon>
          <strong>
            {travaux.type?.value}
            {travaux.description ? " : " : ""}
          </strong>
        </Typography>
        <Typography>&nbsp;{travaux.description}</Typography>
      </Grid>
    );
  };

  //Remove Contrat Locataire

  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };
  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le dossier travaux ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce dossier : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.travaux > 0 && (
              <li>{nbrEntity.travaux} travaux associé(s)</li>
            )}
            {nbrEntity.devis > 0 && (
              <li>{nbrEntity.devis} devi(s) associé(s)</li>
            )}
            {nbrEntity.financements > 0 && (
              <li>{nbrEntity.financements} financement(s) associé(s)</li>
            )}
            {nbrEntity.evenements > 0 && (
              <li>{nbrEntity.evenements} évènement(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  renderADApres = () => {
    const {
      has_audit_apres,
      audit_avant,
      audit_apres,
      taux_amelioration_dpe,
      taux_amelioration_ges,
    } = this.state;

    let data =
      audit_apres?.type_audit === AuditUtils.TYPE_AUDIT[1].name
        ? audit_apres
        : null;
    let fromAuditResultat = false;

    const shouldUseAuditAvant =
      !has_audit_apres ||
      (!data && audit_avant?.type_audit === AuditUtils.TYPE_AUDIT[1].name);

    if (shouldUseAuditAvant) {
      data = audit_avant?.audit_resultat_valide;
      fromAuditResultat = true;
    }

    if (!data) {
      return null;
    }

    const resultat = data?.resultat;
    const consommationEnergetique = data?.consommation_energetique;
    const etiquetteGes = data?.etiquette_ges;
    const co2 = data?.co2;

    return (
      <>
        <TableRow>
          <TableCell
            align="center"
            colSpan="3"
            style={{ border: "transparent" }}
          >
            <HeaderTitle
              label={
                <>
                  Données énergétiques
                  {fromAuditResultat && (
                    <InfoLightTooltip
                      title={DossierTravauxUtils.AUDIT_ENERGETIQUE}
                      placement="top"
                    />
                  )}
                </>
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography>
              <strong>Étiquette énergie DPE</strong>
            </Typography>
          </TableCell>
          <TableCell align="center">{audit_avant?.resultat}</TableCell>
          <TableCell align="center">{resultat}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography>
              <strong>Consommation énergétique</strong>
            </Typography>
          </TableCell>
          <TableCell align="center">
            {audit_avant?.consommation_energetique
              ? audit_avant?.consommation_energetique + " kWh/m²/an"
              : ""}
          </TableCell>
          <TableCell align="center">
            {consommationEnergetique
              ? consommationEnergetique + " kWh/m²/an"
              : ""}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography>
              <strong>Taux d&apos;amélioration réel DPE</strong>
            </Typography>
          </TableCell>
          <TableCell align="center" colSpan="2">
            {Render.ifNotEmpty(taux_amelioration_dpe, "", " %")}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography>
              <strong>Étiquette GES</strong>
            </Typography>
          </TableCell>
          <TableCell align="center">{audit_avant?.etiquette_ges}</TableCell>
          <TableCell align="center">{etiquetteGes}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography>
              <strong>Émission CO2</strong>
            </Typography>
          </TableCell>
          <TableCell align="center">
            {audit_avant?.co2 ? audit_avant?.co2 + " CO2/m²/an" : ""}
          </TableCell>
          <TableCell align="center">{co2 ? co2 + " CO2/m²/an" : ""}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" style={{ border: "transparent" }}>
            <Typography>
              <strong>Taux d&apos;amélioration réel GES</strong>
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            colSpan="2"
            style={{ border: "transparent" }}
          >
            {Render.ifNotEmpty(taux_amelioration_ges, "", " %")}
          </TableCell>
        </TableRow>
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      logement,
      technicien,
      conseiller,
      plan_financement,
      travaux,
      audit_avant,
      audit_apres,
      raison_abandon_dt,
      donneurordres,
      code_identification,
      dispositifs,
      types_prets,
      raison_contact,
      origine_contact,
      mode_contact,
      type_loyer_avant,
      type_loyer_apres,
      type_gestion_avant,
      type_gestion_apres,
      info_fiscale,
      updated_at,
      ouverture,
      envoi,
      reception,
      demande_paiement,
      debut,
      statut,
      infos,
      carton,
      ergo_preconise,
      ergo_passe,
      date_fin_travaux,
      raison_fin_travaux,
      reference_externe,
      date_fin_dossier,
      date_visite_avant_travaux,
      date_rapport_technicien,
      contrat_amo_valide,
      beneficiaire_pret_bancaire,
      lot,
      nb_logement_contact,
      nb_logement,
      chauffage_avant,
      chauffage_apres,
      surface_avant,
      surface_apres,
      potentiel_lhi,
      potentiel_al,
      dup,
      nombre_logement_concerne,
      nombre_loyer_libre_avant,
      nombre_loyer_libre_apres,
      nombre_loyer_conventionne_avant,
      nombre_loyer_conventionne_apres,
      nombre_loyer_conventionne_tres_social_avant,
      nombre_loyer_conventionne_tres_social_apres,
      date_relance_primo,
      indice_degradation_avant,
      indice_degradation_apres,
      cotation_insalubre_avant,
      cotation_insalubre_apres,
      date_demande_document,
      date_validation_document,
      date_visite_apres_travaux,
      statut_avant_annulation,
      date_demande_paiement_cotisation,
      cotisation_provisionnelle,
      cotisation_paye,
      date_facturation_amo_sans_subvention,
      amo_sans_subvention_prevue,
      amo_sans_subvention_payee,
      reference_personnalise,
      has_visite_avant_travaux,
      has_visite_apres_travaux,
      id,
      alert,
      affichage_statut_personnalise,
      affichage_reference_personnalise,
      statut_personnalise,
      raison_attente,
    } = this.state;
    const beneficiaire = logement?.beneficiaire;
    const listTravaux = audit_avant?.audit_resultat_valide?.typetravaux ?? null;
    const { statut_propriete, categorie_ressource } = beneficiaire ?? {};
    const evenementsLink = "/evenements/travaux/dossier_travaux/" + id;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar sx={{ bgcolor: categorie_ressource?.color_mpr }}>
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bénéficiaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prénom : </strong>
                {beneficiaire?.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {beneficiaire?.nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(beneficiaire?.tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(beneficiaire?.mail)}
              </Typography>
              <Typography>
                <strong>Statut de propriété : </strong>
                {statut_propriete?.value}
              </Typography>
              <Typography>
                <strong>Catégorie de ressources : </strong>
                {categorie_ressource?.value}
              </Typography>
              <CheckBoxView
                label="Accès au numérique ?"
                value={beneficiaire?.acces_numerique}
              />
              <Typography>
                <strong>Année fiscale de référence : </strong>
                {info_fiscale?.annee}
              </Typography>
              <Typography>
                <strong>Revenu fiscal de référence : </strong>
                {Render.euro(info_fiscale?.revenu_fiscal)}
              </Typography>
              <Typography>
                <strong>Revenu brut : </strong>
                {Render.euro(info_fiscale?.revenu_brut)}
              </Typography>
              <Typography>
                <strong>Nombre de parts : </strong>
                {info_fiscale?.nb_parts}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <PersonAdd />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Primo Contact
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Raison de contact : </strong>
                {raison_contact?.value}
              </Typography>
              <Typography>
                <strong>Origine de contact : </strong>
                {origine_contact?.value}
              </Typography>
              <Typography>
                <strong>Mode de contact : </strong>
                {mode_contact?.value}
              </Typography>
              <Typography>
                <strong>Nombre de logements au moment du contact : </strong>
                {nb_logement_contact}
              </Typography>
              <CheckBoxView label="Potentiel LHI" value={potentiel_lhi} />
              <CheckBoxView label="Potentiel AL" value={potentiel_al} />
              <CheckBoxView
                label="DUP (Déclaration d'Utilité Publique)"
                value={dup}
              />
              <Typography>
                <strong>Date de relance : </strong>
                {Render.date(date_relance_primo)}
              </Typography>
            </CardContent>
          </Card>
          {this.canEditDosserTravaux && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <Business />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    SOLIHA
                  </Typography>
                }
              />
              <CardContent>
                <Typography>
                  <strong>Conseiller en charge : </strong>
                  {Render.fullName(conseiller)}
                </Typography>
                <Typography>
                  <strong>Technicien en charge : </strong>
                  {Render.fullName(technicien)}
                </Typography>
                <Typography>
                  <strong>Référence : </strong>
                  DT{id}
                </Typography>
                {Boolean(affichage_reference_personnalise) && (
                  <Typography>
                    <strong>Référence personnalisée : </strong>
                    {reference_personnalise}
                  </Typography>
                )}
                <Typography>
                  <strong>N° carton/archive : </strong>
                  {carton}
                </Typography>
                <HeaderTitle
                  label={
                    <>
                      AMO subventionnée
                      <InfoLightTooltip
                        title={DossierTravauxUtils.AMO_SUBVENTION}
                        placement="top"
                      />
                    </>
                  }
                />
                <Typography>
                  <strong>Frais AMO prévus : </strong>
                  {Render.euro(
                    plan_financement?.montant_amo_subventions_previsionnel,
                  )}
                </Typography>
                <CheckBoxShow
                  checked={
                    plan_financement?.montant_amo_subventions_previsionnel ===
                    plan_financement?.montant_amo_subventions_definitif
                  }
                  label="Frais AMO payés"
                  data={Render.euro(
                    plan_financement?.montant_amo_subventions_definitif,
                  )}
                />
                <HeaderTitle
                  label={
                    <>
                      AMO non subventionnée
                      <InfoLightTooltip
                        title={DossierTravauxUtils.AMO_SANS_SUBVENTION}
                        placement="top"
                      />
                    </>
                  }
                />
                <Typography>
                  <strong>Frais AMO prévus : </strong>
                  {Render.euro(amo_sans_subvention_prevue)}
                </Typography>
                <Typography>
                  <strong>Date de paiement de l&apos;AMO : </strong>
                  {Render.date(date_facturation_amo_sans_subvention)}
                </Typography>
                <CheckBoxShow
                  checked={
                    amo_sans_subvention_prevue === amo_sans_subvention_payee
                  }
                  label="Frais AMO payés"
                  data={Render.euro(amo_sans_subvention_payee)}
                />
                <HeaderTitle label="cotisation" />
                <Typography>
                  <strong>Frais de cotisation prévus : </strong>
                  {Render.euro(cotisation_provisionnelle)}
                </Typography>
                <Typography>
                  <strong>Date de paiement de la cotisation : </strong>
                  {Render.date(date_demande_paiement_cotisation)}
                </Typography>
                <CheckBoxShow
                  checked={cotisation_provisionnelle === cotisation_paye}
                  label="Frais de cotisation payés"
                  data={Render.euro(cotisation_paye)}
                />
              </CardContent>
            </Card>
          )}
          {this.canEditDosserTravaux && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <RecordVoiceOver />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Donneur d&apos;ordres
                  </Typography>
                }
              />
              <CardContent>
                <Typography>
                  <strong>Donneur d&apos;ordres : </strong>
                  {donneurordres?.nom}
                </Typography>
                <Typography>
                  <strong>Code d&apos;identification : </strong>
                  {code_identification}
                </Typography>
                <Typography>
                  <strong>Référence externe / BDC : </strong>
                  {reference_externe}
                </Typography>
              </CardContent>
            </Card>
          )}
          {this.CAN_USE_DT && (
            <DocumentTemplate baseURL={this.FRONT_URL} objectId={id} />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Adresse : </strong>
                {Render.address(logement?.adresse)}
              </Typography>
              <Typography>
                <strong>Ville : </strong>
                {logement?.adresse?.ville?.cp} {logement?.adresse?.ville?.ville}
              </Typography>
              <Typography>
                <strong>Étage : </strong>
                {logement?.adresse?.etage}
              </Typography>
              <Typography>
                <strong>Complément de localisation : </strong>
                {logement?.adresse?.complement_localisation}
              </Typography>
              <Typography>
                <strong>Type : </strong>
                {logement?.type?.value}
              </Typography>
              <Typography>
                <strong>Typologie : </strong>
                {logement?.typologie?.value}
              </Typography>
              <CheckBoxView
                label="Prêt bancaire"
                value={beneficiaire_pret_bancaire}
              />
              {Boolean(beneficiaire_pret_bancaire) && (
                <Typography>
                  <strong>Types de prêts : </strong>
                  {types_prets?.map((type_pret) => type_pret.value)?.join(", ")}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Build />
                </CardAvatar>
              }
              title={
                <div>
                  <Typography variant="h6" component="h5">
                    Dossier travaux DT{id} :{" "}
                    {DossierTravauxUtils.renderStatut(statut)}
                  </Typography>
                  <Typography variant="subtitle1" component="p">
                    Date dernière mise à jour : {Render.date(updated_at)}
                  </Typography>
                </div>
              }
            />
            <CardContent>
              <Grid item xs={12}>
                {Boolean(affichage_statut_personnalise) && (
                  <Typography>
                    <strong>Statut personnalisé : </strong>
                    {statut_personnalise?.value}
                  </Typography>
                )}
                {statut !== "annule" && raison_attente && (
                  <>
                    <Typography>
                      <strong>Raison d&apos;attente : </strong>
                      {raison_attente?.value}
                    </Typography>
                  </>
                )}
                {statut === "annule" && (
                  <>
                    <Typography>
                      <strong>Raison abandon : </strong>
                      {raison_abandon_dt?.value}
                    </Typography>
                    {statut_avant_annulation && (
                      <Typography>
                        <strong>Statut avant annulation : </strong>
                        {DossierTravauxUtils.renderStatut(
                          statut_avant_annulation,
                        )}
                      </Typography>
                    )}
                  </>
                )}
                <HeaderTitle label="étapes" />
                <Typography>
                  <strong>
                    Ouverture du dossier{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.PRIMO_CONTACT}
                    />{" "}
                    :{" "}
                  </strong>
                  {Render.date(ouverture)}
                </Typography>
                <Typography>
                  <strong>
                    Demande de documents bénéficiaire{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.ATTENTE_DOCUMENTS_VALIDATION}
                    />{" "}
                    :{" "}
                  </strong>
                  {Render.date(date_demande_document)}
                </Typography>
                <Typography>
                  <strong>
                    Validation de documents{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.VALIDATION_DOCUMENTS}
                    />{" "}
                    :{" "}
                  </strong>
                  {Render.date(date_validation_document)}
                </Typography>
                <Typography>
                  <strong>
                    Visite avant travaux{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.VISITE_A_PROGRAMMER}
                    />{" "}
                    :{" "}
                  </strong>
                  {has_visite_avant_travaux ? (
                    <>{Render.date(date_visite_avant_travaux)}</>
                  ) : (
                    "Pas de visite avant travaux"
                  )}
                </Typography>
                <Typography>
                  <strong>
                    Envoi de l&apos;audit{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.MONTAGE_BENEFICIAIRE}
                    />{" "}
                    :{" "}
                  </strong>
                  {Render.date(audit_avant?.date_envoi)}
                </Typography>
                <Typography>
                  <strong>
                    Contrat AMO signé{" "}
                    <InfoLightTooltip title={DossierTravauxUtils.RECU_SIGNE} />{" "}
                    :{" "}
                  </strong>
                  {Render.date(contrat_amo_valide)}
                </Typography>
                <Grid container columnSpacing={2}>
                  <Grid item>
                    <Typography>
                      <strong>
                        Envoi aux caisses financeurs{" "}
                        <InfoLightTooltip
                          title={DossierTravauxUtils.DEPOSE_ENVOYE}
                        />{" "}
                        :{" "}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckBoxShow checked={envoi} />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item>
                    <Typography>
                      <strong>
                        Notification des caisses financeurs{" "}
                        <InfoLightTooltip
                          title={DossierTravauxUtils.AGREE_NOTIFIE}
                        />{" "}
                        :{" "}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckBoxShow checked={reception} />
                  </Grid>
                </Grid>
                <Typography>
                  <strong>
                    Début des travaux{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.DEBUT_TRAVAUX}
                    />{" "}
                    :{" "}
                  </strong>
                  {Render.date(debut)}
                </Typography>
                <Typography>
                  <strong>
                    Fin des travaux{" "}
                    <InfoLightTooltip title={DossierTravauxUtils.FIN_TRAVAUX} />{" "}
                    :{" "}
                  </strong>
                  {Render.date(date_fin_travaux)}
                </Typography>
                <Typography>
                  <strong>
                    Visite après travaux{" "}
                    <InfoLightTooltip
                      title={DossierTravauxUtils.VISITE_FIN_TRAVAUX}
                    />{" "}
                    :{" "}
                  </strong>
                  {has_visite_apres_travaux ? (
                    <>{Render.date(date_visite_apres_travaux)}</>
                  ) : (
                    "Pas de visite après travaux"
                  )}
                </Typography>
                <Typography>
                  <strong>Rapport technicien : </strong>
                  {Render.date(date_rapport_technicien)}
                </Typography>
                <Grid container columnSpacing={2}>
                  <Grid item>
                    <Typography>
                      <strong>
                        Demande de paiement aux caisses financeurs{" "}
                        <InfoLightTooltip title={DossierTravauxUtils.FACTURE} />{" "}
                        :{" "}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckBoxShow checked={demande_paiement} />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item>
                    <Typography>
                      <strong>
                        Payé{" "}
                        <InfoLightTooltip title={DossierTravauxUtils.PAYE} /> :{" "}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckBoxShow
                      checked={
                        statut_avant_annulation == null
                          ? statut_avant_annulation === "paye" ||
                            statut_avant_annulation === "cloture"
                          : statut === "paye" || statut === "cloture"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item>
                    <Typography>
                      <strong>
                        Cloturé{" "}
                        <InfoLightTooltip title={DossierTravauxUtils.CLOTURE} />{" "}
                        :{" "}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CheckBoxShow
                      checked={
                        statut_avant_annulation == null
                          ? statut_avant_annulation === "cloture"
                          : statut === "cloture"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  {date_fin_travaux && (
                    <Typography>
                      <strong>Raison de fin des travaux : </strong>
                      {raison_fin_travaux}
                    </Typography>
                  )}
                </Grid>
                <Typography>
                  <strong>Fin de dossier : </strong>
                  {Render.date(date_fin_dossier)}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          <DispositifView
            dispositifs={dispositifs}
            ville={logement?.adresse?.ville}
            lot={lot}
          />
          {this.GED_DT_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="travaux"
              type="DT"
              objectId={id}
              permissions={this.GED_DT_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <TravauxLinkCard
            beneficiaire={logement?.beneficiaire}
            logement={logement}
            audit={audit_avant}
            auditApres={audit_apres}
            dossierTravaux={{ id: id }}
            hideAuditApres={audit_apres ? false : true}
            hideDossierTravaux={true}
          />
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Handyman />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Définition
                </Typography>
              }
            />
            <CardContent>
              {travaux.length > 0 ? (
                <>
                  <Grid item>
                    <Typography>
                      <strong>Typologie de travaux : </strong>
                    </Typography>
                  </Grid>
                  {travaux.map((travaux, key) => {
                    return (
                      <Grid item key={key} xs={12}>
                        {this.iconTravaux(travaux)}
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <Grid item>
                  <Typography>
                    <strong>Aucune typologie de travaux enregistrées</strong>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <HeaderTitle label="Bouquet travaux validé" />
                {!audit_avant && (
                  <Typography>
                    Aucun audit rattaché et donc aucun bouquet travaux validés
                  </Typography>
                )}
                {audit_avant && !listTravaux && (
                  <Typography>
                    Aucun bouquet travaux validés dans l&apos;audit rattaché
                  </Typography>
                )}
                {listTravaux?.tree?.length > 0 && (
                  <SimpleTreeView
                    tree={listTravaux.tree}
                    defaultExpanded={listTravaux.parent_ids ?? []}
                    collapseAll
                  />
                )}
                {this.checkErgo(travaux) && (
                  <>
                    <HeaderTitle label="Ergothérapeute" />
                    <Typography>
                      <strong>Ergothérapeute préconisé : </strong>
                      {this.NO_YES[ergo_preconise]}
                    </Typography>
                    <Typography>
                      <strong>Ergothérapeute passé : </strong>
                      {this.NO_YES[ergo_passe]}
                    </Typography>
                  </>
                )}
                <HeaderTitle label="Informations complémentaires" />
                <MultilineTypography sx={{ px: 1 }}>
                  {infos ? infos : "Aucune information complémentaire"}
                </MultilineTypography>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <CompareIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations avant/après travaux
                </Typography>
              }
            />
            <CardContent>
              <TableContainer component="div">
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#0A4F75" }}>
                      <TableCell />
                      <TableCell align="center" style={{ color: "white" }}>
                        <Typography>
                          <strong>Avant</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white" }}>
                        <Typography>
                          <strong>Après</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {audit_avant?.type_audit ===
                      AuditUtils.TYPE_AUDIT[1].name && (
                      <>{this.renderADApres()}</>
                    )}
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan="3"
                        style={{ border: "transparent" }}
                      >
                        <HeaderTitle label="Logements" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Nombre de logements</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {nombre_logement_concerne}
                      </TableCell>
                      <TableCell align="center">{nb_logement}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Chauffage</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{chauffage_avant}</TableCell>
                      <TableCell align="center">{chauffage_apres}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Surface</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {surface_avant ? surface_avant + " m²" : ""}
                      </TableCell>
                      <TableCell align="center">
                        {surface_apres ? surface_apres + " m²" : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Type de loyer</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {type_loyer_avant?.value}
                      </TableCell>
                      <TableCell align="center">
                        {type_loyer_apres?.value}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ border: "transparent" }}>
                        <Typography>
                          <strong>Type de gestion</strong>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "transparent" }}
                      >
                        {type_gestion_avant?.value}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "transparent" }}
                      >
                        {type_gestion_apres?.value}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan="3"
                        style={{ border: "transparent" }}
                      >
                        <HeaderTitle label="Nombre de logements en loyer" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Loyer libre</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {nombre_loyer_libre_avant}
                      </TableCell>
                      <TableCell align="center">
                        {nombre_loyer_libre_apres}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Loyer conventionné</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {nombre_loyer_conventionne_avant}
                      </TableCell>
                      <TableCell align="center">
                        {nombre_loyer_conventionne_apres}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ border: "transparent" }}>
                        <Typography>
                          <strong>Loyer conventionné très social</strong>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "transparent" }}
                      >
                        {nombre_loyer_conventionne_tres_social_avant}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "transparent" }}
                      >
                        {nombre_loyer_conventionne_tres_social_apres}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan="3"
                        style={{ border: "transparent" }}
                      >
                        <HeaderTitle label="Insalubrité" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography>
                          <strong>Indice de dégradation</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {cotation_insalubre_avant}
                      </TableCell>
                      <TableCell align="center">
                        {cotation_insalubre_apres}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ border: "transparent" }}>
                        <Typography>
                          <strong>Cotation insalubrité</strong>
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "transparent" }}
                      >
                        {indice_degradation_avant}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "transparent" }}
                      >
                        {indice_degradation_apres}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          {this.CAN_VIEW_TRAVAUX_EVENTS && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <Today />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Évènements
                  </Typography>
                }
                action={
                  <Link to={evenementsLink + "?form=open"}>
                    <Button size="small">
                      {this.CAN_EDIT_TRAVAUX_EVENTS ? "Ajouter" : "Voir"}
                    </Button>
                  </Link>
                }
              />
              <CardContent>
                <Link to={evenementsLink}>
                  <Evenements
                    modelId={id}
                    modelType="dossier_travaux"
                    dense
                    limit={5}
                  />
                </Link>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12}>
          <DevisListForm
            modelId={id}
            modelType="dossier_travaux"
            onUpdate={this.loadAsyncData}
            section="travaux"
            canEdit={this.canEditDosserTravaux}
            title={
              plan_financement?.montant_total_travaux_ttc_devis
                ? plan_financement?.montant_total_travaux_ht_devis
                  ? `${Render.euro(
                      plan_financement.montant_total_travaux_ht_devis,
                      "Travaux : ",
                      " HT",
                    )}${Render.euro(
                      plan_financement.montant_total_travaux_ttc_devis,
                      " / ",
                      " TTC",
                    )}`
                  : `${Render.euro(
                      plan_financement.montant_total_travaux_ttc_devis,
                      "Travaux : ",
                      " TTC",
                    )}`
                : "Travaux"
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FinancementListForm
            dossierTravauxId={id}
            plan_financement={plan_financement}
            cotiPrev={cotisation_provisionnelle}
            cotiPaye={cotisation_paye}
            amoSansSubPrev={amo_sans_subvention_prevue}
            amoSansSubPaye={amo_sans_subvention_payee}
            statutDT={statut}
            onUpdate={this.loadAsyncData}
            canEdit={this.canEditDosserTravaux}
            title="Subventions"
          />
        </Grid>
        <Grid item xs={12} lg={6} />
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.canEditDosserTravaux && (
            <Link to={this.FRONT_URL + "/modifier/" + id}>
              <Button size="small" color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=DossierTravaux&id=" + id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {alert}
      </Grid>
    );
  }
}

DossiersTravauxView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
  uiCustomization: PropTypes.object,
};

export default withStyles(styles)(
  withUserContext(withUiCustomizationContext(DossiersTravauxView)),
);
