import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Business from "@mui/icons-material/Business";

import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

class ConfigOperateur extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      value: "",
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { errors } = this.state;

    return (
      <TablePopUpContainer
        icon={<Business />}
        dialogProps={{
          fullWidth: true,
        }}
        title="Opérateur"
        autoSize={false}
        searchBar
        backendUrl="/config-operateur"
        colDef={[
          { field: "id", editable: false, hide: true },
          {
            headerName: "Libellé",
            field: "value",
          },
        ]}
        formInitData={() => {
          this.setState({
            id: 0,
            value: "",
            errors: null,
          });
        }}
        formSetData={(data) => {
          this.setState({
            id: data.id ?? false,
            value: data.value ?? "",
            errors: null,
          });
        }}
        formGetData={() => ({
          id: this.state.id,
          value: this.state.value,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        getDeleteContent={(data) => data.value}
        getDeleteError={(data, error) => (
          <>
            {error?.countDispositifs > 0 && (
              <li>{error.countDispositifs} dispositif(s) associé(s)</li>
            )}
            {error?.countDts > 0 && (
              <li>{error.countDts} dossier(s) travaux associé(s)</li>
            )}
          </>
        )}
        formTitle={
          this.state.id ? "Modifier l'opérateur" : "Ajouter un opérateur"
        }
        formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Libellé"
                name="value"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.value}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.value)}
              />
            </Grid>
          </Grid>
        }
      />
    );
  }
}

export default ConfigOperateur;
