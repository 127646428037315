import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import List from "@mui/icons-material/List";
import TrendingUp from "@mui/icons-material/TrendingUp";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";

import SearchBarService from "services/SearchBarService";

import Render from "Utils/RenderUtils";

import { withUserContext } from "context/UserContext";

class IRLsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.irl");
    this.CAN_DELETE = this.USER.can("delete.irl");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/irls";
    this.FRONT_URL = "/tiers/irls";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // DATA FORM
      annee: "",
      trimestre: "",
      valeur: "",
      publication: "",
      // END DATA FORM
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  //Remove IRL
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const entities = err.response.data;
          this.onError(entities);
        }
      });
  };
  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer l'IRL ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (entities) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer l'IRL : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {entities.contrat_locataire > 0 && (
              <li>
                {entities.contrat_locataire} contrat(s) locataire(s) associé(s)
              </li>
            )}
            {entities.contrat_proprietaire > 0 && (
              <li>
                {entities.contrat_proprietaire} contrat(s) proprietaire(s)
                associé(s)
              </li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const { annee, trimestre, valeur, publication } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <TrendingUp />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  IRL
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Année : </strong>
                {annee}
              </Typography>
              <Typography>
                <strong>Trimestre : </strong>T{trimestre}
              </Typography>
              <Typography>
                <strong>Valeur : </strong>
                {valeur}
              </Typography>
              <Typography>
                <strong>Date de publication : </strong>
                {Render.date(publication)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button size="small" color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=IRL&id=" + this.state.id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

IRLsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(IRLsView);
