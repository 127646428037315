import styled from "@mui/system/styled";
import MuiCard from "@mui/material/Card";

const Card = styled(MuiCard)(({ theme }) => ({
  overflow: "visible",
  marginBottom: theme.spacing(2),
  "& .MuiCardContent-root:last-child": {
    paddingBottom: theme.spacing(1),
  },
}));

export default Card;
