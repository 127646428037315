class AbortRequest {
  constructor() {
    this.abortController = null;
  }

  abort() {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    return this.abortController;
  }

  getSignal() {
    return this.abortController.signal;
  }

  abortAndGetSignal() {
    return this.abort().signal;
  }
}

export default AbortRequest;
