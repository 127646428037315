import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoAuth } from "variables/axiosConfigs.jsx";
import logo from "assets/img/logo-hapy.png";
import { Link } from "react-router-dom";

//Services
import { verifyEmail, verifyLength } from "services/controlForms.jsx";

// @mui/material components
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import TextInput from "components/CustomInput/TextInput";

class ResetPasswordFrom extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      token: this.QUERY_PARAMS.get("token") || "",
      userEmail: this.QUERY_PARAMS.get("email") || "",
      first: this.QUERY_PARAMS.get("first") === "1",
      password: "",
      passwordConfirm: "",
      errorEmail: false,
      successEmail: false,
      errorPassword: false,
      successPassword: false,
      errorPasswordConfirm: false,
      successPasswordConfirm: false,
      message: "",
      loading: false,
    };
  }

  getUserMail = () => {
    this.setState({
      message: "",
      loading: true,
    });

    const data = {
      email: this.state.userEmail,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirm,
      token: this.state.token,
    };

    axiosApiBackendNoAuth
      .post("/reset-password", data)
      .then(() => {
        this.props.history.push("/?passwordChanged=true");
      })
      .catch((error) => {
        let errMsg = "Votre demande a échoué.";
        if (error.response?.status === 400 || error.response?.status === 422) {
          if (
            error.response?.data?.error === "passwords.token" ||
            error.response?.data?.errors?.token
          ) {
            errMsg = (
              <>
                Le Lien est expiré, faites un{" "}
                <Link to="/forgot-password">mot de passe oublié</Link>.
              </>
            );
          } else {
            errMsg = "Email ou mot de passe invalide.";
          }
        }
        this.setState({
          message: errMsg,
          loading: false,
        });
      });
  };

  forgotPasswordClick = (e) => {
    e.preventDefault();
    this.getUserMail();
  };

  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBlurEmail = () => {
    let control = verifyEmail(this.state.userEmail);
    this.setState({
      successEmail: control,
      errorEmail: !control,
    });
  };

  onBlurPassword = () => {
    let control = verifyLength(this.state.password, 8);
    this.setState({
      successPassword: control,
      errorPassword: !control,
    });
  };

  onBlurPasswordConfirm = () => {
    let control =
      verifyLength(this.state.passwordConfirm, 8) &&
      this.state.passwordConfirm === this.state.password;
    this.setState({
      successPasswordConfirm: control,
      errorPasswordConfirm: !control,
    });
  };

  render() {
    const { successEmail, successPassword, successPasswordConfirm } =
      this.state;

    return (
      <Grid container columnSpacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardHeader
              title={
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <img style={{ width: "70%" }} src={logo} alt="Logo Hapy" />
                </Box>
              }
            />
            <CardContent>
              <Typography sx={{ textAlign: "center", my: 1 }} variant="h5">
                {this.state.first ? "Initialisation " : "Réinitialisation "}
                de votre mot de passe
              </Typography>

              <form onSubmit={this.forgotPasswordClick}>
                <TextInput
                  sx={
                    successEmail ? { label: { color: "success.main" } } : null
                  }
                  label="Adresse email"
                  name="userEmail"
                  type="email"
                  onChange={this.change}
                  onBlur={this.onBlurEmail}
                  value={this.state.userEmail}
                  error={this.state.errorEmail}
                  color={successEmail ? "success" : null}
                  disabled={this.state.first ? true : false}
                  required
                />
                <TextInput
                  sx={
                    successPassword
                      ? { label: { color: "success.main" } }
                      : null
                  }
                  label="Mot de passe"
                  name="password"
                  type="password"
                  onChange={this.change}
                  onBlur={this.onBlurPassword}
                  value={this.state.password}
                  error={this.state.errorPassword}
                  color={successPassword ? "success" : null}
                  required
                />
                <TextInput
                  sx={
                    successPasswordConfirm
                      ? { label: { color: "success.main" } }
                      : null
                  }
                  label="Confirmation mot de passe"
                  name="passwordConfirm"
                  type="password"
                  onChange={this.change}
                  onBlur={this.onBlurPasswordConfirm}
                  value={this.state.passwordConfirm}
                  error={this.state.errorPasswordConfirm}
                  color={successPassword ? "success" : null}
                  required
                />

                <Typography color="error">{this.state.message}</Typography>

                <Box
                  sx={{
                    marginBottom: "0",
                    color: "#999999",
                    fontSize: "14px",
                    py: "10px",
                  }}
                >
                  <small>*</small> Champs obligatoires
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <LoadingButton type="submit" loading={this.state.loading}>
                    {this.state.first ? "Initialiser " : "Réinitialiser "}
                    le mot de passe
                  </LoadingButton>
                </Box>
              </form>
              <Typography align="right">
                <Link to="/">se connecter</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

ResetPasswordFrom.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
};

export default ResetPasswordFrom;
