import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Person from "@mui/icons-material/Person";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

// components
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import SelectInput from "components/CustomSelect/SelectInput.jsx";
import PersonneUtils from "Utils/PersonneUtils";
import CreatePersonneForm from "views/Forms/Personne/CreatePersonneForm";

function PersonneEditCard(props) {
  const { arrKey, personne, onChange, onDelete, errors } = props;

  const handleChange = (name, value, subName) => {
    onChange(arrKey, name, value, subName);
  };

  const handleChangeEvent = (event, subName) => {
    handleChange(event.target.name, event.target.value, subName);
  };

  const handleChangeCheckbox = (event, subName) => {
    handleChange(event.target.name, event.target.checked, subName);
  };

  const handleChangeEventLien = (event) => {
    handleChangeEvent(event, "lien");
  };

  const handleChangeCheckboxLien = (event) => {
    handleChangeCheckbox(event, "lien");
  };

  const handleDelete = () => {
    onDelete(arrKey, personne);
  };

  const handlePersonneHorsGestionChange = (personneId) => {
    handleChange("id", personneId);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <CardAvatar color="social">
            <Person />
          </CardAvatar>
        }
        title={
          <Typography variant="h6" component="h5">
            Personnes
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <CreatePersonneForm
            label="Choisir une personne"
            apiUrl="/personnes"
            personne={personne}
            personne_id={personne.id}
            errors={errors}
            onPersonneChange={handlePersonneHorsGestionChange}
          />
          <Grid item xs={12}>
            <SelectInput
              label="Statut"
              name="statut"
              onChange={handleChangeEventLien}
              value={personne.lien.statut}
              error={Boolean(errors?.["lien.statut"])}
            >
              <MenuItem value="">
                <em>Non renseigné</em>
              </MenuItem>
              {PersonneUtils.STATUT.map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </SelectInput>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(personne.lien.referent)}
                  onChange={handleChangeCheckboxLien}
                  name="referent"
                />
              }
              style={errors?.["lien.referent"] ? { color: "red" } : null}
              label="Titulaire contrat "
            />
          </Grid>
          <IconButton onClick={handleDelete} size="large">
            <Tooltip title="Supprimer">
              <RemoveCircle color="error" fontSize="large" />
            </Tooltip>
          </IconButton>
        </Grid>
      </CardContent>
    </Card>
  );
}

PersonneEditCard.propTypes = {
  arrKey: PropTypes.any,
  personne: PropTypes.any,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.any,
};

export default PersonneEditCard;
