import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import FileCopy from "@mui/icons-material/FileCopy";
import Input from "@mui/icons-material/Input";
import Search from "@mui/icons-material/Search";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import DocumentTemplateRenderer from "components/CustomAgRenderer/DocumentTemplateRenderer";
import SearchBar from "components/SearchBar/SearchBar";

import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";

class DocumentTemplate extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/" + this.props.objectId + "/documents/templates";
    this.AG_UTILS = new AgGridUtils("/documents/templates");
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.BACKEND_URL);

    this.gridApi = null;
    // this.gridColumnApi = null;

    this.state = {
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
      alert: null,
      downloading: false,
      snackbar: false,
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          flex: 3,
          headerName: "Fichier",
          field: "filename",
          sort: "asc",
          cellStyle: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            padding: 0,
          },
          valueFormatter: (params) =>
            Render.removeFileExtension(params.data.filename),
        },
        {
          flex: 1,
          cellStyle: {
            textAlign: "right",
          },
          headerName: "Détails",
          field: "buttons",
          editable: false,
          sortable: false,
          cellRenderer: DocumentTemplateRenderer,
          cellRendererParams: (params) => ({
            buttons: [
              {
                label: <FileCopy />,
                templateType: params.data?.templateType,
                objectId: this.props.objectId,
                fileName: params.data.filename,
                loading: this.state.downloading,
                callback: this.props?.callback,
                onClick: () => this.downloadFile(params),
              },
            ],
          }),
        },
      ]),
      getRowStyle: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.props.baseURL + this.BACKEND_URL)
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  alertError = (title, errorMessage) => {
    return (
      <AlertDialog
        title={title}
        onCancel={this.clearAlert}
        cancelLabel="OK"
        cancelColor="error"
      >
        {errorMessage}
      </AlertDialog>
    );
  };

  updateDownloadingState = (downloading, row) => {
    this.setState({ downloading }, () => {
      const nodesToRedraw = [];

      this.gridApi.forEachNode((node) => {
        if (node.data.filename === row.data.filename) {
          nodesToRedraw.push(node);
        }
      });

      if (nodesToRedraw.length > 0) {
        this.gridApi.redrawRows({ rowNodes: nodesToRedraw });
      }
    });
  };

  downloadFile = (row) => {
    const url = this.props.baseURL + this.BACKEND_URL + "/" + row.data.filename;
    this.updateDownloadingState(true, row);

    axiosApiBackendNoJson
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, row.data.filename);
      })
      .catch((error) => {
        const titleError = "Erreur de génération du fichier";
        if (error.response.status === 404) {
          this.setState({
            alert: this.alertError(titleError, "Le modèle est introuvable"),
          });
          this.loadAsyncData();
        } else {
          this.setState({
            alert: this.alertError(
              titleError,
              "Une erreur est survenue côté serveur",
            ),
          });
        }
      })
      .finally(() => {
        this.updateDownloadingState(false, row);
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onBlur = (/*event*/) => {
    // this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    // this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    const { alert } = this.state;

    return (
      <Card>
        {alert}
        <CardHeader
          avatar={
            <CardAvatar
              sx={{ bgcolor: (this.props.color ?? "info") + ".main" }}
            >
              <Input />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              {this.props.title ?? "Générer un document"}
            </Typography>
          }
          action={
            <Button
              square
              onClick={() =>
                this.setState({ showSearch: !this.state.showSearch })
              }
              size="small"
            >
              <Search />
            </Button>
          }
        />
        <CardContent>
          <Grid
            container
            style={{
              minHeight: "100px",
            }}
          >
            <Grid item xs={12}>
              <Collapse in={!!this.state.showSearch}>
                <Box>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Box>
              </Collapse>
            </Grid>
            <Grid item xs={12} className="ag-theme-material">
              <AgGridReact
                overlayNoRowsTemplate="Aucune donnée à afficher."
                enableCellTextSelection={true}
                animateRows={true}
                headerHeight={0}
                onGridReady={this.onGridReady}
                enableFilter={false}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.data}
                quickFilterText={this.state.quickFilterText}
                pagination={true}
                getRowStyle={this.state.getRowStyle}
                domLayout="autoHeight"
                paginationPageSize="5"
                rowMultiSelectWithClick={true}
                localeText={AG_GRID_LOCALE}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

DocumentTemplate.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  baseURL: PropTypes.string.isRequired,
  objectId: PropTypes.any.isRequired,
  callback: PropTypes.func,
};

export default DocumentTemplate;
