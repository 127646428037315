export default class DispositifUtils {
  static renderTableDispositifs = (dispositifs) => {
    return dispositifs.map((dispositif) => dispositif.value).join(", ");
  };

  static renderAnah = (ville) => {
    return ville?.zonesanah?.map((anah) => anah.value).join(", ");
  };

  static renderEpci = (ville) => {
    return ville?.epci?.map((epci) => epci.value).join(", ");
  };
}
