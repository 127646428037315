import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircle from "@mui/icons-material/CheckCircle";
import WarningRounded from "@mui/icons-material/WarningRounded";

const ParcelleCadastraleInput = ({
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  name,
  onChange,
  InputLabelProps,
  value,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(null);

  const handleChangeParcelleCadastrale = (event) => {
    const inputValue = event.target.value;
    const isValidNumber = /^(?:\d{4}\/)*\d{4}$/.test(inputValue);
    const characterOnly = inputValue.replace(/[^0-9/]/g, "");
    event.target.value = characterOnly;
    onChange(event);

    setIsValid(isValidNumber);
  };

  return (
    <TextField
      {...rest}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      onChange={handleChangeParcelleCadastrale}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      value={value ?? ""}
      InputProps={{
        endAdornment: isValid !== null && (
          <InputAdornment position="end">
            {isValid ? (
              <CheckCircle color="success" />
            ) : (
              <WarningRounded color="error" />
            )}
          </InputAdornment>
        ),
        ...rest.InputProps,
      }}
    />
  );
};

ParcelleCadastraleInput.propTypes = {
  fullWidth: PropTypes.any,
  margin: PropTypes.any,
  variant: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  InputLabelProps: PropTypes.any,
  value: PropTypes.any,
};

export default memo(ParcelleCadastraleInput);
