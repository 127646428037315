import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import AddBox from "@mui/icons-material/AddBox";
import Description from "@mui/icons-material/Description";
import Euro from "@mui/icons-material/Euro";
import GetApp from "@mui/icons-material/GetApp";
import HomeWork from "@mui/icons-material/HomeWork";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import ToggleChip from "components/CustomButtons/ToggleChip";
import MenuBottom from "components/MenuBottom/MenuBottom.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import AbortRequest from "services/AbortRequest";
import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import CLUtils from "Utils/CLUtils";
import FoyerUtils from "Utils/FoyerUtils";
import QueryUtils from "Utils/QueryUtils";
import Render from "Utils/RenderUtils";

import { withUserContext } from "context/UserContext";

class ContratsLocataires extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/contrats-locataires";
    this.FRONT_URL = "/contrats-locataires";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.ABORT_REQUEST = new AbortRequest();

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      filters: this.QUERY_PARAMS.get("filters")?.split(",") ?? ["presents"],
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Type contrat",
          field: "logement.contratproprietaire.type",
        },
        {
          headerName: "Locataire",
          field: "referents_name",
          valueGetter: (params) =>
            FoyerUtils.referentsFullName(params.data.foyer?.referentsactifs),
        },
        {
          headerName: "Type de bien",
          field: "logement.type",
        },
        {
          headerName: "Téléphone",
          field: "referents_telephone",
          valueGetter: (params) =>
            FoyerUtils.referentsPhones(params.data.foyer?.referentsactifs),
        },
        {
          headerName: "Email",
          field: "referents_email",
          valueGetter: (params) =>
            FoyerUtils.referentsEmails(params.data.foyer?.referentsactifs),
        },
        {
          headerName: "Type de paiement",
          field: "type_paiement.value",
        },
        {
          headerName: "Date entrée",
          field: "date_entree",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Date de préavis",
          field: "date_preavis",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Date de sortie",
          field: "date_sortie",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Nom de contrat CP",
          field: "logement.contratproprietaire.nom_contrat.value",
        },
        {
          headerName: "Solde",
          field: "solde",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Solde Mensuel",
          field: "solde_en_cours",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "N° comptable",
          field: "id_ciel",
        },
        {
          headerName: "Adresse",
          field: "adresse",
          valueGetter: (params) =>
            Render.address(params.data.logement?.adresse),
        },
        {
          headerName: "Code Postal",
          field: "logement.adresse.ville.cp",
        },
        {
          headerName: "Ville",
          field: "logement.adresse.ville.ville",
        },
        {
          headerName: "Référents sociaux",
          field: "referents_sociaux",
          valueGetter: (params) => {
            return params.data.foyer.referents_sociaux
              ?.map((rs) => {
                return rs.pivot.is_principal
                  ? Render.fullName(rs) + " (Principal)"
                  : Render.fullName(rs);
              })
              .join(", ");
          },
        },
        {
          headerName: "Propriétaire",
          field: "logement.proprietaire",
          valueGetter: (params) =>
            (params.data.logement?.proprietaire?.nom ?? "") +
            " " +
            (params.data.logement?.proprietaire?.prenom ?? ""),
        },
        {
          headerName: "Numéro contrat assurance",
          field: "ass_hab_num",
          comparator: AgGridUtils.naturalSort,
        },
        {
          headerName: "Type paiement",
          field: "logement.contratproprietaire.type_paiement",
        },
      ]),
      getRowStyle: (params) => {
        if (params.data.solde_ae <= -0.01) {
          return { color: "red" };
        }
      },
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: {
          filters: this.state.filters,
        },
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      })
      .catch(() => {});
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  toggleFilter = (filter) => {
    let filters = this.state.filters;
    let index = filters.indexOf(filter);

    if (index !== -1) {
      filters.splice(index, 1);
    } else {
      filters.push(filter);
    }

    this.setState({ filters: filters }, () => {
      this.loadAsyncData();
      if (filters.length) {
        QueryUtils.replaceSearchParams({ filters: filters.join(",") });
      } else {
        QueryUtils.deleteSearchParams("filters");
      }
    });
  };

  hasFilter = (filter) => {
    return this.state.filters.includes(filter);
  };

  render() {
    return (
      <Grid container columnSpacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <HomeWork />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">
                  Gestion des Contrats de Location
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                alignItems="center"
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item>
                  <ToggleChip
                    sx={{ m: 0.5 }}
                    color="primary"
                    label="Tous"
                    on={this.state.filters.length === 0}
                    onClick={() => {
                      this.setState({ filters: [] }, () => {
                        this.loadAsyncData();
                        QueryUtils.deleteSearchParams("filters");
                      });
                    }}
                  />
                  {CLUtils.ETATS.map((etat) => {
                    return (
                      <ToggleChip
                        sx={{ m: 0.5 }}
                        key={etat.name}
                        color="primary"
                        label={etat.label}
                        on={this.hasFilter(etat.name)}
                        onClick={() => this.toggleFilter(etat.name)}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL + "/ajouter"}>
            <Button size="small" square round>
              <AddBox />
            </Button>
          </Link>
          <Link to={this.FRONT_URL + "/echeancier"}>
            <Button size="small" square round>
              <Euro />
            </Button>
          </Link>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=ContratLocataire"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

ContratsLocataires.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ContratsLocataires);
