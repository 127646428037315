export default class AgGridUtils {
  constructor(url) {
    this.URL = url;
  }

  applyOrder(columnDefs) {
    let colsOrder = JSON.parse(localStorage.getItem(`colsOrder${this.URL}`));
    if (colsOrder != null) {
      return columnDefs.sort((a, b) => colsOrder[a.field] - colsOrder[b.field]);
    }
    return columnDefs;
  }

  saveOrder(columnApi) {
    const cols = columnApi.getAllGridColumns();
    var colsOrder = {};
    for (const key in cols) {
      colsOrder[cols[key].getId()] = key;
    }
    localStorage.setItem(`colsOrder${this.URL}`, JSON.stringify(colsOrder));
  }

  /**
   * Allow sorting xx1 => xx2 => xx18 and not xx1 => xx18 => xx2.
   */
  static naturalSort = (a, b) => {
    if (a == null || b == null) {
      return;
    }

    const ax = [],
      bx = [];

    // Split the strings into chunks (either purely numeric or non-numeric)
    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || ""]);
    });
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || ""]);
    });

    // Compare each chunk
    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }

    // Handle different length strings (e.g., "a" vs "a1")
    return ax.length - bx.length;
  };

  static getPageSize(height, ref, min = 4, max = 50) {
    const filtersHeight = ref?.current?.clientHeight ?? 0;
    const pageSize = Math.trunc((height - 280 - filtersHeight) / 50);
    return Math.max(min, Math.min(max, pageSize));
  }

  static exportCSV(gridApi) {
    if (gridApi !== null) {
      let params = {
        columnSeparator: localStorage.getItem("export_separator") || ";",
      };
      gridApi.exportDataAsCsv(params);
    }
  }
}
