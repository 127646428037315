import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

// @mui/lab components
import TimelineContent from "@mui/lab/TimelineContent";

// @mui/material components
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// @mui/icons-material
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// core components
import Render from "Utils/RenderUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import TextInput from "components/CustomInput/TextInput";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import { withUserContext } from "context/UserContext";
import Button from "components/Button/Button";

class EvenementReplies extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/evenements-replies";
    this.inputRef = React.createRef();

    this.state = {
      id: "",
      loading: false,
      message: "",
      openDialog: false,
      deleteAlert: null,
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSuccess = () => {
    this.closeFormDialog();
    this.props.refreshData();
    this.setState({ id: "", message: "", loading: false });
  };

  handleError = (error) => {
    let errors = error.response?.data?.errors ?? error.response?.data ?? {};
    this.setState({
      errors: errors,
      loading: false,
    });
  };

  handleReply = () => {
    this.setState({ loading: true });

    const { id } = this.state;
    const { user } = this.props;

    const dataIn = {
      evenement_id: this.props.evenement.id,
      user_id: user.id,
      message: this.state.message,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.handleSuccess();
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then(() => {
          this.handleSuccess();
        })
        .catch((err) => {
          this.handleError(err);
        });
    }
  };

  // Used by parent
  openFormDialog = (reply) => {
    this.setState(
      {
        openDialog: true,
        id: reply?.id ?? "",
        message: reply?.message ?? "",
      },
      () => this.inputRef.current.focus(),
    );
  };

  closeFormDialog = () => {
    this.setState({
      id: "",
      message: "",
      errors: null,
      openDialog: false,
      loading: false,
    });
  };

  closeDeleteAlert = () => {
    this.setState({ deleteAlert: null });
  };

  handleDelete = (replyId) => {
    axiosApiBackend.delete(this.BACKEND_URL + "/" + replyId).then(() => {
      this.closeDeleteAlert();
      this.props.refreshData();
    });
  };

  deleteAlert = (reply) => {
    this.setState({
      deleteAlert: (
        <AlertDialog
          title="Êtes vous sûr de vouloir supprimer cette réponse ?"
          content={reply.message}
          onConfirm={() => this.handleDelete(reply.id)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={() => this.closeDeleteAlert()}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  render() {
    const { evenement, user } = this.props;
    const { id, message, loading, openDialog, deleteAlert, errors } =
      this.state;
    const replies = evenement.replies ?? [];

    return (
      <>
        {replies.map((reply, key) => {
          const userReply = reply.user;
          const userNameReply = Render.fullName(userReply);
          const displayActionButtons =
            userReply && user.id && userReply.id == user.id;

          return (
            <TimelineContent key={key}>
              <Paper sx={{ py: 1, px: 2 }} elevation={3}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="textSecondary">
                    Réponse de {userNameReply} -{" "}
                    {Render.dateTime(reply.created_at)}
                  </Typography>
                  <Box display="flex" flexDirection="row-reverse">
                    {displayActionButtons && (
                      <>
                        <Button
                          sx={{ mx: "1px" }}
                          size="small"
                          square
                          round
                          onClick={() => this.openFormDialog(reply)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          sx={{ mx: "1px" }}
                          size="small"
                          square
                          round
                          color="error"
                          onClick={() => this.deleteAlert(reply)}
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </>
                    )}
                  </Box>
                </Stack>
                <MultilineTypography>{reply.message}</MultilineTypography>
              </Paper>
            </TimelineContent>
          );
        })}
        {deleteAlert}
        <AlertDialog
          keepMounted
          open={openDialog}
          title={id ? "Modifier une réponse" : "Ajouter une réponse"}
          loading={loading}
          onConfirm={this.handleReply}
          confirmLabel="Valider"
          confirmColor="success"
          onCancel={this.closeFormDialog}
          cancelLabel="Annuler"
          cancelColor="primary"
          fullWidth
        >
          <Grid item xs={12}>
            <TextInput
              inputRef={this.inputRef}
              label="Message"
              name="message"
              onChange={this.handleChange}
              value={message}
              multiline
              minRows={2}
              error={Boolean(errors?.message)}
            />
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

EvenementReplies.propTypes = {
  evenement: PropTypes.object,
  refreshData: PropTypes.func,
  user: PropTypes.object,
};

export default withUserContext(EvenementReplies);
