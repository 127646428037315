import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function ErrorDisplay(props) {
  const { children, errors } = props;

  return (
    <>
      {errors && (
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" color="error">
            {Object.entries(errors).length === 0 ? (
              <>Une erreur est survenue.</>
            ) : (
              <>Merci de corriger les champs en rouge du formulaire.</>
            )}
          </Typography>
          {children}
        </Grid>
      )}
    </>
  );
}
ErrorDisplay.propTypes = {
  errors: PropTypes.any,
  children: PropTypes.any,
};

export default ErrorDisplay;
