import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Savings from "@mui/icons-material/Savings";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import WarningTextField from "components/CustomInput/WarningTextField";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch.jsx";
import DatePicker from "components/DatePicker/DatePicker.jsx";

import ApurementUtils from "Utils/ApurementUtils";
import DateUtils from "Utils/DateUtils";
import FoyerUtils from "Utils/FoyerUtils";
// utils
import Render from "Utils/RenderUtils";

class ApurementsForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/apurements";
    this.FRONT_URL = "/tiers/apurements";

    this.state = {
      // DATA FORM
      cl_id: +this.QUERY_PARAMS.get("cl_id") || "",
      statut: ApurementUtils.STATUTS[0].label,
      date_debut: new Date(),
      total: "",
      mensualite: "",
      description: "",
      raison_id: "",
      cls: [],
      selected_cl: null,
      // END DATA FORM
      loading: false,
      is_past_date: false,
      errors: null,
      error_message: "",
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          if (key === "date_debut") {
            value = value !== null ? new Date(value) : new Date();
            state.is_past_date = this.checkPastDate(value);
          }
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  handleOnResponse = (data) => {
    this.setState({
      cls: data,
      selected_cl: data.find((cl) => cl.id === this.state.cl_id),
    });
  };

  handleChangeCL = (name, value) => {
    this.setState({
      [name]: value,
      selected_cl: this.state.cls.find((cl) => cl.id === value),
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  checkPastDate = (date) => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    return date < firstDayOfMonth;
  };

  handleDateDebut = (name, value) => {
    this.setState({
      [name]: value,
      is_past_date: this.checkPastDate(value),
    });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      cl_id: this.state.cl_id,
      statut: this.state.statut,
      date_debut: DateUtils.toDBString(this.state.date_debut),
      total: this.state.total,
      mensualite: this.state.mensualite,
      description: this.state.description,
      raison_id: this.state.raison_id,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      error_message: "",
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      loading: false,
      errors: error.response?.data?.errors ?? {},
      error_message:
        error.response?.data?.errors?.message ??
        "Merci de corriger les champs en rouge du formulaire.",
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      errors,
      total,
      mensualite,
      date_debut,
      error_message,
      selected_cl,
      is_past_date,
    } = this.state;

    const nbMois = ApurementUtils.getNbMois(total, mensualite);
    const dateDeFin = ApurementUtils.getDateFin(date_debut, total, mensualite);
    const derniereMensualite = ApurementUtils.getDerniereMensualite(
      total,
      mensualite,
    );
    const conflictCLDate = ApurementUtils.hasConflictCLDate(
      selected_cl?.date_sortie,
      dateDeFin,
    );

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>{error_message}</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Savings />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le Plan d&apos;Apurement</>
                  ) : (
                    <>Ajouter un Plan d&apos;Apurement</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              {conflictCLDate && (
                <Alert severity="warning">
                  Attention, la date de fin de l&apos;apurement dépasse la date
                  de sortie du{" "}
                  <Link to={"/contrats-locataires/detail/" + selected_cl.id}>
                    Contrat Locataire ({Render.date(selected_cl?.date_sortie)})
                  </Link>
                </Alert>
              )}
              <Grid container columnSpacing={2} alignItems="start">
                <Grid item xs={12}>
                  <SelectSearch
                    name="cl_id"
                    label="Choisir un contrat locataire"
                    apiUrl="/contrats-locataires"
                    value={this.state.cl_id}
                    onChange={this.handleChangeCL}
                    onResponse={this.handleOnResponse}
                    buildOptionLabel={(data) =>
                      "(CL" +
                      data.id +
                      ") " +
                      FoyerUtils.referentsFullName(
                        data.foyer && data.foyer.referentsactifs,
                      )
                    }
                    shrink
                    error={Boolean(errors?.cl_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Statut"
                    name="statut"
                    value={this.state.statut}
                    onChange={this.handleChange}
                    shrink
                    error={Boolean(errors?.statut)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {ApurementUtils.STATUTS.map((type) => (
                      <MenuItem value={type.name} key={type.name}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Raison"
                    name="raison_id"
                    apiUrl="/config-raison-apurements"
                    value={this.state.raison_id}
                    onChange={this.handleChangeByName}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.raison_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DatePicker
                    label="Date de début"
                    name="date_debut"
                    value={this.state.date_debut}
                    onChange={this.handleDateDebut}
                    views={["year", "month"]}
                    error={Boolean(errors?.date_debut)}
                    textFieldSlot={WarningTextField}
                    textFieldProps={{
                      isWarning: conflictCLDate || is_past_date,
                      helperText: is_past_date
                        ? "Attention la date choisie est dans le passé"
                        : "",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography>
                    <strong>Date de fin : </strong>
                    {Render.monthYear(dateDeFin)}
                  </Typography>
                  <Typography>
                    <strong>Nombre de mensualités : </strong>
                    {nbMois}
                  </Typography>
                  <Typography>
                    <strong>Dernière mensualité : </strong>
                    {Render.euro(derniereMensualite)}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Total"
                    name="total"
                    value={this.state.total}
                    onChange={this.handleChange}
                    error={Boolean(errors?.total)}
                    isWarning={conflictCLDate}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant de la mensualité"
                    name="mensualite"
                    value={this.state.mensualite}
                    onChange={this.handleChange}
                    error={Boolean(errors?.mensualite)}
                    isWarning={conflictCLDate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    name="description"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.description}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.description)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

ApurementsForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default ApurementsForm;
