import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Build from "@mui/icons-material/Build";
import Home from "@mui/icons-material/Home";
import Person from "@mui/icons-material/Person";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import CheckBoxSelect from "components/CheckBox/CheckBoxSelect";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import NumberInput from "components/CustomInput/NumberInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import UserSelectSearch from "components/CustomSelect/UserSelectSearch";
import FormCollection from "components/Form/FormCollection";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import PhoneInput from "components/PhoneInput/PhoneInput";

import AbortRequest from "services/AbortRequest";

import BeneficiaireUtils from "Utils/BeneficiaireUtils";
import DateUtils from "Utils/DateUtils";
import Render from "Utils/RenderUtils";
import Validation from "Utils/ValidationUtils";

import AdresseForm from "./Adresse/AdresseForm";

import { withUserContext } from "context/UserContext";

import InfoFiscaleForm from "./InfoFiscale/InfoFiscaleForm";

class PrimoContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/primo-contact";
    this.BACKEND_URL_BENEFICIAIRE = "/beneficiaires";
    this.FRONT_URL = "/dossiers-travaux";
    this.ABORT_REQUEST = new AbortRequest();

    this.USER = props.user;
    const conseillerId = this.USER.is("conseillerhabitat") ? this.USER.id : "";
    const technicienId = this.USER.is("technicientravaux") ? this.USER.id : "";

    this.state = {
      // NESTED DATA
      infos_fiscales: [],
      dispositifs: [],
      all_dispositifs: [],
      travaux: [],
      typesTravaux: [],
      listBeneficiaire: [],
      adresse: null,
      // DATA FORM BENEFICIAIRE
      civilite_id: "",
      prenom: "",
      nom: "",
      ddn: "",
      nir: "",
      is_address_different: 0,
      contact_info: "",
      tel: "",
      mail: "",
      situation_professionelle_id: "",
      situation_id: "",
      crp_id: "",
      retraite_complementaire_id: "",
      apa: "",
      statut_propriete_id: "",
      categorie_ressource_id: "",
      acces_numerique: true,
      // DATA FORM LOGEMENT
      type_id: "",
      typologie_id: "",
      annee_construction: "",
      nombre_total_occupants: "",
      type_propriete: "",
      dpe: "",
      secteur_id: "",
      // DATA FORM DT
      ouverture: this.EDIT_MODE ? "" : DateUtils.toDBString(new Date()),
      date_demande_document: "",
      raison_contact_id: "",
      origine_contact_id: "",
      mode_contact_id: "",
      surface_avant: "",
      donneur_ordres_id: "",
      technicien_id: technicienId ?? "",
      conseiller_id: conseillerId ?? "",
      infos: "",
      ergo_preconise: 0,
      ergo_passe: 0,
      // END DATA FORM
      dispositifs_to_sync: {},
      selected_dispositifs: [],
      alert: null,
      loading: false,
      errBenef: null,
      errLog: null,
      errDT: null,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.getConfigDispositifs();
    this.getConfigTypeTravaux();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  getDefaultInfoFiscale = () => {
    return {
      id: "",
      annee: "",
      revenu_fiscal: "",
      revenu_brut: "",
      nb_parts: "",
    };
  };

  handleAddCollection = (field, getDefault) => {
    return () => {
      this.setState((prevState) => {
        let items = prevState[field];
        items.push(getDefault());
        return { [field]: items };
      });
    };
  };

  handleChangeCollection = (field) => {
    return (key, name, value) => {
      this.setState((prevState) => {
        let items = prevState[field];
        items[key][name] = value;
        return { [field]: items };
      });
    };
  };

  handleDeleteCollection = (field) => {
    return (key) => {
      this.setState((prevState) => {
        let items = prevState[field];
        items.splice(key, 1);
        return { [field]: items };
      });
    };
  };

  checkDoublonBeneficiaire = async () => {
    const { nom, prenom, id } = this.state;

    if (nom && prenom) {
      const result = await axiosApiBackend.get(this.BACKEND_URL_BENEFICIAIRE, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: {
          nom: nom,
          prenom: prenom,
        },
      });

      const beneficiaireTrouve = BeneficiaireUtils.findDuplicateBeneficiary(
        result.data,
        nom,
        prenom,
        id,
      );

      if (beneficiaireTrouve) {
        this.setState({
          alert: (
            <AlertDialog
              title="Bénéficiaire déjà enregistré avec le même nom et prénom"
              onCancel={this.clearAlert}
              cancelLabel="Fermer"
              cancelColor="gray"
            >
              <Typography>
                <strong>ID : </strong>
                {beneficiaireTrouve.id}
              </Typography>
              <Typography>
                <strong>Bénéficiaire : </strong>
                <Link
                  to={`${this.BACKEND_URL_BENEFICIAIRE}/detail/${beneficiaireTrouve.id}`}
                >
                  {BeneficiaireUtils.getNomComplet(beneficiaireTrouve)}
                </Link>
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {beneficiaireTrouve.tel ? (
                  Render.telephoneAsLink(beneficiaireTrouve.tel)
                ) : (
                  <span>Non renseigné</span>
                )}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {beneficiaireTrouve.mail ? (
                  Render.email(beneficiaireTrouve.mail)
                ) : (
                  <span>Non renseigné</span>
                )}
              </Typography>
            </AlertDialog>
          ),
        });
      }
    }
  };

  handleSubmit = () => {
    this.setState({
      errBenef: null,
      errLog: null,
      errDT: null,
      errors: null,
      loading: true,
    });

    const dataIn = {
      beneficiaire: {
        civilite_id: this.state.civilite_id,
        prenom: this.state.prenom,
        nom: this.state.nom,
        ddn: this.state.ddn,
        nir: this.state.nir,
        contact_info: this.state.contact_info,
        tel: this.state.tel,
        mail: this.state.mail,
        situation_professionelle_id: this.state.situation_professionelle_id,
        situation_id: this.state.situation_id,
        crp_id: this.state.crp_id,
        retraite_complementaire_id: this.state.retraite_complementaire_id,
        apa: this.state.apa,
        statut_propriete_id: this.state.statut_propriete_id,
        categorie_ressource_id: this.state.categorie_ressource_id,
        infos_fiscales: this.state.infos_fiscales,
        acces_numerique: this.state.acces_numerique,
      },

      logement: {
        type_id: this.state.type_id,
        typologie_id: this.state.typologie_id,
        annee_construction: this.state.annee_construction,
        nombre_total_occupants: this.state.nombre_total_occupants,
        type_propriete: this.state.type_propriete,
        dpe: this.state.dpe,
        adresse: this.state.adresse,
        secteur_id: this.state.secteur_id,
      },

      dossiertravaux: {
        travaux: this.state.travaux,
        ouverture: this.state.ouverture,
        raison_contact_id: this.state.raison_contact_id,
        origine_contact_id: this.state.origine_contact_id,
        mode_contact_id: this.state.mode_contact_id,
        surface_avant: this.state.surface_avant,
        donneur_ordres_id: this.state.donneur_ordres_id,
        technicien_id: this.state.technicien_id,
        conseiller_id: this.state.conseiller_id,
        infos: this.state.infos,
        dispositifs_to_sync: this.state.dispositifs_to_sync,
        date_demande_document: this.state.date_demande_document,
        ergo_preconise: this.state.ergo_preconise,
        ergo_passe: this.state.ergo_passe,
      },
    };

    axiosApiBackend
      .post(this.BACKEND_URL, dataIn)
      .then((res) => {
        this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  handleError = (error) => {
    let errors = error.response?.data?.errors ?? {};
    this.setState({
      errBenef: Validation.parse(errors, "beneficiaire."),
      errLog: Validation.parse(errors, "logement."),
      errDT: Validation.parse(errors, "dossiertravaux."),
      errors: errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  // travaux list
  getDefaultTravaux = () => {
    return {
      type_id: "",
      description: "",
    };
  };
  handleAddTravaux = () => {
    let newData = [...this.state.travaux];

    newData.push(this.getDefaultTravaux());
    this.setState({ travaux: newData });
  };
  handleChangeTravaux = (key, event) => {
    this.handleChangeTravauxByName(key, event.target.name, event.target.value);
  };
  handleChangeTravauxByName = (key, name, value) => {
    let newData = this.state.travaux;
    newData[key][name] = value;
    this.setState({ travaux: newData });
  };
  handleRemoveTravaux = (key) => {
    let newData = this.state.travaux;
    newData.splice(key, 1);
    this.setState({ travaux: newData });
  };

  handleChangeTypesTravaux = (event, key) => {
    let newData = this.state.travaux;
    const selectedTypeId = event.target.value;
    var newType = this.state.typesTravaux.find(
      (typeTravaux) => selectedTypeId === typeTravaux.id,
    );

    newData[key][event.target.name] = selectedTypeId;
    newData[key]["type"] = newType;

    this.setState({ travaux: newData });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  // Renvoi true si au moins un des travaux a has_ergo à true
  checkErgo = (travaux) => {
    return travaux.some((t) => t?.type?.has_ergo);
  };

  getConfigDispositifs = () => {
    axiosApiBackend.get("/config-dispositif").then((result) => {
      this.updateDispositifsList(this.state.dispositifs, result.data);
    });
  };

  getConfigTypeTravaux = () => {
    axiosApiBackend.get("/config-types-travaux").then((result) => {
      this.setState({
        typesTravaux: result.data,
      });
    });
  };

  updateDispositifsToSync(data) {
    const dispositifs_to_sync = {};

    data.forEach((item) => {
      if (item.checked) {
        dispositifs_to_sync[item.id] = {
          override_operateur_id: item.override_operateur_id || null,
        };
      }
    });

    return dispositifs_to_sync;
  }

  updateDispositifsList(dispositifsDT, dispositifsList) {
    const updatedDispositifs = dispositifsList.map((dispositif) => {
      const { id, value, operateur } = dispositif;
      const operePar = operateur?.value ? " opéré par " + operateur?.value : "";
      let checked = false;
      let override_operateur_id = null;

      const matchDispositif = dispositifsDT.find(
        (dispositifDT) => dispositifDT.id === dispositif.id,
      );

      if (matchDispositif) {
        checked = true;
        override_operateur_id = matchDispositif.pivot.override_operateur_id;
      }

      return {
        id,
        value: value + operePar,
        checked,
        override_operateur_id,
        is_recommended: dispositif.is_recommended,
      };
    });

    this.setState({
      selected_dispositifs: updatedDispositifs,
      dispositifs_to_sync: this.updateDispositifsToSync(updatedDispositifs),
    });
  }
  render() {
    const {
      id,
      adresse,
      infos_fiscales,
      selected_dispositifs,
      typesTravaux,
      errBenef,
      errLog,
      errDT,
      errors,
    } = this.state;

    return (
      <Grid container columnSpacing={2}>
        {this.state.alert}
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
            {errBenef.nir && (
              <Typography variant="h6" component="h4" color="error">
                Ce nir est déjà associé à un bénéficiaire.
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bénéficiaire
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <HeaderTitle label="Identité du demandeur" />
                </Grid>
                <Grid item xs={12}>
                  <SelectSearch
                    label="Civilité"
                    name="civilite_id"
                    apiUrl="/config-civilite-beneficiaire"
                    onChange={this.handleChangeByName}
                    value={this.state.civilite_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errBenef?.civilite_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Prénom"
                    name="prenom"
                    value={this.state.prenom}
                    onChange={this.handleChange}
                    onBlur={this.checkDoublonBeneficiaire}
                    error={Boolean(errBenef?.prenom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    onBlur={this.checkDoublonBeneficiaire}
                    error={Boolean(errBenef?.nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    label="Date de naissance"
                    name="ddn"
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.ddn}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errBenef?.ddn)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="NIR (numéro sécurité sociale)"
                    name="nir"
                    value={this.state.nir}
                    onChange={this.handleChange}
                    error={Boolean(errBenef?.nir)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Coordonnées de Contact" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Information contact"
                    name="contact_info"
                    value={this.state.contact_info}
                    onChange={this.handleChange}
                    error={Boolean(errBenef?.contact_info)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="tel"
                    value={this.state.tel}
                    onChange={this.handleChangeByName}
                    error={Boolean(errBenef?.tel)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email"
                    name="mail"
                    value={this.state.mail}
                    onChange={this.handleChange}
                    error={Boolean(errBenef?.mail)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Situation Professionnelle et Sociale" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Situation professionnelle"
                    name="situation_professionelle_id"
                    apiUrl="/config-situation-professionelle"
                    onChange={this.handleChangeByName}
                    value={this.state.situation_professionelle_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errBenef?.situation_professionelle_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="situation_id"
                    label="Situation familliale"
                    apiUrl="/config-situations"
                    onChange={this.handleChangeByName}
                    value={this.state.situation_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errBenef?.situation_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Caisse de retraite principale"
                    name="crp_id"
                    apiUrl="/config-beneficiaire-crp"
                    onChange={this.handleChangeByName}
                    value={this.state.crp_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errBenef?.crp_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Caisse de retraite complémentaire"
                    name="retraite_complementaire_id"
                    apiUrl="/config-retraite-complementaire"
                    onChange={this.handleChangeByName}
                    value={this.state.retraite_complementaire_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errBenef?.retraite_complementaire_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Informations Fiscales" />
                </Grid>
                <Grid item xs={12}>
                  <InfoFiscaleForm
                    infosFiscales={infos_fiscales}
                    addRow={this.handleAddCollection(
                      "infos_fiscales",
                      this.getDefaultInfoFiscale,
                    )}
                    updateRow={this.handleChangeCollection("infos_fiscales")}
                    removeRow={this.handleDeleteCollection("infos_fiscales")}
                    errors={Validation.parse(errBenef, "infos_fiscales.")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Handicap" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Allocation personnalisée d'autonomie (APA)"
                    name="apa"
                    value={this.state.apa}
                    onChange={this.handleChange}
                    error={Boolean(errBenef?.apa)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Ressources" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="statut_propriete_id"
                    label="Statut de propriété"
                    apiUrl="/config-statut-propriete"
                    onChange={this.handleChangeByName}
                    value={this.state.statut_propriete_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errBenef?.statut_propriete_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="categorie_ressource_id"
                    label="Catégorie de ressources"
                    apiUrl="/config-categorie-ressource"
                    onChange={this.handleChangeByName}
                    value={this.state.categorie_ressource_id}
                    buildOptionLabel={(data) => data.value}
                    buildOptionAdditionalData={(data) => ({
                      color_mpr: data.color_mpr,
                    })}
                    renderExtra={(data) => (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 1,
                          backgroundColor: data.color_mpr,
                          ml: 1,
                        }}
                      />
                    )}
                    shrink
                    error={Boolean(errBenef?.categorie_ressource_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Autres Informations" />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxInput
                    label="Accès au numérique"
                    name="acces_numerique"
                    value={this.state.acces_numerique}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errLog}
                  isEditMode={Boolean(id)}
                  hideRegion
                />
                <Grid item xs={12}>
                  <HeaderTitle label="Détails de la zone" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="secteur_id"
                    label="Secteur Géographique"
                    apiUrl="/config-logement-travaux-secteur"
                    onChange={this.handleChangeByName}
                    value={this.state.secteur_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.secteur_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Caractéristiques" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="type_id"
                    label="Type de logement"
                    apiUrl="/config-types-logement"
                    onChange={this.handleChangeByName}
                    value={this.state.type_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errLog?.type_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="typologie_id"
                    label="Typologie du logement"
                    apiUrl="/config-typologies"
                    onChange={this.handleChangeByName}
                    value={this.state.typologie_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errLog?.typologie_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Année de construction"
                    name="annee_construction"
                    value={this.state.annee_construction}
                    onChange={this.handleChange}
                    error={Boolean(errLog?.annee_construction)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nombre total d'occupants"
                    name="nombre_total_occupants"
                    value={this.state.nombre_total_occupants}
                    onChange={this.handleChange}
                    error={Boolean(errLog?.nombre_total_occupants)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    name="type_propriete"
                    label="Type de propriété"
                    value={this.state.type_propriete}
                    onChange={this.handleChange}
                    error={Boolean(errLog?.type_propriete)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value="Individuel">Individuel</MenuItem>
                    <MenuItem value="Collectif">Collectif</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="DPE"
                    name="dpe"
                    value={this.state.dpe}
                    onChange={this.handleChange}
                    error={Boolean(errLog?.dpe)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Dossier Travaux
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date d'ouverture"
                    name="ouverture"
                    type="date"
                    value={this.state.ouverture}
                    onChange={this.handleChange}
                    error={Boolean(errDT?.ouverture)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="raison_contact_id"
                    label="Raison de contact"
                    apiUrl="/config-raison-contact-dt"
                    onChange={this.handleChangeByName}
                    value={this.state.raison_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errDT?.raison_contact_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="origine_contact_id"
                    label="Origine de contact"
                    apiUrl="/config-origine-contact-dt"
                    onChange={this.handleChangeByName}
                    value={this.state.origine_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errDT?.origine_contact_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="mode_contact_id"
                    label="Mode de contact"
                    apiUrl="/config-mode-contact-dt"
                    onChange={this.handleChangeByName}
                    value={this.state.mode_contact_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errDT?.mode_contact_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Demande de documents"
                    name="date_demande_document"
                    type="date"
                    value={this.state.date_demande_document}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_demande_document)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <NumberInput
                    label="Surface du logement"
                    name="surface_avant"
                    value={this.state.surface_avant}
                    onChange={this.handleChange}
                    error={Boolean(errDT?.surface_avant)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Typologie de travaux" />
                </Grid>
                <Grid item xs={12}>
                  <FormCollection
                    items={this.state.travaux}
                    rowTitle="Typologie de Travaux"
                    addRow={this.handleAddTravaux}
                    removeRow={this.handleRemoveTravaux}
                    tooltipAdd="Ajouter une typologie de travaux"
                    tooltipDelete="Supprimer"
                    dialogDeleteTitle={(rowNumber) =>
                      "Voulez-vous supprimer la typologie de travaux n° " +
                      rowNumber +
                      " ?"
                    }
                    formInputs={(travaux, key) => (
                      <>
                        <Grid item xs={12}>
                          <SelectInput
                            label="Typologie"
                            name="type_id"
                            value={travaux?.type_id}
                            onChange={(event) =>
                              this.handleChangeTypesTravaux(event, key)
                            }
                            displayEmpty
                            error={Boolean(errDT?.[`travaux.${key}.type_id`])}
                          >
                            {travaux?.type_id ? (
                              <MenuItem
                                key={travaux.type_id}
                                value={travaux.type_id}
                              >
                                {travaux?.type?.value}
                              </MenuItem>
                            ) : (
                              <MenuItem value="">
                                <em>Non renseigné</em>
                              </MenuItem>
                            )}
                            {typesTravaux
                              ?.filter(
                                (item) =>
                                  !Object.values(this.state.travaux)
                                    .map((travaux) => travaux.type_id)
                                    .includes(item.id),
                              )
                              .map((item, key) => (
                                <MenuItem key={key} value={item.id}>
                                  {item.value}
                                </MenuItem>
                              ))}
                          </SelectInput>
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label="Description complémentaire de la typologie de travaux"
                            name="description"
                            multiline
                            value={travaux?.description}
                            onChange={(event) =>
                              this.handleChangeTravaux(key, event)
                            }
                            error={Boolean(
                              errors?.[`travaux.${key}.description`],
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
                {this.checkErgo(this.state.travaux) && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label="Ergothérapeute préconisé"
                        name="ergo_preconise"
                        onChange={this.handleChange}
                        value={this.state.ergo_preconise}
                        error={Boolean(errDT?.ergo_preconise)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        <MenuItem value={0}>Non</MenuItem>
                        <MenuItem value={1}>Oui</MenuItem>
                      </SelectInput>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label="Ergothérapeute passé"
                        name="ergo_passe"
                        onChange={this.handleChange}
                        value={this.state.ergo_passe}
                        error={Boolean(errDT?.ergo_passe)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        <MenuItem value={0}>Non</MenuItem>
                        <MenuItem value={1}>Oui</MenuItem>
                      </SelectInput>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <HeaderTitle label="Donneur d'ordre" />
                </Grid>
                <Grid item xs={12}>
                  <SelectSearch
                    name="donneur_ordres_id"
                    label="Donneur d'ordres"
                    apiUrl="/donneurs-ordres"
                    onChange={this.handleChangeByName}
                    value={this.state.donneur_ordres_id}
                    buildOptionLabel={(data) => data.nom}
                    shrink
                    error={Boolean(errDT?.donneur_ordres_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Soliha" />
                </Grid>
                <Grid item xs={12}>
                  <UserSelectSearch
                    label="Conseiller en charge"
                    name="conseiller_id"
                    apiUrl="/user-list/conseillers"
                    onChange={this.handleChangeByName}
                    value={this.state.conseiller_id}
                    error={Boolean(errDT?.conseiller_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserSelectSearch
                    label="Technicien en charge"
                    name="technicien_id"
                    apiUrl="/user-list/techniciens"
                    onChange={this.handleChangeByName}
                    value={this.state.technicien_id}
                    error={Boolean(errors?.technicien_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Dispositifs et programmes" />
                </Grid>
                <Grid item xs={12}>
                  {selected_dispositifs.length > 0 && (
                    <CheckBoxSelect
                      items={selected_dispositifs}
                      checkBoxLabelKey="value"
                      checkBoxHintKey="is_recommended"
                      checkBoxHintText="Recommandé"
                      selectLabel="Opérateur de remplacement"
                      selectApiUrl="config-operateur"
                      selectValueKey="override_operateur_id"
                      selectEmptyLabelValue="Par défaut"
                      onChange={(data) => {
                        this.setState({
                          dispositifs_to_sync:
                            this.updateDispositifsToSync(data),
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Complément" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    minRows={2}
                    maxRows={8}
                    onChange={this.handleChange}
                    value={this.state.infos}
                    error={Boolean(errDT?.infos)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

PrimoContactForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(PrimoContactForm);
