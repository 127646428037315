import Render from "./RenderUtils";

export default class FoyerUtils {
  static referentsFullName = (personnes) => {
    if (!personnes) {
      return "";
    }
    return personnes
      .filter((personne) => personne.lien.referent)
      .map((personne) => (personne.prenom ?? "") + " " + (personne.nom ?? ""))
      .join(", ");
  };

  static referentsPhones = (personnes) => {
    if (!personnes) {
      return "";
    }
    return personnes
      .filter((personne) => personne.lien.referent)
      .map((personne) => Render.telephone(personne.telephone ?? ""))
      .filter((a) => a)
      .join(", ");
  };

  static referentsEmails = (personnes) => {
    if (!personnes) {
      return "";
    }
    return personnes
      .filter((personne) => personne.lien.referent)
      .map((personne) => personne.email ?? "")
      .filter((a) => a)
      .join(", ");
  };
}
