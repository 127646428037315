import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import AccessibilityIcon from "@mui/icons-material/Accessibility";
import AccountBox from "@mui/icons-material/AccountBox";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import Euro from "@mui/icons-material/EuroSymbol";
import FeedIcon from "@mui/icons-material/Feed";
import Home from "@mui/icons-material/Home";
import List from "@mui/icons-material/List";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import Person from "@mui/icons-material/Person";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import TravauxLinkCard from "components/LinkCard/TravauxLinkCard";
import MenuBottom from "components/MenuBottom/MenuBottom";
import DocumentTemplate from "components/Table/DocumentTemplate";

import SearchBarService from "services/SearchBarService";

import Render from "Utils/RenderUtils";

import AdresseView from "./Adresse/AdresseView";
import BeneficiaireSubTable from "views/Forms/TravauxSubTables/BeneficiaireSubTable";
import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

import InfoFiscaleView from "./InfoFiscale/InfoFiscaleView";

class BeneficiairesView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.beneficiaire");
    this.CAN_EDIT = this.USER.can("edit.beneficiaire");
    this.CAN_USE_BENEFICIAIRE = this.USER.can("use.beneficiaire");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    // Ged beneficiaire permissions
    this.GED_BENEFICIAIRE_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.travaux.beneficiaire"),
      CAN_EDIT: this.USER.can("edit.ged.travaux.beneficiaire"),
      CAN_DELETE: this.USER.can("delete.ged.travaux.beneficiaire"),
    };

    this.BACKEND_URL = "/beneficiaires";
    this.FRONT_URL = "/beneficiaires";
    this.NO_YES = ["Non", "Oui"];
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      logementstravaux: [],
      user: null,
      date_last_user_toggle: "",
      civilite_benef: null,
      statut_propriete: null,
      categorie_ressource: null,
      situation_professionelle: null,
      retraite_complementaire: null,
      crp: null,
      crp_conjoint: null,
      infos_fiscales: [],
      adresse: null,
      // DATA FORM
      nom: "",
      prenom: "",
      is_address_different: "",
      contact_info: "",
      tel: "",
      mail: "",
      contact_info_2: "",
      tel_2: "",
      mail_2: "",
      crp_id: "",
      crp_conjoint_id: "",
      ddn: "",
      carte_invalidite: "",
      apa: "",
      situation_id: "",
      handicap: "",
      taux_zero: "",
      nir: "",
      num_pension: "",
      primo_accedant: "",
      gir: "",
      acc_social_renforce: "",
      situation_professionelle_id: "",
      retraite_complementaire_id: "",
      acces_numerique: "",
      // END DATA FORM
      errors: null,
      id: props.match.params.id,
      alert: null,
      dt_actif: null,
      entityLists: null,
      toggleDisabled: false,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        if (result.data.logementstravaux !== null) {
          state.entityLists = {
            logementTravaux: result.data.logementstravaux,
          };
        }
        this.setState(state);
      });
  }

  handleActive = (event) => {
    const status = event.target.checked ? 1 : 0;
    const Data = { user_status: status };

    this.setState({ toggleDisabled: true });

    axiosApiBackend
      .post(this.BACKEND_URL + "/" + this.state.id + "/actif", Data)
      .then((rep) => {
        rep.data.user?.deleted_at != null
          ? this.setState({
              user: null,
              date_last_user_toggle: rep.data.date_last_user_toggle,
            })
          : this.setState({
              user: rep.data.user,
              date_last_user_toggle: rep.data.date_last_user_toggle,
            });
      })
      .catch((err) => {
        this.setState({ errors: err });
      })
      .finally(() => {
        this.setState({ toggleDisabled: false });
      });
  };

  //Remove Bénéficiaire
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le bénéficiaire ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer le bénéficiaire : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.logements > 0 && (
              <li>{nbrEntity.logements} logement(s) associée(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      logementstravaux,
      dt_actif,
      errors,
      situation,
      civilite_benef,
      is_address_different,
      adresse,
      statut_propriete,
      categorie_ressource,
      situation_professionelle,
      retraite_complementaire,
      toggleDisabled,
      crp,
      crp_conjoint,
      infos_fiscales,
    } = this.state;

    const logementtravaux =
      logementstravaux.length > 0
        ? logementstravaux[logementstravaux.length - 1]
        : null;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar sx={{ bgcolor: categorie_ressource?.color_mpr }}>
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Identité
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Civilité : </strong>
                {civilite_benef?.value}
              </Typography>
              <Typography>
                <strong>Prénom : </strong>
                {this.state.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {this.state.nom}
              </Typography>
              <Typography>
                <strong>Date de naissance : </strong>
                {Render.date(this.state.ddn)}
              </Typography>
              <Typography>
                <strong>Age : </strong>
                {Render.age(this.state.ddn)}
              </Typography>
              <Typography>
                <strong>NIR (numéro sécurité sociale) : </strong>
                {Render.nir(this.state.nir)}
              </Typography>
              <Typography>
                <strong>Adresse différente du logement : </strong>
                {this.NO_YES[is_address_different]}
              </Typography>
              {Boolean(is_address_different) && (
                <>
                  <HeaderTitle label="Adresse" />
                  <AdresseView adresse={adresse} hideRegion />
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <PermContactCalendarIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Coordonnées de Contact
                </Typography>
              }
            />
            <CardContent>
              <HeaderTitle label="contact 1" />
              <Typography>
                <strong>Information contact : </strong>
                {this.state.contact_info}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(this.state.tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(this.state.mail)}
              </Typography>
              <HeaderTitle label="contact 2" />
              <Typography>
                <strong>Information contact 2 : </strong>
                {this.state.contact_info_2}
              </Typography>
              <Typography>
                <strong>Téléphone 2 : </strong>
                {Render.telephoneAsLink(this.state.tel_2)}
              </Typography>
              <Typography>
                <strong>Email 2 : </strong>
                {Render.email(this.state.mail_2)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <AccessibilityIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Handicap
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Carte d&apos;invalidité : </strong>
                {this.NO_YES[this.state.carte_invalidite]}
              </Typography>
              <Typography>
                <strong>Type de handicap : </strong>
                {this.state.handicap}
              </Typography>
              <Typography>
                <strong>
                  Allocation personnalisée d&apos;autonomie (APA) :{" "}
                </strong>
                {this.NO_YES[this.state.apa]}
              </Typography>
              <Typography>
                <strong>Groupement iso-ressources (GIR) : </strong>
                {this.state.gir}
              </Typography>
            </CardContent>
          </Card>
          {this.GED_BENEFICIAIRE_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="travaux"
              type="beneficiaire"
              objectId={id}
              permissions={this.GED_BENEFICIAIRE_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <FeedIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Situation Professionnelle et Sociale
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Situation professionnelle : </strong>
                {situation_professionelle?.value}
              </Typography>
              <Typography>
                <strong>Situation familiale : </strong>
                {situation?.value}
              </Typography>
              <Typography>
                <strong>Caisse de retraite principale : </strong>
                {crp?.value}
              </Typography>
              <Typography>
                <strong>Caisse de retraite principale du conjoint : </strong>
                {crp_conjoint?.value}
              </Typography>
              <Typography>
                <strong>Caisse de retraite complémentaire : </strong>
                {retraite_complementaire?.value}
              </Typography>
              <Typography>
                <strong>Numéro de Pension : </strong>
                {this.state.num_pension}
              </Typography>
              <CheckBoxView
                label="Accompagnement social renforcé"
                value={this.state.acc_social_renforce}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <AttachFileIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations Fiscales
                </Typography>
              }
            />
            <CardContent>
              <InfoFiscaleView infosFiscales={infos_fiscales} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Ressources
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Statut de propriété : </strong>
                {statut_propriete?.value}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>
                  <strong>Catégorie des ressources : </strong>
                  {categorie_ressource?.value}
                </Typography>
                <Box
                  sx={{
                    width: 25,
                    height: 25,
                    borderRadius: 1,
                    backgroundColor: categorie_ressource?.color_mpr,
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <PlaylistAddIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Autres Informations
                </Typography>
              }
            />
            <CardContent>
              <CheckBoxView
                label="Prêt à taux 0 au cours des 5 dernières années ?"
                value={this.state.taux_zero}
              />
              <CheckBoxView
                label="Primo accédant ?"
                value={this.state.primo_accedant}
              />
              <CheckBoxView
                label="Accès au numérique ?"
                value={this.state.acces_numerique}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TravauxLinkCard
            beneficiaire={{ id: id }}
            logement={logementtravaux}
            dossierTravaux={dt_actif}
            hideBeneficiaire={true}
            hideAudit={true}
            hideAuditApres={true}
            entityLists={this.state.entityLists}
          />
          {this.CAN_EDIT && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <AccountBox />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Compte Utilisateur
                  </Typography>
                }
              />
              <CardContent>
                <Grid item xs={12}>
                  {errors && (
                    <Typography variant="h6" component="h4" color="error">
                      Une adresse mail est nécessaire pour créer un compte
                      bénéficiaire.
                    </Typography>
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={toggleDisabled}
                        checked={Boolean(this.state.user)}
                        onChange={this.handleActive}
                      />
                    }
                    sx={{ ml: 0 }}
                    label={
                      <Typography>
                        <strong>
                          {this.state.user ? "Compte actif" : "Compte inactif"}
                        </strong>
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                  {this.state.date_last_user_toggle && (
                    <>
                      <Typography>
                        <strong>
                          {this.state.user ? "Activé" : "Désactivé"} le :{" "}
                        </strong>
                        {Render.dateTime(this.state.date_last_user_toggle)}
                      </Typography>
                      {this.state.user &&
                        (this.state.user.last_connection_at ? (
                          <Typography>
                            <strong>Dernière connexion le : </strong>
                            {Render.dateTime(
                              this.state.user.last_connection_at,
                            )}
                          </Typography>
                        ) : (
                          <Typography>
                            <strong>Jamais connecté</strong>
                          </Typography>
                        ))}
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          )}
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logements
                </Typography>
              }
            />
            <CardContent>
              <br />
              <BeneficiaireSubTable logementstravaux={logementstravaux} />
            </CardContent>
          </Card>
          {this.CAN_USE_BENEFICIAIRE && (
            <DocumentTemplate baseURL={this.FRONT_URL} objectId={id} />
          )}
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button size="small" color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL + "/logs?type=Beneficiaire&id=" + this.state.id
              }
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

BeneficiairesView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(BeneficiairesView);
