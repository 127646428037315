import React from "react";
import { Link } from "react-router-dom";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Payment from "@mui/icons-material/Payment";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button.jsx";
import SelectInput from "components/CustomSelect/SelectInput";

import CPUtils from "Utils/CPUtils";
import FileUtils from "Utils/FileUtils";
import FoyerUtils from "Utils/FoyerUtils";

class ExportComptablePaiements extends React.Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.YEARS = [
      d.getFullYear(),
      d.getFullYear() - 1,
      d.getFullYear() - 2,
      d.getFullYear() - 3,
    ];
    this.TYPES = ["Virement", "Chèque", "Espèces", "Prélèvement"];
    this.TYPES_CONTRAT = CPUtils.TYPES;

    this.state = {
      // FORM
      year: this.YEARS[0],
      month: d.getMonth() + 1,
      type: [],
      contractNames: [],
      type_contrat_ids: [],
      nom_contrat_ids: [],
      missingIdsRequired: true,
      // END FORM
      loading: false,
      missingCLs: null,
      missingCPs: null,
      errorMessage: null,
      open: false,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get("/config-nom-contrat-proprietaires").then((result) => {
      this.setState({
        contractNames: result.data,
      });
    });
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
      missingCLs: null,
      missingCPs: null,
      errorMessage: null,
    });
    const {
      year,
      month,
      type,
      type_contrat_ids,
      nom_contrat_ids,
      missingIdsRequired,
    } = this.state;

    axiosApiBackendNoJson
      .get(`/comptabilite/paiements/${year}/${month}`, {
        params: {
          typesPaiements: type,
          typeContratIds: type_contrat_ids,
          nomContratIds: nom_contrat_ids.map((a) => a.id),
          missingIdsRequired: missingIdsRequired ? 1 : 0,
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.toggle();
        FileUtils.downLoadFile(res.data, res.headers, "export-comptable.txt");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage:
            error?.response?.data?.error?.message ?? "Une erreur est survenue",
          missingCLs: error?.response?.data?.error?.cls ?? null,
          missingCPs: error?.response?.data?.error?.cps ?? null,
        });
      });
  };

  getStyles = (array, value) => {
    return {
      fontWeight: array.includes(value) ? "700" : "500",
    };
  };
  getStylesObj = (array, value) => {
    return {
      fontWeight: array.some((obj) => obj.id === value.id) ? "700" : "500",
    };
  };

  render() {
    const {
      errorMessage,
      missingCLs,
      missingCPs,
      loading,
      open,
      month,
      year,
      type,
      contractNames,
      type_contrat_ids,
      nom_contrat_ids,
      missingIdsRequired,
    } = this.state;

    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<Payment />}
          onClick={this.toggle}
        >
          Export comptable paiements
        </Button>
        <AlertDialog
          open={open}
          fullWidth
          outsideClickCancel
          title="Export comptable paiements - Choisir une période d'exportation"
          onConfirm={this.handleSubmit}
          confirmLabel="Exporter"
          confirmColor="primary"
          onCancel={this.toggle}
          cancelLabel="Annuler"
          cancelColor="reversed"
          loading={loading}
        >
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          {missingCLs !== null && missingCLs.length > 0 && (
            <>
              <Typography variant="h5" color="error">
                Contrats Locataires
              </Typography>
              <ul>
                {missingCLs.map((cl, key) => (
                  <li key={key}>
                    <Link
                      to={"/contrats-locataires/modifier/" + cl.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CL{cl.id}{" "}
                      {FoyerUtils.referentsFullName(cl.referentsactifs)} -{" "}
                      {cl.type}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {missingCPs !== null && missingCPs.length > 0 && (
            <>
              <Typography variant="h5" color="error">
                Contrat Propriétaires
              </Typography>
              <ul>
                {missingCPs.map((cp, key) => (
                  <li key={key}>
                    <Link
                      to={"/contrats-proprietaires/modifier/" + cp.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CP{cp.id} {cp.prenom} {cp.nom} - {cp.type}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Mois"
                name="month"
                value={month}
                onChange={this.handleChange}
              >
                <MenuItem value={1}>Janvier</MenuItem>
                <MenuItem value={2}>Février</MenuItem>
                <MenuItem value={3}>Mars</MenuItem>
                <MenuItem value={4}>Avril</MenuItem>
                <MenuItem value={5}>Mai</MenuItem>
                <MenuItem value={6}>Juin</MenuItem>
                <MenuItem value={7}>Juillet</MenuItem>
                <MenuItem value={8}>Août</MenuItem>
                <MenuItem value={9}>Septembre</MenuItem>
                <MenuItem value={10}>Octobre</MenuItem>
                <MenuItem value={11}>Novembre</MenuItem>
                <MenuItem value={12}>Décembre</MenuItem>
              </SelectInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Année"
                name="year"
                value={year}
                onChange={this.handleChange}
              >
                {this.YEARS.map((value, key) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Type de paiement"
                name="type"
                value={type}
                onChange={this.handleChange}
                displayEmpty
                multiple
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Tous</em>;
                  }

                  return selected.join(", ");
                }}
              >
                {this.TYPES.map((value, key) => (
                  <MenuItem
                    key={key}
                    value={value}
                    style={this.getStyles(type, value)}
                  >
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Type de contrat"
                name="type_contrat_ids"
                value={type_contrat_ids}
                onChange={this.handleChange}
                displayEmpty
                multiple
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Tous</em>;
                  }

                  return selected
                    .map((s) => this.TYPES_CONTRAT[s] ?? "")
                    .join(", ");
                }}
              >
                {this.TYPES_CONTRAT.map((value, key) => (
                  <MenuItem
                    key={key}
                    value={key}
                    style={this.getStyles(type_contrat_ids, key)}
                  >
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Nom de contrat"
                name="nom_contrat_ids"
                value={nom_contrat_ids}
                onChange={this.handleChange}
                displayEmpty
                multiple
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Tous</em>;
                  }

                  return selected.map((s) => s.value).join(", ");
                }}
              >
                {contractNames?.map((name, key) => (
                  <MenuItem
                    key={key}
                    value={name}
                    style={this.getStylesObj(nom_contrat_ids, name)}
                  >
                    {name.value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Afficher les identifants manquants"
                control={
                  <Checkbox
                    name="missingIdsRequired"
                    checked={missingIdsRequired}
                    onChange={this.handleChangeCheckbox}
                  />
                }
              />
            </Grid>
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

export default ExportComptablePaiements;
