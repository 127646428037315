import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui/material components
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const PercentInput = ({
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  name,
  onChange,
  InputLabelProps,
  InputProps,
  value,
  ...rest
}) => {
  const handleChangeNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/,/g, ".");
    event.target.value = val;
    onChange(event);
  };

  return (
    <TextField
      {...rest}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      onChange={handleChangeNumber}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
        ...InputProps,
      }}
      value={value ?? ""}
    />
  );
};

PercentInput.propTypes = {
  fullWidth: PropTypes.any,
  margin: PropTypes.any,
  variant: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  InputLabelProps: PropTypes.any,
  InputProps: PropTypes.any,
  value: PropTypes.any,
};

export default memo(PercentInput);
