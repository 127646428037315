import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import TableHead from "components/Table/TableHead";
import TableRow from "components/Table/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

// @mui/icons-material

// core components
import LogementTravauxUtils from "Utils/LogementTravauxUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import AuditUtils from "Utils/AuditUtils";

class LogementSubTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { withLinks, audits, dossiers_travaux } = this.props;

    const rowsForLinks = LogementTravauxUtils.buildRowsForLinks(
      audits,
      dossiers_travaux,
    );

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dossier Travaux</TableCell>
              <TableCell>Audit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsForLinks.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>Aucun Dossier Travaux / Audit</TableCell>
              </TableRow>
            )}
            {rowsForLinks.map((row, key) => (
              <TableRow key={key}>
                {((row.audit && !row.audit.isApres) || row.dossier_travaux) && (
                  <TableCell rowSpan={row.dossier_travaux?.span}>
                    {DossierTravauxUtils.renderInfo(
                      row.dossier_travaux,
                      withLinks,
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {AuditUtils.renderInfo(row.audit, withLinks)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

LogementSubTable.defaultProps = {
  withLinks: true,
};

LogementSubTable.propTypes = {
  withLinks: PropTypes.bool,
  audits: PropTypes.any,
  dossiers_travaux: PropTypes.any,
};

export default LogementSubTable;
