import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import Euro from "@mui/icons-material/Euro";
import EuroSymbol from "@mui/icons-material/EuroSymbol";
import FamilyRestroom from "@mui/icons-material/FamilyRestroom";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import Info from "@mui/icons-material/Info";
import List from "@mui/icons-material/List";
import ListAlt from "@mui/icons-material/ListAlt";
import Today from "@mui/icons-material/Today";
import TrendingUp from "@mui/icons-material/TrendingUp";
import WarningRounded from "@mui/icons-material/WarningRounded";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import GenerateFileWithDatesDropdown from "components/CustomDropdown/GenerateFileWithDatesDropdown.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import EcheanciersCard from "components/Echeancier/EcheanciersCard";
import Evenements from "components/Evenements/Evenements.jsx";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import ImmobilierLinkCard from "components/LinkCard/ImmobilierLinkCard";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ShowMoreBox from "components/ShowMoreBox/ShowMoreBox";
import DocumentTemplate from "components/Table/DocumentTemplate";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";

import SearchBarService from "services/SearchBarService";

import CLUtils from "Utils/CLUtils";
import CPUtils from "Utils/CPUtils";
import FoyerUtils from "Utils/FoyerUtils";
import IRLUtils from "Utils/IRLUtils";
import PersonneUtils from "Utils/PersonneUtils";
import Render from "Utils/RenderUtils";

import MensualiteView from "views/Forms/Mensualite/MensualiteView";
import Apurements from "views/Tables/Apurements";
import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

import ProcedureCLView from "./ProcedureCL/ProcedureCLView";

class ContratsLocatairesView extends Component {
  constructor(props) {
    super(props);
    let id = Number(props.match.params.id);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.contratlocataire");
    this.CAN_USE_CONTRAT_LOCATAIRE = this.USER.can("use.contratlocataire");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/contrats-locataires";
    this.FRONT_URL = "/contrats-locataires";
    this.NOW = new Date();
    this.NO_YES = ["Non", "Oui"];

    // Ged CL permissions
    this.GED_CL_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.cl"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.cl"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.cl"),
    };

    // ref Component Evenement
    this.evenements = React.createRef();
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(id);

    this.state = {
      // NESTED DATA
      apurement: null,
      logement: null,
      foyer: null,
      mensualites: [],
      origine_fin_contrat: null,
      type_paiement: null,
      irl_origine: null,
      procedures: [],
      // DATA FORM
      id_ciel: "",
      id_ciel_dg: "",
      foyer_id: "",
      solde: "",
      solde_ae: "",
      date_entree: "",
      date_sortie: "",
      montant_caf: "",
      logement_id: "",
      actif: "",
      mensualite_loyer: "",
      mensualite_charges: "",
      mensualite_garage: "",
      mensualite_charges_garage: "",
      mensualite_ordures: "",
      mensualite_assurance: "",
      label_loyer: "",
      loyer_origine: "",
      depot_de_garantie: "",
      date_preavis: "",
      nom_rib: "",
      bic: "",
      iban: "",
      jour_paiement: "",
      mandat_signature: "",
      mandat_date_signature: "",
      accompagnement_infos: {
        niveau_accompagnement: "",
        date_debut: "",
        date_fin: "",
      },
      date_revision: "",
      date_revision_custom: "",
      chaudiere_numero_contrat: "",
      chaudiere_debut_contrat: "",
      chaudiere_derniere_revision: "",
      ass_hab_nom: "",
      ass_hab_num: "",
      ass_hab_debut: "",
      ass_hab_fin: "",
      // END DATA FORM
      id: id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData = () => {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let st = result.data ?? {};
        this.setState(st, () => {
          this.evenements.current?.loadAsyncData();
        });
      });
  };

  handleActive = (event) => {
    const actif = event.target.checked ? 1 : 0;
    const actifData = {
      actif: actif,
    };

    axiosApiBackend
      .put(this.BACKEND_URL + "/" + this.state.id + "/actif", actifData)
      .then(() => {
        this.setState({ actif: actif });
      });
  };

  renderEtat = (actif, date_sortie) => {
    if (actif === 0) {
      return "Clôturé";
    }
    if (
      actif === 1 &&
      date_sortie !== null &&
      date_sortie !== "" &&
      new Date(date_sortie) < this.NOW
    ) {
      return "Parti";
    }
    return "Présent";
  };

  renderPersonne = (personne, divider, key) => {
    const lien = personne.lien || {};

    return (
      <React.Fragment key={key}>
        {divider && <Divider variant="middle" />}
        <Typography>
          <strong>Locataire : </strong>
          <Link to={"/tiers/locataires/detail/" + personne.id}>
            {PersonneUtils.getNomComplet(personne)}
          </Link>
          <br />
          <strong>Date de naissance : </strong>
          {Render.date(personne.date_naissance)}{" "}
          {Render.age(personne.date_naissance, "(", " ans)")}
          <br />
          <strong>Téléphone : </strong>
          {Render.telephoneAsLink(personne.telephone)}
          <br />
          <strong>Email : </strong>
          {Render.email(personne.email)}
          <br />
          <strong>Statut : </strong>
          {lien.statut}
          <br />
          <strong>Titulaire contrat : </strong>
          {this.NO_YES[lien.referent]}
        </Typography>
      </React.Fragment>
    );
  };

  sortReferentFoyer = (a, b) => {
    if (a.lien.referent && !b.lien.referent) {
      return -1;
    }
    if (!a.lien.referent && b.lien.referent) {
      return 1;
    }
    return 0;
  };

  //Remove Contrat Locataire

  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le contrat ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce contrat : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.apurements > 0 && (
              <li>{nbrEntity.apurements} apurement(s) associé(s)</li>
            )}
            {nbrEntity.operation > 0 && (
              <li>{nbrEntity.operation} opération(s) associée(s)</li>
            )}
            {nbrEntity.operationCp > 0 && (
              <li>
                {nbrEntity.operationCp} opération(s) associée(s) sur le Contrat
                Proprietaire
              </li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      logement_id,
      origine_fin_contrat,
      type_paiement,
      date_preavis,
      accompagnement_infos,
      logement,
      foyer,
      procedures,
    } = this.state;

    const cp = logement?.contratproprietaire;
    const personnesactives = foyer?.personnesactives;
    const personnes = personnesactives ? [...personnesactives] : []; // spread operator pour ne pas que la fonction shift modifie le state
    const referentsFullName = FoyerUtils.referentsFullName(personnes);
    personnes.sort(this.sortReferentFoyer);
    const firstReferent = personnes.length > 0 ? personnes.shift() : null;

    const evenementsLink = "/evenements/immobilier/contrat_locataire/" + id;

    const typeRevisionTootip =
      CPUtils.TYPE_REVISION_FORM[cp?.type_revision]?.tooltip;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" component="h4">
            Infos Contrat Locataire CL{id}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div style={{ textAlign: "right" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(this.state.actif)}
                  onChange={this.handleActive}
                />
              }
              label={this.renderEtat(this.state.actif, this.state.date_sortie)}
              labelPlacement="start"
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="social">
                  <FamilyRestroom />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  <DisableLink
                    to={foyer?.id && "/tiers/foyers/detail/" + foyer?.id}
                  >
                    Foyer FO{foyer?.id}
                  </DisableLink>
                </Typography>
              }
            />
            <CardContent>
              <ShowMoreBox
                header={
                  firstReferent && this.renderPersonne(firstReferent, false)
                }
              >
                {personnes?.length > 0 &&
                  personnes.map((personne, key) =>
                    this.renderPersonne(personne, true, key),
                  )}
              </ShowMoreBox>
              <Typography>
                <strong>N°CAF : </strong>
                {foyer?.ncaf}
              </Typography>
              <Typography>
                <strong>Référents sociaux : </strong>
                {foyer?.referents_sociaux
                  ?.map((rs) => {
                    return rs.pivot.is_principal
                      ? Render.fullName(rs) + " (Principal)"
                      : Render.fullName(rs);
                  })
                  .join(", ")}
              </Typography>
              <Typography>
                <strong>Niveau d&apos;accompagnement : </strong>
                {accompagnement_infos.niveau_accompagnement ?? ""}
              </Typography>
              <Typography>
                <strong>Date de début : </strong>
                {Render.date(accompagnement_infos?.date_debut)}
              </Typography>
              <Typography>
                <strong>Date de fin : </strong>
                {Render.date(accompagnement_infos?.date_fin)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <FormatListBulleted />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Comptable
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Numéro identifiant comptable : </strong>
                {this.state.id_ciel}
              </Typography>
              <Typography>
                <strong>
                  Numéro identifiant comptable - Dépôt de garantie :{" "}
                </strong>
                {this.state.id_ciel_dg}
              </Typography>
              <Typography>
                <strong>Paiement du locataire par : </strong>
                {type_paiement?.value}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Today />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Évènements
                </Typography>
              }
              action={
                logement_id && (
                  <Link to={evenementsLink + "?form=open"}>
                    <Button size="small">Ajouter</Button>
                  </Link>
                )
              }
            />
            <CardContent>
              <Link to={evenementsLink}>
                <Evenements
                  ref={this.evenements}
                  loadOnMount={false}
                  modelId={id}
                  modelType="contrat_locataire"
                  dense
                  limit={5}
                />
              </Link>
            </CardContent>
          </Card>
          {this.GED_CL_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="CL"
              objectId={id}
              permissions={this.GED_CL_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Info />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Assurance Habitation Locataire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Assureur : </strong>
                {this.state.ass_hab_nom}
              </Typography>
              <Typography>
                <strong>Numéro de contrat : </strong>
                {this.state.ass_hab_num}
              </Typography>
              <Typography>
                <strong>Date de début assurance : </strong>
                {Render.date(this.state.ass_hab_debut)}
              </Typography>
              <Typography>
                <strong>Date de fin assurance : </strong>
                {Render.date(this.state.ass_hab_fin)}
              </Typography>
              {Boolean(logement?.chaudiere_gaz_individuel) && (
                <>
                  <HeaderTitle label="Chaudière" />
                  <Typography>
                    <strong>Numéro de contrat : </strong>
                    {this.state.chaudiere_numero_contrat}
                  </Typography>
                  <Typography>
                    <strong>Date de début de contrat : </strong>
                    {Render.date(this.state.chaudiere_debut_contrat)}
                  </Typography>
                  <Typography>
                    <strong>Dernière révision : </strong>
                    {Render.date(this.state.chaudiere_derniere_revision)}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Description />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Contrat
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Dépôt de garantie : </strong>
                {Render.euro(this.state.depot_de_garantie)}
              </Typography>
              <Typography>
                <strong>Type Contrat : </strong>
                {cp?.type}
              </Typography>
              <Typography>
                <strong>Type de paiement : </strong>
                {cp?.type_paiement}
              </Typography>
              <Typography>
                <strong>Date d&apos;entrée : </strong>
                {Render.date(this.state.date_entree)}
              </Typography>
              <Typography>
                <strong>Date de préavis : </strong>
                {Render.date(date_preavis)}
              </Typography>
              <Typography>
                <strong>Date de sortie : </strong>
                {Render.date(this.state.date_sortie)}
              </Typography>
              <Typography>
                <strong>Origine fin de contrat : </strong>
                {origine_fin_contrat?.value}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="info">
                  <TrendingUp />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Révision du loyer{" "}
                  <InfoLightTooltip title={CPUtils.REVISION_TOOLTIP} />
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>
                  Type de révision{" "}
                  {typeRevisionTootip && (
                    <InfoLightTooltip title={typeRevisionTootip} />
                  )}{" "}
                  :{" "}
                </strong>
                {cp?.type_revision}
              </Typography>
              {[CPUtils.TYPE_REVISION[1], CPUtils.TYPE_REVISION[2]].includes(
                cp?.type_revision,
              ) && (
                <>
                  <Typography>
                    <strong>
                      IRL d&apos;origine{" "}
                      <InfoLightTooltip title={CPUtils.IRL_TOOLTIP} /> :{" "}
                    </strong>
                    {IRLUtils.irl(cp?.irl_origine)}
                  </Typography>
                  <Typography>
                    <strong>
                      Date de révision{" "}
                      <InfoLightTooltip title={CPUtils.DATE_REVISION_TOOLTIP} />{" "}
                      :{" "}
                    </strong>
                    {cp?.date_revision}
                    {cp?.date_revision === CPUtils.DATE_REVISION[4] && (
                      <> - {Render.dayMonth(cp?.date_revision_custom)}</>
                    )}
                  </Typography>
                </>
              )}
              {CPUtils.TYPE_REVISION[3] === cp?.type_revision && (
                <>
                  <Typography>
                    <strong>
                      IRL d&apos;origine{" "}
                      <InfoLightTooltip title={CPUtils.IRL_TOOLTIP} /> :{" "}
                    </strong>
                    {IRLUtils.irl(this.state.irl_origine)}
                  </Typography>
                  <Typography>
                    <strong>
                      Date de révision{" "}
                      <InfoLightTooltip title={CPUtils.DATE_REVISION_TOOLTIP} />{" "}
                      :{" "}
                    </strong>
                    {this.state.date_revision}
                    {this.state.date_revision === CPUtils.DATE_REVISION[4] && (
                      <> - {Render.dayMonth(this.state.date_revision_custom)}</>
                    )}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <ListAlt />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Procédures
                </Typography>
              }
              action={
                <Link to={"/tiers/procedures/ajouter?cl_id=" + id}>
                  <Button size="small">Ajouter</Button>
                </Link>
              }
            />
            <CardContent>
              <ProcedureCLView procedures={procedures} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <>
                  {this.state.solde < 0 ? (
                    <CardAvatar big color="error">
                      <WarningRounded />
                    </CardAvatar>
                  ) : (
                    <CardAvatar big color="success">
                      <AccountBalanceWalletIcon />
                    </CardAvatar>
                  )}
                </>
              }
              title={
                <Typography variant="h6">
                  Solde
                  {/* {this.state.solde < 0 ? <>Dette</> : <>Solde</>} */}
                </Typography>
              }
            />
            <CardContent>
              <Typography
                variant="h3"
                gutterBottom
                style={{ whiteSpace: "nowrap" }}
              >
                {Render.euro(this.state.solde_ae)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="info">
                  <EuroSymbol />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Infos Bancaires
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Nom RIB : </strong>
                {this.state.nom_rib}
              </Typography>
              <Typography>
                <strong>BIC : </strong>
                {this.state.bic}
              </Typography>
              <Typography>
                <strong>IBAN : </strong>
                {Render.iban(this.state.iban)}
              </Typography>
              <Typography>
                <strong>Jour de paiement : </strong>
                {this.state.jour_paiement}
              </Typography>
              <CheckBoxView
                label="Mandat de prélèvement signé"
                value={this.state.mandat_signature}
              />
              <Typography>
                <strong>Date de signature du mandat : </strong>
                {Render.date(this.state.mandat_date_signature)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ImmobilierLinkCard
            proprietaire={logement?.proprietaire}
            logement={logement}
            contratProprietaire={logement?.contratproprietaire}
            contratLocataire={{ id: id }}
            hideContratLocataire={true}
          />
          {this.CAN_USE_CONTRAT_LOCATAIRE && (
            <DocumentTemplate
              baseURL="/contrats-locataires"
              objectId={id}
              color="gla"
              callback={this.loadAsyncData}
            />
          )}
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Mensualités
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>
                  {this.state.label_loyer ?? CLUtils.LABEL_LOYER[0]} :{" "}
                </strong>
                {Render.euro(this.state.mensualite_loyer)}
              </Typography>
              <Typography>
                <strong>Charges : </strong>
                {Render.euro(this.state.mensualite_charges)}
              </Typography>
              <Typography>
                <strong>Garage : </strong>
                {Render.euro(this.state.mensualite_garage)}
              </Typography>
              <Typography>
                <strong>Charges garage : </strong>
                {Render.euro(this.state.mensualite_charges_garage)}
              </Typography>
              <Typography>
                <strong>TOM : </strong>
                {Render.euro(this.state.mensualite_ordures)}
              </Typography>
              <Typography>
                <strong>Assurance : </strong>
                {Render.euro(this.state.mensualite_assurance)}
              </Typography>
              <CheckBoxView
                label={
                  <>
                    Avance CAF{" "}
                    <InfoLightTooltip
                      title={
                        "Activer l'avance de la CAF ajoute une ligne CAF ESTIMEE à l'échéancier."
                      }
                    />
                  </>
                }
                value={this.state.avance_caf}
              />
              <Typography>
                <strong>CAF estimée (APL ou AL) : </strong>
                {Render.euro(this.state.montant_caf)}
              </Typography>
              <MensualiteView mensualites={this.state.mensualites} />
            </CardContent>
          </Card>
          <div style={{ margin: "-10px 0" }}>
            <Apurements dense clId={id} history={this.props.history} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <EcheanciersCard
            referentsFullName={referentsFullName}
            contractId={id}
            typePaiement={cp?.type_paiement}
            onSoldeUpdate={() => this.loadAsyncData()}
          />
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL +
                "/logs?type=ContratLocataire&id=" +
                this.state.id
              }
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
          <GenerateFileWithDatesDropdown
            buttonText="HC"
            tooltipText="Historique comptable"
            baseUrl="/contrats-locataires"
            documentUrl="historique-comptable"
            idContrat={id}
          />
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

ContratsLocatairesView.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ContratsLocatairesView);
