import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigAlertTable from "components/Table/ConfigAlertTable";

// context
import { withUserContext } from "context/UserContext";

class AlertTravauxConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/alertes-travaux";
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <ConfigAlertTable
            type="travaux"
            channel="database"
            cardTitle="Alertes Travaux"
            name="alerte"
            backUrl="/config-alertes"
            frontUrl={this.FRONT_URL}
          />
          {/* <ConfigAlertTable
            type="travaux"
            channel="mail"
            cardTitle="Emails Travaux"
            name="email"
            backUrl="/config-alertes"
            frontUrl={this.FRONT_URL}
          /> */}
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigAlert"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

AlertTravauxConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AlertTravauxConfig);
