import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";
import logo from "assets/img/logo-hapy.png";

// @mui/material components
import withStyles from "@mui/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { withUserContext } from "context/UserContext";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.PATH_REDIRECT = props.pathRedirect;
    this.MENU_ROUTES = props.routes;
    this.BASE_PATH = props.basePath;
    this.SWITCH_ROUTES = this.renderRoutes();

    this.mainPanelRef = null;

    this.state = {
      mobileOpen: false,
      miniActive: false,
    };

    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history) {
      if (e.history.location.pathname !== e.location.pathname) {
        this.mainPanelRef.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  findCurrentRoute(routes, pathname) {
    let currentRoute = null;
    routes.forEach((route) => {
      if (route.divider) {
        return null;
      }
      if (route.redirect) {
        return null;
      }
      if (this.testPath(this.BASE_PATH + route.path, pathname)) {
        currentRoute = route;
      }
      if (route.collapse) {
        route.views.forEach((viewRoute) => {
          if (this.testPath(viewRoute.path, pathname)) {
            currentRoute = viewRoute;
          }
        });
      }
    });
    return currentRoute;
  }

  testPath(path, pathToTest) {
    let pathRegex = path;
    const regexReplace = /:[^/]*/g;
    pathRegex = pathRegex.replace(regexReplace, "[^/]*");
    const regexTest = RegExp("^" + pathRegex + "$");
    if (regexTest.test(pathToTest)) {
      return true;
    }
    return false;
  }

  renderRoutes = () => {
    const { user } = this.props;

    // key={Date.now()} to force render of the component in case the link render the same Component.

    return (
      <Switch>
        {this.MENU_ROUTES.map((prop, key) => {
          if (prop.divider) {
            return null;
          }
          if (!user.canOne(prop.permission)) {
            return null;
          }
          if (prop.redirect) {
            return (
              <Redirect
                from={this.BASE_PATH + prop.path}
                to={this.BASE_PATH + prop.pathTo}
                key={key}
              />
            );
          }
          if (prop.collapse) {
            return prop.views.map((prop, key) => {
              return this.renderRoute(prop, key);
            });
          }
          return this.renderRoute(prop, key);
        })}
      </Switch>
    );
  };

  renderRoute = (prop, key) => {
    const { user } = this.props;
    if (!user.canOne(prop.permission)) {
      return (
        <Redirect
          exact
          from={this.BASE_PATH + prop.path}
          to={prop.pathTo || this.PATH_REDIRECT}
          key={key}
        />
      );
    }
    return (
      <Route
        exact
        path={this.BASE_PATH + prop.path}
        render={(props) => (
          <prop.component
            key={Date.now()}
            {...props}
            {...prop.componentProps}
          />
        )}
        key={key}
      />
    );
  };

  render() {
    const { classes, color, linkPath, linkText, configBackground, ...rest } =
      this.props;
    const currentRoute = this.findCurrentRoute(
      this.MENU_ROUTES,
      this.props.location.pathname,
    );
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: false,
      });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.MENU_ROUTES}
          basePath={this.BASE_PATH}
          logoText=""
          logo={logo}
          image=""
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          bgColor="white"
          miniActive={this.state.miniActive}
          color={color}
          linkPath={linkPath}
          linkText={linkText}
          configBackground={configBackground}
          {...rest}
        />
        <div className={mainPanel} ref={(r) => (this.mainPanelRef = r)}>
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            currentRoute={currentRoute}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {currentRoute && currentRoute.fullscreen === true ? (
            <div className={classes.map}>{this.SWITCH_ROUTES}</div>
          ) : (
            <>
              <div className={classes.content}>
                <div className={classes.container}>{this.SWITCH_ROUTES}</div>
              </div>
              <Footer fluid />
            </>
          )}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  location: PropTypes.any,
  classes: PropTypes.object.isRequired,
  pathRedirect: PropTypes.string,
  routes: PropTypes.any,
  color: PropTypes.string,
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
  configBackground: PropTypes.any,
  basePath: PropTypes.string,
  user: PropTypes.object,
};

export default withStyles(appStyle)(withUserContext(Dashboard));
