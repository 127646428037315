import DateUtils from "./DateUtils";

export default class ApurementUtils {
  static STATUTS = [
    { name: "en_cours", label: "En cours" },
    { name: "termine", label: "Terminé" },
    { name: "annule", label: "Annulé" },
  ];

  static renderStatut(name) {
    const statut = ApurementUtils.STATUTS.find(
      (statut) => statut.name === name,
    );
    return statut ? statut.label : "";
  }

  static getNbMois = (total, mensualite) => {
    total = parseFloat(total);
    mensualite = parseFloat(mensualite);
    if (total && mensualite) {
      return Math.ceil((total / mensualite).toFixed(8));
    }
    return null;
  };

  static getDateFin = (dateDebut, total, mensualite) => {
    total = parseFloat(total);
    mensualite = parseFloat(mensualite);
    if (dateDebut && total && mensualite) {
      return DateUtils.addMonths(
        new Date(dateDebut),
        this.getNbMois(total, mensualite) - 1,
      );
    }
    return null;
  };

  static getDerniereMensualite = (total, mensualite) => {
    total = parseFloat(total);
    mensualite = parseFloat(mensualite);
    if (total && mensualite) {
      const last = (total % mensualite).toFixed(8);
      return last == 0 ? mensualite : last;
    }
    return null;
  };

  static hasConflictCLDate = (clDateSortie, dateFinApurement) => {
    return Boolean(clDateSortie && dateFinApurement > new Date(clDateSortie));
  };
}
