import React from "react";
import PropTypes from "prop-types";
import Render from "Utils/RenderUtils";
import SelectSearch from "components/CustomSelect/SelectSearch";

function UserSelectSearch(props) {
  const { ...rest } = props;

  return (
    <SelectSearch
      buildOptionLabel={(data) => Render.fullName(data)}
      buildOptionAdditionalData={(data) => ({
        is_active: data.is_active,
      })}
      sortOptions={(a, b) => b.is_active - a.is_active}
      groupBy={(option) => {
        if (option.label === "Non renseigné") {
          return "";
        }
        return option.is_active ? "Actif" : "Inactif";
      }}
      shrink
      {...rest}
    />
  );
}

UserSelectSearch.propTypes = {
  name: PropTypes.any,
};

export default UserSelectSearch;
