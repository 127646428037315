import React from "react";
import Render from "Utils/RenderUtils";
import DisableLink from "components/DisableLink/DisableLink";

export default class DossierTravauxUtils {
  static STATUT = [
    { name: "primo_contact", label: "Primo Contact", default_filter: true },
    {
      name: "attente_documents_validation",
      label: "Attente de documents bénéficiaire et vérification éligibilité",
      default_filter: true,
    },
    {
      name: "visite_a_programmer",
      label: "Visite à programmer",
      default_filter: true,
    },
    {
      name: "visite_programmee",
      label: "Visite programmée",
      default_filter: true,
    },
    {
      name: "etude_technique",
      label: "Étude technique en cours",
      default_filter: true,
    },
    {
      name: "montage_dossier",
      label: "Dossier en cours de montage (bénéficiaire)",
      default_filter: true,
    },
    {
      name: "document_recus_signe",
      label: "Document reçus et signés / Dossier en cours de montage (admin)",
      default_filter: true,
    },
    { name: "envoye", label: "Déposé / Envoyé", default_filter: true },
    { name: "notifie", label: "Agréé / Notifié", default_filter: true },
    { name: "debut_travaux", label: "Travaux débutés", default_filter: true },
    {
      name: "visite_fin_travaux_a_programmer",
      label: "Visite fin des travaux à programmer",
      default_filter: true,
    },
    {
      name: "visite_fin_travaux_programmee",
      label: "Visite fin des travaux programmée",
      default_filter: true,
    },
    {
      name: "fin_travaux",
      label: "Travaux terminés / Demande de facturation à faire",
      default_filter: true,
    },
    { name: "facture", label: "Facturé", default_filter: true },
    { name: "paye", label: "Payé", default_filter: true },
    { name: "cloture", label: "Clôturé", default_filter: false },
    { name: "annule", label: "Annulé", default_filter: false },
  ];

  static PRIMO_CONTACT =
    "La date d'ouverture du dossier détermine le statut initial : 'Primo Contact'.";
  static ATTENTE_DOCUMENTS_VALIDATION =
    "Si la date date de demande de documents est avant la date actuelle, le status sera : 'Attente de documents bénéficiaire et vérification d'éligibilité'";
  static VALIDATION_DOCUMENTS =
    "Si la date de validation des documents est avant la date actuelle, le status sera : 'Attente de documents bénéficiaire et vérification d'éligibilité'";
  static VISITE_A_PROGRAMMER =
    "Si la date visite a programmer est après la date actuelle, le statut sera : 'Visite programmée'. Si la date est avant la date actuelle, le statut sera : 'Étude technique en cours'.";
  static MONTAGE_BENEFICIAIRE =
    "La date d'envoi des documents détermine le statut : 'Dossier en cours de montage (bénéficiaire)'. Elle doit être antérieure à la date actuelle.";
  static RECU_SIGNE =
    "Si la date de dépôt du contrat AMO validé est avant la date actuelle, le status sera : 'Documents reçus et signés / Dossier en cours de montage (admin)'";
  static DEPOSE_ENVOYE =
    "Si les dates d'envoi pour tous les financeurs gérés en interne sont avant la date actuelle, le status sera : 'Déposé / Envoyé'";
  static AGREE_NOTIFIE =
    "Si les dates de notification pour tous les financeurs gérés en interne sont avant la date actuelle, le status sera : 'Agréé / Notifié'";
  static DEBUT_TRAVAUX =
    "Si la date de début des travaux est avant la date actuelle, le status sera : 'Travaux débutés'";
  static FIN_TRAVAUX =
    "Si la date de fin des travaux est avant la date actuelle, le status sera : 'Visite fin des travaux à programmer'";
  static VISITE_FIN_TRAVAUX =
    "Si la date de visite après travaux est avant la date actuelle, le statut sera : 'Visite fin des travaux programmée'. Si la date est avant la date actuelle, le statut sera : 'Travaux terminés / Demande de facturation à faire'.";
  static FACTURE =
    "Si les dates de demande pour tous les financeurs gérés en interne sont avant la date actuelle, le status sera : 'Facturé'";
  static PAYE =
    "Si les montants notifiés et payés des subventions sont identiques OU le champ 'Valider manuellement les paiements' est cochée pour tous les financeurs gérés en interne, le status sera : 'Payé'";
  static CLOTURE =
    "Si les montants notifiés et payés des subventions sont identiques OU le champ 'Valider manuellement les paiements' est coché et les montants prévisionnels, les frais AMO prévu et payés sont identiques pour tous les financeurs gérés en interne, et la date de visite après travaux est avant la date actuelle, le status sera : 'Clôturé'. Indiquez 0€/0€ si un financeur géré en interne n'a pas de frais d'AMO.";
  static AUDIT_ENERGETIQUE =
    "Données provenant de l'estimation validée de l'audit avant travaux'.";
  static AMO_SUBVENTION =
    "Frais d'AMO couvert par les financeurs, correspondant à la somme des AMO renseignées dans les subventions";
  static AMO_SANS_SUBVENTION =
    "Frais d'AMO non couvert par les financeurs et à la charge du bénéficiaire.";

  static getStatut = (name) => {
    return this.STATUT.find((statut) => statut.name === name);
  };

  static getStatutDefaultFilter = () => {
    return this.STATUT.filter((statut) => statut.default_filter === true).map(
      (statut) => statut.name,
    );
  };

  static renderStatut = (name) => {
    return this.getStatut(name)?.label ?? "";
  };

  static listFinanceurs = (items) => {
    let listValues = items
      ?.map((item) => item?.financeur?.value ?? null)
      .filter((a) => a != null);
    return [...new Set(listValues)].join(", ");
  };

  static renderTravaux = (travaux) => {
    return travaux?.map((t) => t?.type?.value)?.join(", ");
  };

  static renderInfo = (dt, withLink = true) => {
    if (dt == null) {
      return;
    }

    const url = withLink ? "/dossiers-travaux/detail/" + dt.id : null;

    return (
      <DisableLink to={url}>
        DT {dt.id}
        {Render.ifNotEmpty(
          DossierTravauxUtils.renderTravaux(dt.travaux),
          " - ",
        )}{" "}
        ({DossierTravauxUtils.renderStatut(dt.statut)})
      </DisableLink>
    );
  };

  static renderForListForAudit = (dt) => {
    const travaux = DossierTravauxUtils.renderTravaux(dt.travaux);
    const statut = DossierTravauxUtils.renderStatut(dt.statut);
    const audit = dt.audit_avant_id ? " - AD" + dt.audit_avant_id + " lié" : "";
    return `DT${dt.id}${travaux ? " - " + travaux : ""} ${statut} ${audit}`;
  };

  static renderIdStatut = (dt) => {
    const statut = DossierTravauxUtils.renderStatut(dt.statut);
    return `DT ${dt.id} ${statut}`;
  };
}
