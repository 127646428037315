// React / axiosApiBackend / PropTypes etc
import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
// translations
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Notifications from "@mui/icons-material/Notifications";

// core components
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import CheckboxRenderer from "components/CustomAgRenderer/CheckboxRenderer";
import SearchBar from "components/SearchBar/SearchBar";

// others
import SearchBarService from "services/SearchBarService";

// utils
import AgGridUtils from "Utils/AgGridUtils";

class ConfigAlertTable extends Component {
  constructor(props) {
    super(props);

    this.BACKEND_URL = props.backUrl;
    this.FRONT_URL = props.frontUrl;
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;

    this.state = {
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "Active",
          field: "enable",
          cellRenderer: CheckboxRenderer,
          cellRendererParams: (params) => ({
            checked: params.value,
            onChange: (enable) => this.toggleEnabled(params, enable),
          }),
        },
        { headerName: "Nom de l'" + props.name, field: "name" },
        { headerName: "Déclencheur", field: "trigger" },
        { headerName: "Destinataire", field: "recipient" },
        { headerName: "Contenu", field: "content" },
      ]),
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
      },
      data: null,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const params = {
      type: this.props.type,
      channel: this.props.channel,
    };
    axiosApiBackend.get(this.BACKEND_URL, { params: params }).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  toggleEnabled = (params, enable) => {
    axiosApiBackend
      .put(this.BACKEND_URL + "/" + params.data.id)
      .then(() => {
        const stateEnable = enable ? "activée" : "désactivée";
        this.setState({
          snackbar: true,
          snackbarMessage: (
            <>
              L&apos;alerte <b>{params.data.name}</b> a bien été{" "}
              <b>{stateEnable}</b>
            </>
          ),
          snackbarColor: "success",
        });
      })
      .catch(() => {
        this.setState({
          snackbar: true,
          snackbarMessage: "Une erreur côté serveur est survenue",
          snackbarColor: "error",
        });
      });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.snackbarColor}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="config">
                  <Notifications />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.props.cardTitle}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize="10"
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

ConfigAlertTable.defaultProps = {
  cardTitle: "Alertes",
  name: "alerte",
};

ConfigAlertTable.propTypes = {
  type: PropTypes.string,
  channel: PropTypes.string,
  cardTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  frontUrl: PropTypes.string.isRequired,
};

export default ConfigAlertTable;
