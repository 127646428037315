import styled from "@mui/system/styled";
import Timeline from "@mui/lab/Timeline";

const StyledTimeline = styled(Timeline, {
  shouldForwardProp: (prop) => prop !== "dense",
})(({ dense }) => ({
  "& .MuiTimelineDot-root": {
    marginBottom: "4px",
  },
  ...(dense && {
    padding: "0",
    // marginTop: "0",
    "& .MuiTimelineItem-root": {
      minHeight: "65px",
    },
    "& .MuiTimelineContent-root": {
      paddingRight: "0px",
    },
    "& .MuiPaper-root": {
      paddingRight: "10px",
      paddingLeft: "10px",
      paddingTop: "3px",
      paddingBottom: "3px",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    "& svg": {
      fontSize: "1rem",
    },
  }),
}));

export default StyledTimeline;
