import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function DisableLink(props) {
  const { to, children, ...rest } = props;

  return (
    <>
      {to == null ? (
        <>{children}</>
      ) : (
        <Link {...rest} to={to}>
          {children}
        </Link>
      )}
    </>
  );
}

DisableLink.propTypes = {
  to: PropTypes.any,
  children: PropTypes.any,
};

export default DisableLink;
