import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import CustomTimeline from "components/Timeline/CustomTimeline";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";

import Render from "Utils/RenderUtils";

import TimelineDot from "@mui/lab/TimelineDot";

class AccompagnementFoyerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { accompagnements } = this.props;

    accompagnements.sort(function (a, b) {
      return new Date(b.date_debut) - new Date(a.date_debut);
    });

    const activeId = accompagnements.length > 0 ? accompagnements[0].id : null;

    return (
      <>
        {accompagnements.length <= 0 ? (
          <Typography>
            <strong>Aucun accompagnement n&apos;a été saisi.</strong>
          </Typography>
        ) : (
          <CustomTimeline
            items={accompagnements}
            visibleItems={1}
            renderDot={(item) => (
              <TimelineDot
                color={activeId === item.id ? "success" : "primary"}
              />
            )}
            renderBody={(item) => {
              return (
                <>
                  <Typography>
                    <strong>Niveau d&apos;accompagnement : </strong>
                    {item.typesuivi?.value}
                  </Typography>
                  <Typography>
                    <strong>Nom du dispositif d&apos;accompagnement : </strong>
                    {item.accompagnement?.nom}
                  </Typography>
                  <Typography>
                    <strong>Date de début : </strong>
                    {Render.date(item.date_debut)}
                  </Typography>
                  <Typography>
                    <strong>Date de fin : </strong>
                    {Render.date(item.date_fin)}
                  </Typography>
                  <Typography>
                    <strong>
                      Observations complementaires{" "}
                      <InfoLightTooltip title="Observations faites par l'organisme orientant le ménage" />{" "}
                      :{" "}
                    </strong>
                  </Typography>
                  <MultilineTypography sx={{ px: 1 }}>
                    {item.observations_complementaires
                      ? item.observations_complementaires
                      : "Aucune observation complémentaire"}
                  </MultilineTypography>
                  {Boolean(item.procedure_de_renouvellement) && (
                    <>
                      <HeaderTitle label="Procédure de renouvellement" />
                      <Typography>
                        <strong>Date de demande de renouvellement : </strong>
                        {Render.date(item.date_demande_renouvellement)}
                      </Typography>
                      <Typography>
                        <strong>Motif de demande de renouvellement : </strong>
                      </Typography>
                      <MultilineTypography sx={{ px: 1 }}>
                        {item.motif_demande_renouvellement
                          ? item.motif_demande_renouvellement
                          : "Aucune motif de renouvellement"}
                      </MultilineTypography>
                    </>
                  )}
                  {Boolean(item.procedure_cloture) && (
                    <>
                      <Typography>
                        <HeaderTitle label="Procédure de clôture" />
                        <strong>Date de demande de clôture : </strong>
                        {Render.date(item.date_demande_cloture)}
                      </Typography>
                      <Typography>
                        <strong>Motif de demande de clôture : </strong>
                      </Typography>
                      <MultilineTypography sx={{ px: 1 }}>
                        {item.motif_demande_cloture
                          ? item.motif_demande_cloture
                          : "Aucun motif de demande de clôture"}
                      </MultilineTypography>
                    </>
                  )}
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

AccompagnementFoyerView.propTypes = {
  accompagnements: PropTypes.array.isRequired,
};

export default AccompagnementFoyerView;
