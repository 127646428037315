import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import Render from "Utils/RenderUtils";
import CustomTimeline from "components/Timeline/CustomTimeline";

class AuditAvenantListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { avenants, currentAuditId } = this.props;

    avenants.sort(function (a, b) {
      return new Date(b.id) - new Date(a.id);
    });

    return (
      <>
        {avenants.length <= 1 ? (
          <Typography>
            <strong>Aucun avenant pour cet Audit.</strong>
          </Typography>
        ) : (
          <CustomTimeline
            items={avenants}
            visibleItems={3}
            renderDot={(item) => (
              <TimelineDot
                color={currentAuditId === item.id ? "success" : "primary"}
              />
            )}
            renderTo={(item) => "/audits/detail/" + item.id}
            renderBody={(item) => {
              const fontWeight =
                currentAuditId === item.id ? "bold" : "regular";
              return (
                <>
                  <Typography fontWeight={fontWeight}>
                    AD{item.id} {Render.date(item.date_demande)}{" "}
                    {item.is_avenant && "(Avenant)"}
                  </Typography>
                  <Typography fontWeight={fontWeight}>
                    {Render.amount(
                      item?.count_estimations,
                      "estimation",
                      "estimations",
                    )}
                  </Typography>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

AuditAvenantListView.propTypes = {
  avenants: PropTypes.array.isRequired,
  currentAuditId: PropTypes.number.isRequired,
};

export default AuditAvenantListView;
