import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components

// @mui/icons-material

// core components
import Button from "components/Button/Button";
import { withUserContext } from "context/UserContext";
import EcheancierComptable from "components/Echeancier/EcheancierComptable";
import Echeancier from "components/Echeancier/Echeancier";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import { Typography } from "@mui/material";
import Euro from "@mui/icons-material/Euro";

class EcheanciersCard extends Component {
  constructor(props) {
    super(props);

    this.ECHEANCIER_CPT_REF = React.createRef();
    this.ECHEANCIER_REF = React.createRef();

    this.state = {
      vueComptable: false,
    };
  }

  componentDidMount() {}

  // this is used in parent component
  // eslint-disable-next-line react/no-unused-class-component-methods
  reloadEcheancier = () => {
    this.ECHEANCIER_CPT_REF.current?.loadAsyncData();
    this.ECHEANCIER_REF.current?.loadAsyncData();
  };

  changeEcheancierVue = () => {
    this.setState({ vueComptable: !this.state.vueComptable });
  };

  render() {
    const { referentsFullName, contractId, typePaiement, onSoldeUpdate } =
      this.props;
    const { vueComptable } = this.state;

    return (
      <Card>
        <CardHeader
          avatar={
            <CardAvatar color="success">
              <Euro />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              ÉCHÉANCIER - {referentsFullName} (CL{contractId})
            </Typography>
          }
          action={
            <Button onClick={this.changeEcheancierVue}>
              {vueComptable ? "Vue Avis d'echeance" : "Vue Comptable"}
            </Button>
          }
        />
        <CardContent>
          {vueComptable ? (
            <EcheancierComptable
              ref={this.ECHEANCIER_CPT_REF}
              dataURL="/operations-locataires"
              contractId={contractId}
              typePaiement={typePaiement}
              onSoldeUpdate={onSoldeUpdate}
              entityLink="contrats-proprietaires"
              entityName="CP"
            />
          ) : (
            <Echeancier
              ref={this.ECHEANCIER_REF}
              dataURL="/operations-locataires"
              contractId={contractId}
              typePaiement={typePaiement}
              onSoldeUpdate={onSoldeUpdate}
            />
          )}
        </CardContent>
      </Card>
    );
  }
}

EcheanciersCard.propTypes = {
  referentsFullName: PropTypes.any,
  contractId: PropTypes.any.isRequired,
  typePaiement: PropTypes.any,
  onSoldeUpdate: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default withUserContext(EcheanciersCard);
