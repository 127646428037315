import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

// @mui/material components
import MuiButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// @mui/icons-material
import Add from "@mui/icons-material/Add";

import AlertDialog from "components/AlertDialog/AlertDialog";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";

class CreatePersonneForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/personnes";

    this.state = {
      personne_id: props.personne_id ?? "",
      personne: props.personne ?? null,
      personne_key: 0,
      personnes: [],
      openCreatePersonnePopup: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personne_id !== this.props.personne_id) {
      this.setState({ personne_id: this.props.personne_id });
    }
  }

  findPersonne = (id, personnes = null) => {
    personnes = personnes ?? this.state.personnes;
    return personnes?.find((l) => l.id === id) ?? null;
  };

  handleOnResponsePersonne = (data) => {
    this.setState({
      personnes: data,
    });
  };

  handleChangePersonne = (name, value) => {
    const selectedPersonne = this.findPersonne(value) ?? null;
    this.setState({
      personne: selectedPersonne,
      personne_id: value,
    });

    if (this.props.onPersonneChange) {
      this.props.onPersonneChange(value);
    }
  };

  handlePersonneCreated = (data) => {
    this.setState((prevState) => ({
      personne: data,
      personne_id: data.id,
      personne_key: prevState.personne_key + 1,
    }));

    if (this.props.onPersonneChange) {
      this.props.onPersonneChange(data.id);
    }
  };

  handleOpenCreatePersonnePopup = () => {
    this.setState({ openCreatePersonnePopup: true });
  };

  handleCloseCreatePersonnePopup = () => {
    this.setState({
      civilite_id: "",
      nom: "",
      prenom: "",
      date_naissance: "",
      nationalite: "",
      openCreateLogementPopup: false,
      openCreatePersonnePopup: false,
    });
  };

  handleSubmitCreatePersonne = () => {
    const { civilite_id, nom, prenom, date_naissance, nationalite } =
      this.state;

    const dataIn = {
      civilite_id,
      nom,
      prenom,
      date_naissance,
      nationalite,
    };

    axiosApiBackend
      .post(this.BACKEND_URL, dataIn)
      .then((res) => {
        this.handlePersonneCreated(res.data);
      })
      .catch((err) => {
        this.handleError(err);
      });

    this.handleCloseCreatePersonnePopup();
  };

  render() {
    const { errors, label, apiUrl } = this.props;
    const {
      personne_key,
      personne_id,
      openCreatePersonnePopup,
      civilite_id,
      nom,
      prenom,
      date_naissance,
      nationalite,
    } = this.state;

    return (
      <>
        <Grid item xs={12} lg>
          <SelectSearch
            // Réinitialise le composant SelectSearch avec la personne crée
            key={personne_key}
            name="personne_id"
            label={label}
            apiUrl={apiUrl}
            value={personne_id}
            onChange={this.handleChangePersonne}
            onResponse={this.handleOnResponsePersonne}
            sortOptions={(a, b) => b.key - a.key}
            buildOptionLabel={(data) =>
              (data.prenom ?? "") + " " + (data.nom ?? "")
            }
            shrink
            error={Boolean(errors?.id)}
          />
        </Grid>
        {!personne_id && (
          <Grid item>
            <Tooltip title="Ajouter une personne" placement="right">
              <MuiButton
                color="primary"
                onClick={this.handleOpenCreatePersonnePopup}
              >
                <Add />
              </MuiButton>
            </Tooltip>
          </Grid>
        )}
        <AlertDialog
          title="Créer une personne"
          open={openCreatePersonnePopup}
          maxWidth="md"
          fullWidth={true}
          onConfirm={this.handleSubmitCreatePersonne}
          confirmLabel="Enregistrer"
          confirmColor="primary"
          onCancel={this.handleCloseCreatePersonnePopup}
          cancelLabel="Annuler"
          cancelColor="reversed"
        >
          <Grid container columnSpacing={2} spacing={2} alignItems="center">
            <Grid item xs={12} lg={4}>
              <SelectSearch
                label="Civilité"
                apiUrl="/config-user-civilite"
                onChange={(name, value) =>
                  this.setState({ civilite_id: value })
                }
                value={civilite_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.civilite_id)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInput
                label="Prénom"
                value={prenom}
                onChange={(event) =>
                  this.setState({ prenom: event.target.value })
                }
                error={Boolean(errors?.prenom)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextInput
                label="Nom"
                value={nom}
                onChange={(event) => this.setState({ nom: event.target.value })}
                error={Boolean(errors?.nom)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Date de naissance"
                type="date"
                value={date_naissance}
                onChange={(event) =>
                  this.setState({ date_naissance: event.target.value })
                }
                error={Boolean(errors?.date_naissance)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Nationalité"
                onChange={(event) =>
                  this.setState({ nationalite: event.target.value })
                }
                value={nationalite}
                error={Boolean(errors?.nationalite)}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

CreatePersonneForm.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  apiUrl: PropTypes.string.isRequired,
  personne_id: PropTypes.string,
  personne: PropTypes.object,
  onPersonneChange: PropTypes.func,
};

export default CreatePersonneForm;
