import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Business from "@mui/icons-material/Business";
import Description from "@mui/icons-material/Description";

import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import CheckBoxTable from "components/Table/CheckBoxTable";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

import { withUserContext } from "context/UserContext";

class EpciConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/epci";

    this.state = {
      id: 0,
      value: "",
      villes: [],
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelectionChanged = (ids) => {
    this.setState({ villes: ids });
  };

  render() {
    const { id, value, villes, errors } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <TablePopUpContainer
            icon={<Business />}
            dialogProps={{
              fullWidth: true,
              maxWidth: "md",
            }}
            title="EPCI"
            backendUrl="/config-epci"
            searchBar
            searchStorageKey="config-epci"
            autoSize={false}
            colDef={[
              { field: "id", editable: false, hide: true },
              {
                headerName: "Libellé",
                field: "value",
                flex: 1,
              },
              {
                headerName: "Communes",
                field: "villes",
                flex: 4,
                valueGetter: (params) =>
                  params.data.villes.map((ville) => ville.ville).join(", "),
              },
            ]}
            formInitData={() => {
              this.setState({
                id: 0,
                value: "",
                villes: [],
                errors: null,
              });
            }}
            formSetData={(data) => {
              this.setState({
                id: data.id ?? 0,
                value: data.value ?? "",
                villes: data.villes.map((ville) => ville.id) ?? [],
                errors: null,
              });
            }}
            formGetData={() => ({
              id: id,
              value: value,
              villes: villes,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            getDeleteContent={(data) => data.value}
            getDeleteError={(data, error) =>
              (data.value ?? "") +
              " est associé à " +
              (error ?? "") +
              " dispositif(s)"
            }
            formTitle={id ? "Modifier l'EPCI" : "Ajouter un EPCI"}
            formConfirmLabel={id ? "Modifier" : "Ajouter"}
            formContent={
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    label="Libellé"
                    name="value"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={value}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.value)}
                  />
                </Grid>
                <CheckBoxTable
                  backendUrl="/villes"
                  selectedIds={villes}
                  onSelectionChanged={this.handleSelectionChanged}
                  colDef={[
                    {
                      headerName: "Code Postal",
                      field: "cp",
                      checkboxSelection: true,
                      flex: 1,
                    },
                    {
                      headerName: "Ville",
                      field: "ville",
                      flex: 1,
                    },
                  ]}
                />
              </Grid>
            }
          />
        </Grid>

        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigEpci"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

EpciConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EpciConfig);
