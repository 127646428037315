import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import Info from "@mui/icons-material/Info";

import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormCollection from "components/Form/FormCollection";
import LightTooltip from "components/Tooltip/LightTooltip";

class AccompagnementFoyerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  event = (handleChangeFn) => {
    return (event) => handleChangeFn(event.target.name, event.target.value);
  };

  render() {
    const { accompagnements, addRow, updateRow, removeRow, errors } =
      this.props;

    return (
      <FormCollection
        items={accompagnements}
        rowTitle="Accompagnement"
        addRow={addRow}
        removeRow={removeRow}
        tooltipAdd="Ajouter un accompagnement"
        tooltipDelete="Supprimer l'accompagnement"
        dialogDeleteTitle={(rowNumber) =>
          "Voulez-vous supprimer l'accompagnement n° " + rowNumber + " ?"
        }
        formInputs={(accompagnement, key) => {
          return (
            <>
              <Grid item xs={12} lg={6}>
                <SelectSearch
                  name="suivi_id"
                  label="Niveau d'accompagnement"
                  apiUrl="/config-types-suivi"
                  value={accompagnement.suivi_id ?? ""}
                  onChange={(name, value) => updateRow(key, name, value)}
                  buildOptionLabel={(data) => data.value}
                  shrink
                  error={Boolean(errors?.[key + ".suivi_id"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectSearch
                  name="accompagnement_id"
                  label="Nom du dispositif d'accompagnement"
                  apiUrl="/accompagnements"
                  value={accompagnement.accompagnement_id ?? ""}
                  onChange={(name, nom) => updateRow(key, name, nom)}
                  buildOptionLabel={(data) => data.nom}
                  shrink
                  error={Boolean(errors?.[key + ".accompagnement_id"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput
                  label="Date de début"
                  name="date_debut"
                  type="date"
                  value={accompagnement.date_debut ?? ""}
                  onChange={this.event((name, value) => {
                    updateRow(key, name, value);
                    // Add n+1 to date_fin
                    if (!accompagnement.date_fin) {
                      let dateDebut = new Date(value);
                      dateDebut.setFullYear(dateDebut.getFullYear() + 1);
                      const endDate = dateDebut.toISOString().split("T")[0];
                      updateRow(key, "date_fin", endDate);
                    }
                  })}
                  error={Boolean(errors?.[key + ".date_debut"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInput
                  label="Date de fin"
                  name="date_fin"
                  type="date"
                  value={accompagnement.date_fin ?? ""}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  error={Boolean(errors?.[key + ".date_fin"])}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={
                    <LightTooltip title="Observations faites par l'organisme orientant le ménage">
                      <span>
                        Observations complémentaires <Info fontSize="small" />
                      </span>
                    </LightTooltip>
                  }
                  name="observations_complementaires"
                  multiline
                  minRows={2}
                  maxRows={8}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  value={accompagnement.observations_complementaires ?? ""}
                  error={Boolean(
                    errors?.[key + ".observations_complementaires"],
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckBoxInput
                  name="procedure_de_renouvellement"
                  checked={Boolean(accompagnement.procedure_de_renouvellement)}
                  onChange={(name, value) => updateRow(key, name, value)}
                  value={accompagnement.procedure_de_renouvellement}
                  error={Boolean(
                    errors?.[key + ".procedure_de_renouvellement"],
                  )}
                  label="Procédure de renouvellement"
                />
              </Grid>
              {Boolean(accompagnement.procedure_de_renouvellement) && (
                <>
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="Date de demande de renouvellement"
                      name="date_demande_renouvellement"
                      type="date"
                      value={accompagnement.date_demande_renouvellement ?? ""}
                      onChange={this.event((name, value) =>
                        updateRow(key, name, value),
                      )}
                      error={Boolean(
                        errors?.[key + ".date_demande_renouvellement"],
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      label="Motif de demande de renouvellement"
                      name="motif_demande_renouvellement"
                      multiline
                      minRows={2}
                      maxRows={8}
                      onChange={this.event((name, value) =>
                        updateRow(key, name, value),
                      )}
                      value={accompagnement.motif_demande_renouvellement ?? ""}
                      error={Boolean(
                        errors?.[key + ".motif_demande_renouvellement"],
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <CheckBoxInput
                  name="procedure_cloture"
                  checked={Boolean(accompagnement.procedure_cloture)}
                  onChange={(name, value) => updateRow(key, name, value)}
                  value={accompagnement.procedure_cloture}
                  error={Boolean(errors?.[key + ".procedure_cloture"])}
                  label="Procédure de clôture"
                />
              </Grid>
              {Boolean(accompagnement.procedure_cloture) && (
                <>
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="Date de demande de clôture"
                      name="date_demande_cloture"
                      type="date"
                      value={accompagnement.date_demande_cloture ?? ""}
                      onChange={this.event((name, value) =>
                        updateRow(key, name, value),
                      )}
                      error={Boolean(errors?.[key + ".date_demande_cloture"])}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      label="Motif de demande de clôture"
                      name="motif_demande_cloture"
                      multiline
                      minRows={2}
                      maxRows={8}
                      onChange={this.event((name, value) =>
                        updateRow(key, name, value),
                      )}
                      value={accompagnement.motif_demande_cloture ?? ""}
                      error={Boolean(errors?.[key + ".motif_demande_cloture"])}
                    />
                  </Grid>
                </>
              )}
            </>
          );
        }}
      />
    );
  }
}

AccompagnementFoyerForm.propTypes = {
  accompagnements: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default AccompagnementFoyerForm;
