import LoginForm from "views/Forms/Auth/LoginForm";
import ForgotPasswordFrom from "views/Forms/Auth/ForgotPasswordFrom.jsx";
import ResetPasswordFrom from "views/Forms/Auth/ResetPasswordFrom.jsx";
import WaitingServerErrorPage from "views/Pages/WaitingServerErrorPage";

var indexRoutes = [
  {
    exact: true,
    path: "/",
    name: "Login",
    component: LoginForm,
  },
  {
    exact: true,
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordFrom,
  },
  {
    exact: true,
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPasswordFrom,
  },
  {
    exact: true,
    path: "/en-attente-des-serveurs",
    name: "Reset Password",
    component: WaitingServerErrorPage,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/",
    name: "Login",
  },
];

export default indexRoutes;
