import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { axiosApiBackend } from "variables/axiosConfigs";
import Typography from "@mui/material/Typography";
import CustomMarker from "views/Maps/CustomMarker";
import Box from "@mui/material/Box";
import styled from "@mui/styles/styled";
import { withUiCustomizationContext } from "context/UiCustomizationContext";

const CaptionBox = styled(Box)(() => ({
  position: "absolute",
  right: "70px",
  bottom: "20px",
  color: "white",
  textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
  display: "flex",
  alignItems: "center",
}));

class MapGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/contrats-locataires";
    // couleurs pour chaque type de contrat (Loc/Sous-loc, MG, MGGS)
    this.TYPE_COLORS = {
      "Sous-location": "LightSalmon",
      "Mandat de gestion": "Khaki",
      MGGS: "PaleGreen",
    };

    this.state = {
      contratLocataires: [],
      center: { lat: 43.695547, lng: 7.257504 },
      zoom: 12,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    let params = {
      filters: ["presents"],
    };
    axiosApiBackend.get(this.BACKEND_URL, { params }).then((result) => {
      this.setState({ contratLocataires: result.data });
    });
  }

  renderMarkers = () => {
    return this.state.contratLocataires
      .filter(
        (cl) =>
          cl.logement?.adresse?.latitude != null &&
          cl.logement?.adresse?.longitude != null,
      )
      .map((cl) => (
        <CustomMarker
          key={cl.id}
          lat={cl.logement.adresse.latitude}
          lng={cl.logement.adresse.longitude}
          text={cl.id + ""}
          disablelink={"/contrats-locataires/detail/" + cl.id}
          color={
            this.TYPE_COLORS[cl.logement.contratproprietaire?.type] ?? "azure"
          }
        />
      ));
  };

  render() {
    const { center, zoom } = this.state;
    const { uiCustomization } = this.props;

    return (
      <>
        <div style={{ height: "calc(100vh - 70px)", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: uiCustomization?.google_maps_key }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            {this.renderMarkers()}
          </GoogleMapReact>
          <CaptionBox>
            <CustomMarker color={this.TYPE_COLORS["Sous-location"]} />
            <Typography>&nbsp;Sous-location&nbsp;&nbsp;</Typography>
            <CustomMarker color={this.TYPE_COLORS["Mandat de gestion"]} />
            <Typography>&nbsp;Mandat de gestion&nbsp;&nbsp;</Typography>
            <CustomMarker color={this.TYPE_COLORS["MGGS"]} />
            <Typography>&nbsp;MGGS</Typography>
          </CaptionBox>
        </div>
      </>
    );
  }
}

MapGoogle.propTypes = {
  uiCustomization: PropTypes.object,
};

export default withUiCustomizationContext(MapGoogle);
