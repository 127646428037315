import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui/icons-material
import ExpandMore from "@mui/icons-material/ExpandMore";

const styles = (theme) => ({
  verticalMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  alignCenter: {
    textAlign: "center",
  },
  icon: {
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  iconExpanded: {
    transform: "rotate(180deg)",
  },
});

class ShowMoreBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  toggleActive = () => {
    this.setState((prevState) => ({ ...prevState, active: !prevState.active }));
  };

  render() {
    const { classes, header, children } = this.props;
    const { active } = this.state;
    const iconClass = classes.icon + (active ? " " + classes.iconExpanded : "");
    return (
      <Box>
        <Box>{header}</Box>
        {children ? (
          <>
            <Collapse in={active} timeout="auto" unmountOnExit>
              <Box>{children}</Box>
            </Collapse>
            <Box className={classes.alignCenter}>
              <IconButton
                size="small"
                onClick={this.toggleActive}
                className={iconClass}
              >
                <ExpandMore />
              </IconButton>
            </Box>
          </>
        ) : null}
      </Box>
    );
  }
}

ShowMoreBox.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.node,
  children: PropTypes.node,
};

export default withStyles(styles)(ShowMoreBox);
