import React from "react";
import PropTypes from "prop-types";

const TextIcon = ({ Icon, sx, ...rest }) => {
  return (
    <Icon
      {...rest}
      sx={{
        fontSize: "1.2em",
        verticalAlign: "text-bottom",
        ...sx,
      }}
    />
  );
};

TextIcon.propTypes = {
  Icon: PropTypes.any,
  sx: PropTypes.any,
};

export default TextIcon;
