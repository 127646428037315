import React from "react";
import PropTypes from "prop-types";

// @mui/material components

// @mui/icons-material
import Grid from "@mui/material/Grid";

// core components
import FormCollection from "components/Form/FormCollection";
import TextInput from "components/CustomInput/TextInput";
import NumberInput from "components/CustomInput/NumberInput";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import CurrencyInput from "components/CustomInput/CurrencyInput";

class InfoFiscaleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  event = (handleChangeFn) => {
    return (event) => handleChangeFn(event.target.name, event.target.value);
  };

  nextYear = (year) => {
    return year ? +year + 1 : "";
  };

  render() {
    const { infosFiscales, addRow, updateRow, removeRow, errors } = this.props;

    return (
      <FormCollection
        items={infosFiscales}
        linkedRelationsCountKey="dossiers_travaux_count"
        linkedRelationName="Dossier Travaux"
        rowTitle="Information Fiscale"
        addRow={addRow}
        removeRow={removeRow}
        tooltipAdd="Ajouter une Information Fiscale"
        tooltipDelete="Supprimer l'Information Fiscale"
        dialogDeleteTitle={(rowNumber) =>
          "Voulez-vous supprimer l'Information Fiscale n° " + rowNumber + " ?"
        }
        formInputs={(item, key) => {
          return (
            <>
              <Grid item xs={12} lg={6}>
                <TextInput
                  label={
                    <>Année RFR (déclaration {this.nextYear(item.annee)})</>
                  }
                  name="annee"
                  value={item.annee ?? ""}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  inputProps={{
                    maxLength: 4,
                  }}
                  error={Boolean(errors?.[key + ".annee"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CurrencyInput
                  label={
                    <>
                      Montant RFR{" "}
                      <InfoLightTooltip title="Revenu fiscal de référence." />
                    </>
                  }
                  name="revenu_fiscal"
                  value={item.revenu_fiscal ?? ""}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  error={Boolean(errors?.[key + ".revenu_fiscal"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CurrencyInput
                  label="Revenu brut global"
                  name="revenu_brut"
                  value={item.revenu_brut ?? ""}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  error={Boolean(errors?.[key + ".revenu_brut"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <NumberInput
                  label="Nombre de parts"
                  name="nb_parts"
                  value={item.nb_parts ?? ""}
                  onChange={this.event((name, value) =>
                    updateRow(key, name, value),
                  )}
                  error={Boolean(errors?.[key + ".nb_parts"])}
                />
              </Grid>
            </>
          );
        }}
      />
    );
  }
}

InfoFiscaleForm.propTypes = {
  infosFiscales: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default InfoFiscaleForm;
