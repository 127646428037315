export default class FileUtils {
  /**
   * Build a file and download it from an url. Compute the file name from the headers.
   *
   * @param {*} axiosInstance axios instance used for the call
   * @param {*} url url where the file content is
   * @param {*} options options used for the call
   * @param {string} fallbackName Name that will be used if none have been found in headers
   * @param {function} callback callback that is called on response, have the response as a parameter
   */
  static downLoadFromUrl(
    axiosInstance,
    url,
    options,
    fallbackName,
    callback = null,
  ) {
    axiosInstance.get(url, options).then((res) => {
      if (null != callback) {
        callback(res);
      }
      FileUtils.downLoadFile(res.data, res.headers, fallbackName);
    });
  }

  /**
   * Build a file and download it. Compute the file name from the headers.
   *
   * @param {*} file File content
   * @param {*} headers File headers to compute the filename
   * @param {string} fallbackName Name that will be used if none have been found in headers
   */
  static downLoadFile(file, headers, fallbackName = "file.txt") {
    const contentDisposition =
      headers["Content-Disposition"] || headers["content-disposition"];
    const contentType =
      headers["Content-Type"] || headers["content-type"] || "text/plain";
    const fileName = this.getFileName(contentDisposition, fallbackName);

    const blob = new Blob([file], { type: contentType });
    let URL = window.URL || window.webkitURL;
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    URL.revokeObjectURL(url);
  }

  /**
   * Extract the filename from a Content Disposition header. Return fallback name if it fails.
   *
   * @param {string} contentDisposition Content Disposition header
   * @param {string} fallbackName Name that will be used if none have been found in the header
   */
  static getFileName(contentDisposition, fallbackName = null) {
    let res1 = /filename\*=utf-8''([^;]*)/.exec(contentDisposition);
    if (!(res1 && res1[1])) {
      res1 = /filename=([^;]*)/.exec(contentDisposition);
    }
    let ret = res1 && res1[1] ? decodeURI(res1[1]) : fallbackName;
    let res2 = /(["'])(.*)\1/.exec(ret);
    return res2 && res2[2] ? res2[2] : ret;
  }

  /**
   * Convert a JSON Array Buffer Response to an Object.
   *
   * @param {object} data response as Array Buffer
   */
  static getDataFromArrayBuffer(data) {
    if (null != data) {
      const decoder = new TextDecoder("utf-8");
      return JSON.parse(decoder.decode(data));
    }
    return null;
  }
}
