import React from "react";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Calculate from "@mui/icons-material/Calculate";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button.jsx";
import SelectInput from "components/CustomSelect/SelectInput";

class CalculDePointe extends React.Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.MONTHS = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    this.YEARS = [
      d.getFullYear(),
      d.getFullYear() - 1,
      d.getFullYear() - 2,
      d.getFullYear() - 3,
    ];

    this.state = {
      // FORM
      year: this.YEARS[0],
      month: d.getMonth(),
      // END FORM
      loading: false,
      errorMessage: null,
      successMessage: null,
      open: false,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
      errorMessage: null,
      successMessage: null,
    });
    const { year, month } = this.state;

    axiosApiBackendNoJson
      .get(`/calcule-pointe/${year}/${month + 1}`)
      .then(() => {
        this.setState({
          loading: false,
          successMessage: `Calcule de pointe effectué pour ${this.MONTHS[month]}  ${year}.`,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage:
            error.response?.data?.error?.message ?? "Une erreur est survenue",
        });
      });
  };

  render() {
    const { errorMessage, successMessage, loading, open, month, year } =
      this.state;

    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<Calculate />}
          onClick={this.toggle}
        >
          Calcul de pointe
        </Button>
        <AlertDialog
          open={open}
          fullWidth
          title="Générer le calcul de pointe pour un mois"
          onConfirm={this.handleSubmit}
          confirmLabel="Générer"
          confirmColor="primary"
          onCancel={this.toggle}
          cancelLabel="Annuler"
          cancelColor="reversed"
          loading={loading}
        >
          {errorMessage && (
            <Typography color="error.main">{errorMessage}</Typography>
          )}
          {successMessage && (
            <Typography color="success.main">{successMessage}</Typography>
          )}
          Attention, cette opération peut prendre quelques minutes.
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Mois"
                name="month"
                value={month}
                onChange={this.handleChange}
              >
                {this.MONTHS.map((value, key) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Année"
                name="year"
                value={year}
                onChange={this.handleChange}
              >
                {this.YEARS.map((value, key) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

export default CalculDePointe;
