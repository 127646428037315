import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

// core components
import Render from "Utils/RenderUtils";
import InfoPopover from "components/InfoPopover/InfoPopover";

const styles = () => ({});

class MensualiteView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { mensualites } = this.props;

    return (
      <>
        {mensualites.map((mensualite, key) => (
          <Typography key={key}>
            <strong>{mensualite.config.label} : </strong>
            {Render.euro(mensualite.montant)} au {mensualite.debit}
            {Boolean(mensualite.description) && (
              <InfoPopover
                id={"mensualite-popover-" + key}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Typography>Description : {mensualite.description}</Typography>
              </InfoPopover>
            )}
          </Typography>
        ))}
      </>
    );
  }
}

MensualiteView.propTypes = {
  mensualites: PropTypes.array.isRequired,
  classes: PropTypes.any,
};

export default withStyles(styles)(MensualiteView);
