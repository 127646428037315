import React, { Component } from "react";

import Grid from "@mui/material/Grid";
// @mui/material components
import TextField from "@mui/material/TextField";

// @mui/icons-material
import Build from "@mui/icons-material/Build";

import NumberInput from "components/CustomInput/NumberInput";
// core components
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

class ConfigStatutPersonnaliseDT extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // DATA FORM
      id: "",
      value: "",
      ordre: "",
      // END DATA FORM
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { errors } = this.state;

    return (
      <TablePopUpContainer
        icon={<Build />}
        dialogProps={{
          fullWidth: true,
        }}
        title="Statuts personnalisés"
        autoSize={false}
        backendUrl="/config-statut-personnalise-dt"
        colDef={[
          { field: "id", editable: false, hide: true },
          {
            headerName: "Libellé",
            field: "value",
          },
          {
            headerName: "Ordre",
            field: "ordre",
          },
        ]}
        formInitData={() => {
          this.setState({
            id: "",
            value: "",
            ordre: "",
            errors: null,
          });
        }}
        formSetData={(data) => {
          this.setState({
            errors: null,
            id: data.id,
            value: data.value,
            ordre: data.ordre,
          });
        }}
        formGetData={() => ({
          id: this.state.id,
          value: this.state.value,
          ordre: this.state.ordre,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        getDeleteContent={(data) => data.value}
        getDeleteError={(data, error) =>
          (data.value ?? "") +
          " est associé à " +
          (error ?? "") +
          " dossiers travaux."
        }
        formTitle={
          this.state.id
            ? "Modifier le statut personnalisé"
            : "Ajouter un statut personnalisé"
        }
        formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                label="Statut"
                name="value"
                fullWidth
                margin="normal"
                value={this.state.value}
                onChange={this.handleChange}
                error={Boolean(errors?.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberInput
                label="Ordre"
                name="ordre"
                value={this.state.ordre}
                onChange={this.handleChange}
                error={Boolean(errors?.ordre)}
              />
            </Grid>
          </Grid>
        }
      />
    );
  }
}

export default ConfigStatutPersonnaliseDT;
