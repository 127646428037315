import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import MuiButton from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Accessibility from "@mui/icons-material/Accessibility";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import FamilyRestroom from "@mui/icons-material/FamilyRestroom";
import Info from "@mui/icons-material/Info";
import LaptopChromebook from "@mui/icons-material/LaptopChromebook";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import AddCard from "components/CustomCard/AddCard";
import ErrorDisplay from "components/CustomCard/ErrorDisplay";
import PersonneEditCard from "components/CustomCard/PersonneEditCard";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import ListTitle from "components/ListTitle/ListTitle";
import PhoneInput from "components/PhoneInput/PhoneInput";
import LightTooltip from "components/Tooltip/LightTooltip";

import Validation from "Utils/ValidationUtils";

import AccompagnementFoyerForm from "./AccompagnementFoyer/AccompagnementFoyerForm";
import InfoFiscaleForm from "./InfoFiscale/InfoFiscaleForm";
import CreateLogementForm from "./Logement/CreateLogementForm";
import ReferentsSociauxForm from "./ReferentsSociaux/ReferentsSociauxForm";

class FoyersForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/foyers";
    this.FRONT_URL = "/tiers/foyers";

    this.state = {
      // NESTED DATA
      personnesactives: [
        this.getDefaultPersonne(+this.QUERY_PARAMS.get("personne_id") || "", 1),
      ],
      ressources_principales: [],
      partenaires: [],
      accompagnements: [],
      referents_sociaux: [],
      infos_fiscales: [],
      // DATA FORM
      ncaf: "",
      situation_handicap: "",
      situation_familiale_id: "",
      orienteur_id: "",
      orienteur_nom: "",
      orienteur_email: "",
      orienteur_tel: "",
      orienteur_mesures_accompagnement: "",
      situation_menage_entree_id: "",
      situation_menage_sortie_id: "",
      dalo_demande: "",
      dalo_decision: "",
      dalo_date_depot: "",
      date_depot_rg: "",
      date_depot_ta: "",
      dalo_date_decision: "",
      dls_demande: 0,
      dls_numero: "",
      dls_date_depot: "",
      dls_date_renouvellement: "",
      dls_secteur: "",
      action_logement: "",
      fsl_date: "",
      fsl_avance: "",
      fsl_accorde: "",
      fsl_demande: 0,
      fsl_decision: "",
      fsl_maintien: "",
      fsl_garantie_bailleur: "",
      fluide: "",
      cheque_energie: "",
      is_asso: "",
      montant_total_revenus_decembre: "",
      revenus: "",
      colocation: "",
      infos: "",
      msa: "",
      hors_gestion_immobiliere: 0,
      logement_hors_gestion_id: "",
      logement_apres_id: "",
      logement_hors_gestion: "",
      logement_apres: "",
      // END DATA FORM
      loading: false,
      errors: null,
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value ?? "";
        });
        this.setState(state);
      });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeEvent = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };

  handleChangeCheckbox = (event) => {
    this.handleChange(event.target.name, event.target.checked);
  };

  getDefaultReferentSocial = () => {
    return {
      id: "",
      pivot: { is_principal: 0 },
    };
  };
  handleAddReferentSocial = () => {
    this.setState((prevState) => {
      let referentsSociaux = prevState.referents_sociaux;
      referentsSociaux.push(this.getDefaultReferentSocial());
      return { referents_sociaux: referentsSociaux };
    });
  };
  handleChangeReferentSocial = (key, name, value) => {
    this.setState((prevState) => {
      let referentsSociaux = [...prevState.referents_sociaux];

      const nameParts = name.split(".");
      if (nameParts.length > 1) {
        if (name === "pivot.is_principal" && value) {
          // Radio behavior for Référent principal checkbox
          referentsSociaux = referentsSociaux.map((referentSocial, index) => {
            if (index === key) {
              return { ...referentSocial, pivot: { is_principal: true } };
            } else {
              return { ...referentSocial, pivot: { is_principal: false } };
            }
          });
        } else {
          // Handle other pivot data if needed
          referentsSociaux[key][nameParts[0]][nameParts[1]] = value;
        }
      } else {
        referentsSociaux[key][name] = value;
      }

      return { referents_sociaux: referentsSociaux };
    });
  };
  handleRemoveReferentSocial = (key) => {
    this.setState((prevState) => {
      let referentsSociaux = prevState.referents_sociaux;
      referentsSociaux.splice(key, 1);
      return { referents_sociaux: referentsSociaux };
    });
  };

  getDefaultAccompagnement = () => {
    return {
      id: "",
      suivi_id: "",
      date_debut: "",
      date_fin: "",
      accompagnement_id: "",
    };
  };

  getDefaultInfoFiscale = () => {
    return {
      id: "",
      annee: "",
      revenu_fiscal: "",
      revenu_brut: "",
      nb_parts: "",
    };
  };

  handleAddCollection = (field, getDefault) => {
    return () => {
      this.setState((prevState) => {
        let items = prevState[field];
        items.push(getDefault());
        return { [field]: items };
      });
    };
  };

  handleChangeCollection = (field) => {
    return (key, name, value) => {
      this.setState((prevState) => {
        let items = prevState[field];
        items[key][name] = value;
        return { [field]: items };
      });
    };
  };

  handleDeleteCollection = (field) => {
    return (key) => {
      this.setState((prevState) => {
        let items = prevState[field];
        items.splice(key, 1);
        return { [field]: items };
      });
    };
  };

  getDefaultPersonne = (id = "", referent = "") => {
    return {
      id: id ?? "",
      is_asso: "",
      lien: {
        statut: "",
        referent: referent ?? "",
      },
    };
  };

  handleAddPersonne = () => {
    this.setState((prevState) => {
      let newData = prevState.personnesactives;
      newData.push(this.getDefaultPersonne());
      return { personnesactives: newData };
    });
  };

  handleChangePersonne = (key, name, value, subname) => {
    // let newData = this.state.liens;
    // newData[dataKey][name] = value;
    this.setState((prevState) => {
      let newDatas = prevState.personnesactives;
      let newData = newDatas[key];
      if (subname) {
        newData = newData[subname];
      }
      newData[name] = value;
      return { personnesactives: newDatas };
    });
  };

  alertDeletePersonne = (key, personne) => {
    const name =
      personne && (personne.prenom || personne.nom)
        ? (personne.prenom ?? "") + " " + (personne.nom ?? "")
        : "cette personne";
    const handleDelete = () => {
      this.clearAlert();
      this.handleRemovePersonne(key);
    };

    this.setState({
      alert: (
        <AlertDialog
          title={"Voulez-vous supprimer " + name + " ?"}
          onConfirm={handleDelete}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  handleRemovePersonne = (key) => {
    this.setState((prevState) => {
      let newData = prevState.personnesactives;
      newData.splice(key, 1);
      return { personnesactives: newData };
    });
  };

  addPartenaire = () => {
    let newData = [...this.state.partenaires];

    newData.push({
      id: "",
      foyer_id: "",
      nom: "",
      organisme: "",
      tel: "",
      email: "",
      mesures_accompagnement: "",
    });
    this.setState({
      partenaires: newData,
    });
  };

  handleChangeName = (name, value, key) => {
    let newData = this.state.partenaires;
    newData[key][name] = value;

    this.setState({ partenaires: newData });
  };

  handleChangeArray = (key, event) => {
    let newData = this.state.partenaires;
    newData[key][event.target.name] = event.target.value;

    this.setState({ partenaires: newData });
  };

  handleLogementHorsGestionChange = (logementId) => {
    this.setState({
      logement_hors_gestion_id: logementId,
    });
  };

  handleLogementSortieChange = (logementId) => {
    this.setState({
      logement_apres_id: logementId,
    });
  };

  onClickDelete = (event, key) => {
    this.setState({
      alert: (
        <AlertDialog
          title={"Voulez-vous supprimer le partenaire n° " + (key + 1) + " ?"}
          onConfirm={() => this.handleDelete(key)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  handleDelete = (key) => {
    this.clearAlert();
    let newData = this.state.partenaires;
    newData.splice(key, 1);
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      ncaf: this.state.ncaf,
      is_asso: this.state.is_asso,
      montant_total_revenus_decembre: this.state.montant_total_revenus_decembre,
      ressources_principales: this.state.ressources_principales.map(
        (rp) => rp.id,
      ),
      referents_sociaux: this.state.referents_sociaux,
      personnes: this.state.personnesactives,
      situation_handicap: this.state.situation_handicap,
      dalo_demande: this.state.dalo_demande,
      dalo_decision: this.state.dalo_demande ? this.state.dalo_decision : "",
      dalo_date_depot: this.state.dalo_demande
        ? this.state.dalo_date_depot
        : "",
      date_depot_rg: this.state.dalo_demande ? this.state.date_depot_rg : "",
      date_depot_ta: this.state.dalo_demande ? this.state.date_depot_ta : "",
      dalo_date_decision: this.state.dalo_demande
        ? this.state.dalo_date_decision
        : "",
      dls_demande: this.state.dls_demande,
      dls_numero: this.state.dls_demande ? this.state.dls_numero : "",
      dls_secteur: this.state.dls_demande ? this.state.dls_secteur : "",
      dls_date_depot: this.state.dls_demande ? this.state.dls_date_depot : "",
      dls_date_renouvellement: this.state.dls_demande
        ? this.state.dls_date_renouvellement
        : "",
      action_logement: this.state.dls_demande
        ? this.state.action_logement
        : false,
      fsl_demande: this.state.fsl_demande,
      fsl_avance: this.state.fsl_demande ? this.state.fsl_avance : "",
      fsl_accorde: this.state.fsl_demande ? this.state.fsl_accorde : "",
      fsl_decision: this.state.fsl_demande ? this.state.fsl_decision : "",
      fsl_date: this.state.fsl_demande ? this.state.fsl_date : "",
      fsl_maintien: this.state.fsl_demande ? this.state.fsl_maintien : false,
      fsl_garantie_bailleur: this.state.fsl_demande
        ? this.state.fsl_garantie_bailleur
        : false,
      fluide: this.state.fsl_demande ? this.state.fluide : false,
      cheque_energie: this.state.cheque_energie,
      situation_familiale_id: this.state.situation_familiale_id,
      orienteur_id: this.state.orienteur_id,
      orienteur_nom: this.state.orienteur_nom,
      orienteur_email: this.state.orienteur_email,
      orienteur_tel: this.state.orienteur_tel,
      orienteur_mesures_accompagnement:
        this.state.orienteur_mesures_accompagnement,
      situation_menage_entree_id: this.state.situation_menage_entree_id,
      situation_menage_sortie_id: this.state.situation_menage_sortie_id,
      revenus: this.state.revenus,
      colocation: this.state.colocation,
      partenaires: this.state.partenaires,
      infos: this.state.infos,
      msa: this.state.msa,
      accompagnements: this.state.accompagnements,
      infos_fiscales: this.state.infos_fiscales,
      hors_gestion_immobiliere: this.state.hors_gestion_immobiliere,
      logement_hors_gestion_id: this.state.hors_gestion_immobiliere
        ? this.state.logement_hors_gestion_id
        : null,
      logement_apres_id: this.state.situation_menage_sortie_id
        ? this.state.logement_apres_id
        : null,
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const {
      personnesactives,
      ressources_principales,
      partenaires,
      accompagnements,
      referents_sociaux,
      infos_fiscales,
      // DATA FORM
      ncaf,
      situation_handicap,
      situation_familiale_id,
      orienteur_id,
      orienteur_nom,
      orienteur_email,
      orienteur_tel,
      orienteur_mesures_accompagnement,
      situation_menage_entree_id,
      situation_menage_sortie_id,
      dalo_demande,
      dalo_decision,
      dalo_date_depot,
      date_depot_rg,
      date_depot_ta,
      dalo_date_decision,
      dls_demande,
      dls_numero,
      dls_date_depot,
      dls_date_renouvellement,
      dls_secteur,
      action_logement,
      fsl_date,
      fsl_avance,
      fsl_accorde,
      fsl_demande,
      fsl_decision,
      fsl_maintien,
      fsl_garantie_bailleur,
      fluide,
      cheque_energie,
      is_asso,
      montant_total_revenus_decembre,
      revenus,
      colocation,
      infos,
      msa,
      hors_gestion_immobiliere,
      logement_hors_gestion_id,
      logement_apres_id,
      logement_hors_gestion,
      logement_apres,
      loading,
      errors,
      id,
      alert,
    } = this.state;

    const url_logement = id
      ? `/logements?hors_gestion_immobiliere=1&edit_foyer_id=${id}`
      : `/logements?hors_gestion_immobiliere=1`;

    return (
      <Grid container columnSpacing={2}>
        <ErrorDisplay errors={errors}>
          {!personnesactives.length > 0 && (
            <Typography variant="h6" component="h4" color="error">
              Veuillez rattacher au moins une personne au foyer.
            </Typography>
          )}
        </ErrorDisplay>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <FamilyRestroom />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Ménage
                </Typography>
              }
            />
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <SelectSearch
                    name="situation_familiale_id"
                    label="Situation familiale"
                    apiUrl="/config-situations-familiale"
                    value={situation_familiale_id}
                    onChange={this.handleChange}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.situation_familiale_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Ressources" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Revenus actuels"
                    name="revenus"
                    value={revenus}
                    onChange={this.handleChangeEvent}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.revenus)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant total des revenus hors APL (décembre N-1)"
                    name="montant_total_revenus_decembre"
                    value={montant_total_revenus_decembre}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.montant_total_revenus_decembre)}
                    onChange={this.handleChangeEvent}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectSearchMultiple
                    apiUrl="/config-ressources-principales"
                    getOptionLabel={(data) => data.value}
                    onChange={(event, newValue) => {
                      this.setState({ ressources_principales: newValue });
                    }}
                    value={ressources_principales}
                    label="Nature des ressources principales"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoFiscaleForm
                    infosFiscales={infos_fiscales}
                    addRow={this.handleAddCollection(
                      "infos_fiscales",
                      this.getDefaultInfoFiscale,
                    )}
                    updateRow={this.handleChangeCollection("infos_fiscales")}
                    removeRow={this.handleDeleteCollection("infos_fiscales")}
                    errors={Validation.parse(errors, "infos_fiscales.")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(situation_handicap)}
                        onChange={this.handleChangeCheckbox}
                        name="situation_handicap"
                      />
                    }
                    label="Reconnaissance handicap"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(is_asso)}
                        onChange={this.handleChangeCheckbox}
                        name="is_asso"
                      />
                    }
                    label="Ce foyer est une association ou une entreprise"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(colocation)}
                        onChange={this.handleChangeCheckbox}
                        name="colocation"
                      />
                    }
                    label="Colocation"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hors_gestion_immobiliere"
                        checked={Boolean(hors_gestion_immobiliere)}
                        onChange={this.handleChangeCheckbox}
                      />
                    }
                    label="Foyer hors gestion immobilière"
                  />
                </Grid>
                {Boolean(hors_gestion_immobiliere) && (
                  <>
                    <Grid item xs={12}>
                      <HeaderTitle label="Logement Hors gestion immobilière" />
                    </Grid>
                    <CreateLogementForm
                      label="Choisir un logement hors gestion"
                      apiUrl={url_logement}
                      logement_id={logement_hors_gestion_id}
                      logement={logement_hors_gestion}
                      onLogementChange={this.handleLogementHorsGestionChange}
                    />
                  </>
                )}
                <Grid item xs={12}>
                  <HeaderTitle label="Informations complémentaires" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    minRows={2}
                    onChange={this.handleChangeEvent}
                    value={infos}
                    error={Boolean(errors?.infos)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Situation à l'entrée" />
                </Grid>
                <Grid item xs={12}>
                  <SelectSearch
                    name="situation_menage_entree_id"
                    label="Situation du ménage avant l’entrée en logement"
                    apiUrl="/config-situation-menage-entree"
                    value={situation_menage_entree_id}
                    onChange={this.handleChange}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.situation_menage_entree_id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Situation à la sortie" />
                </Grid>
                <Grid item xs={12}>
                  <SelectSearch
                    name="situation_menage_sortie_id"
                    label="Situation du ménage à la sortie"
                    apiUrl="/config-situation-menage-sortie"
                    value={situation_menage_sortie_id}
                    onChange={this.handleChange}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.situation_menage_sortie_id)}
                  />
                </Grid>
                {Boolean(situation_menage_sortie_id) && (
                  <>
                    <CreateLogementForm
                      label="Choisir un logement hors gestion"
                      apiUrl={url_logement}
                      logement_id={logement_apres_id}
                      logement={logement_apres}
                      onLogementChange={this.handleLogementSortieChange}
                    />
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Accessibility />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Accompagnements Sociaux
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <ReferentsSociauxForm
                    referentsSociaux={referents_sociaux}
                    addRow={this.handleAddReferentSocial}
                    updateRow={this.handleChangeReferentSocial}
                    removeRow={this.handleRemoveReferentSocial}
                    errors={Validation.parse(errors, "referents_sociaux.")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <HeaderTitle label="Accompagnements" />
              </Grid>
              <AccompagnementFoyerForm
                accompagnements={accompagnements}
                addRow={this.handleAddCollection(
                  "accompagnements",
                  this.getDefaultAccompagnement,
                )}
                updateRow={this.handleChangeCollection("accompagnements")}
                removeRow={this.handleDeleteCollection("accompagnements")}
                errors={Validation.parse(errors, "accompagnements.")}
              />
              {/* Orienteur */}
              <Grid item xs={12}>
                <HeaderTitle label="Orienteur" />
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="orienteur_id"
                    label="Orienteur"
                    apiUrl="/config-orienteur"
                    value={orienteur_id}
                    onChange={this.handleChange}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.orienteur_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="orienteur_nom"
                    value={orienteur_nom}
                    onChange={this.handleChangeEvent}
                    error={Boolean(errors?.orienteur_nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Mesures accompagnement"
                    name="orienteur_mesures_accompagnement"
                    value={orienteur_mesures_accompagnement}
                    onChange={this.handleChangeEvent}
                    error={Boolean(errors?.orienteur_mesures_accompagnement)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="orienteur_tel"
                    value={orienteur_tel}
                    onChange={this.handleChange}
                    error={Boolean(errors?.orienteur_tel)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email"
                    name="orienteur_email"
                    type="email"
                    value={orienteur_email}
                    onChange={this.handleChangeEvent}
                    error={Boolean(errors?.orienteur_email)}
                  />
                </Grid>
              </Grid>
              {/* Partenaire */}
              <Grid item xs={12}>
                <HeaderTitle label="Partenaires" />
              </Grid>

              <Grid container columnSpacing={2}>
                {partenaires.map((partenaire, key) => (
                  <Grid
                    container
                    columnSpacing={2}
                    alignItems="center"
                    key={key}
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <ListTitle>Partenaire n°{key + 1} :</ListTitle>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title="Supprimer le partenaire"
                          placement="left"
                        >
                          <MuiButton
                            color="error"
                            onClick={(event) => this.onClickDelete(event, key)}
                          >
                            <Delete />
                          </MuiButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Organisme"
                        name="organisme"
                        value={partenaire?.organisme ?? ""}
                        onChange={(event) => this.handleChangeArray(key, event)}
                        error={Boolean(
                          errors?.["partenaires." + key + ".organisme"],
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Nom"
                        name="nom"
                        value={partenaire?.nom ?? ""}
                        onChange={(event) => this.handleChangeArray(key, event)}
                        error={Boolean(errors?.["partenaires." + key + ".nom"])}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <PhoneInput
                        label="Téléphone"
                        name="tel"
                        value={partenaire?.tel}
                        onChange={(name, value) =>
                          this.handleChangeName(name, value, key)
                        }
                        error={Boolean(errors?.["partenaires." + key + ".tel"])}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Email"
                        name="email"
                        value={partenaire?.email ?? ""}
                        onChange={(event) => this.handleChangeArray(key, event)}
                        error={Boolean(
                          errors?.["partenaires." + key + ".email"],
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Mesures accompagnement"
                        name="mesures_accompagnement"
                        value={partenaire?.mesures_accompagnement ?? ""}
                        onChange={(event) => this.handleChangeArray(key, event)}
                        error={Boolean(
                          errors?.[
                            "partenaires." + key + ".mesures_accompagnement"
                          ],
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}

                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ paddingBottom: 1 }}
                >
                  <Grid item>
                    <Tooltip title="Ajouter un partenaire" placement="left">
                      <MuiButton color="primary" onClick={this.addPartenaire}>
                        <Add />
                      </MuiButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LaptopChromebook />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Démarches Administratives/Sociales
                </Typography>
              }
            />
            <CardContent>
              <Grid item xs={12}>
                <HeaderTitle label="CAF - Caisse d'allocations familiales" />
              </Grid>

              <TextInput
                label={
                  <LightTooltip title="S'il s'agit d'une colocation, vous pouvez ajouter plusieurs numéros en les séparant par une virgule (sans espace). Par exemple : 1234567,1234568.">
                    <span>
                      Numéro <Info fontSize="small" />
                    </span>
                  </LightTooltip>
                }
                name="ncaf"
                onChange={this.handleChangeEvent}
                value={ncaf}
                error={Boolean(errors?.ncaf)}
              />
              <Grid item xs={12}>
                <HeaderTitle label="MSA - Mutualité sociale agricole" />
              </Grid>
              <TextInput
                label="Numéro"
                name="msa"
                onChange={this.handleChangeEvent}
                value={msa}
                error={Boolean(errors?.msa)}
                inputProps={{
                  maxLength: 30,
                }}
              />
              <Grid item xs={12}>
                <HeaderTitle label="DALO - Droit au logement opposable" />
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(dalo_demande)}
                        onChange={this.handleChangeCheckbox}
                        name="dalo_demande"
                      />
                    }
                    label="Demande"
                  />
                </Grid>
                {dalo_demande ? (
                  <>
                    <Grid item xs={12}>
                      <TextInput
                        label="Date de dépôt"
                        name="dalo_date_depot"
                        type="date"
                        value={dalo_date_depot}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.dalo_date_depot)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date décision"
                        name="dalo_date_decision"
                        type="date"
                        value={dalo_date_decision}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.dalo_date_decision)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label="Décision"
                        name="dalo_decision"
                        value={dalo_decision}
                        onChange={this.handleChangeEvent}
                        shrink
                        error={Boolean(errors?.dalo_decision)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        <MenuItem value="Non-prioritaire">
                          Non-prioritaire
                        </MenuItem>
                        <MenuItem value="Prioritaire">Prioritaire</MenuItem>
                      </SelectInput>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date dépôt du Recours Gracieux"
                        name="date_depot_rg"
                        type="date"
                        value={date_depot_rg}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.date_depot_rg)}
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date dépôt du Tribunal Administratif"
                        name="date_depot_ta"
                        type="date"
                        value={date_depot_ta}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.date_depot_ta)}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <HeaderTitle label="DLS - Demande de logement Social" />
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(dls_demande)}
                        onChange={this.handleChangeCheckbox}
                        name="dls_demande"
                      />
                    }
                    label="Demande"
                  />
                </Grid>
                {dls_demande ? (
                  <>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Numéro"
                        name="dls_numero"
                        onChange={this.handleChangeEvent}
                        value={dls_numero}
                        error={Boolean(errors?.dls_numero)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Secteur"
                        name="dls_secteur"
                        value={dls_secteur}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.dls_secteur)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date dépôt"
                        name="dls_date_depot"
                        type="date"
                        value={dls_date_depot}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.dls_date_depot)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date de renouvellement"
                        name="dls_date_renouvellement"
                        type="date"
                        value={dls_date_renouvellement}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.dls_date_renouvellement)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(action_logement)}
                            onChange={this.handleChangeCheckbox}
                            name="action_logement"
                          />
                        }
                        label="Action logement"
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <HeaderTitle label="FSL - Fonds de solidarité logement" />
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(fsl_demande)}
                        onChange={this.handleChangeCheckbox}
                        name="fsl_demande"
                      />
                    }
                    label="Demande"
                  />
                </Grid>
                {fsl_demande ? (
                  <>
                    <Grid item xs={12} lg={6}>
                      <CurrencyInput
                        label="Montant avancé"
                        name="fsl_avance"
                        value={fsl_avance}
                        error={Boolean(errors?.fsl_avance)}
                        onChange={this.handleChangeEvent}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label="Décision FSL"
                        name="fsl_decision"
                        value={fsl_decision}
                        onChange={this.handleChangeEvent}
                        shrink
                        error={Boolean(errors?.fsl_decision)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        <MenuItem value={0}>Refusé</MenuItem>
                        <MenuItem value={1}>Accepté</MenuItem>
                      </SelectInput>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date"
                        name="fsl_date"
                        type="date"
                        value={fsl_date}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.fsl_date)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CurrencyInput
                        label="Montant accordé"
                        name="fsl_accorde"
                        value={fsl_accorde}
                        error={Boolean(errors?.fsl_accorde)}
                        onChange={this.handleChangeEvent}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(fsl_maintien)}
                            onChange={this.handleChangeCheckbox}
                            name="fsl_maintien"
                          />
                        }
                        label="Maintien FSL"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(fsl_garantie_bailleur)}
                            onChange={this.handleChangeCheckbox}
                            name="fsl_garantie_bailleur"
                          />
                        }
                        label="Garantie bailleur FSL"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(fluide)}
                            onChange={this.handleChangeCheckbox}
                            name="fluide"
                          />
                        }
                        label="Fluide"
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <HeaderTitle label="Divers" />
                  </Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(cheque_energie)}
                        onChange={this.handleChangeCheckbox}
                        name="cheque_energie"
                      />
                    }
                    label="Chèque énergie"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="stretch" spacing={1}>
            {personnesactives.map((personne, key) => (
              <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                <PersonneEditCard
                  arrKey={key}
                  personne={personne}
                  onChange={this.handleChangePersonne}
                  onDelete={this.alertDeletePersonne}
                  errors={Validation.parse(errors, "personnes." + key + ".")}
                />
              </Grid>
            ))}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AddCard onClick={this.handleAddPersonne} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={loading}
            isEdit={Boolean(id)}
          />
        </Grid>
        {alert}
      </Grid>
    );
  }
}

FoyersForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};
export default FoyersForm;
