import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import Euro from "@mui/icons-material/Euro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Home from "@mui/icons-material/Home";
import HowToReg from "@mui/icons-material/HowToReg";
import List from "@mui/icons-material/List";
import Today from "@mui/icons-material/Today";
import WarningRounded from "@mui/icons-material/WarningRounded";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Evenements from "components/Evenements/Evenements.jsx";
import ImmobilierLinkCard from "components/LinkCard/ImmobilierLinkCard";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import TableHead from "components/Table/TableHead";
import TextIcon from "components/TextIcon/TextIcon";
import CustomTimeline from "components/Timeline/CustomTimeline";

import SearchBarService from "services/SearchBarService";

import CPUtils from "Utils/CPUtils";
import FoyerUtils from "Utils/FoyerUtils";
import Render from "Utils/RenderUtils";

import AdresseView from "./Adresse/AdresseView";
import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

import TimelineDot from "@mui/lab/TimelineDot";
import styled from "@mui/system/styled";

const BigDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StyledAccordionActions = styled(AccordionActions)({
  paddingTop: 0,
  paddingBottom: 0,
});

const StyledAccordionDetails = styled(AccordionDetails)({
  paddingTop: 0,
  paddingBottom: 0,
});

class ProprietairesView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.proprietaire");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/proprietaires";
    this.FRONT_URL = "/tiers/proprietaires";
    this.NOW = new Date();
    this.NO_YES = ["Non", "Oui"];
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);
    this.ADHESION_MENSUALITE = [
      "",
      "Tout quittancer le premier mois",
      "2 mois",
      "3 mois",
      "4 mois",
      "5 mois",
      "6 mois",
      "7 mois",
      "8 mois",
      "9 mois",
      "10 mois",
      "11 mois",
      "12 mois",
    ];

    // Ged Proprietaire permissions
    this.GED_PROPRIETAIRE_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.proprietaire"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.proprietaire"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.proprietaire"),
    };

    this.state = {
      // NESTED DATA
      ville: null,
      logements: [],
      estime_parc: null,
      type: null,
      adresse: null,
      adhesion_cp: null,
      // DATA FORM
      type_id: "",
      societe: "",
      nom: "",
      prenom: "",
      contact: "",
      contact_2: "",
      telephone: "",
      telephone_2: "",
      email: "",
      email_2: "",
      profession: "",
      profession_2: "",
      estime_parc_id: "",
      usufruitier: "",
      infos: "",
      date_naissance: "",
      lieu_naissance: "",
      nationalite: "",
      num_adherent: "",
      adhesion_auto_renew: "",
      adhesions: [],
      // END DATA FORM
      id: Number(props.match.params.id),
      alert: null,
      mostRecentLogement: null,
      contratProprietaire: null,
      contratLocataireActif: null,
      entityLists: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id + "?adhesion_year_desc=true")
      .then((result) => {
        let state = result.data ?? {};

        if (result.data?.logements.length) {
          const mostRecentLogement =
            result.data.logements[result.data.logements.length - 1];
          state.mostRecentLogement = mostRecentLogement;
          state.contratProprietaire = mostRecentLogement.contratproprietaire;
          state.contratLocataireActif = this.getContratLocataireActif(
            mostRecentLogement?.contratslocataires,
          );
          state.entityLists = {
            logement: result.data.logements,
          };
        }
        this.setState(state);
      });
  }

  getContratLocataireActif = (contratsLocataires) => {
    if (contratsLocataires === null) {
      return null;
    }

    let now = new Date();
    const nowDay = now.getDate().toString().padStart(2, "0");
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const today = now.getFullYear() + "-" + month + "-" + nowDay;

    let contratLocataireActif = null;
    for (let cl of contratsLocataires) {
      if (cl.date_sortie === null) {
        contratLocataireActif = cl;
        break;
      } else if (cl.date_sortie >= today) {
        contratLocataireActif = cl;
      }
    }
    return contratLocataireActif;
  };

  renderEtat = (actif, date_sortie) => {
    if (actif === 0) {
      return "Clôturé";
    }
    if (
      actif === 1 &&
      date_sortie !== null &&
      date_sortie !== "" &&
      new Date(date_sortie) < this.NOW
    ) {
      return "Parti";
    }
    return "Présent";
  };
  //Remove Propriétaire

  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le propriétaire ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer le propriétaire : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.logements > 0 && (
              <li>{nbrEntity.logements} logement(s) associée(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  //Calcul solde total CP

  soldeTotalCp = (logements) => {
    if (logements.length) {
      let solde = 0;
      logements.forEach((logement) => {
        const cp = logement.contratproprietaire;
        if (cp && cp.solde_du) {
          solde += cp.solde_du;
        }
      });
      return Render.euro(solde);
    }
  };

  statutCp = (logement) => {
    if (logement) {
      const cp = logement.contratproprietaire || {};
      if (
        cp.date_fin !== null &&
        cp.date_fin !== "" &&
        new Date(cp.date_fin) < this.NOW &&
        (cp.solde_du === 0 || cp.solde_du == null)
      ) {
        return "Contrat terminé";
      }
      return Render.euro(cp.solde_du);
    }
  };

  renderEvenements = () => {
    const { logements } = this.state;

    return logements.map((logement, key) => {
      const activeCL = logement.contratslocataires.find((cl) => cl.active);
      const clId = activeCL?.id ?? null;
      const logementId = logement.id;
      const evenementsLink = "/evenements/immobilier/logement/" + logementId;

      return (
        <Accordion key={key} disableGutters>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Logement {logementId}
              {clId ? " - CL " + clId : ""}
            </Typography>
          </AccordionSummary>
          <StyledAccordionActions>
            <Link to={evenementsLink + "?form=open"} style={{ float: "right" }}>
              <Button size="small">Ajouter</Button>
            </Link>
          </StyledAccordionActions>
          <StyledAccordionDetails>
            <Link to={evenementsLink}>
              <Evenements
                modelId={logementId}
                modelType="logement"
                dense
                limit={5}
              />
            </Link>
          </StyledAccordionDetails>
        </Accordion>
      );
    });
  };

  render() {
    const {
      id,
      type,
      adresse,
      estime_parc,
      mostRecentLogement,
      contratLocataireActif,
      contratProprietaire,
      entityLists,
      date_naissance,
      lieu_naissance,
      nationalite,
      adhesions,
    } = this.state;

    const evenementsLink = "/evenements/immobilier/proprietaire/" + id;

    const logements = this.state.logements.map((logement, i) => (
      <TableRow key={i} alignitems="flex-start">
        <TableCell>
          <Link to={"/tiers/logements/detail/" + logement.id}>
            {Render.fullAddress(logement?.adresse)} (LP {logement.id})
          </Link>
        </TableCell>
        <TableCell>
          {logement.contratslocataires.length ? (
            logement.contratslocataires.map((cl, y) => (
              <div key={y}>
                -{" "}
                <Link to={"/contrats-locataires/detail/" + cl.id}>
                  {FoyerUtils.referentsFullName(
                    cl.foyer && cl.foyer.referentsactifs,
                  )}{" "}
                  : {this.renderEtat(cl.actif, cl.date_sortie)} (CL {cl.id})
                </Link>
              </div>
            ))
          ) : (
            <strong>Jamais loué</strong>
          )}
        </TableCell>
        <TableCell>
          {logement.contratproprietaire ? (
            <Link
              to={
                "/contrats-proprietaires/detail/" +
                logement.contratproprietaire.id
              }
            >
              {CPUtils.renderCP(logement.contratproprietaire)}
            </Link>
          ) : (
            <strong>Pas de contrat</strong>
          )}
        </TableCell>
        <TableCell align="right">{this.statutCp(logement)}</TableCell>
      </TableRow>
    ));

    const missingAdhesionCp =
      adhesions.length != 0 && this.state.adhesion_cp == null;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <HowToReg />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Propriétaire ( {this.state.id} )
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Type : </strong>
                {type?.value}
              </Typography>
              <Typography>
                <strong>Société : </strong>
                {this.NO_YES[this.state.societe]}
              </Typography>
              <Typography>
                <strong>Prénom : </strong>
                {this.state.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {this.state.nom}
              </Typography>
              <Typography>
                <strong>Date de naissance : </strong>
                {Render.date(date_naissance)}
              </Typography>
              <Typography>
                <strong>Lieu de naissance : </strong>
                {lieu_naissance}
              </Typography>
              <Typography>
                <strong>Nationalité : </strong>
                {nationalite}
              </Typography>
              <BigDivider variant="middle" />
              <AdresseView adresse={adresse} hideRegion />
              <BigDivider variant="middle" />
              <Typography>
                <strong>Contact Info : </strong>
                {this.state.contact}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(this.state.telephone)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(this.state.email)}
              </Typography>
              <Typography>
                <strong>Profession : </strong>
                {this.state.profession}
              </Typography>
              <BigDivider variant="middle" />
              <Typography>
                <strong>Contact Info 2 : </strong>
                {this.state.contact_2}
              </Typography>
              <Typography>
                <strong>Téléphone 2 : </strong>
                {Render.telephoneAsLink(this.state.telephone_2)}
              </Typography>
              <Typography>
                <strong>Email 2 : </strong>
                {Render.email(this.state.email_2)}
              </Typography>
              <Typography>
                <strong>Profession 2 : </strong>
                {this.state.profession_2}
              </Typography>
              <BigDivider variant="middle" />
              <Typography>
                <strong>Parc de logement éstimé : </strong>
                {estime_parc?.value}
              </Typography>
              <Typography>
                <strong>Usufruitier : </strong>
                {this.NO_YES[this.state.usufruitier]}
              </Typography>
              <Typography>
                <strong>Informations complémentaires : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {this.state.infos}
              </MultilineTypography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Today />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Évènements du propriétaire
                </Typography>
              }
              action={
                <Link to={evenementsLink + "?form=open"}>
                  <Button size="small">Ajouter</Button>
                </Link>
              }
            />
            <CardContent>
              <Link to={evenementsLink}>
                <Evenements
                  modelId={id}
                  modelType="proprietaire"
                  dense
                  limit={5}
                />
              </Link>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Adhésion
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Numéro d&apos;adhérent : </strong>
                {this.state.num_adherent}
              </Typography>
              <Typography>
                <strong>Contrat Propriétaire à débiter : </strong>
                <Link
                  to={
                    "/contrats-proprietaires/detail/" +
                    this.state.adhesion_cp?.id
                  }
                >
                  {CPUtils.renderCP(this.state.adhesion_cp)}
                </Link>
                {this.state.adhesion_cp?.date_fin && (
                  <Typography color="warning.main" component="span">
                    {" "}
                    <TextIcon Icon={WarningRounded} /> Attention, ce contrat
                    n&apos;est plus actif à partir du{" "}
                    {Render.date(this.state.adhesion_cp?.date_fin)}.
                    L&apos;adhésion ne sera plus débitée après cette date.
                  </Typography>
                )}
                {missingAdhesionCp && (
                  <Typography color="warning.main" component="span">
                    {" "}
                    <TextIcon Icon={WarningRounded} /> Choisissez le contrat à
                    partir duquel débiter l&apos;adhésion !
                  </Typography>
                )}
              </Typography>
              {adhesions.length > 0 && (
                <CheckBoxView
                  label="Renouvellement automatique"
                  value={this.state.adhesion_auto_renew}
                />
              )}

              {adhesions.length > 0 && (
                <CustomTimeline
                  sx={{ mt: 1 }}
                  items={adhesions}
                  visibleItems={3}
                  renderDot={(item) => {
                    const isActive = item?.active ?? false;
                    return (
                      <>
                        <TimelineDot
                          color={isActive ? "success" : "primary"}
                          sx={{
                            mb: 0,
                          }}
                        >
                          <Euro />
                        </TimelineDot>
                        <Typography variant="body2" color="textPrimary">
                          {item.year}
                        </Typography>
                      </>
                    );
                  }}
                  renderBody={(item) => {
                    const remaingPayment = item.adhesion_annuelle - item.paid;
                    return (
                      <>
                        <Typography>
                          {"Montant payé pour l'année " + item.year}
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                          Mensualités max:{" "}
                          {
                            this.ADHESION_MENSUALITE[
                              item.adhesion_mensualite_max
                            ]
                          }
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                          {item.paid} € sur {item.adhesion_annuelle} €
                          {remaingPayment > 0 &&
                            " - reste à payer " + remaingPayment + " €"}
                        </Typography>
                      </>
                    );
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ImmobilierLinkCard
            logement={mostRecentLogement}
            contratProprietaire={contratProprietaire}
            contratLocataire={contratLocataireActif}
            proprietaire={{ id: id }}
            hideProprietaire={true}
            entityLists={entityLists}
          />
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.logements.length > 1 ? (
                    <>{this.state.logements.length} Logements</>
                  ) : (
                    <>Logement</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <br />
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Adresse</TableCell>
                      <TableCell>Contrat(s) Locataire(s)</TableCell>
                      <TableCell>Contrat Propriétaire</TableCell>
                      <TableCell align="right">Solde</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logements}
                    {this.state.logements.length > 0 && (
                      <TableRow>
                        <TableCell size="medium" align="right" colSpan={3}>
                          <strong>Solde total</strong>
                        </TableCell>
                        <TableCell align="right">
                          {this.soldeTotalCp(this.state.logements)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Link
                to={"/tiers/logements/ajouter?proprietaire_id=" + this.state.id}
              >
                <Button size="small">
                  <Add /> Ajouter un logement
                </Button>
              </Link>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Today />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Évènements par logements (CL Actifs)
                </Typography>
              }
            />
            <CardContent>{this.renderEvenements()}</CardContent>
          </Card>
          {this.GED_PROPRIETAIRE_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="proprietaire"
              objectId={id}
              permissions={this.GED_PROPRIETAIRE_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL + "/logs?type=Proprietaire&id=" + this.state.id
              }
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

ProprietairesView.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ProprietairesView);
