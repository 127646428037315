import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import SensorOccupied from "@mui/icons-material/SensorOccupied";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Visibility from "@mui/icons-material/Visibility";
import GetApp from "@mui/icons-material/GetApp";

// core components
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple";
import TextInput from "components/CustomInput/TextInput";
import AlertDialog from "components/AlertDialog/AlertDialog";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";
import AgGridUtils from "Utils/AgGridUtils";

// context
import { withUserContext } from "context/UserContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class RolesManager extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.BACKEND_URL = "/roles";
    this.FRONT_URL = "/configuration/roles";

    this.gridApi = null;

    this.state = {
      deleted_at: null,
      // DATA FORM
      id: "",
      name: "",
      description: "",
      slug: "",
      permissions: [],
      created_at: "",
      updated_at: "",
      editable: "",
      // END DATA FORM
      setErrs: null,
      errors: null,
      response: null,
      alert: null,
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeEvent = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };

  permissionsAlert = (permissions) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Liste des permissions"
          onConfirm={this.clearAlert}
          confirmLabel="Fermer"
          confirmColor="primary"
          fullWidth
        >
          <ul>
            {permissions.map((permission) => (
              <li key={permission.id}>
                {permission.name} ({permission.slug})
              </li>
            ))}
          </ul>
        </AlertDialog>
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { errors } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <TablePopUpContainer
            onGridReady={this.onGridReady}
            dialogProps={{
              maxWidth: "lg",
              fullWidth: true,
            }}
            icon={<SensorOccupied />}
            title="Gestion des rôles"
            backendUrl={this.BACKEND_URL}
            frontUrl={this.FRONT_URL}
            etatFilter="activated"
            searchBar={true}
            filter={true}
            filterColumns={true}
            hideButton={(data) => !data?.editable}
            labelFilter={[
              { label: "Actif", value: "activated" },
              { label: "Supprimé", value: "deleted" },
              { label: "Tous", value: "all" },
            ]}
            colDef={[
              {
                headerName: "Role",
                field: "name",
                cellDataType: "text",
              },
              {
                headerName: "Slug",
                field: "slug",
                cellDataType: "text",
              },
              {
                headerName: "Description",
                field: "description",
                cellDataType: "text",
              },
              {
                headerName: "Permissions",
                field: "permissions",
                cellDataType: "text",
                autoHeight: true,
                cellRenderer: ButtonRenderer,
                valueGetter: (params) =>
                  params.data.permissions.map((a) => a.slug),
                cellRendererParams: (params) => ({
                  buttons: [
                    {
                      label: <Visibility />,
                      color: "primary",
                      square: true,
                      onClick: () =>
                        this.permissionsAlert(params.data.permissions),
                    },
                  ],
                }),
              },
            ]}
            formInitData={() => {
              this.setState({
                errors: null,
                id: "",
                name: "",
                slug: "",
                description: "",
                permissions: [],
                created_at: "",
                updated_at: "",
                deleted_at: null,
              });
            }}
            formSetData={(data) => {
              this.setState({
                errors: null,
                id: data.id ?? "",
                name: data.name ?? "",
                slug: data.slug ?? "",
                permissions: data.permissions ?? [],
                description: data.description ?? "",
                deleted_at: data.deleted_at ?? null,
              });
            }}
            formGetData={() => ({
              id: this.state.id,
              name: this.state.name,
              slug: this.state.slug,
              description: this.state.description,
              permissions: this.state.permissions,
              deleted_at: this.state.deleted_at,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            getDeleteContent={(data) => data.label}
            getDeleteError={(data, error) =>
              error?.message ?? (error ?? "") + " utilisateur(s) ont ce rôle."
            }
            formTitle={
              this.state.deleted_at
                ? "Restaurer le rôle"
                : this.state.id
                  ? "Modifier le rôle"
                  : "Ajouter un rôle"
            }
            formConfirmLabel={
              this.state.deleted_at
                ? "Restaurer"
                : this.state.id
                  ? "Modifier"
                  : "Ajouter"
            }
            formContent={
              <Grid container alignItems="center">
                {!this.state.deleted_at && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Nom du rôle"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.name)}
                        helperText={errors?.name}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Slug"
                        name="slug"
                        value={this.state.slug}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.slug)}
                        helperText={errors?.slug}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Description"
                        name="description"
                        value={this.state.description}
                        onChange={this.handleChangeEvent}
                        error={Boolean(errors?.description)}
                        helperText={errors?.description}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectSearchMultiple
                        label="Permissions"
                        apiUrl="/permissions"
                        getOptionLabel={(option) => `${option.name}`}
                        onChange={(event, newValue) => {
                          this.setState({ permissions: newValue });
                        }}
                        value={this.state.permissions}
                        error={Boolean(errors?.permissions)}
                        helperText={errors?.permissions}
                        filterKey="restrict"
                        filter={true}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props} key={option.id}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          );
                        }}
                        disableCloseOnSelect={true}
                        filterSelectedOptions={false}
                        limitTags={3}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            }
          />
        </Grid>
        <MenuBottom>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=RolesManager"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

RolesManager.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(RolesManager);
