export default class EvenementUtils {
  static evenementsPageTitle = (id, type) => {
    const types = {
      audit: "de l'Audit (AD",
      dossier_travaux: "du Dossier Travaux (DT",
      contrat_locataire: "du Contrat Locataire (CL",
      contrat_proprietaire: "du Contrat Propriétaire (CP",
      proprietaire: "du Propriétaire (",
      foyer: "du Foyer (FO",
      logement: "du Logement (LP",
    };

    return "Évènements " + types[type] + id + ")";
  };

  static evenementEntityName = ({ eventable_type_readable, eventable_id }) => {
    const types = {
      audit: "AD",
      dossier_travaux: "DT",
      contrat_locataire: "CL",
      contrat_proprietaire: "CP",
      proprietaire: "Propriétaire",
      foyer: "FO",
      logement: "LP",
    };

    return types[eventable_type_readable] + eventable_id;
  };

  static evenementEntityPath = ({ eventable_type_readable, eventable_id }) => {
    const types = {
      audit: "/audits",
      dossier_travaux: "/dossiers-travaux",
      contrat_locataire: "/contrats-locataires",
      contrat_proprietaire: "/contrats-proprietaires",
      proprietaire: "/tiers/proprietaires",
      foyer: "/tiers/foyers",
      logement: "/tiers/logements",
    };

    return types[eventable_type_readable] + "/detail/" + eventable_id;
  };
}
