import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import HeadsetMic from "@mui/icons-material/HeadsetMic";

// core components
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";

// context
import { withUserContext } from "context/UserContext";

class OrigineEvenementConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/hapy-evenement";
    this.LABEL = "événement(s)";
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<HeadsetMic />}
            title="Origine"
            backendUrl="/config-origine-evenements"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigEvenement"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

OrigineEvenementConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(OrigineEvenementConfig);
