import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

import FileCopy from "@mui/icons-material/FileCopy";
import AlertDialog from "components/AlertDialog/AlertDialog";
import FileUtils from "Utils/FileUtils";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";

class GenerateMandatPrelevement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
      open: false,
      alert: null,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleAlert = () => {
    this.setState({
      loading: true,
      errorMessage: null,
    });

    const url =
      "/contrats-locataires/" +
      this.props.clId +
      "/documents/templates/" +
      this.props.fileName;

    axiosApiBackendNoJson
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        this.setState({ loading: false });
        this.toggle();

        FileUtils.downLoadFile(
          res.data,
          res.headers,
          "Autorisation-de-prelevement.pdf",
        );

        const callback = this.props?.callback ?? null;
        if (callback !== null) callback();
      })
      .catch((error) => {
        let errorMessage = null;
        if (error.response && error.response.status === 400) {
          const res = FileUtils.getDataFromArrayBuffer(error.response.data);
          errorMessage = res && res.error && res.error.message;
        }
        this.setState({
          loading: false,
          errorMessage: errorMessage ?? "Erreur inconnue",
        });
      });
  };

  render() {
    return (
      <>
        <Button square size="small" onClick={this.toggle}>
          <FileCopy />
        </Button>
        <AlertDialog
          open={this.state.open}
          title="Réinitialiser et générer un mandat de prélèvement SEPA"
          onConfirm={this.handleAlert}
          confirmLabel="Générer"
          confirmColor="primary"
          confirmDelay={5}
          onCancel={this.toggle}
          cancelLabel="Annuler"
          cancelColor="gray"
          fullWidth
        >
          <Typography>
            Êtes-vous sur de vouloir générer un nouveau mandat de prélèvement ?
            Les informations relatives à la signature du mandat actuel seront
            réinitialisés.
          </Typography>
        </AlertDialog>
      </>
    );
  }
}

GenerateMandatPrelevement.propTypes = {
  clId: PropTypes.number.isRequired,
  callback: PropTypes.func,
  fileName: PropTypes.string.isRequired,
};

export default GenerateMandatPrelevement;
