import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AddBox from "@mui/icons-material/AddBox";
import Build from "@mui/icons-material/Build";
import Description from "@mui/icons-material/Description";
import GetApp from "@mui/icons-material/GetApp";

import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import ToggleChip from "components/CustomButtons/ToggleChip";
import MenuBottom from "components/MenuBottom/MenuBottom";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import AbortRequest from "services/AbortRequest";
import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import DispositifUtils from "Utils/DispositifUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import QueryUtils from "Utils/QueryUtils";
import Render from "Utils/RenderUtils";

import { withUserContext } from "context/UserContext";

class DossiersTravaux extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.canEditDosserTravaux = this.USER.can("edit.dossiertravaux");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/dossiers-travaux";
    this.FRONT_URL = "/dossiers-travaux";
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.NO_YES = ["Non", "Oui"];
    this.ABORT_REQUEST = new AbortRequest();
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      filters:
        this.QUERY_PARAMS.get("filters")?.split(",") ??
        DossierTravauxUtils.getStatutDefaultFilter(),
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Code d'identification",
          field: "code_identification",
          comparator: AgGridUtils.naturalSort,
        },
        {
          headerName: "Statut",
          field: "statut",
          valueGetter: (params) =>
            DossierTravauxUtils.renderStatut(params.data.statut),
        },
        {
          headerName: "Type de travaux",
          field: "travaux",
          valueGetter: (params) =>
            params.data?.travaux?.map((t) => t.type?.value).join(", "),
        },
        {
          headerName: "Bénéficiaire",
          field: "beneficiaire",
          valueGetter: (params) =>
            (params.data.logement?.beneficiaire?.prenom ?? "") +
            " " +
            (params.data.logement?.beneficiaire?.nom ?? ""),
        },
        {
          headerName: "Statut de propriété",
          field: "logement.beneficiaire.statut_propriete.value",
        },
        {
          headerName: "Catégorie de ressources",
          field: "logement.beneficiaire.categorie_ressource.value",
        },
        {
          headerName: "Accès au numérique",
          field: "logement.beneficiaire.acces_numerique",
          valueFormatter: (params) => this.NO_YES[params.value],
        },
        {
          headerName: "Téléphone",
          field: "tel",
          valueGetter: (params) =>
            Render.telephone(params.data.logement?.beneficiaire?.tel),
        },
        {
          headerName: "Adresse",
          field: "adresse",
          valueGetter: (params) =>
            Render.address(params.data.logement?.adresse),
        },
        {
          headerName: "Code postal",
          field: "logement.adresse.ville.cp",
        },
        {
          headerName: "Ville",
          field: "logement.adresse.ville.ville",
        },
        {
          headerName: "Technicien",
          field: "technicien",
          valueGetter: (params) => Render.fullName(params.data.technicien),
        },
        {
          headerName: "Conseiller en charge",
          field: "conseiller",
          valueGetter: (params) => Render.fullName(params.data.conseiller),
        },
        {
          headerName: "Donneur d'ordres",
          field: "donneurordres.nom",
        },
        {
          headerName: "Référence Externe",
          field: "reference_externe",
        },
        {
          headerName: "Zone Anah",
          field: "logement.adresse.ville.zoneanah.value",
          valueGetter: (params) =>
            DispositifUtils.renderAnah(params.data.logement.adresse?.ville),
        },
        {
          headerName: "EPCI",
          field: "logement.adresse.ville.epci.value",
          valueGetter: (params) =>
            DispositifUtils.renderEpci(params.data.logement.adresse?.ville),
        },
        {
          headerName: "Dispositif",
          field: "dispositifs",
          valueGetter: (params) =>
            DispositifUtils.renderTableDispositifs(
              params.data.dispositifs,
              params.data.operateur,
            ),
        },
        {
          headerName: "Financeurs",
          field: "financeurs",
          valueGetter: (params) =>
            DossierTravauxUtils.listFinanceurs(params.data.financements),
        },
        {
          headerName: "Ouverture dossier",
          field: "ouverture",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Visite avant travaux",
          field: "date_visite_avant_travaux",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Envoi",
          field: "envoi",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Réception",
          field: "reception",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Début travaux",
          field: "debut",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Date fin de dossier",
          field: "date_fin_dossier",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Raison abandon",
          field: "raison_abandon_dt.value",
        },
        {
          headerName: "Rapport technicien",
          field: "date_rapport_technicien",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Résultat audit avant travaux",
          field: "audit_avant",
          valueGetter: (params) => params.data.audit_avant?.resultat,
        },
        {
          headerName: "Résultat audit après travaux",
          field: "bi_resultat_apres_travaux",
        },
        {
          headerName: "N° carton/archive",
          field: "carton",
          comparator: AgGridUtils.naturalSort,
        },
        {
          headerName: "Type",
          field: "logement.type.value",
        },
        {
          headerName: "Dernière mise à jour",
          field: "updated_at",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Statut personnalisé",
          field: "statut_personnalise.value",
        },
      ]),
      getRowStyle: null,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: {
          filters: this.state.filters,
        },
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      })
      .catch(() => {});
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  toggleFilter = (filter) => {
    let filters = this.state.filters;
    let index = filters.indexOf(filter);

    if (index !== -1) {
      filters.splice(index, 1);
    } else {
      filters.push(filter);
    }

    this.setState({ filters: filters }, () => {
      this.loadAsyncData();
      if (filters.length) {
        QueryUtils.replaceSearchParams({ filters: filters.join(",") });
      } else {
        QueryUtils.deleteSearchParams("filters");
      }
    });
  };

  hasFilter = (filter) => {
    return this.state.filters.includes(filter);
  };

  render() {
    return (
      <Grid container columnSpacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">
                  Gestion des Dossiers Travaux
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                alignItems="center"
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item>
                  <ToggleChip
                    sx={{ m: 0.5 }}
                    color="primary"
                    label="Tous"
                    on={this.state.filters.length === 0}
                    onClick={() => {
                      this.setState({ filters: [] }, () => {
                        this.loadAsyncData();
                        QueryUtils.deleteSearchParams("filters");
                      });
                    }}
                  />
                  {DossierTravauxUtils.STATUT.map((statut) => {
                    return (
                      <ToggleChip
                        sx={{ m: 0.5 }}
                        key={statut.name}
                        color="primary"
                        label={statut.label}
                        on={this.hasFilter(statut.name)}
                        onClick={() => this.toggleFilter(statut.name)}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          {this.canEditDosserTravaux && (
            <Link to={this.FRONT_URL + "/ajouter"}>
              <Button size="small" square round>
                <AddBox />
              </Button>
            </Link>
          )}
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=DossierTravaux"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

DossiersTravaux.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(DossiersTravaux);
