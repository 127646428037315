import styled from "@mui/system/styled";
import MuiCardContent from "@mui/material/CardContent";

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));

export default CardContent;
