import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

class IsolatedTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  setValue = (value) => {
    this.setState({ value: value });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getValue = () => {
    return this.state.value;
  };

  handleChange = (event) => {
    this.setValue(event.target.value);
  };

  handleKeyUp = (event) => {
    if (event.key === "Enter" && this.props.onPressEnter) {
      this.props.onPressEnter();
    }
  };

  render() {
    const { onPressEnter, ...rest } = this.props;
    const { value } = this.state;

    return (
      <TextField
        {...rest}
        value={value}
        onKeyUp={this.handleKeyUp}
        onChange={this.handleChange}
      />
    );
  }
}

IsolatedTextField.propTypes = {
  onPressEnter: PropTypes.func,
};

export default IsolatedTextField;
