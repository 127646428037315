import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

// @mui/material components
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";

// @mui/icons-material
import NoteAdd from "@mui/icons-material/NoteAdd";

// core components
import Button from "components/Button/Button.jsx";

class CafBordereauxImportPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: false,
      errorMessage: "Erreur.",
      success: false,
      successData: null,
      import_files: [],
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const formData = new FormData();
    this.state.import_files.forEach((file, i) => {
      formData.append("caf_file" + i, file);
    });

    axiosApiBackendNoJson
      .post("/caf/importBordereaux", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.setState({
          loading: false,
          success: true,
          successData: res.data.success,
        });
      })
      .catch((error) => {
        let msg =
          error.response?.status === 400
            ? error.response?.data?.error?.message
            : null;

        this.setState({
          loading: false,
          error: true,
          errorMessage: msg ?? "Une erreur est survenue coté serveur.",
        });
      });

    this.setState({
      loading: true,
      error: false,
      errorMessage: null,
      success: false,
      successData: null,
      import_files: [],
    });
  };

  render() {
    const { successData } = this.state;
    const { ncaf, ouverture, suspension, radiation, inconnu } =
      successData?.clsToCheck ?? {};

    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<NoteAdd />}
          onClick={this.handleOpen}
        >
          Caf - Import Bordereaux
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth>
          <DialogTitle>Caf - Import Bordereaux</DialogTitle>
          <DialogContent>
            {this.state.error && (
              <Typography color="error">{this.state.errorMessage}</Typography>
            )}
            {this.state.success && (
              <Typography component="div">
                <h3>Locataires à vérifier</h3>
                <p>
                  Type de fichier : <strong>{successData.fileType}</strong>
                </p>
                {ncaf?.length > 0 && (
                  <div>
                    <h4>
                      Mise à jour des numéros CAF avec les prénoms et noms :
                    </h4>
                    <ul>
                      {ncaf?.map((loc) => (
                        <li key={loc.id}>
                          FO{loc.foyer_id} {loc.firstname} {loc.lastname} ncaf :{" "}
                          {loc.prevNcaf} =&gt; {loc.ncaf} : {loc.amount}€
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {ouverture?.length > 0 && (
                  <div>
                    <h4>Ouverture(s) :</h4>
                    <ul>
                      {ouverture?.map((loc) => (
                        <li key={loc.id}>
                          (CL{loc.id}, {loc.ncaf}) {loc.firstname}{" "}
                          {loc.lastname} : {loc.amount}€
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {suspension?.length > 0 && (
                  <div>
                    <h4>Suspension(s) :</h4>
                    <ul>
                      {suspension?.map((loc) => (
                        <li key={loc.id}>
                          (CL{loc.id}, {loc.ncaf}) {loc.firstname}{" "}
                          {loc.lastname} : {loc.amount}€
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {radiation?.length > 0 && (
                  <div>
                    <h4>Radiation(s) :</h4>
                    <ul>
                      {radiation?.map((loc) => (
                        <li key={loc.id}>
                          (cL{loc.id}, {loc.ncaf}) {loc.firstname}{" "}
                          {loc.lastname} : {loc.amount}€
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {inconnu?.length > 0 && (
                  <div>
                    <h4>Inconnu(s) :</h4>
                    <ul>
                      {inconnu?.map((loc) => (
                        <li key={loc.ncaf}>
                          ({loc.ncaf}) {loc.firstname} {loc.lastname} :{" "}
                          {loc.amount}€
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Typography>
            )}
            <FormControl fullWidth>
              <FilePond
                labelIdle="Ajoutez le bordereau Caf<br>(MAX 10Mo)"
                files={this.state.import_files}
                allowMultiple={false}
                maxFiles={1}
                maxTotalFileSize={10000000}
                acceptedFileTypes={["text/csv", "text/plain"]}
                onupdatefiles={(fileItems) => {
                  this.setState({
                    import_files: fileItems.map((fileItem) => fileItem.file),
                  });
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="reversed">
              Annuler
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              loading={this.state.loading}
            >
              Envoyer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

CafBordereauxImportPopup.propTypes = {
  classes: PropTypes.any,
};

export default CafBordereauxImportPopup;
