import React from "react";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import Button from "components/Button/Button";
import DelayButton from "components/CustomButtons/DelayButton";

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      open,
      outsideClickCancel,
      title,
      content,
      loading,
      onConfirm,
      confirmLabel,
      confirmColor,
      confirmDelay,
      confirmDisabled,
      onCancel,
      cancelLabel,
      cancelColor,
      children,
      ...rest
    } = this.props;

    const hasContent = content != null && content !== "";
    const hasChildren = children != null && children !== "";

    return (
      <Dialog
        open={open}
        onClose={outsideClickCancel ? onCancel : null}
        {...rest}
      >
        <DialogTitle>{title}</DialogTitle>
        {(hasContent || hasChildren) && (
          <DialogContent>
            {hasContent && <Typography>{content}</Typography>}
            {children}
          </DialogContent>
        )}
        <DialogActions>
          {onCancel && (
            <Button color={cancelColor} onClick={onCancel}>
              {cancelLabel}
            </Button>
          )}
          {onConfirm && (
            <DelayButton
              loading={loading}
              color={confirmColor}
              onClick={onConfirm}
              label={confirmLabel}
              delay={confirmDelay}
              disabled={confirmDisabled}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.defaultProps = {
  open: true,
  outsideClickCancel: false,
  confirmLabel: "Oui",
  confirmColor: "success",
  cancelLabel: "Non",
  cancelColor: "primary",
};

AlertDialog.propTypes = {
  open: PropTypes.any,
  outsideClickCancel: PropTypes.bool,
  title: PropTypes.any,
  content: PropTypes.any,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.any,
  confirmColor: PropTypes.any,
  confirmDelay: PropTypes.number,
  confirmDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.any,
  cancelColor: PropTypes.any,
  children: PropTypes.any,
};

export default AlertDialog;
