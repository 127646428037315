import React from "react";
import PropTypes from "prop-types";

import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import EvenementIcon from "components/Evenements/EvenementIcon";

const CenterBox = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
}));

class EvenementIconRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { ...rest } = this.props;

    return (
      <CenterBox>
        <EvenementIcon {...rest} />
      </CenterBox>
    );
  }
}

EvenementIconRenderer.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  dotSize: PropTypes.number,
  iconSize: PropTypes.number,
};

export default EvenementIconRenderer;
