export default class Validation {
  /**
   * Parse errors from the backend validation
   *
   * @param {*} errors returned by the backend
   * @param {*} errorKey specific key to extract
   */
  static parse(errors, errorKey) {
    if (null === errors) {
      return null;
    }

    return Object.fromEntries(
      Object.entries(errors)
        .filter(([key /*, value*/]) => key.startsWith(errorKey))
        .map(([key, value]) => [key.substring(errorKey.length), value]),
    );
  }
}
