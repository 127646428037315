import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Build from "@mui/icons-material/Build";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import Home from "@mui/icons-material/Home";
import Info from "@mui/icons-material/Info";
import List from "@mui/icons-material/List";
import Public from "@mui/icons-material/Public";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import TravauxLinkCard from "components/LinkCard/TravauxLinkCard";
import MenuBottom from "components/MenuBottom/MenuBottom";
import DocumentTemplate from "components/Table/DocumentTemplate";

import SearchBarService from "services/SearchBarService";

import DispositifUtils from "Utils/DispositifUtils";
import Render from "Utils/RenderUtils";

import AdresseView from "./Adresse/AdresseView";
import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

import LogementSubTable from "./TravauxSubTables/LogementSubTable";

class LogementsTravauxView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.logementtravaux");
    this.CAN_EDIT = this.USER.can("edit.logementtravaux");
    this.CAN_USE_LT = this.USER.can("use.logementtravaux");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    // Ged logement permissions
    this.GED_LOGEMENT_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.travaux.logement"),
      CAN_EDIT: this.USER.can("edit.ged.travaux.logement"),
      CAN_DELETE: this.USER.can("delete.ged.travaux.logement"),
    };

    this.BACKEND_URL = "/logements-travaux";
    this.FRONT_URL = "/logements-travaux";
    this.NO_YES = ["Non", "Oui"];
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      beneficiaire: null,
      adresse: null,
      dossiers_travaux: [],
      audits: [],
      secteur: null,
      vacant: null,
      type: null,
      typologie: null,
      type_zone: null,
      // DATA FORM
      annee_construction: "",
      dpe: "",
      is_vacant: false,
      nombre_total_occupants: "",
      nombre_enfants_occupants: "",
      prefixe_cadastrale: "",
      section_cadastrale: "",
      numero_parcelle_cadastrale: "",
      type_propriete: "",
      // END DATA FORM
      errors: null,
      id: props.match.params.id,
      alert: null,
      dt_actif: null,
      audit_actif: null,
      entityLists: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        if (result.data.dossiers_travaux !== null) {
          state.entityLists = {
            audit: result.data.audits,
            dossierTravaux: result.data.dossiers_travaux,
          };
        }
        this.setState(state);
      });
  }

  //Remove Logement Travaux
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
    this.clearAlert();
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le logement ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer le logement : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.audits > 0 && (
              <li>{nbrEntity.audits} audit(s) associé(s)</li>
            )}
            {nbrEntity.dt > 0 && (
              <li>{nbrEntity.dt} dossier(s) travaux associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      beneficiaire,
      dt_actif,
      audit_actif,
      type,
      adresse,
      typologie,
      secteur,
      vacant,
      audits,
      dossiers_travaux,
      type_zone,
      is_vacant,
    } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <AdresseView adresse={adresse} hideRegion />
              <Typography>
                <strong>Données cadastrales : </strong>
                {Render.fullCadastre(this.state)}
              </Typography>
              <Typography>
                <strong>Secteur Géographique : </strong>
                {secteur?.value}
              </Typography>
              <Typography>
                <strong>Type de zone : </strong>
                {type_zone?.value}
              </Typography>
            </CardContent>
          </Card>
          {this.GED_LOGEMENT_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="travaux"
              type="logement"
              objectId={id}
              permissions={this.GED_LOGEMENT_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Info />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Caractéristiques
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Type : </strong>
                {type?.value}
              </Typography>
              <Typography>
                <strong>Typologie : </strong>
                {typologie?.value}
              </Typography>
              <Typography>
                <strong>Type de propriété : </strong>
                {this.state.type_propriete}
              </Typography>
              <Typography>
                <strong>Année de construction : </strong>
                {this.state.annee_construction}
              </Typography>
              <Typography>
                <strong>DPE : </strong>
                {this.state.dpe}
              </Typography>
              <HeaderTitle label="Occupants" />
              <Typography>
                <strong>Nombre total occupants : </strong>
                {this.state.nombre_total_occupants}
              </Typography>
              <Typography>
                <strong>Nombre enfants occupants : </strong>
                {this.state.nombre_enfants_occupants}
              </Typography>
              <Typography>
                <strong>Vacant : </strong>
                {this.NO_YES[is_vacant]}
              </Typography>
              {Boolean(is_vacant) && (
                <Typography>
                  <strong>Vacant depuis : </strong>
                  {vacant?.value}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Public />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Zone Géographique
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Zone ANAH : </strong>
                {DispositifUtils.renderAnah(adresse?.ville)}
              </Typography>
              <Typography>
                <strong>EPCI : </strong>
                {DispositifUtils.renderEpci(adresse?.ville)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TravauxLinkCard
            beneficiaire={beneficiaire}
            logement={{ id: id }}
            audit={audit_actif}
            dossierTravaux={dt_actif}
            hideLogement={true}
            hideAuditApres={true}
            entityLists={this.state.entityLists}
          />
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Dossiers Travaux / Audits
                </Typography>
              }
            />
            <CardContent>
              <br />
              <LogementSubTable
                audits={audits}
                dossiers_travaux={dossiers_travaux}
              />
            </CardContent>
          </Card>
          {this.CAN_USE_LT && (
            <DocumentTemplate baseURL={this.FRONT_URL} objectId={id} />
          )}
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button size="small" color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL +
                "/logs?type=LogementTravaux&id=" +
                this.state.id
              }
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

LogementsTravauxView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LogementsTravauxView);
