import styled from "@mui/system/styled";
import Avatar from "@mui/material/Avatar";

const CardAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== "big" && prop !== "color",
})(({ theme, big, color = "primary" }) => ({
  backgroundColor: theme.palette[color].main,
  width: "60px",
  height: "60px",
  boxShadow: theme.shadows[3],
  color: theme.palette[color].contrastText,
  ...(big && {
    width: "86px",
    height: "86px",
    "& .MuiSvgIcon-root ": {
      width: "36px",
      height: "36px",
    },
  }),
}));

export default CardAvatar;
