import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

// @mui/icons-material

// core components
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormCollection from "components/Form/FormCollection";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import DateUtils from "Utils/DateUtils.jsx";

class ConventionnementLogementForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  event = (handleChangeCn) => {
    return (event) => handleChangeCn(event.target.name, event.target.value);
  };

  calculateEndDateConventionnement = (
    date_debut_conventionnement,
    duree_conventionnement,
    date_fin_conventionnement,
  ) => {
    if (
      date_debut_conventionnement &&
      duree_conventionnement &&
      duree_conventionnement !== "Personnalisée"
    ) {
      const dateDebut = new Date(date_debut_conventionnement);
      const dureeConventionnement = duree_conventionnement.split(" ")[0];
      const dureeAnnee = parseInt(dureeConventionnement);
      const dateFin = DateUtils.addYears(dateDebut, dureeAnnee);

      return DateUtils.toDBString(dateFin);
    } else {
      return date_fin_conventionnement;
    }
  };

  render() {
    const { conventionnements, addRow, updateRow, removeRow, errors } =
      this.props;

    return (
      <FormCollection
        items={conventionnements}
        rowTitle="Conventionnement"
        addRow={addRow}
        removeRow={removeRow}
        tooltipAdd="Ajouter un conventionnement"
        tooltipDelete="Supprimer le conventionnement"
        dialogDeleteTitle={(rowNumber) =>
          "Voulez-vous supprimer le conventionnement n° " + rowNumber + " ?"
        }
        formInputs={(conventionnement, key) => (
          <>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Date de début"
                name="date_debut_conventionnement"
                type="date"
                value={conventionnement.date_debut_conventionnement}
                onChange={(event) => {
                  const { name, value } = event.target;
                  const endDateConventionnement =
                    this.calculateEndDateConventionnement(
                      value,
                      conventionnement.duree_conventionnement,
                      conventionnement.date_fin_conventionnement,
                    );
                  updateRow(key, name, value);
                  updateRow(
                    key,
                    "date_fin_conventionnement",
                    endDateConventionnement,
                  );
                }}
                error={Boolean(errors?.[key + ".date_debut_conventionnement"])}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectInput
                label="Durée"
                name="duree_conventionnement"
                value={conventionnement.duree_conventionnement}
                onChange={(event) => {
                  const { name, value } = event.target;
                  const endDateConventionnement =
                    this.calculateEndDateConventionnement(
                      conventionnement.date_debut_conventionnement,
                      value,
                      conventionnement.date_fin_conventionnement,
                    );
                  updateRow(key, name, value);
                  updateRow(
                    key,
                    "date_fin_conventionnement",
                    endDateConventionnement,
                  );
                }}
                error={Boolean(errors?.[key + ".duree_conventionnement"])}
              >
                <MenuItem value="">
                  <em>Non renseigné</em>
                </MenuItem>
                <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                <MenuItem value="6 ans">6 ans</MenuItem>
                <MenuItem value="9 ans">9 ans</MenuItem>
              </SelectInput>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Date de fin"
                name="date_fin_conventionnement"
                type="date"
                value={conventionnement.date_fin_conventionnement}
                onChange={(event) => {
                  const { name, value } = event.target;
                  if (value !== conventionnement.date_fin_conventionnement) {
                    updateRow(key, "duree_conventionnement", "Personnalisée");
                  }
                  updateRow(key, name, value);
                }}
                error={Boolean(errors?.[key + ".date_fin_conventionnement"])}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="N° ANAH"
                name="numero_conventionnement"
                value={conventionnement.numero_conventionnement}
                onChange={this.event((name, value) =>
                  updateRow(key, name, value),
                )}
                error={Boolean(errors?.[key + ".numero_conventionnement"])}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Date d'envoi du dossier"
                name="date_envoi_dossier"
                type="date"
                value={conventionnement.date_envoi_dossier}
                onChange={this.event((name, value) =>
                  updateRow(key, name, value),
                )}
                error={Boolean(errors?.[key + ".date_envoi_dossier"])}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectSearch
                label="Conventionnement"
                name="conventionnement_id"
                apiUrl="/config-logement-conventionnement"
                onChange={(name, value) => {
                  updateRow(key, name, value);
                  updateRow(key, "type_conv_id", "");
                }}
                value={conventionnement.conventionnement_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.[key + ".conventionnement_id"])}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectSearch
                label="Type conventionnement"
                name="type_conv_id"
                loadOnMount={Boolean(conventionnement.conventionnement_id)}
                apiUrl="/config-types-conventionnement"
                filterKey="conventionnement_id"
                filter={conventionnement.conventionnement_id}
                onChange={(name, value) => updateRow(key, name, value)}
                value={conventionnement.type_conv_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.[key + ".type_conv_id"])}
                disabled={!conventionnement.conventionnement_id}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CurrencyInput
                label="Loyer max"
                name="conventionnement_loyer_max"
                value={conventionnement.conventionnement_loyer_max}
                onChange={this.event((name, value) =>
                  updateRow(key, name, value),
                )}
                error={Boolean(errors?.[key + ".conventionnement_loyer_max"])}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CheckBoxInput
                label="Travaux"
                name="travaux"
                value={conventionnement.travaux}
                onChange={(name, value) => updateRow(key, name, value)}
                error={Boolean(errors?.[key + ".travaux"])}
              />
            </Grid>
          </>
        )}
      />
    );
  }
}

ConventionnementLogementForm.propTypes = {
  conventionnements: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default ConventionnementLogementForm;
