import React, { Component } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// @mui/material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";

import ToggleOffOutlined from "@mui/icons-material/ToggleOffOutlined";

import EvenementIconRenderer from "components/CustomAgRenderer/EvenementIconRenderer";
import EvenementIcon from "components/Evenements/EvenementIcon";
// core components
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

// variables
import AllIcons from "variables/AllIcons";

class ConfigTypeTravaux extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      value: "",
      has_ergo: false,
      icon: "",
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleIcon = (event, value) => {
    this.setState({ icon: value });
  };

  render() {
    const { errors } = this.state;

    return (
      <TablePopUpContainer
        icon={<ToggleOffOutlined />}
        dialogProps={{
          fullWidth: true,
        }}
        title="Type de travaux"
        autoSize={false}
        backendUrl="/config-types-travaux"
        colDef={[
          {
            headerName: "Libellé",
            field: "value",
            flex: 3,
          },
          {
            headerName: "Ergothérapeute",
            field: "has_ergo",
            valueFormatter: (params) => (params.value ? "Oui" : "Non"),
          },
          {
            headerName: "Icône",
            field: "icon",
            cellRenderer: EvenementIconRenderer,
            cellRendererParams: (params) => ({
              color: "transparent",
              icon: params.data.icon,
              sx: { color: "primary.main" },
            }),
          },
        ]}
        formInitData={() => {
          this.setState({
            id: 0,
            value: "",
            has_ergo: false,
            icon: "build",
            errors: null,
          });
        }}
        formSetData={(data) => {
          this.setState({
            id: data.id,
            value: data.value,
            has_ergo: data.has_ergo,
            icon: data.icon,
            errors: null,
          });
        }}
        formGetData={() => ({
          id: this.state.id,
          value: this.state.value,
          has_ergo: this.state.has_ergo,
          icon: this.state.icon,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        getDeleteContent={(data) => data.value}
        getDeleteError={(data, error) =>
          (data.value ?? "") +
          " est associé à " +
          (error ?? "") +
          " dossiers travaux."
        }
        formTitle={
          this.state.id
            ? "Modifier le type de travaux"
            : "Ajouter un type de travaux"
        }
        formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                label="Libellé"
                name="value"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.value}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Ergothérapeute"
                control={
                  <Checkbox
                    name="has_ergo"
                    checked={this.state.has_ergo}
                    onChange={this.handleChangeCheckbox}
                  />
                }
              />
            </Grid>
            <Grid item xs lg={10}>
              <Autocomplete
                disableClearable
                options={AllIcons}
                onChange={this.handleIcon}
                value={this.state.icon}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <Icon>{option}</Icon>&nbsp;{option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Icône"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item lg={2}>
              <EvenementIcon
                color="transparent"
                icon={this.state.icon}
                sx={{ color: "primary.main" }}
              />
            </Grid>
          </Grid>
        }
      />
    );
  }
}

export default ConfigTypeTravaux;
