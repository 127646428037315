import React, { Component } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

class ConfigContainer extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = props.backendUrl;

    this.state = {
      id: null,
      value: "",
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { icon, title, label } = this.props;
    const { id, value, errors } = this.state;

    return (
      <TablePopUpContainer
        icon={icon}
        dialogProps={{
          fullWidth: true,
        }}
        title={title}
        backendUrl={this.BACKEND_URL}
        autoSize={false}
        colDef={[
          {
            headerName: "Libellé",
            field: "value",
          },
        ]}
        formInitData={() => {
          this.setState({
            id: null,
            value: "",
            errors: null,
          });
        }}
        formSetData={(data) => {
          this.setState({
            id: data.id ?? null,
            value: data.value ?? "",
            errors: null,
          });
        }}
        formGetData={() => ({
          id: id,
          value: value,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        getDeleteContent={(data) => data.value}
        getDeleteError={(data, error) =>
          (data.value ?? "") + " est associé à " + (error ?? "") + " " + label
        }
        formTitle={id ? "Modifier le champ" : "Ajouter un champ"}
        formConfirmLabel={id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                inputRef={(input) => input && input.focus()}
                label="Libellé"
                name="value"
                fullWidth
                margin="normal"
                variant="outlined"
                value={value}
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.value)}
              />
            </Grid>
          </Grid>
        }
      />
    );
  }
}

ConfigContainer.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  backendUrl: PropTypes.string,
  label: PropTypes.string,
};

export default ConfigContainer;
