import Logs from "views/Tables/Logs";

export default class LogRoutesBuilder {
  constructor(routes, iconColor) {
    this.ROUTES = routes;
    this.ICON_COLOR = iconColor;
  }

  // Get the first redirect key index to write new route before
  redirectIndex() {
    return this.ROUTES.findIndex((object) => {
      return object.redirect;
    });
  }

  isPropExist(route, prop) {
    return Object.prototype.hasOwnProperty.call(route, prop);
  }

  routeObject(route) {
    return {
      sidebar: false,
      permission: ["view.log.activity"],
      path: route.path + "/logs",
      name: "Logs",
      component: Logs,
      fullscreen: true,
      componentProps: {
        title: "Logs " + route.name,
        iconColor: this.ICON_COLOR,
        parentPath: route.path,
      },
    };
  }

  // Add logs routes for each required views
  build() {
    for (const route of this.ROUTES) {
      // Check if logs property exist with true value
      if (this.isPropExist(route, "logs") && route.logs) {
        // Add logs route with the current route path
        this.ROUTES.splice(this.redirectIndex(), 0, this.routeObject(route));
      }

      // Check if the route has views property
      if (this.isPropExist(route, "views") && route.views.length > 0) {
        // Loop on the views array
        for (const view of route.views) {
          // If the view object has logs enable then add the logs route
          if (this.isPropExist(view, "logs") && view.logs) {
            route.views.push(this.routeObject(view));
          }
        }
      }
    }
  }
}
