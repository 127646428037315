import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui
import Typography from "@mui/material/Typography";
import Person from "@mui/icons-material/Person";

// components
import Render from "Utils/RenderUtils";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import PersonneUtils from "Utils/PersonneUtils";

function PersonneCard(props) {
  const { personne } = props;

  return (
    <Card>
      <CardHeader
        avatar={
          <CardAvatar color="social">
            <Person />
          </CardAvatar>
        }
        title={
          <Typography variant="h6" component="h5">
            <Link to={"/tiers/locataires/detail/" + personne.id}>
              {PersonneUtils.getNomComplet(personne)}
            </Link>
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body1" component="p">
          NIR : {Render.nir(personne.nir)}
          <br />
          Date de naissance : {Render.date(personne.date_naissance)}{" "}
          {Render.age(personne.date_naissance, "(", " ans)")}
          <br />
          Téléphone : {Render.telephoneAsLink(personne.telephone)}
          <br />
          Email : {Render.email(personne.email)}
          <br />
          Statut : {personne.lien.statut}
          <br />
          Entrée dans le foyer : {Render.date(personne.lien.entree)}
          <br />
          Titulaire du contrat : {personne.lien.referent ? "oui" : "non"}
        </Typography>
      </CardContent>
    </Card>
  );
}

PersonneCard.propTypes = {
  personne: PropTypes.any,
};

export default PersonneCard;
