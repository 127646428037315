import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// @mui/icons-material components
import Business from "@mui/icons-material/Business";

// core components
import Render from "Utils/RenderUtils";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import DispositifUtils from "Utils/DispositifUtils";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";

class DispositifView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  renderDispositifs = () => {
    const { dispositifs } = this.props;

    if (dispositifs.length === 0) {
      return <Typography>Aucun dispositif</Typography>;
    }

    return (
      <List disablePadding>
        {dispositifs.map((dispositif, key) => {
          let dates = [
            Render.date(dispositif?.date_debut),
            Render.date(dispositif?.date_fin),
          ];

          let operateur = dispositif?.operateur?.value;
          const overrideOperateur = dispositif?.override_operateur;
          if (overrideOperateur) {
            operateur = overrideOperateur?.value;
          }

          return (
            <ListItem disablePadding key={key}>
              <ListItemText
                primary={
                  <>
                    {dispositif?.value}{" "}
                    {dispositif?.description && (
                      <InfoLightTooltip title={dispositif.description} />
                    )}
                    - Opéré par : {operateur}{" "}
                    {overrideOperateur && (
                      <InfoLightTooltip title="L'opérateur a été manuellement remplacé" />
                    )}
                  </>
                }
                secondary={dates.filter((date) => date).join(" - ")}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  render() {
    const { ville, lot } = this.props;

    return (
      <Card>
        <CardHeader
          avatar={
            <CardAvatar>
              <Business />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              Dispositifs et programmes
            </Typography>
          }
        />
        <CardContent>
          {lot !== undefined && (
            <Typography>
              <strong>Lot : </strong>
              {lot}
            </Typography>
          )}
          <Typography>
            <strong>Zone ANAH : </strong>
            {DispositifUtils.renderAnah(ville)}
          </Typography>
          <Typography>
            <strong>EPCI : </strong>
            {DispositifUtils.renderEpci(ville)}
          </Typography>
          <HeaderTitle label="Dispositifs" />
          {this.renderDispositifs()}
        </CardContent>
      </Card>
    );
  }
}

DispositifView.propTypes = {
  dispositifs: PropTypes.any,
  ville: PropTypes.any,
  lot: PropTypes.any,
};

export default DispositifView;
