import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AccountBalance from "@mui/icons-material/AccountBalance";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import Home from "@mui/icons-material/Home";
import HowToReg from "@mui/icons-material/HowToReg";
import List from "@mui/icons-material/List";
import Payment from "@mui/icons-material/Payment";
import Security from "@mui/icons-material/Security";
import Today from "@mui/icons-material/Today";
import TrendingUp from "@mui/icons-material/TrendingUp";
import WarningRounded from "@mui/icons-material/WarningRounded";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import GenerateFileWithDatesDropdown from "components/CustomDropdown/GenerateFileWithDatesDropdown";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import EcheanciersCardCP from "components/EcheancierCP/EcheanciersCardCP";
import Evenements from "components/Evenements/Evenements.jsx";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import ImmobilierLinkCard from "components/LinkCard/ImmobilierLinkCard";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import RegularisationChargePopup from "components/Popups/RegularisationChargePopup";
import DocumentTemplate from "components/Table/DocumentTemplate";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import LightTooltip from "components/Tooltip/LightTooltip";

import SearchBarService from "services/SearchBarService";

import CPUtils from "Utils/CPUtils";
import IRLUtils from "Utils/IRLUtils";
import Render from "Utils/RenderUtils";

import MensualiteView from "views/Forms/Mensualite/MensualiteView";
import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

class ContratsProprietairesView extends React.Component {
  constructor(props) {
    super(props);
    let id = Number(props.match.params.id);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.contratproprietaire");
    this.canUseGetordresdepaiements = this.USER.can("use.getordresdepaiements");
    this.canUseContratProprietaire = this.USER.can("use.contratproprietaire");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.ECHEANCIER_REF = React.createRef();

    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/contrats-proprietaires";
    this.FRONT_URL = "/contrats-proprietaires";
    this.NO_YES = ["Non", "Oui"];

    // Ged CP permissions
    this.GED_CP_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.cp"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.cp"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.cp"),
    };
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.evenements = React.createRef();
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(id);

    this.state = {
      // NESTED DATA
      logement: null,
      nom_contrat: [],
      origine_fin_contrat: [],
      mensualites: [],
      // DATA FORM
      logement_id: +this.QUERY_PARAMS.get("logement_id") || "",
      duree_mandat: "",
      date_bail: "",
      date_fin: "",
      date_guaranteed: "",
      type: "",
      type_revision: "",
      date_revision: "",
      date_revision_custom: null,
      depot_de_garantie: "",
      mensualite_loyer: "",
      mensualite_charges: "",
      mensualite_garage: "",
      mensualite_charges_garage: "",
      mensualite_ordures: "",
      mensualite_gestion: "",
      jour_paiement: "",
      moyen_paiement: "",
      dg_conserve: "",
      gli: "",
      has_gli_offerte: "",
      nom_rib: "",
      bic: "",
      iban: "",
      infos: "",
      type_paiement: "",
      solde: "",
      solde_ae: "",
      solde_du: "",
      mensualite_gestion_pourcent: "",
      date_preavis: "",
      assurance_date_debut: "",
      assurance_date_fin: "",
      assurance_nom: "",
      num_registre_contrat: "",
      id_ciel: "",
      frais_gestion_offert: "",
      has_vacance_offerte: "",
      has_avance_paiements: "",
      frais_gestion_pourcent_sur_loyers_complet: "",
      // END DATA FORM
      contratlocataireActif: null,
      id: id,
      errors: null,
      alert: null,
      entityLists: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        if (result.data.logement !== null) {
          state.contratlocataireActif = this.getContratlocataireActif(
            result.data.logement.contratslocataires,
          );
          state.entityLists = {
            contratLocataire: result.data.logement.contratslocataires,
          };
        }
        this.setState(state, () => {
          this.evenements.current?.loadAsyncData();
        });
      });
  }

  getContratlocataireActif = (contratslocataires) => {
    let now = new Date();
    const nowDay = now.getDate().toString().padStart(2, "0");
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const today = now.getFullYear() + "-" + month + "-" + nowDay;

    let contratlocataireActif = null;
    for (let cl of contratslocataires) {
      if (cl.date_sortie === null) {
        contratlocataireActif = cl;
        break;
      } else if (cl.date_sortie >= today) {
        contratlocataireActif = cl;
      }
    }
    return contratlocataireActif;
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le contrat ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Non"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce contrat : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.operation > 0 && (
              <li>{nbrEntity.operation} operation(s) associée(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      alert,
      assurance_date_debut,
      assurance_date_fin,
      assurance_nom,
      bic,
      contratlocataireActif,
      date_bail,
      date_fin,
      date_guaranteed,
      date_preavis,
      date_revision,
      date_revision_custom,
      depot_de_garantie,
      dg_conserve,
      duree_mandat,
      entityLists,
      frais_gestion_offert,
      gli,
      has_gli_offerte,
      iban,
      id_ciel,
      infos,
      irl_origine,
      jour_paiement,
      logement,
      loyer_origine,
      mensualites,
      mensualite_charges,
      mensualite_charges_garage,
      mensualite_garage,
      mensualite_gestion,
      mensualite_gestion_pourcent,
      mensualite_loyer,
      mensualite_ordures,
      moyen_paiement,
      nom_contrat,
      nom_rib,
      num_registre_contrat,
      origine_fin_contrat,
      has_vacance_offerte,
      has_avance_paiements,
      frais_gestion_pourcent_sur_loyers_complet,
      // solde,
      solde_ae,
      solde_du,
      type,
      type_paiement,
      type_revision,
    } = this.state;

    const proprietaire = logement?.proprietaire;
    const evenementsLink = "/evenements/immobilier/contrat_proprietaire/" + id;

    const typeRevisionTootip =
      CPUtils.TYPE_REVISION_FORM[this.state.type_revision]?.tooltip;

    const hasAdhesionWarning =
      this.state.date_fin && proprietaire?.adhesion_cp_id === this.state.id;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <HowToReg />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Propriétaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prénom : </strong>
                {logement?.proprietaire?.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {logement?.proprietaire?.nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(logement?.proprietaire?.telephone)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(logement?.proprietaire?.email)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Description />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Caractéristique du contrat (CP{id})
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Type : </strong>
                {type}
              </Typography>
              <Typography>
                <strong>Nom de contrat : </strong>
                {nom_contrat?.value}
              </Typography>
              <Typography>
                <strong>Numéro de registre : </strong>
                {num_registre_contrat}
              </Typography>
              <Typography>
                <strong>Type de paiement : </strong>
                {type_paiement}
              </Typography>
              {type === CPUtils.TYPES[2] && (
                <>
                  <Typography>
                    <strong>Durée des garanties securités : </strong>
                    {duree_mandat && (
                      <>
                        {duree_mandat === 1200 ? (
                          <>Illimité</>
                        ) : (
                          <>{duree_mandat} Mois</>
                        )}
                      </>
                    )}
                  </Typography>
                  {duree_mandat !== 1200 && (
                    <Typography>
                      <strong>Date de fin des garanties securités : </strong>
                      {Render.date(date_guaranteed)}
                    </Typography>
                  )}
                </>
              )}
              {type === CPUtils.TYPES[2] && (
                <Typography>
                  <strong>Avances des paiements : </strong>
                  {this.NO_YES[has_avance_paiements]}
                </Typography>
              )}
              {type === CPUtils.TYPES[2] && (
                <Typography>
                  <strong>Vacance offerte : </strong>
                  {this.NO_YES[has_vacance_offerte]}
                </Typography>
              )}
              <HeaderTitle
                label={
                  type === CPUtils.TYPES[0]
                    ? "Période de bail"
                    : "Période de mandat"
                }
              />
              <Typography>
                <strong>
                  {type === CPUtils.TYPES[0]
                    ? "Date de début de bail : "
                    : "Date de début de mandat : "}
                </strong>
                {Render.date(date_bail)}
              </Typography>
              <Typography>
                <strong>Date de préavis : </strong>
                {Render.date(date_preavis)}
              </Typography>
              <Typography>
                <strong>
                  {type === CPUtils.TYPES[0]
                    ? "Date de fin de bail : "
                    : "Date de fin de mandat : "}
                </strong>
                {Render.date(date_fin)}
                {hasAdhesionWarning && (
                  <LightTooltip
                    title={
                      <>
                        Attention l&apos;adhésion ne se débitera plus une fois
                        ce contrat terminé.{" "}
                        <Link
                          to={"/tiers/proprietaires/detail/" + proprietaire?.id}
                        >
                          Modifiez les paramètres d&apos;adhésion dans le
                          propriétaire.
                        </Link>
                      </>
                    }
                  >
                    <WarningRounded
                      color="warning"
                      sx={{
                        verticalAlign: "text-bottom",
                      }}
                    />
                  </LightTooltip>
                )}
              </Typography>
              <Typography>
                <strong>Origine fin de contrat : </strong>
                {origine_fin_contrat?.value}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <FormatListBulleted />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Comptable
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Numéro identifiant comptable : </strong>
                {id_ciel}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="success">
                  <AccountBalance />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Loyer et DG
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Loyer d&apos;origine : </strong>
                {Render.euro(loyer_origine)}
              </Typography>
              <Typography>
                <strong>Dépôt de garantie : </strong>
                {Render.euro(depot_de_garantie)}
              </Typography>
              <Typography>
                <strong>DG conservé : </strong>
                {this.NO_YES[dg_conserve]}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="info">
                  <TrendingUp />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Révision du loyer{" "}
                  <InfoLightTooltip title={CPUtils.REVISION_TOOLTIP} />
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>
                  Type de révision{" "}
                  {typeRevisionTootip && (
                    <InfoLightTooltip title={typeRevisionTootip} />
                  )}{" "}
                  :{" "}
                </strong>
                {type_revision}
              </Typography>
              {[
                CPUtils.TYPE_REVISION[1],
                CPUtils.TYPE_REVISION[2],
                CPUtils.TYPE_REVISION[3],
              ].includes(type_revision) && (
                <>
                  <Typography>
                    <strong>
                      IRL d&apos;origine{" "}
                      <InfoLightTooltip title={CPUtils.IRL_TOOLTIP} /> :{" "}
                    </strong>
                    {IRLUtils.irl(irl_origine)}
                  </Typography>
                  <Typography>
                    <strong>
                      Date de révision{" "}
                      <InfoLightTooltip title={CPUtils.DATE_REVISION_TOOLTIP} />{" "}
                      :{" "}
                    </strong>
                    {date_revision}
                    {date_revision === CPUtils.DATE_REVISION[4] && (
                      <> - {Render.dayMonth(date_revision_custom)}</>
                    )}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Security />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  <>
                    Garantie Loyer Impayé{" "}
                    <InfoLightTooltip title="La GLI n'est pas appliquée si le locataire entre après le 15." />
                  </>
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>GLI : </strong>
                {this.NO_YES[gli]}
              </Typography>
              {Boolean(this.state.gli) && type === CPUtils.TYPES[2] && (
                <Typography>
                  <strong>GLI offerte : </strong>
                  {this.NO_YES[has_gli_offerte]}
                </Typography>
              )}
              <Typography>
                <strong>Nom assurance : </strong>
                {assurance_nom}
              </Typography>
              <Typography>
                <strong>Date de début assurance : </strong>
                {Render.date(assurance_date_debut)}
              </Typography>
              <Typography>
                <strong>Date de fin assurance : </strong>
                {Render.date(assurance_date_fin)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Adresse : </strong>
                {Render.address(logement?.adresse)}
              </Typography>
              <Typography>
                <strong>Ville : </strong>
                {logement?.adresse?.ville?.cp} {logement?.adresse?.ville?.ville}
              </Typography>
            </CardContent>
          </Card>
          {this.canUseGetordresdepaiements && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <AccountBalance />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Informations Bancaires
                  </Typography>
                }
              />
              <CardContent>
                <Typography>
                  <strong>Nom RIB : </strong>
                  {nom_rib}
                </Typography>
                <Typography>
                  <strong>BIC : </strong>
                  {bic}
                </Typography>
                <Typography>
                  <strong>IBAN : </strong>
                  {Render.iban(iban)}
                </Typography>
              </CardContent>
            </Card>
          )}
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Payment />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Paiement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Jour de paiement : </strong>
                {jour_paiement}
              </Typography>
              <Typography>
                <strong>Moyen de paiement au propriétaire : </strong>
                {moyen_paiement}
              </Typography>
              <Typography>
                <strong>Informations complémentaires : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>{infos}</MultilineTypography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <CalendarToday />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Mensualités
                </Typography>
              }
              action={
                <RegularisationChargePopup
                  contrat_proprietaire_id={id}
                  type_contrat_proprietaire={type}
                  date_debut_bail={date_bail}
                  date_fin_bail={date_fin}
                  onSuccess={() => {
                    this.loadAsyncData();
                    this.ECHEANCIER_REF.current?.reloadEcheancier();
                  }}
                />
              }
            />
            <CardContent>
              <Grid item xs={12}>
                <HeaderTitle label="Logement(s)" />
              </Grid>
              <Typography>
                <strong>Montant du loyer : </strong>
                {Render.euro(mensualite_loyer)}
              </Typography>
              <Typography>
                <strong>Montant des charges : </strong>
                {Render.euro(mensualite_charges)}
              </Typography>
              <Typography>
                <strong>
                  Montant des TOM (Taxes d&apos;Ordure Ménagère) :{" "}
                </strong>
                {Render.euro(mensualite_ordures)}
              </Typography>
              <Grid item xs={12}>
                <HeaderTitle label="Garage(s)" />
              </Grid>
              <Typography>
                <strong>Montant du loyer : </strong>
                {Render.euro(mensualite_garage)}
              </Typography>
              <Typography>
                <strong>Montant des charges : </strong>
                {Render.euro(mensualite_charges_garage)}
              </Typography>
              <Grid item xs={12}>
                <HeaderTitle label="Frais de gestion" />
              </Grid>
              <Typography>
                <strong>Montant : </strong>
                {Render.euro(mensualite_gestion)}
              </Typography>
              <Typography>
                <strong>
                  Pourcentage{" "}
                  <InfoLightTooltip
                    title={
                      frais_gestion_pourcent_sur_loyers_complet
                        ? CPUtils.FRAIS_GESTION_POURCENT_LOYER_COMPLET_TRUE_TOOLTIP
                        : CPUtils.FRAIS_GESTION_POURCENT_LOYER_COMPLET_FALSE_TOOLTIP
                    }
                  />
                </strong>{" "}
                : {Render.percent(mensualite_gestion_pourcent)}
                {CPUtils.renderFraisGestion(
                  this.state,
                  mensualite_gestion_pourcent,
                  frais_gestion_pourcent_sur_loyers_complet,
                )}
              </Typography>
              <CheckBoxView
                label="Frais de gestion offerts"
                value={frais_gestion_offert}
              />
              {mensualites && mensualites.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <HeaderTitle label="Autres mensualités" />
                  </Grid>
                  <MensualiteView mensualites={mensualites} />
                </>
              ) : null}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="success">
                  <AccountBalanceWallet />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Solde
                </Typography>
              }
            />
            <CardContent>
              <Typography
                variant="h3"
                gutterBottom
                style={{ whiteSpace: "nowrap" }}
              >
                {Render.euro(solde_ae)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ImmobilierLinkCard
            proprietaire={proprietaire}
            logement={logement}
            contratProprietaire={{
              id: id,
              loyer_origine: loyer_origine,
              depot_de_garantie: depot_de_garantie,
              mensualite_loyer: mensualite_loyer,
              mensualite_charges: mensualite_charges,
              mensualite_garage: mensualite_garage,
              mensualite_charges_garage: mensualite_charges_garage,
              mensualite_ordures: mensualite_ordures,
              jour_paiement: jour_paiement,
            }}
            contratLocataire={contratlocataireActif}
            hideContratProprietaire={true}
            entityLists={entityLists}
          />
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Today />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Évènements
                </Typography>
              }
              action={
                <Link to={evenementsLink + "?form=open"}>
                  <Button size="small">Ajouter</Button>
                </Link>
              }
            />
            <CardContent>
              <Link to={evenementsLink}>
                <Evenements
                  ref={this.evenements}
                  loadOnMount={false}
                  modelId={id}
                  modelType="contrat_proprietaire"
                  dense
                  limit={5}
                />
              </Link>
            </CardContent>
          </Card>
          {this.canUseContratProprietaire && (
            <DocumentTemplate
              baseURL="/contrats-proprietaires"
              objectId={id}
              color="gla"
            />
          )}
          {this.GED_CP_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="CP"
              objectId={id}
              permissions={this.GED_CP_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <EcheanciersCardCP
            contractId={id}
            typePaiement={type_paiement}
            onSoldeUpdate={() => this.loadAsyncData()}
            soldeDu={solde_du}
            moyenPaiement={moyen_paiement}
          />
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={this.FRONT_URL + "/logs?type=ContratProprietaire&id=" + id}
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
          <GenerateFileWithDatesDropdown
            buttonText="CRG"
            tooltipText="Compte Rendu de Gérance"
            baseUrl="/contrats-proprietaires"
            documentUrl="compte-rendu-gerance"
            idContrat={id}
          />
        </MenuBottom>
        {alert}
      </Grid>
    );
  }
}

ContratsProprietairesView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ContratsProprietairesView);
