import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

// @mui/material components
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function SelectSearchMultiple(props) {
  const {
    label,
    apiUrl,
    error,
    helperText,
    filter,
    filterKey,
    sortOptions,
    ...rest
  } = props;
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    let config = filter ? { params: { [filterKey]: filter } } : null;
    axiosApiBackend.get(apiUrl, config).then((result) => {
      let data = result.data;

      if (sortOptions) {
        data = sortOptions(data);
      }

      setOptionData(data);
    });
  }, []);

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      options={optionData}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          margin="normal"
          error={error}
          helperText={helperText}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
}

SelectSearchMultiple.propTypes = {
  label: PropTypes.string,
  apiUrl: PropTypes.string,
  helperText: PropTypes.any,
  error: PropTypes.any,
  filter: PropTypes.any,
  filterKey: PropTypes.any,
  sortOptions: PropTypes.func,
};

export default SelectSearchMultiple;
