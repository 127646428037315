import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import Euro from "@mui/icons-material/Euro";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Person from "@mui/icons-material/Person";

import Button from "components/Button/Button";
// core components
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import MenuBottom from "components/MenuBottom/MenuBottom";
import DocumentTemplatePopup from "components/Popups/DocumentTemplatePopup";

import Ged from "views/Tables/Ged";

// context
import { withUserContext } from "context/UserContext";

class FoyerConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    // Ged permissions
    const permission = this.USER.can("config.ged.immobilier.foyer");
    this.GED_FOYER_PERMISSIONS = {
      CAN_VIEW: permission,
      CAN_EDIT: permission,
      CAN_DELETE: permission,
    };
    this.FRONT_URL = "/configuration/foyers";
    this.LABEL = "foyer(s)";

    this.state = {
      // DATA FORM
      id: "",
      label: "",
      // END DATA FORM
      errors: null,
      alert: null,
    };
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Euro />}
            title="Nature des ressources principales"
            backendUrl="/config-ressources-principales"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<DoubleArrow />}
            title="Type de suivi"
            backendUrl="/config-types-suivi"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<Person />}
            title="Orienteur"
            backendUrl="/config-orienteur"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {this.GED_FOYER_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="templates"
              section="immobilier"
              type="foyer"
              permissions={this.GED_FOYER_PERMISSIONS}
              title="Modèles de documents"
              iconColor="config.main"
              buttonColor="config"
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              popupComponent={DocumentTemplatePopup}
              frontUrl={this.FRONT_URL}
            />
          )}
          <ConfigContainer
            icon={<PeopleAlt />}
            title="Situation familiale"
            backendUrl="/config-situations-familiale"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<PeopleAlt />}
            title="Situation du ménage avant l'entrée"
            backendUrl="/config-situation-menage-entree"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<PeopleAlt />}
            title="Situation du ménage à la sortie"
            backendUrl="/config-situation-menage-sortie"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigFoyer"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

FoyerConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(FoyerConfig);
