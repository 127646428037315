import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AccountBalance from "@mui/icons-material/AccountBalance";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Description from "@mui/icons-material/Description";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import Home from "@mui/icons-material/Home";
import HowToReg from "@mui/icons-material/HowToReg";
import Info from "@mui/icons-material/Info";
import Payment from "@mui/icons-material/Payment";
import Security from "@mui/icons-material/Security";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import IbanIMaskInput from "components/CustomInput/IbanIMaskInput";
import PercentInput from "components/CustomInput/PercentInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import DatePicker from "components/DatePicker/DatePicker.jsx";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import LightTooltip from "components/Tooltip/LightTooltip";

import CPUtils from "Utils/CPUtils";
import DateUtils from "Utils/DateUtils";
import IRLUtils from "Utils/IRLUtils";
import Render from "Utils/RenderUtils";
import Validation from "Utils/ValidationUtils";

import MensualiteForm from "views/Forms/Mensualite/MensualiteForm";

import { withUserContext } from "context/UserContext";

class ContratsProprietairesForm extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.canUSeGetordresdepaiements = this.USER.can("use.getordresdepaiements");
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/contrats-proprietaires";
    this.FRONT_URL = "/contrats-proprietaires";

    this.state = {
      // NESTED DATA
      mensualites: [],
      logement: null,
      // DATA FORM
      logement_id: +this.QUERY_PARAMS.get("logement_id") || "",
      date_bail: "",
      date_fin: "",
      type: "",
      duree_mandat: "",
      type_revision: CPUtils.TYPE_REVISION[1],
      date_revision: CPUtils.DATE_REVISION[0],
      date_revision_custom: new Date(),
      loyer_origine: "",
      depot_de_garantie: "",
      mensualite_loyer: "",
      mensualite_charges: "",
      mensualite_garage: "",
      mensualite_charges_garage: "",
      mensualite_ordures: "",
      mensualite_gestion: "",
      jour_paiement: "",
      moyen_paiement: CPUtils.MOYEN_PAIEMENT[1],
      dg_conserve: true,
      gli: true,
      has_gli_offerte: false,
      nom_rib: "",
      bic: "",
      iban: "",
      infos: "",
      type_paiement: CPUtils.TYPE_PAIEMENT[0],
      nom_contrat_id: "",
      mensualite_gestion_pourcent: "",
      date_preavis: "",
      assurance_date_debut: "",
      assurance_date_fin: "",
      assurance_nom: "",
      origine_fin_contrat_id: "",
      irl_origine_id: "",
      num_registre_contrat: "",
      id_ciel: "",
      frais_gestion_offert: false,
      has_vacance_offerte: false,
      has_avance_paiements: false,
      frais_gestion_pourcent_sur_loyers_complet: false,
      // END DATA FORM
      loading: false,
      id: props.match.params.id,
      errors: null,
      contratlocataireActif: null,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        state.date_revision_custom = new Date(
          result.data.date_revision_custom ?? Date.now(),
        );
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeNumber = (event) => {
    let { name, value } = event.target;
    value = value.replace(/,/g, ".");
    this.setState({ [name]: value });
  };

  handleChangeIban = (event) => {
    let { name, value } = event.target;
    value = value.replace(/[. ,]/g, "");
    this.setState({ [name]: value });
  };

  handleChangeLogement = (name, value) => {
    this.setState({ [name]: value });

    if (value) {
      axiosApiBackend.get(`/logements/${value}`).then((result) => {
        const logement = result.data;
        this.setState({
          logement: logement,
          logement_id: value,
        });
      });
    } else {
      this.setState({
        logement: null,
        logement_id: null,
      });
    }
  };

  getDefault = () => {
    return {
      id_op_config: "",
      montant: "",
      debit: 1,
      description: "",
    };
  };
  handleAddArray = () => {
    this.setState((prevState) => {
      let mensualites = prevState.mensualites;
      mensualites.push(this.getDefault());
      return { mensualites: mensualites };
    });
  };
  handleChangeArray = (key, name, value) => {
    this.setState((prevState) => {
      let mensualites = prevState.mensualites;
      mensualites[key][name] = value;
      return { mensualites: mensualites };
    });
  };
  handleRemoveArray = (key) => {
    this.setState((prevState) => {
      let mensualites = prevState.mensualites;
      mensualites.splice(key, 1);
      return { mensualites: mensualites };
    });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      logement_id: this.state.logement_id,
      date_bail: this.state.date_bail,
      date_fin: this.state.date_fin,
      type: this.state.type,
      duree_mandat: this.state.duree_mandat,
      type_revision: this.state.type_revision,
      date_revision: this.state.date_revision,
      date_revision_custom: DateUtils.toDBString(
        this.state.date_revision_custom,
      ),
      loyer_origine: this.state.loyer_origine,
      depot_de_garantie: this.state.depot_de_garantie,
      mensualite_loyer: this.state.mensualite_loyer,
      mensualite_charges: this.state.mensualite_charges,
      mensualite_garage: this.state.mensualite_garage,
      mensualite_charges_garage: this.state.mensualite_charges_garage,
      mensualite_ordures: this.state.mensualite_ordures,
      mensualite_gestion: this.state.mensualite_gestion,
      jour_paiement: this.state.jour_paiement,
      moyen_paiement: this.state.moyen_paiement,
      dg_conserve: this.state.dg_conserve,
      gli: this.state.gli,
      has_gli_offerte:
        this.state.gli && this.state.type === CPUtils.TYPES[2]
          ? this.state.has_gli_offerte
          : 0,
      nom_rib: this.state.nom_rib,
      bic: this.state.bic,
      iban: this.state.iban,
      infos: this.state.infos,
      type_paiement: this.state.type_paiement,
      nom_contrat_id: this.state.nom_contrat_id,
      mensualite_gestion_pourcent: this.state.mensualite_gestion_pourcent,
      mensualites: this.state.mensualites.map((mensualite) => ({
        ...mensualite,
        config: undefined,
      })),
      date_preavis: this.state.date_preavis,
      assurance_date_debut: this.state.assurance_date_debut,
      assurance_date_fin: this.state.assurance_date_fin,
      assurance_nom: this.state.assurance_nom,
      origine_fin_contrat_id: this.state.origine_fin_contrat_id,
      irl_origine_id: this.state.irl_origine_id,
      num_registre_contrat: this.state.num_registre_contrat,
      id_ciel: this.state.id_ciel,
      frais_gestion_offert: this.state.frais_gestion_offert,
      has_vacance_offerte: this.state.has_vacance_offerte,
      has_avance_paiements: this.state.has_avance_paiements,
      frais_gestion_pourcent_sur_loyers_complet:
        this.state.frais_gestion_pourcent_sur_loyers_complet,
    };
    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      id,
      errors,
      loading,
      date_bail,
      date_fin,
      type,
      duree_mandat,
      type_revision,
      date_revision,
      date_revision_custom,
      loyer_origine,
      depot_de_garantie,
      mensualite_loyer,
      mensualite_charges,
      mensualite_garage,
      mensualite_charges_garage,
      mensualite_ordures,
      mensualite_gestion,
      jour_paiement,
      moyen_paiement,
      dg_conserve,
      gli,
      has_gli_offerte,
      nom_rib,
      bic,
      iban,
      infos,
      type_paiement,
      nom_contrat_id,
      mensualite_gestion_pourcent,
      date_preavis,
      assurance_date_debut,
      assurance_date_fin,
      assurance_nom,
      origine_fin_contrat_id,
      irl_origine_id,
      num_registre_contrat,
      id_ciel,
      frais_gestion_offert,
      has_vacance_offerte,
      has_avance_paiements,
      frais_gestion_pourcent_sur_loyers_complet,
      logement,
      logement_id,
      mensualites,
    } = this.state;

    const url_logement = id
      ? `/logements?has=nocp&dense=1&edit_cp_id=${id}`
      : `/logements?has=nocp&dense=1`;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <HowToReg />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Propriétaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prénom : </strong>
                {logement?.proprietaire?.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {logement?.proprietaire?.nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(logement?.proprietaire?.telephone)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(logement?.proprietaire?.email)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Description />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Caractéristique du contrat {id ? `CP${id}` : ""}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Type"
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                    error={Boolean(errors?.type)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {CPUtils.TYPES.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Nom de contrat"
                    name="nom_contrat_id"
                    apiUrl="/config-nom-contrat-proprietaires"
                    onChange={this.handleChangeByName}
                    value={nom_contrat_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.nom_contrat_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Numéro de registre"
                    name="num_registre_contrat"
                    onChange={this.handleChange}
                    value={num_registre_contrat}
                    error={Boolean(errors?.num_registre_contrat)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Type de paiement"
                    name="type_paiement"
                    value={type_paiement}
                    onChange={this.handleChange}
                    error={Boolean(errors?.type_paiement)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {CPUtils.TYPE_PAIEMENT.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                {type === CPUtils.TYPES[2] && (
                  <Grid item xs={12} lg={6}>
                    <SelectInput
                      label="Durée des garanties securités"
                      name="duree_mandat"
                      value={duree_mandat}
                      onChange={this.handleChange}
                      error={Boolean(errors?.duree_mandat)}
                    >
                      <MenuItem value={18}>18 Mois</MenuItem>
                      <MenuItem value={24}>24 Mois</MenuItem>
                      <MenuItem value={1200}>Illimité</MenuItem>
                      {/* gestion de la duree illimité en mettant un nb de mois eleve (on suppose qu'un locataire ne reste pas plus de 100ans) */}
                    </SelectInput>
                  </Grid>
                )}
                {type === CPUtils.TYPES[2] && (
                  <Grid item xs={12} lg={6}>
                    <SelectInput
                      label="Avance des paiements"
                      name="has_avance_paiements"
                      value={has_avance_paiements}
                      onChange={this.handleChange}
                      error={Boolean(errors?.has_avance_paiements)}
                    >
                      <MenuItem value={0}>Non</MenuItem>
                      <MenuItem value={1}>Oui</MenuItem>
                    </SelectInput>
                  </Grid>
                )}
                {type === CPUtils.TYPES[2] && (
                  <Grid item xs={12} lg={6}>
                    <SelectInput
                      label="Vacance offerte"
                      name="has_vacance_offerte"
                      value={has_vacance_offerte}
                      onChange={this.handleChange}
                      error={Boolean(errors?.has_vacance_offerte)}
                    >
                      <MenuItem value={0}>Non</MenuItem>
                      <MenuItem value={1}>Oui</MenuItem>
                    </SelectInput>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <HeaderTitle
                    label={
                      type === CPUtils.TYPES[0]
                        ? "Période de bail"
                        : "Période de mandat"
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label={
                      type === CPUtils.TYPES[0]
                        ? "Date de début de bail : "
                        : "Date de début de mandat : "
                    }
                    name="date_bail"
                    type="date"
                    onChange={this.handleChange}
                    value={date_bail}
                    error={Boolean(errors?.date_bail)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de préavis"
                    name="date_preavis"
                    type="date"
                    onChange={this.handleChange}
                    value={date_preavis}
                    error={Boolean(errors?.date_preavis)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label={
                      type === CPUtils.TYPES[0]
                        ? "Date de fin de bail : "
                        : "Date de fin de mandat : "
                    }
                    name="date_fin"
                    type="date"
                    onChange={this.handleChange}
                    value={date_fin}
                    error={Boolean(errors?.date_fin)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Origine fin de contrat "
                    name="origine_fin_contrat_id"
                    apiUrl="/config-origine_fin-contrat-cp"
                    onChange={this.handleChangeByName}
                    value={origine_fin_contrat_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.origine_fin_contrat_id)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <FormatListBulleted />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Comptable
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Numéro identifiant comptable"
                    name="id_ciel"
                    onChange={this.handleChange}
                    value={id_ciel}
                    error={Boolean(errors?.id_ciel)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="success">
                  <AccountBalance />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Loyer et DG
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Loyer d'origine"
                    name="loyer_origine"
                    onChange={this.handleChangeNumber}
                    value={loyer_origine}
                    error={Boolean(errors?.loyer_origine)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Dépôt de garantie"
                    name="depot_de_garantie"
                    onChange={this.handleChangeNumber}
                    value={depot_de_garantie}
                    error={Boolean(errors?.depot_de_garantie)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="DG conservé"
                    name="dg_conserve"
                    value={dg_conserve}
                    onChange={this.handleChange}
                    error={Boolean(errors?.dg_conserve)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="info">
                  <AccountBalance />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  <LightTooltip title={CPUtils.REVISION_TOOLTIP}>
                    <span>
                      Révision du loyer <Info fontSize="small" />
                    </span>
                  </LightTooltip>
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Type de révision"
                    name="type_revision"
                    value={type_revision}
                    onChange={this.handleChange}
                    error={Boolean(errors?.type_revision)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {CPUtils.TYPE_REVISION_FORM.map((value, index) => (
                      <MenuItem key={index} value={value.text}>
                        <LightTooltip title={value.tooltip}>
                          <span>
                            {value.text}{" "}
                            {value.tooltip && <Info fontSize="small" />}
                          </span>
                        </LightTooltip>
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                {[
                  CPUtils.TYPE_REVISION[1],
                  CPUtils.TYPE_REVISION[2],
                  CPUtils.TYPE_REVISION[3],
                ].includes(type_revision) && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <SelectSearch
                        label={
                          <LightTooltip title={CPUtils.IRL_TOOLTIP}>
                            <span>
                              Choisir un IRL d&apos;origine{" "}
                              <Info fontSize="small" />
                            </span>
                          </LightTooltip>
                        }
                        name="irl_origine_id"
                        apiUrl="/irls"
                        value={irl_origine_id}
                        onChange={this.handleChangeByName}
                        sortOptions={(a, b) => b.key - a.key}
                        buildOptionLabel={(irl) => IRLUtils.irl(irl)}
                        shrink
                        error={Boolean(errors?.irl_origine_id)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SelectInput
                        label={
                          <LightTooltip title={CPUtils.DATE_REVISION_TOOLTIP}>
                            <span>
                              Date de révision <Info fontSize="small" />
                            </span>
                          </LightTooltip>
                        }
                        name="date_revision"
                        value={date_revision}
                        onChange={this.handleChange}
                        error={Boolean(errors?.date_revision)}
                      >
                        <MenuItem value="">
                          <em>Non renseigné</em>
                        </MenuItem>
                        {CPUtils.DATE_REVISION.map((type) => (
                          <MenuItem value={type} key={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </Grid>
                    {date_revision === CPUtils.DATE_REVISION[4] && (
                      <Grid item xs={12} lg={6}>
                        <DatePicker
                          label="Date de révision"
                          name="date_revision_custom"
                          value={date_revision_custom}
                          onChange={this.handleChangeByName}
                          views={["month", "day"]}
                          inputFormat="dd/MM"
                          error={Boolean(errors?.date_revision_custom)}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Security />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  <>
                    Garantie Loyer Impayé{" "}
                    <InfoLightTooltip title="La GLI n'est pas appliquée si le locataire entre après le 15." />
                  </>
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="GLI"
                    name="gli"
                    value={gli}
                    onChange={this.handleChange}
                    error={Boolean(errors?.gli)}
                  >
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {Boolean(gli) && type === CPUtils.TYPES[2] && (
                    <SelectInput
                      label="GLI offerte"
                      name="has_gli_offerte"
                      value={has_gli_offerte}
                      onChange={this.handleChange}
                      error={Boolean(errors?.has_gli_offerte)}
                    >
                      <MenuItem value={0}>Non</MenuItem>
                      <MenuItem value={1}>Oui</MenuItem>
                    </SelectInput>
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom assurance"
                    name="assurance_nom"
                    onChange={this.handleChange}
                    value={assurance_nom}
                    error={Boolean(errors?.assurance_nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de début assurance"
                    name="assurance_date_debut"
                    type="date"
                    onChange={this.handleChange}
                    value={assurance_date_debut}
                    error={Boolean(errors?.assurance_date_debut)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de fin assurance"
                    name="assurance_date_fin"
                    type="date"
                    onChange={this.handleChange}
                    value={assurance_date_fin}
                    error={Boolean(errors?.assurance_date_fin)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="prospection">
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <SelectSearch
                    name="logement_id"
                    label={
                      <LightTooltip title="Les logements affichés n'ont pas de contrat propriétaire associé.">
                        <span>
                          Choisir un logement <Info fontSize="small" />
                        </span>
                      </LightTooltip>
                    }
                    apiUrl={url_logement}
                    value={logement_id}
                    onChange={this.handleChangeLogement}
                    buildOptionLabel={(data) =>
                      Render.fullAddress(data.adresse) + " LP" + data.id
                    }
                    shrink
                    error={Boolean(errors?.logement_id)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {this.canUSeGetordresdepaiements && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <AccountBalance />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Informations Bancaires
                  </Typography>
                }
              />
              <CardContent>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="Nom RIB"
                      name="nom_rib"
                      onChange={this.handleChange}
                      value={nom_rib}
                      error={Boolean(errors?.nom_rib)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="BIC"
                      name="bic"
                      onChange={this.handleChange}
                      value={bic}
                      error={Boolean(errors?.bic)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      label="IBAN"
                      name="iban"
                      onChange={this.handleChangeIban}
                      value={iban}
                      InputProps={{
                        inputComponent: IbanIMaskInput,
                      }}
                      error={Boolean(errors?.iban)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="gla">
                  <Payment />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Paiement
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Jour de paiement"
                    name="jour_paiement"
                    onChange={this.handleChangeNumber}
                    value={jour_paiement}
                    error={Boolean(errors?.jour_paiement)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Moyen de paiement au propriétaire"
                    name="moyen_paiement"
                    value={moyen_paiement}
                    onChange={this.handleChange}
                    error={Boolean(errors?.moyen_paiement)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {CPUtils.MOYEN_PAIEMENT.map((type) => (
                      <MenuItem value={type} key={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="infos"
                    multiline
                    maxRows={4}
                    onChange={this.handleChange}
                    value={infos}
                    error={Boolean(errors?.infos)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <CalendarToday />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Mensualités
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                sx={{ paddingBottom: 1 }}
                alignItems="center"
              >
                <Grid item xs={12}>
                  <HeaderTitle label="Logement(s)" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant du loyer"
                    name="mensualite_loyer"
                    onChange={this.handleChangeNumber}
                    value={mensualite_loyer}
                    error={Boolean(errors?.mensualite_loyer)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant des charges"
                    name="mensualite_charges"
                    onChange={this.handleChangeNumber}
                    value={mensualite_charges}
                    error={Boolean(errors?.mensualite_charges)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant des TOM (Taxes d'Ordure Ménagère)"
                    name="mensualite_ordures"
                    onChange={this.handleChangeNumber}
                    value={mensualite_ordures}
                    error={Boolean(errors?.mensualite_ordures)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Garage(s)" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant du loyer"
                    name="mensualite_garage"
                    onChange={this.handleChangeNumber}
                    value={mensualite_garage}
                    error={Boolean(errors?.mensualite_garage)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant des charges"
                    name="mensualite_charges_garage"
                    onChange={this.handleChangeNumber}
                    value={mensualite_charges_garage}
                    error={Boolean(errors?.mensualite_charges_garage)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Frais de gestion" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Montant des frais de gestion"
                    name="mensualite_gestion"
                    value={mensualite_gestion}
                    onChange={this.handleChangeNumber}
                    error={Boolean(errors?.mensualite_gestion)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PercentInput
                    label="Pourcentage des frais de gestion"
                    name="mensualite_gestion_pourcent"
                    onChange={this.handleChangeNumber}
                    value={mensualite_gestion_pourcent}
                    error={Boolean(errors?.mensualite_gestion_pourcent)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {CPUtils.renderFraisGestion(
                            this.state,
                            mensualite_gestion_pourcent,
                            frais_gestion_pourcent_sur_loyers_complet,
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CheckBoxInput
                    label="Frais de gestion offerts (montant et pourcentage)"
                    name="frais_gestion_offert"
                    onChange={this.handleChangeByName}
                    value={frais_gestion_offert}
                    error={Boolean(errors?.frais_gestion_offert)}
                  />
                </Grid>
                {mensualite_gestion_pourcent > 0 && (
                  <Grid item xs={12} lg={6}>
                    <CheckBoxInput
                      label={
                        <>
                          Appliquer le pourcentage des frais de gestion sur
                          le(s) loyer(s) charges comprises
                        </>
                      }
                      name="frais_gestion_pourcent_sur_loyers_complet"
                      onChange={this.handleChangeByName}
                      value={frais_gestion_pourcent_sur_loyers_complet}
                      error={Boolean(
                        errors?.frais_gestion_pourcent_sur_loyers_complet,
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <HeaderTitle label="Autres mensualités" />
                </Grid>
                <MensualiteForm
                  filter="proprietaire"
                  mensualites={mensualites}
                  addRow={this.handleAddArray}
                  updateRow={this.handleChangeArray}
                  removeRow={this.handleRemoveArray}
                  errors={Validation.parse(errors, "mensualites.")}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={loading}
            isEdit={Boolean(id)}
          />
        </Grid>
      </Grid>
    );
  }
}

ContratsProprietairesForm.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ContratsProprietairesForm);
