import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import Button from "components/Button/Button.jsx";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import DatePicker from "components/DatePicker/DatePicker.jsx";

import DateUtils from "Utils/DateUtils";
import OperationUtils from "Utils/OperationUtils";

class EcheancierCPFormPopup extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/config-operations";

    this.state = {
      // NESTED DATA
      operation: null,
      // DATA FORM
      date: new Date(),
      date_ae: new Date(),
      id_op_config: "",
      montant: "",
      debit: "",
      moyen: "",
      id_paiement: "",
      description: "",
      active_pour_solde: false,
      addMirror: false,
      // END DATA FORM
      selectedLabel: null,
      operations: [],
      isDebit: null,
      id: null,
      open: false,
      loading: false,
      errors: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL, { params: { filter: "proprietaire" } })
      .then((result) => {
        const sortedOperations = result.data.sort((a, b) =>
          a.label?.localeCompare(b.label),
        );
        this.setState({ operations: sortedOperations });
      });
  }

  findSelectedLabel = (id) => {
    if (id == null) {
      return null;
    }
    return this.state.operations.find((op) => op.id === id);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeLabel = (event) => {
    let id = event.target.value;
    let selectedLabel = this.findSelectedLabel(id);

    this.setState({
      [event.target.name]: id,
      selectedLabel: selectedLabel,
      addMirror: Boolean(selectedLabel && selectedLabel.imputation_transitive),
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      loading: false,
      errors: false,
    });
  };

  handleSubmit = () => {
    const { selectedLabel } = this.state;

    let dataIn = {
      id: this.state.id,
      id_contrat: this.props.idContrat,
      date: DateUtils.toDBString(this.state.date),
      date_ae: DateUtils.toDBString(this.state.date_ae),
      id_op_config: selectedLabel && selectedLabel.id,
      montant: this.state.montant,
      debit: this.state.isDebit,
      moyen: null,
      id_paiement: null,
      description: this.state.description,
      active_pour_solde: this.state.active_pour_solde,
      addMirror: this.state.addMirror,
    };

    if (selectedLabel && selectedLabel.paiement) {
      dataIn = {
        ...dataIn,
        moyen: this.state.moyen,
        id_paiement: this.state.id_paiement,
      };
    }

    if (dataIn.id) {
      this.handleEdit(dataIn);
    } else {
      this.handleAdd(dataIn);
    }

    this.setState({
      loading: true,
      errors: null,
    });
  };

  handleAdd = (dataIn) => {
    axiosApiBackend
      .post(this.props.dataURL, dataIn)
      .then(() => {
        this.setState({
          open: false,
          loading: false,
        });
        this.props.onSuccess();
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  handleEdit = (dataIn) => {
    axiosApiBackend
      .put(this.props.dataURL + "/" + dataIn.id, dataIn)
      .then(() => {
        this.setState({
          open: false,
          loading: false,
        });
        this.props.onSuccess();
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  handleError = (error) => {
    this.setState({
      loading: false,
      errors: error.response?.data?.errors ?? {},
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  openNew = (isDebit, presetData, operation, overrideData = {}) => {
    let st = {};

    if (presetData === "PAIEMENT") {
      isDebit = true;
      st = {
        id: "",
        date: new Date(),
        date_ae: new Date(),
        id_op_config: 1,
        montant: "",
        debit: "1",
        moyen: "Virement",
        id_paiement: "",
        description: "",
        active_pour_solde: true,
        addMirror: false,
      };
    } else {
      st = {
        id: "",
        date: new Date(),
        date_ae: new Date(),
        id_op_config: "",
        montant: "",
        debit: "1",
        moyen: "",
        id_paiement: "",
        description: "",
        active_pour_solde: false,
        addMirror: false,
      };
    }

    if (operation != null) {
      Object.entries(operation).forEach(([key, value]) => {
        st[key] = value;
      });
    }

    let selectedLabel = this.findSelectedLabel(
      st.id_op_config || overrideData.id_op_config,
    );

    st = {
      ...st,
      ...overrideData,
      open: true,
      selectedLabel: selectedLabel,
      isDebit: isDebit,
    };

    st.date = st.date != null ? new Date(st.date) : null;
    st.date_ae = st.date_ae != null ? new Date(st.date_ae) : null;

    if (overrideData.operation !== undefined) {
      st.addMirror = Boolean(overrideData.operation);
    }

    this.setState(st);
  };

  renderOperationLabel = (op, key) => {
    if (
      op.proprietaire_debit == this.state.isDebit ||
      op.proprietaire_credit == !this.state.isDebit
    ) {
      return (
        <MenuItem key={key} value={op.id}>
          {op.label}
        </MenuItem>
      );
    }
    return null;
  };

  render() {
    const { errors, selectedLabel } = this.state;

    let checkedVal = false;
    let displayCheckbox = false;
    if (this.state.id) {
      // update form
      if (this.state.operation != null && this.state.operation !== "") {
        // has linked operation
        checkedVal = true;
        displayCheckbox = true;
      }
    } else {
      // creation form
      if (selectedLabel && selectedLabel.imputation_transitive) {
        // is a shared label
        checkedVal = this.state.addMirror;
        displayCheckbox = true;
      }
    }

    const isPaiment = Boolean(selectedLabel && selectedLabel.paiement == true);

    return (
      <Dialog open={this.state.open} maxWidth="sm">
        <DialogTitle>
          {this.state.id ? (
            <>Modifier une opération</>
          ) : (
            <>Ajouter une opération</>
          )}
        </DialogTitle>
        <DialogContent>
          {errors && (
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          )}
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12}>
              <CheckBoxInput
                name="active_pour_solde"
                label="Pris en compte dans le calcul du solde"
                onChange={this.handleChangeByName}
                value={this.state.active_pour_solde}
                error={Boolean(errors?.active_pour_solde)}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Date"
                name="date"
                value={this.state.date}
                onChange={this.handleChangeByName}
                error={Boolean(errors?.date)}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Mois concerné par l'opération"
                name="date_ae"
                value={this.state.date_ae}
                onChange={this.handleChangeByName}
                error={Boolean(errors?.date_ae)}
              />
            </Grid>
            <Grid item xs={displayCheckbox ? 6 : 12}>
              <SelectInput
                name="id_op_config"
                label="Label"
                value={this.state.id_op_config}
                onChange={this.handleChangeLabel}
                error={Boolean(errors?.id_op_config)}
                disabled={Boolean(this.state.id)}
              >
                {this.state.operations.map((op, key) =>
                  this.renderOperationLabel(op, key),
                )}
              </SelectInput>
            </Grid>
            {displayCheckbox && (
              <Grid item xs={6}>
                <CheckBoxInput
                  label={
                    (this.state.id ? "Modifier l'" : "Créer une ") +
                    "opération équivalente sur l'échéancier du Contrat Locataire associé"
                  }
                  name="addMirror"
                  value={checkedVal}
                  onChange={this.handleChangeByName}
                  disabled={Boolean(this.state.id)}
                />
              </Grid>
            )}
            {isPaiment && (
              <Grid item xs={12}>
                <Typography>
                  Ce type d&apos;opération est un paiement et correspond à un
                  transfert d&apos;argent depuis ou vers le propriétaire.
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <CurrencyInput
                name="montant"
                label="Montant"
                onChange={this.handleChange}
                value={this.state.montant}
                error={Boolean(errors?.montant)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                au {OperationUtils.renderDebitCredit(this.state.isDebit)}
              </Typography>
            </Grid>
            {isPaiment && (
              <>
                <Grid item xs={6}>
                  <SelectInput
                    name="moyen"
                    label="Moyen de paiement"
                    value={this.state.moyen}
                    onChange={this.handleChange}
                    error={Boolean(errors?.moyen)}
                  >
                    <MenuItem value="">
                      <b>Non renseigné</b>
                    </MenuItem>
                    <MenuItem value="Virement">Virement</MenuItem>
                    <MenuItem value="Chèque">Chèque</MenuItem>
                    <MenuItem value="Espèces">Espèces</MenuItem>
                    <MenuItem value="Prélèvement">Prélèvement</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="id_paiement"
                    label="Identifiant du paiement"
                    onChange={this.handleChange}
                    value={this.state.id_paiement}
                    error={Boolean(errors?.id_paiement)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextInput
                name="description"
                label="Description"
                onChange={this.handleChange}
                value={this.state.description}
                error={Boolean(errors?.description)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="reversed">
            Annuler
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            loading={this.state.loading}
          >
            {this.state.id ? <>Enregistrer</> : <>Ajouter</>}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EcheancierCPFormPopup.propTypes = {
  dataURL: PropTypes.any.isRequired,
  idContrat: PropTypes.any.isRequired,
  typePaiement: PropTypes.any,
  onSuccess: PropTypes.func.isRequired,
};

export default EcheancierCPFormPopup;
