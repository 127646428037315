import React from "react";
import PropTypes from "prop-types";

// @mui/material components

// core components
import LinkCard from "./LinkCard";
import Build from "@mui/icons-material/Build";
import FindInPage from "@mui/icons-material/FindInPage";
import Home from "@mui/icons-material/Home";
import BaseLinkCard from "./BaseLinkCard";
import Render from "Utils/RenderUtils";
import AuditUtils from "Utils/AuditUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import { withUserContext } from "context/UserContext";

class TravauxLinkCard extends BaseLinkCard {
  constructor(props) {
    super(props);
    this.canEditDosserTravaux = props.user.can("edit.dossiertravaux");
    this.CAN_EDIT_BENEF = props.user.can("edit.beneficiaire");
    this.CAN_EDIT_LOGEMENT = props.user.can("edit.logementtravaux");
    this.CAN_EDIT_AUDIT = props.user.can("edit.audit");

    this.state = {};
  }

  isNotNull = (a) => {
    return a != null && a !== "";
  };

  buildBeneficiaireLink = (beneficiaire) => {
    if (beneficiaire == null || !beneficiaire.id) {
      return null;
    }
    let text = (beneficiaire.prenom ?? "") + " " + (beneficiaire.nom ?? "");
    return {
      header: "Bénéficiaire : ",
      to: "/beneficiaires/detail/" + beneficiaire.id,
      text: text.trim() === "" ? "Bénéficiaire" : text,
    };
  };
  buildBeneficiaireFallback = () => {
    if (this.CAN_EDIT_BENEF) {
      return {
        button: true,
        header: "Bénéficiaire : ",
        to: "/beneficiaires/ajouter",
        text: "Créer un Bénéficiaire",
      };
    }
    return null;
  };

  buildLogementLink = (logement) => {
    if (logement == null || !logement.id) {
      return null;
    }
    const detailURL = "/logements-travaux/detail/";
    let text = Render.fullAddress(logement?.adresse);

    const seeAll = this.buildSeeAllData({
      entityList: "logementTravaux",
      mostRecent: logement,
      detailURL: detailURL,
      prefixBadge: "LOG",
      icon: <Home />,
      bodyContent: (lt, isActive) => {
        let textRender = Render.fullAddress(lt?.adresse);

        textRender =
          textRender.trim() === ""
            ? "Adresse non spécifiée"
            : "Adresse: " + textRender;

        return <p style={{ fontWeight: isActive && "bold" }}>{textRender}</p>;
      },
    });

    return {
      header: "Logement : ",
      to: detailURL + logement.id,
      text: text.trim() === "" ? "Logement" : text,
      seeAll: seeAll,
    };
  };
  buildLogementFallback = (beneficiaire_id) => {
    const params = beneficiaire_id ? "?beneficiaire_id=" + beneficiaire_id : "";
    if (this.CAN_EDIT_LOGEMENT) {
      return {
        button: true,
        header: "Logement : ",
        to: "/logements-travaux/ajouter" + params,
        text: "Créer un logement",
      };
    }
    return null;
  };

  buildAuditLink = (audit, header) => {
    if (audit == null || !audit.id) {
      return null;
    }

    const detailURL = "/audits/detail/";
    const seeAll = this.buildSeeAllData({
      entityList: "audit",
      mostRecent: audit,
      detailURL: detailURL,
      prefixBadge: "AD",
      icon: <FindInPage />,
      bodyContent: (ad, isActive) => {
        let startDate = Render.date(ad?.date_demande) ?? "Aucune date";

        return (
          <div style={{ fontWeight: isActive && "bold" }}>
            <p>Date de demande: {startDate}</p>
            <p>Type: {ad.type_audit}</p>
            <p>Statut: {AuditUtils.renderStatut(ad.statut)}</p>
          </div>
        );
      },
    });

    const type = audit.type_audit;
    const statut = AuditUtils.renderStatut(audit.statut);

    return {
      header: header,
      to: detailURL + audit.id,
      text: `AD ${audit.id} - ${type} (${statut})`,
      seeAll: seeAll,
    };
  };

  buildAuditFallback = (logement_id, dossier_travaux_id) => {
    if (this.CAN_EDIT_AUDIT) {
      const toUrl = new URL("http://localhost");
      const params = {
        logement_id: logement_id,
        dossier_travaux_id: dossier_travaux_id,
      };

      Object.keys(params).forEach((param) => {
        if (this.isNotNull(params[param])) {
          toUrl.searchParams.append(param, params[param]);
        }
      });

      return {
        button: true,
        header: "Audits/Diagnostics : ",
        to: "/audits/ajouter" + toUrl.search,
        text: "Créer un audit",
      };
    }
    return null;
  };

  buildDossierTravauxLink = (dossierTravaux) => {
    if (dossierTravaux == null || !dossierTravaux.id) {
      return null;
    }

    const detailURL = "/dossiers-travaux/detail/";
    const seeAll = this.buildSeeAllData({
      entityList: "dossierTravaux",
      mostRecent: dossierTravaux,
      detailURL: detailURL,
      prefixBadge: "DT",
      icon: <Build />,
      bodyContent: (dt, isActive) => {
        let startDate = Render.date(dt?.ouverture) ?? "Aucune date";
        let endDate = Render.date(dt?.date_fin_dossier) ?? "Aucune date";

        return (
          <div style={{ fontWeight: isActive && "bold" }}>
            <p>Ouverture dossier: {startDate}</p>
            <p>Fin de dossier: {endDate}</p>
            <p>Statut: {DossierTravauxUtils.renderStatut(dt.statut)}</p>
          </div>
        );
      },
    });

    const dtStatut = DossierTravauxUtils.renderStatut(dossierTravaux.statut);
    return {
      header: "Dossier Travaux : ",
      to: detailURL + dossierTravaux.id,
      text: "DT" + dossierTravaux.id + (dtStatut ? " (" + dtStatut + ")" : ""),
      seeAll: seeAll,
    };
  };
  buildDossierTravauxFallback = (logement_id) => {
    const params = logement_id ? "?logement_id=" + logement_id : "";
    if (this.canEditDosserTravaux && this.props.logement) {
      return {
        button: true,
        header: "Dossier Travaux : ",
        to: "/dossiers-travaux/ajouter" + params,
        text: "Créer un Dossier Travaux",
      };
    }
    return null;
  };

  buildLinks = (props) => {
    const {
      audit,
      auditApres,
      beneficiaire,
      logement,
      dossierTravaux,
      hideAudit,
      hideAuditApres,
      hideBeneficiaire,
      hideLogement,
      hideDossierTravaux,
    } = props;
    let links = [];

    if (hideBeneficiaire !== true) {
      let bLink =
        this.buildBeneficiaireLink(beneficiaire) ||
        this.buildBeneficiaireFallback();
      if (bLink !== null) {
        links.push(bLink);
      }
    }

    if (hideLogement !== true) {
      let lLink =
        this.buildLogementLink(logement) ||
        this.buildLogementFallback(beneficiaire && beneficiaire.id);
      if (lLink !== null) {
        links.push(lLink);
      }
    }

    if (hideAudit !== true) {
      const auditHeader = hideAuditApres
        ? "Audits/Diagnostics  : "
        : "Audits/Diagnostics (avant) : ";
      let aLink =
        this.buildAuditLink(audit, auditHeader) ||
        this.buildAuditFallback(logement?.id, dossierTravaux?.id);
      if (aLink !== null) {
        links.push(aLink);
      }
    }

    if (hideAuditApres !== true) {
      let aLink = this.buildAuditLink(
        auditApres,
        "Audits/Diagnostics (après) : ",
      );
      if (aLink !== null) {
        links.push(aLink);
      }
    }

    if (hideDossierTravaux !== true) {
      let cpLink =
        this.buildDossierTravauxLink(dossierTravaux) ||
        this.buildDossierTravauxFallback(logement && logement.id);
      if (cpLink !== null) {
        links.push(cpLink);
      }
    }

    return links;
  };

  render() {
    const {
      audit,
      auditApres,
      beneficiaire,
      logement,
      dossierTravaux,
      hideAudit,
      hideAuditApres,
      hideBeneficiaire,
      hideLogement,
      hideDossierTravaux,
    } = this.props;

    const links = this.buildLinks({
      beneficiaire,
      logement,
      audit,
      auditApres,
      dossierTravaux,
      hideBeneficiaire,
      hideLogement,
      hideAudit,
      hideAuditApres,
      hideDossierTravaux,
    });

    return <LinkCard links={links} />;
  }
}

TravauxLinkCard.propTypes = {
  beneficiaire: PropTypes.any,
  logement: PropTypes.any,
  audit: PropTypes.any,
  auditApres: PropTypes.any,
  dossierTravaux: PropTypes.any,
  hideBeneficiaire: PropTypes.bool,
  hideLogement: PropTypes.bool,
  hideAudit: PropTypes.bool,
  hideAuditApres: PropTypes.bool,
  hideDossierTravaux: PropTypes.bool,
};

export default withUserContext(TravauxLinkCard);
