import React from "react";
// import PropTypes from "prop-types";
import planet_love from "assets/img/planet_love.webp";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "components/Image/Image";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardActions from "@mui/material/CardActions";
import Bedtime from "@mui/icons-material/Bedtime";
import axios from "variables/axiosConfigs";

class WaitingServerErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.SECONDS_BETWEEN_CALLS = 30;
    this.SECONDS_START_TIMER = 270; // 4m30s
    this.state = {
      nextAttempt: new Date(),
      nextAttemptTimer: 0,
      loading: false,
      timer: this.SECONDS_START_TIMER,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.autoRetry();
    }, 1000);
    setInterval(() => {
      this.setState((prevState) => ({
        timer: prevState.timer - 1,
        nextAttemptTimer: prevState.nextAttemptTimer - 1,
      }));
    }, 1000);
  }

  autoRetry = () => {
    let nextAttempt = new Date();
    nextAttempt.setSeconds(
      nextAttempt.getSeconds() + this.SECONDS_BETWEEN_CALLS,
    );

    this.setState({
      loading: true,
      nextAttempt: nextAttempt,
      nextAttemptTimer: 0,
    });
    const data = {
      email: "dummy@gmail.com",
      password: "dummy",
    };

    const axiosConfig = axios.create({
      baseURL: window?._env_?.BACKEND_BASE_URL + "/api",
    });
    axiosConfig.defaults.headers.common["Content-Type"] = "application/json";
    axiosConfig.defaults.headers.common["Accept"] = "application/json";
    axiosConfig
      .post("/login", data)
      .then(() => {
        window.location.assign("/");
      })
      .catch((error) => {
        const status = error?.response?.status;
        if (status !== 503) {
          window.location.assign("/");
        }
      })
      .finally(() => {
        let nextTry = Math.max(this.state.nextAttempt - new Date(), 100);
        setTimeout(() => {
          this.autoRetry();
        }, nextTry);
        this.setState({
          loading: false,
          nextAttemptTimer: Math.floor(nextTry / 1000),
        });
      });
  };

  render() {
    const { timer } = this.state;
    const minutes = Math.floor(timer / 60) % 60;
    const secondes = timer % 60;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs />
        <Grid item lg={6} md={8} xs={12}>
          <Card sx={{}}>
            <CardHeader
              avatar={
                <CardAvatar big>
                  <Bedtime />
                </CardAvatar>
              }
              title={<h3>En attente des serveurs</h3>}
            />
            <CardContent>
              <Typography variant="h4">
                HÂPY pense à la planète : nous éteignons les serveurs la nuit et
                le week-end. Veuillez nous accorder quelques minutes pour les
                réveiller. (approx {minutes}m {secondes}s)
              </Typography>
              <Image src={planet_love} alt="planet love gif" />
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                onClick={() => {
                  window.location.assign("/");
                }}
                loading={this.state.loading}
              >
                Rafraichir
              </Button>
            </CardActions>
            {this.state.nextAttemptTimer > 0 && (
              <Typography align="center">
                Prochain essai : {this.state.nextAttemptTimer}s
              </Typography>
            )}
          </Card>
        </Grid>
        <Grid item xs />
      </Grid>
    );
  }
}

WaitingServerErrorPage.propTypes = {};

export default WaitingServerErrorPage;
