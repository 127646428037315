import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Delete from "@mui/icons-material/Delete";
import Home from "@mui/icons-material/Home";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";
import SelectSearch from "components/CustomSelect/SelectSearch";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import Render from "Utils/RenderUtils";

class LogementsCal extends Component {
  constructor(props) {
    super(props);

    this.BACKEND_URL = "/cals";
    this.FRONT_URL = "/tiers/cals";
    this.SEARCH_BAR_SERVICE = new SearchBarService(
      this.FRONT_URL + "/logements",
    );
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;

    this.state = {
      openModal: false,
      logement_id: "",
      id: this.props.calId,
      data: [],
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "",
          field: "actions",
          filter: false,
          editable: false,
          sortable: false,
          minWidth: 50,
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            buttons: [
              {
                label: <Delete />,
                color: "error",
                square: true,
                onClick: () => {
                  this.confirmDeleteAlert(params);
                },
              },
            ],
          }),
        },
        {
          headerName: "#",
          field: "id",
          sort: "asc",
          cellDataType: "number",
        },
        { headerName: "Convention", field: "convention.value" },
        {
          headerName: "Type conventionnement",
          field: "conventionnement_actif.type_conventionnement.value",
        },
        {
          headerName: "Mandat",
          field: "contratproprietaire.type",
        },
        {
          headerName: "CP",
          field: "contratproprietaire.id",
          cellDataType: "number",
        },
        {
          headerName: "Typologie",
          field: "typologie.value",
        },
        {
          headerName: "Adresse",
          valueGetter: (params) => Render.fullAddress(params.data.adresse),
        },
        {
          headerName: "N° ANAH",
          field: "conventionnement_actif.numero_conventionnement",
        },
        {
          headerName: "Surface",
          field: "surface_principale",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Terrasse",
          field: "surface_terrasse",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Balcon",
          field: "surface_balcon",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Cave",
          field: "surface_cave",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.carre(params.value),
        },
        {
          headerName: "Loyer",
          field: "contratproprietaire.mensualite_loyer",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Charges",
          field: "contratproprietaire.mensualite_charges",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        { headerName: "Détail charges", field: "detail_charges" },
        {
          headerName: "TOM",
          field: "contratproprietaire.mensualite_ordures",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Dépôt de garantie",
          field: "contratproprietaire.depot_de_garantie",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        { headerName: "Description du logement", field: "description" },
        {
          headerName: "Date disponibilité",
          field: "date_disponibilite",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "PMR",
          field: "acces_pmr",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.acces_pmr),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Ascenceur",
          field: "ascenseur",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.ascenseur),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        { headerName: "Étage", field: "adresse.etage" },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {}

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  toggleOverlay = (show) => {
    if (this.gridApi !== null) {
      show ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  };

  goToView = (event) => {
    this.props.history.push("/tiers/logements/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleAdd = () => {
    if (!this.state.logement_id) {
      this.setState({
        errors: { logement_id: true },
      });
      return;
    }

    this.setState({
      errors: null,
      loading: true,
    });
    this.toggleOverlay(true);
    this.clearAlert();
    const { cal } = this.props;
    const logementIds = cal.logements.map((c) => c.id);
    logementIds.push(this.state.logement_id);
    const data = {
      ...cal,
      logements: logementIds,
    };

    // add
    axiosApiBackend
      .put(this.BACKEND_URL + "/" + data.id, data)
      .then(() => {
        this.props.onAdd();
        this.toggleOverlay(false);
        this.setState({
          loading: false,
          openModal: false,
          logement_id: "",
        });
      })
      .catch(() => {
        this.toggleOverlay(false);
        this.setState({
          loading: false,
          alert: (
            <AlertDialog
              title="Erreur d'ajout"
              content="Erreur inconnue"
              onCancel={this.clearAlert}
              cancelLabel="Ok"
              cancelColor="info"
            />
          ),
        });
      });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  confirmDeleteAlert = (params) => {
    this.setState({
      alert: (
        <AlertDialog
          title={"Retirer LP" + params.data.id + " de la CAL ?"}
          onConfirm={() => this.handleDelete(params)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
          fullWidth
        />
      ),
    });
  };

  handleDelete = (params) => {
    this.setState({
      loading: true,
    });
    this.toggleOverlay(true);
    this.clearAlert();
    const { cal } = this.props;
    const logementIds = cal.logements
      .map((c) => c.id)
      .filter((v) => v !== params.data.id);
    const data = {
      ...cal,
      logements: logementIds,
    };

    // delete
    axiosApiBackend
      .put(this.BACKEND_URL + "/" + data.id, data)
      .then(() => {
        this.props.onDelete();
        this.toggleOverlay(false);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.toggleOverlay(false);
        this.setState({
          loading: false,
          alert: (
            <AlertDialog
              title="Erreur de suppression"
              content="Erreur inconnue"
              onCancel={this.clearAlert}
              cancelLabel="Ok"
              cancelColor="info"
            />
          ),
        });
      });
  };

  render() {
    const { errors } = this.state;
    const { logements } = this.props;
    const logementIds = (logements ?? []).map((c) => c.id);

    return (
      <>
        <Card>
          <CardHeader
            avatar={
              <CardAvatar>
                <Home />
              </CardAvatar>
            }
            title={
              <Typography variant="h6" component="h5">
                Logements
              </Typography>
            }
          />
          <CardContent>
            <Grid container columnSpacing={2}>
              <Grid item>
                <SearchBar
                  label="Rechercher"
                  name="quickFilterText"
                  value={this.state.quickFilterText}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  resetSearchValue={this.resetSearchValue}
                />
              </Grid>
              <Grid item xs={12} className="ag-theme-material">
                <AgGridReact
                  overlayNoRowsTemplate="Aucune donnée à afficher."
                  enableCellTextSelection={true}
                  animateRows={true}
                  onGridReady={this.onGridReady}
                  rowSelection="multiple"
                  enableFilter={false}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={logements}
                  quickFilterText={this.state.quickFilterText}
                  pagination={true}
                  onRowDoubleClicked={this.goToView}
                  domLayout="autoHeight"
                  paginationPageSize="30"
                  onPaginationChanged={this.autoSizeAll}
                  localeText={AG_GRID_LOCALE}
                  onColumnMoved={this.onColumnMoved}
                  suppressDragLeaveHidesColumns={true}
                />
              </Grid>
              <Grid item>
                <Button onClick={this.toggleModal}>Ajouter un logement</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <AlertDialog
          fullWidth
          open={this.state.openModal}
          title="Ajouter un logement"
          loading={this.state.loading}
          onConfirm={this.handleAdd}
          confirmLabel="Ajouter"
          confirmColor="success"
          onCancel={this.toggleModal}
          cancelLabel="Annuler"
          cancelColor="primary"
        >
          <SelectSearch
            name="logement_id"
            label="Choisir un Logement"
            apiUrl="/logements"
            valuesToHide={logementIds}
            value={this.state.logement_id}
            onChange={(name, value) => this.setState({ [name]: value })}
            buildOptionLabel={(data) => {
              let cp = data.contratproprietaire?.id
                ? " CP" + data.contratproprietaire?.id
                : "";
              let pp =
                (data.proprietaire?.nom ?? "") +
                " " +
                (data.proprietaire?.prenom ?? "");
              pp = pp.trim();
              pp = pp ? " - " + pp : "";

              return Render.fullAddress(data) + " LP" + data.id + cp + pp;
            }}
            shrink
            error={Boolean(errors?.logement_id)}
          />
        </AlertDialog>
        {this.state.alert}
      </>
    );
  }
}

LogementsCal.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  calId: PropTypes.any,
  cal: PropTypes.any,
  logements: PropTypes.any,
  onDelete: PropTypes.any,
  onAdd: PropTypes.any,
};

export default withRouter(LogementsCal);
