import styled from "@mui/system/styled";
import MuiTableHead from "@mui/material/TableHead";

const TableHead = styled(MuiTableHead)((/*{ theme }*/) => ({
  "& .MuiTableRow-root": {
    backgroundColor: "#ecf0f3",
  },
}));

export default TableHead;
