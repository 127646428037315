import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CopyAll from "@mui/icons-material/CopyAll";
import Key from "@mui/icons-material/Key";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import TextInput from "components/CustomInput/TextInput";
import FieldView from "components/FieldView/FieldView";
import PaperList from "components/PaperList/PaperList";

import Render from "Utils/RenderUtils";

class UserTokens extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tokens: [],
      accessToken: "",
      token_name: null,
      token_name_error: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData = () => {
    axiosApiBackend
      .get(`/user/tokens`)
      .then((result) => {
        this.setState({
          tokens: result.data ?? [],
        });
      })
      .catch(() => {});
  };

  handleAddToken = () => {
    this.setState({
      token_name_error: "",
      loading: true,
    });
    const data = {
      name: this.state.token_name,
    };
    axiosApiBackend
      .post(`/user/tokens`, data)
      .then((result) => {
        this.setState({
          token_name: "",
          accessToken: result.data.accessToken,
          loading: false,
        });
        this.loadAsyncData();
      })
      .catch((err) => {
        this.setState({
          token_name_error: err?.response?.data?.message ?? "Erreur Serveur",
          loading: false,
        });
      });
  };

  handleDeleteToken = (token) => {
    this.setState({
      loading: true,
    });
    axiosApiBackend
      .delete(`/user/tokens/${token.name}`)
      .then(() => {
        this.setState({
          accessToken: "",
          loading: false,
        });
        this.loadAsyncData();
        this.clearAlert();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  onClickDeleteToken = (token) => {
    this.setState({
      alert: (
        <AlertDialog
          title={`Voulez-vous révoquer le token ${token.name} ?`}
          onConfirm={() => this.handleDeleteToken(token)}
          confirmLabel="Révoquer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Card>
        <CardHeader
          avatar={
            <CardAvatar>
              <Key />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              3CX tokens
            </Typography>
          }
        />
        <CardContent>
          {this.state.accessToken && (
            <Alert sx={{ mb: 1 }} severity="warning">
              Veillez à copier votre token. Vous ne pourrez plus le voir !{" "}
              <Button
                square
                onClick={() => {
                  navigator.clipboard.writeText(this.state.accessToken);
                }}
              >
                <CopyAll />
              </Button>
              <br />
              <Typography sx={{ wordBreak: "break-all" }}>
                {this.state.accessToken}
              </Typography>
            </Alert>
          )}
          <PaperList
            datas={this.state.tokens}
            buildBody={(token) => (
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={9}>
                  <FieldView label="Nom" value={token.name} />
                  <FieldView
                    label="Créé le"
                    value={Render.dateTime(token.created_at)}
                  />
                  <FieldView
                    label="Expire le"
                    value={Render.dateTime(token.expires_at)}
                  />
                  {Boolean(token.revoked) && (
                    <FieldView label="Révoqué" value="Oui" />
                  )}
                </Grid>
                <Grid item xs={3} textAlign="right">
                  {!token.revoked && (
                    <Button
                      color="error"
                      onClick={() => this.onClickDeleteToken(token)}
                    >
                      Révoquer
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          />
          <Grid
            container
            columnSpacing={2}
            spacing={2}
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <Grid item xs={12} md={6}>
              <TextInput
                label="Nom du token"
                name="token_name"
                value={this.state.token_name}
                onChange={this.handleChange}
                error={Boolean(this.state.token_name_error)}
                helperText={this.state.token_name_error}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                color="success"
                size="small"
                onClick={this.handleAddToken}
              >
                Ajouter un token
              </Button>
            </Grid>
          </Grid>
          {this.state.alert}
        </CardContent>
      </Card>
    );
  }
}

UserTokens.propTypes = {};

export default UserTokens;
