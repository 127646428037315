import React, { Component } from "react";
import PropTypes from "prop-types";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import MenuBottom from "components/MenuBottom/MenuBottom";
import Button from "components/Button/Button.jsx";

class FormSubmitButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onCancel, isEdit, ...rest } = this.props;

    return (
      <>
        <MenuBottom>
          <Button
            sx={{ mr: 1 }}
            color="success"
            size="small"
            startIcon={<SaveIcon />}
            {...rest}
          >
            {isEdit ? <> Enregistrer</> : <>Ajouter</>}
          </Button>
          <Button
            size="small"
            color="error"
            onClick={onCancel}
            startIcon={<CancelIcon />}
          >
            Annuler
          </Button>
        </MenuBottom>
      </>
    );
  }
}

FormSubmitButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default FormSubmitButtons;
