import { add, format } from "date-fns";
import fr from "date-fns/locale/fr";

export default class DateUtils {
  /**
   * Add number of months to the date provided
   *
   * @param {Date} date Starting Date
   * @param {*} months number of months to add
   */
  static addMonths = (date, months) => {
    if (date == null) {
      return date;
    }
    return add(date, {
      months: +months,
    });
  };

  /**
   * Subtract number of months from the date provided
   *
   * @param {Date} date Starting Date
   * @param {*} months number of months to subtract
   */
  static subtractMonths = (date, months) => {
    if (date == null) {
      return date;
    }
    return add(date, {
      months: -months,
    });
  };

  /**
   * Add number of years to the date provided
   *
   * @param {Date} date Starting Date
   * @param {*} years number of years to add
   */
  static addYears = (date, years) => {
    if (date == null) {
      return date;
    }
    return add(date, {
      years: +years,
    });
  };

  /**
   * Subtract number of years from the date provided
   *
   * @param {Date} date Starting Date
   * @param {*} years number of years to subtract
   */
  static subtractYears = (date, years) => {
    if (date == null) {
      return date;
    }
    return add(date, {
      years: -years,
    });
  };

  /**
   * Formats date to `yyyy-MM-dd` or `yyyy-MM-dd HH:mm:ss`
   *
   * @param {Date} date Starting Date
   * @param {boolean} hasTime convert with time
   */
  static toDBString = (date, hasTime = false) => {
    if (date == null || isNaN(date)) {
      return date;
    }
    if (hasTime) {
      return format(date, "yyyy-MM-dd HH:mm:ss", { locale: fr });
    }
    return format(date, "yyyy-MM-dd", { locale: fr });
  };

  /**
   * Get last date of the month
   *
   * @param {Date} date Input Date
   */
  static lastDayOfMonth = (date) => {
    if (date == null) {
      return null;
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  };
}
