import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import DateUtils from "Utils/DateUtils";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material

// core components
import SearchBarService from "services/SearchBarService";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import AgGridUtils from "Utils/AgGridUtils";
import AbortRequest from "services/AbortRequest";
import Render from "Utils/RenderUtils";
import { withUserContext } from "context/UserContext";
import OperationUtils from "Utils/OperationUtils";
import DetailsPopover from "components/Echeancier/DetailsPopover";

class EcheancierComptable extends Component {
  constructor(props) {
    super(props);
    // this.USER = props.user;
    // this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.ABORT_REQUEST = new AbortRequest();
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    const now = new Date();

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      startDate: DateUtils.addMonths(now, -1),
      endDate: now,
      data: null,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
      getRowStyle: (params) => {
        return {
          ...(params.data.indicatif && {
            backgroundColor: OperationUtils.INDICATIF_COLOR,
          }),
        };
      },
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          cellDataType: "number",
          sort: "desc",
          sortIndex: 1,
        },
        {
          headerName: "Label",
          field: "config.label",
        },
        {
          headerName: "Date",
          field: "date",
          cellDataType: "date",
          valueFormatter: (params) => Render.date(params.value),
          sort: "desc",
          sortIndex: 0,
        },
        {
          headerName: "Débit",
          field: "montant_debit",
          cellDataType: "number",
          type: "numericColumn",
          valueGetter: (params) => OperationUtils.renderDebit(params.data),
          valueFormatter: (params) =>
            params.value == null ? null : Number(params.value).toFixed(2),
        },
        {
          headerName: "Crédit",
          field: "montant_debit",
          cellDataType: "number",
          type: "numericColumn",
          valueGetter: (params) => OperationUtils.renderCredit(params.data),
          valueFormatter: (params) =>
            params.value == null ? null : Number(params.value).toFixed(2),
        },
        {
          headerName: "Solde",
          field: "solde",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) =>
            params.value == null ? null : Number(params.value).toFixed(2),
        },
        {
          headerName: "Description",
          field: "description",
        },
        {
          headerName: "Infos",
          field: "popover",
          cellRenderer: (params) => (
            <DetailsPopover
              operation={params.data}
              entityLink={this.props.entityLink}
              entityName={this.props.entityName}
            />
          ),
        },
      ]),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const params = {
      vueComptable: 1,
      contractId: this.props.contractId,
      //   startDate: DateUtils.toDBString(this.state.startDate),
      endDate: DateUtils.toDBString(this.state.endDate),
    };
    axiosApiBackend
      .get(this.props.dataURL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: params,
      })
      .then((result) => {
        const sortedData = this.addSolde(result.data).reverse();
        this.setState({ data: sortedData }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      })
      .catch(() => null);
  }

  filterOnDate = (op1, op2) => {
    if (op1 > op2) {
      return op1;
    }
    if (op1 < op2) {
      return op2;
    }
    return op1.id - op2.id;
  };

  addSolde = (ops) => {
    let solde = 0;
    ops = ops.map((op) => {
      if (!op.indicatif) {
        solde += OperationUtils.getMontant(op);

        op.solde = solde;
      }
      return op;
    });
    return ops;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  render() {
    return (
      <Grid
        container
        style={{
          minHeight: "300px",
        }}
      >
        <Grid item xs={12} className="ag-theme-material">
          <AgGridReact
            overlayNoRowsTemplate="Aucune donnée à afficher."
            enableCellTextSelection={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            rowSelection="multiple"
            enableFilter={false}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.state.data}
            // quickFilterText={this.state.quickFilterText}
            // pagination={true}
            getRowStyle={this.state.getRowStyle}
            domLayout="autoHeight"
            paginationPageSize={AgGridUtils.getPageSize(
              window.innerHeight,
              this.filtersRef,
            )}
            onPaginationChanged={this.autoSizeAll}
            localeText={AG_GRID_LOCALE}
            onColumnMoved={this.onColumnMoved}
            suppressDragLeaveHidesColumns={true}
          />
        </Grid>
      </Grid>
    );
  }
}

EcheancierComptable.propTypes = {
  dataURL: PropTypes.any.isRequired,
  contractId: PropTypes.any.isRequired,
  typePaiement: PropTypes.any,
  onSoldeUpdate: PropTypes.func.isRequired,
  entityLink: PropTypes.any,
  entityName: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EcheancierComptable);
