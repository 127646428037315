import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import Render from "Utils/RenderUtils";
import CustomTimeline from "components/Timeline/CustomTimeline";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";

class ConventionnementLogementView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type_conventionnement: null,
    };
  }

  componentDidMount() {}

  onReverseDate = (a, b) => {
    if (
      !a.date_debut_conventionnement ||
      a.date_debut_conventionnement > b.date_debut_conventionnement
    )
      return -1;
    if (
      !b.date_debut_conventionnement ||
      a.date_debut_conventionnement < b.date_debut_conventionnement
    )
      return 1;
    return 0;
  };

  render() {
    const { conventionnements } = this.props;
    conventionnements.sort(this.onReverseDate);

    return (
      <>
        {conventionnements.length <= 0 ? (
          <Typography>
            <strong>Aucun conventionnement n&apos;a été saisie.</strong>
          </Typography>
        ) : (
          <CustomTimeline
            items={conventionnements}
            itemShouldCollapse={(item) => !item.actif}
            renderDot={(item) => (
              <TimelineDot color={item.actif ? "success" : "primary"} />
            )}
            renderBody={(item) => {
              return (
                <>
                  <Typography>
                    <strong>Date de début : </strong>
                    {Render.date(item.date_debut_conventionnement)}
                  </Typography>
                  <Typography>
                    <strong>Durée : </strong>
                    {item.duree_conventionnement}
                  </Typography>
                  <Typography>
                    <strong>Date de fin : </strong>
                    {Render.date(item.date_fin_conventionnement)}
                  </Typography>
                  <Typography>
                    <strong>N° ANAH : </strong>
                    {item.numero_conventionnement}
                  </Typography>
                  <Typography>
                    <strong>Conventionnement : </strong>
                    {item.conventionnement?.value}
                  </Typography>
                  <Typography>
                    <strong>Type : </strong>
                    {item.type_conventionnement?.value}
                  </Typography>
                  <Typography>
                    <strong>Loyer max : </strong>
                    {Render.euro(item.conventionnement_loyer_max)}
                  </Typography>
                  <Typography>
                    <strong>Date d&apos;envoi du dossier : </strong>
                    {Render.date(item.date_envoi_dossier)}
                  </Typography>
                  <CheckBoxView label="Travaux" value={item.travaux} />
                  <CheckBoxView label="Actif" value={item.actif} />
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

ConventionnementLogementView.propTypes = {
  conventionnements: PropTypes.array.isRequired,
};

export default ConventionnementLogementView;
