import React from "react";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, placement = "right", ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement={placement} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

export default LightTooltip;
