import React, { Component } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DisplaySettings from "@mui/icons-material/DisplaySettings";

import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import SettingsFormContainer from "components/SettingsFormContainer/SettingsFormContainer";

class UiCustomizationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // DATA FORM
      dep_code: "",
      google_maps_key: "",
      affichage_champ_dt: null,
      // END DATA FORM
      errors: null,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeAffichage = (name, value) => {
    this.setState((prevState) => ({
      affichage_champ_dt: {
        ...prevState.affichage_champ_dt,
        [name]: value,
      },
    }));
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { errors } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <SettingsFormContainer
            icon={<DisplaySettings />}
            title="Modifier les Customisations UI"
            backendUrl="/settings/ui-customization"
            formSetData={(data) => {
              this.setState({
                errors: null,
                dep_code: data.dep_code,
                google_maps_key: data.google_maps_key,
                affichage_champ_dt: data.affichage_champ_dt,
              });
            }}
            formGetData={() => ({
              dep_code: this.state.dep_code,
              google_maps_key: this.state.google_maps_key,
              affichage_champ_dt: this.state.affichage_champ_dt,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            formContent={
              <Grid container columnSpacing={2}>
                {errors && (
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h4" color="error">
                      {Object.entries(errors).length === 0 ? (
                        <>Une erreur est survenue.</>
                      ) : (
                        <>
                          Merci de corriger les champs en rouge du formulaire.
                        </>
                      )}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <HeaderTitle label="Configuration des Paramètres de Localisation" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Département"
                    name="dep_code"
                    apiUrl="/departements"
                    onChange={this.handleChangeByName}
                    value={this.state.dep_code}
                    buildOptionValue={(data) => data.code}
                    buildOptionLabel={(data) => data.code + " " + data.nom}
                    shrink
                    error={Boolean(errors?.dep_code)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label="Clé Google Maps"
                    name="google_maps_key"
                    onChange={this.handleChange}
                    value={this.state.google_maps_key}
                    error={Boolean(errors?.google_maps_key)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeaderTitle label="Gestion de l'Affichage des Champs du Dossier Travaux" />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxInput
                    label="Statut personnalisé"
                    name="statut_personnalise"
                    onChange={this.handleChangeAffichage}
                    value={this.state.affichage_champ_dt?.statut_personnalise}
                    error={Boolean(errors?.affichage_champ_dt)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxInput
                    label="Référence personnalisée"
                    name="reference_personnalise"
                    onChange={this.handleChangeAffichage}
                    value={
                      this.state.affichage_champ_dt?.reference_personnalise
                    }
                    error={Boolean(errors?.affichage_champ_dt)}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    );
  }
}

UiCustomizationSettings.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default UiCustomizationSettings;
