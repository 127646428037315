export const AG_GRID_LOCALE = {
  // Set Filter
  selectAll: "(Sélectionner tout)",
  selectAllSearchResults: "(Sélectionner tous les résultats de la recherche)",
  searchOoo: "Rechercher...",
  blanks: "(Vides)",
  noMatches: "Aucune correspondance",

  // Number Filter & Text Filter
  filterOoo: "Filtrer...",
  equals: "Égal à",
  notEqual: "Différent de",
  blank: "Vide",
  notBlank: "Non vide",
  empty: "Choisir un",

  // Number Filter
  lessThan: "Moins que",
  greaterThan: "Plus que",
  lessThanOrEqual: "Moins ou égal",
  greaterThanOrEqual: "Plus ou égal",
  inRange: "Dans la plage",
  inRangeStart: "de",
  inRangeEnd: "à",

  // Text Filter
  contains: "Contient",
  notContains: "Ne contient pas",
  startsWith: "Commence par",
  endsWith: "Finit par",

  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",

  // Filter Conditions
  andCondition: "ET",
  orCondition: "OU",

  // Filter Buttons
  applyFilter: "Appliquer",
  resetFilter: "Réinitialiser",
  clearFilter: "Effacer",
  cancelFilter: "Annuler",

  // Filter Titles
  textFilter: "Filtre de texte",
  numberFilter: "Filtre numérique",
  dateFilter: "Filtre de date",
  setFilter: "Définir le filtre",

  // Group Column Filter
  groupFilterSelect: "Sélectionner le champ:",

  // Side Bar
  columns: "Colonnes",
  filters: "Filtres",

  // columns tool panel
  pivotMode: "Mode pivot",
  groups: "Groupes de lignes",
  rowGroupColumnsEmptyMessage: "Glisser ici pour définir les groupes de lignes",
  values: "Valeurs",
  valueColumnsEmptyMessage: "Glisser ici pour agréger",
  pivots: "Étiquettes de colonne",
  pivotColumnsEmptyMessage:
    "Glisser ici pour définir les étiquettes de colonne",

  // Header of the Default Group Column
  group: "Groupe",

  // Row Drag
  rowDragRow: "ligne",
  rowDragRows: "lignes",

  // Other
  loadingOoo: "Chargement...",
  loadingError: "ERR",
  noRowsToShow: "Aucune ligne à afficher",
  enabled: "Activé",

  // Menu
  pinColumn: "Épingler la colonne",
  pinLeft: "Épingler à gauche",
  pinRight: "Épingler à droite",
  noPin: "Sans épingler",
  valueAggregation: "Agrégation de valeurs",
  noAggregation: "Aucun",
  autosizeThiscolumn: "Redimensionner automatiquement cette colonne",
  autosizeAllColumns: "Redimensionner automatiquement toutes les colonnes",
  groupBy: "Grouper par",
  ungroupBy: "Dégrouper par",
  addToValues: "Ajouter ${variable} aux valeurs",
  removeFromValues: "Retirer ${variable} des valeurs",
  addToLabels: "Ajouter ${variable} aux étiquettes",
  removeFromLabels: "Retirer ${variable} des étiquettes",
  resetColumns: "Réinitialiser les colonnes",
  expandAll: "Développer tous les groupes de lignes",
  collapseAll: "Fermer tous les groupes de lignes",
  copy: "Copier",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "Copier avec des en-têtes",
  copyWithGroupHeaders: "Copier avec des en-têtes de groupe",
  cut: "Couper",
  paste: "Coller",
  ctrlV: "Ctrl+V",
  export: "Exporter",
  csvExport: "Exporter en CSV",
  excelExport: "Exporter en Excel",

  // Enterprise Menu Aggregation and Status Bar
  sum: "Somme",
  first: "Premier",
  last: "Dernier",
  min: "Min",
  max: "Max",
  none: "Aucun",
  count: "Compte",
  avg: "Moyenne",
  filteredRows: "Filtré",
  selectedRows: "Sélectionné",
  totalRows: "Total de lignes",
  totalAndFilteredRows: "Lignes",
  more: "Plus",
  to: "à",
  of: "de",
  page: "Page",
  pageLastRowUnknown: "?",
  nextPage: "Page suivante",
  lastPage: "Dernière page",
  firstPage: "Première page",
  previousPage: "Page précédente",

  // Pivoting
  pivotColumnGroupTotals: "Total",

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Graphique pivot & Mode pivot",
  pivotChart: "Graphique pivot",
  chartRange: "Plage du graphique",

  columnChart: "Colonne",
  groupedColumn: "Groupé",
  stackedColumn: "Empilé",
  normalizedColumn: "100% Empilé",

  barChart: "Barre",
  groupedBar: "Groupé",
  stackedBar: "Empilé",
  normalizedBar: "100% Empilé",

  pieChart: "Camembert",
  pie: "Camembert",
  doughnut: "Donut",

  line: "Ligne",

  xyChart: "X Y (Dispersion)",
  scatter: "Dispersion",
  bubble: "Bulle",

  areaChart: "Zone",
  area: "Zone",
  stackedArea: "Empilé",
  normalizedArea: "100% Empilé",

  histogramChart: "Histogramme",
  histogramFrequency: "Fréquence",

  combinationChart: "Combinaison",
  columnLineCombo: "Colonne & Ligne",
  AreaColumnCombo: "Zone & Colonne",

  // Charts
  pivotChartTitle: "Graphique pivot",
  rangeChartTitle: "Graphique de plage",
  settings: "Réglages",
  data: "Données",
  format: "Format",
  categories: "Catégories",
  defaultCategory: "(Aucun)",
  series: "Séries",
  xyValues: "Valeurs X Y",
  paired: "Mode apparié",
  axis: "Axe",
  navigator: "Navigateur",
  color: "Couleur",
  thickness: "Épaisseur",
  xType: "Type X",
  automatic: "Automatique",
  category: "Catégorie",
  number: "Nombre",
  time: "Temps",
  autoRotate: "Rotation automatique",
  xRotation: "Rotation X",
  yRotation: "Rotation Y",
  ticks: "Graduations",
  width: "Largeur",
  height: "Hauteur",
  length: "Longueur",
  padding: "Remplissage",
  spacing: "Espacement",
  chart: "Graphique",
  title: "Titre",
  titlePlaceholder: "Titre du graphique - double-cliquez pour éditer",
  background: "Arrière-plan",
  font: "Police",
  top: "Haut",
  right: "Droite",
  bottom: "Bas",
  left: "Gauche",
  labels: "Étiquettes",
  size: "Taille",
  minSize: "Taille minimale",
  maxSize: "Taille maximale",
  legend: "Légende",
  position: "Position",
  markerSize: "Taille du marqueur",
  markerStroke: "Trait du marqueur",
  markerPadding: "Remplissage du marqueur",
  itemSpacing: "Espacement des éléments",
  itemPaddingX: "Remplissage des éléments X",
  itemPaddingY: "Remplissage des éléments Y",
  layoutHorizontalSpacing: "Espacement horizontal",
  layoutVerticalSpacing: "Espacement vertical",
  strokeWidth: "Largeur du trait",
  lineDash: "Trait de ligne",
  offset: "Décalage",
  offsets: "Décalages",
  tooltips: "Infobulles",
  callout: "Renvoi",
  markers: "Marqueurs",
  shadow: "Ombre",
  blur: "Flou",
  xOffset: "Décalage X",
  yOffset: "Décalage Y",
  lineWidth: "Largeur de ligne",
  normal: "Normal",
  bold: "Gras",
  italic: "Italique",
  boldItalic: "Gras Italique",
  predefined: "Prédéfini",
  fillOpacity: "Opacité du remplissage",
  strokeOpacity: "Opacité de la ligne",
  histogramBinCount: "Nombre de bacs",
  columnGroup: "Colonne",
  barGroup: "Barre",
  pieGroup: "Camembert",
  lineGroup: "Ligne",
  scatterGroup: "X Y (Dispersion)",
  areaGroup: "Zone",
  histogramGroup: "Histogramme",
  combinationGroup: "Combinaison",
  groupedColumnTooltip: "Groupé",
  stackedColumnTooltip: "Empilé",
  normalizedColumnTooltip: "100% Empilé",
  groupedBarTooltip: "Groupé",
  stackedBarTooltip: "Empilé",
  normalizedBarTooltip: "100% Empilé",
  pieTooltip: "Camembert",
  doughnutTooltip: "Donut",
  lineTooltip: "Ligne",
  groupedAreaTooltip: "Zone",
  stackedAreaTooltip: "Empilé",
  normalizedAreaTooltip: "100% Empilé",
  scatterTooltip: "Dispersion",
  bubbleTooltip: "Bulle",
  histogramTooltip: "Histogramme",
  columnLineComboTooltip: "Colonne & Ligne",
  areaColumnComboTooltip: "Zone & Colonne",
  customComboTooltip: "Combinaison personnalisée",
  noDataToChart: "Aucune donnée disponible pour être tracée.",
  pivotChartRequiresPivotMode:
    "Le graphique pivot nécessite le mode pivot activé.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Lié à la grille",
  chartUnlinkToolbarTooltip: "Détaché de la grille",
  chartDownloadToolbarTooltip: "Télécharger le graphique",
  seriesChartType: "Type de graphique de série",
  seriesType: "Type de série",
  secondaryAxis: "Axe secondaire",

  // ARIA
  ariaChecked: "coché",
  ariaColumn: "Colonne",
  ariaColumnGroup: "Groupe de colonnes",
  ariaColumnList: "Liste des colonnes",
  ariaColumnSelectAll: "Basculer la sélection de toutes les colonnes",
  ariaDateFilterInput: "Entrée du filtre de date",
  ariaDefaultListName: "Liste",
  ariaFilterColumnsInput: "Entrée du filtre de colonnes",
  ariaFilterFromValue: "Filtrer à partir de la valeur",
  ariaFilterInput: "Entrée du filtre",
  ariaFilterList: "Liste de filtre",
  ariaFilterToValue: "Filtrer jusqu'à la valeur",
  ariaFilterValue: "Valeur du filtre",
  ariaFilterMenuOpen: "Ouvrir le menu de filtre",
  ariaFilteringOperator: "Opérateur de filtrage",
  ariaHidden: "caché",
  ariaIndeterminate: "indéterminé",
  ariaInputEditor: "Éditeur d'entrée",
  ariaMenuColumn: "Appuyez sur CTRL ENTER pour ouvrir le menu de la colonne.",
  ariaRowDeselect: "Appuyez sur ESPACE pour désélectionner cette ligne",
  ariaRowSelectAll:
    "Appuyez sur Espace pour basculer la sélection de toutes les lignes",
  ariaRowToggleSelection:
    "Appuyez sur Espace pour basculer la sélection de la ligne",
  ariaRowSelect: "Appuyez sur ESPACE pour sélectionner cette ligne",
  ariaSearch: "Recherche",
  ariaSortableColumn: "Appuyez sur ENTER pour trier",
  ariaToggleVisibility: "Appuyez sur ESPACE pour basculer la visibilité",
  ariaToggleCellValue:
    "Appuyez sur ESPACE pour basculer la valeur de la cellule",
  ariaUnchecked: "non coché",
  ariaVisible: "visible",
  ariaSearchFilterValues: "Rechercher des valeurs de filtre",

  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: "Groupes de lignes",
  ariaValuesDropZonePanelLabel: "Valeurs",
  ariaPivotDropZonePanelLabel: "Étiquettes de colonne",
  ariaDropZoneColumnComponentDescription: "Appuyez sur SUPPR pour supprimer",
  ariaDropZoneColumnValueItemDescription:
    "Appuyez sur ENTER pour changer le type d'agrégation",
  ariaDropZoneColumnGroupItemDescription: "Appuyez sur ENTER pour trier",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " de ",
  ariaDropZoneColumnComponentSortAscending: "croissant",
  ariaDropZoneColumnComponentSortDescending: "décroissant",

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: "Menu de la colonne",
  ariaLabelCellEditor: "Éditeur de cellule",
  ariaLabelDialog: "Dialogue",
  ariaLabelSelectField: "Sélectionner le champ",
  ariaLabelTooltip: "Infobulle",
  ariaLabelContextMenu: "Menu contextuel",
  ariaLabelSubMenu: "Sous-menu",
  ariaLabelAggregationFunction: "Fonction d'agrégation",

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: " ",
  decimalSeparator: ",",

  // Data types
  true: "Vrai",
  false: "Faux",
  invalidDate: "Date invalide",
  invalidNumber: "Nombre invalide",
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",
};
