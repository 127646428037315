import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import BusinessIcon from "@mui/icons-material/Business";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import List from "@mui/icons-material/List";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import StarsRatingRender from "components/CustomAgEditor/StarsRatingRender";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";

import SearchBarService from "services/SearchBarService";

import Render from "Utils/RenderUtils";

import DevisTravaux from "views/Tables/DevisTravaux";

import { withUserContext } from "context/UserContext";

class EntreprisesView extends React.Component {
  constructor(props) {
    super(props);
    // this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.entreprise." + props.section);
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/entreprises";
    this.FRONT_URL = (props.urlPrefix ?? "") + "/entreprises/" + props.section;
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      commune_siege: null,
      devis: [],
      // DATA FORM
      nom: "",
      siret: "",
      rc_numero: "",
      gerant: "",
      tel: "",
      email: "",
      zone_activite: "",
      infos: "",
      note_total: null,
      secteur_activite: "",
      chiffre_affaire: "",
      // END DATA FORM
      errors: null,
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id, {
        params: {
          section: this.props.section,
        },
      })
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  //Remove Entreprise
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id, {
        params: { section: this.props.section },
      })
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
    this.clearAlert();
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer l'entreprise ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer l'entreprise : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.devis > 0 && (
              <li>{nbrEntity.devis} devi(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const { commune_siege, devis } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <BusinessIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations Générales
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Nom : </strong>
                {this.state.nom}
              </Typography>
              <Typography>
                <strong>Siret : </strong>
                {Render.siret(this.state.siret)}
              </Typography>
              <Typography>
                <strong>RC numéro : </strong>
                {this.state.rc_numero}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ContactPhoneIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Contact Principal
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Nom du gérant : </strong>
                {this.state.gerant}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(this.state.tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(this.state.email)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LocationOnIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Localisation
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Commune du siège social : </strong>
                {commune_siege?.cp} {commune_siege?.ville}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <InfoIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Informations Supplémentaires
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Informations complémentaires : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {this.state.infos}
              </MultilineTypography>
              <Grid container direction="row" alignItems="center">
                <Typography component="span">
                  <strong>Note : </strong>
                </Typography>
                <StarsRatingRender
                  value={this.state.note_total}
                  readOnly={true}
                  precision={0.01}
                  fontSize="1rem"
                  showRating={true}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <WorkIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Activité
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Zone d&apos;activité : </strong>
                {this.state.zone_activite}
              </Typography>
              <Typography>
                <strong>Secteur d&apos;activité : </strong>
                {this.state.secteur_activite}
              </Typography>
              <Typography>
                <strong>Chiffre d&apos;affaire : </strong>
                {Render.euro(this.state.chiffre_affaire)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {this.props.section === "travaux" && (
          <Grid item xs={12}>
            <DevisTravaux devis={devis} history={this.props.history} />
          </Grid>
        )}
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={`${this.FRONT_URL}/logs?type=Entreprise&section=${this.props.section}&id=${this.state.id}`}
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

EntreprisesView.propTypes = {
  section: PropTypes.string,
  urlPrefix: PropTypes.string,
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EntreprisesView);
