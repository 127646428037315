import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";

import EditableTree from "../Tree/EditableTree";

class ConfigTreeContainer extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = this.props.backendUrl;

    this.state = {
      data: null,
      expandedNodes: [],
      errors: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    this.setState({ errors: null });

    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      const parent_ids = result.data?.parent_ids ?? [];
      const leaf_ids = result.data?.leaf_ids ?? [];

      this.setState({
        data: result.data?.tree ?? [],
        expandedNodes: parent_ids.concat(leaf_ids),
      });
    });
  }

  refreshCallback = () => {
    this.loadAsyncData();
  };

  render() {
    const { icon, title, backendUrl } = this.props;
    const { data, expandedNodes } = this.state;

    return (
      <Card>
        <CardHeader
          avatar={<CardAvatar color="config">{icon}</CardAvatar>}
          title={
            <Typography variant="h6" component="h5">
              {title}
            </Typography>
          }
        />
        <CardContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} className="ag-theme-material">
              {data && (
                <EditableTree
                  tree={data}
                  expandedNodes={expandedNodes}
                  backendUrl={backendUrl}
                  refreshCallback={this.refreshCallback}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ConfigTreeContainer.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  backendUrl: PropTypes.string,
  label: PropTypes.string,
};

export default ConfigTreeContainer;
