import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import CustomTimeline from "components/Timeline/CustomTimeline";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import Render from "Utils/RenderUtils";

class InfoFiscaleView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  nextYear = (year) => {
    return year ? +year + 1 : "";
  };

  render() {
    const { infosFiscales } = this.props;

    infosFiscales.sort(function (a, b) {
      return new Date(b.annee) - new Date(a.annee);
    });

    const activeId = infosFiscales.length > 0 ? infosFiscales[0].id : null;

    return (
      <>
        {infosFiscales.length <= 0 ? (
          <Typography>
            <strong>Aucune Information Fiscale n&apos;a été saisie.</strong>
          </Typography>
        ) : (
          <>
            <Typography>
              <strong>Informations Fiscales : </strong>
            </Typography>
            <CustomTimeline
              items={infosFiscales}
              visibleItems={1}
              renderDot={(item) => (
                <TimelineDot
                  color={activeId === item.id ? "success" : "primary"}
                />
              )}
              renderBody={(item) => {
                return (
                  <>
                    <Typography>
                      <strong>
                        Revenus de {item.annee || "?"} (déclaration{" "}
                        {this.nextYear(item.annee) || "?"})
                      </strong>
                    </Typography>
                    <Typography>
                      <strong>
                        Montant RFR
                        <InfoLightTooltip title="Revenu fiscal de référence." />{" "}
                        :{" "}
                      </strong>
                      {Render.euro(item.revenu_fiscal)}
                    </Typography>
                    <Typography>
                      <strong>Revenu brut global : </strong>
                      {Render.euro(item.revenu_brut)}
                    </Typography>
                    <Typography>
                      <strong>Nombre de parts : </strong>
                      {item.nb_parts}
                    </Typography>
                  </>
                );
              }}
            />
          </>
        )}
      </>
    );
  }
}

InfoFiscaleView.propTypes = {
  infosFiscales: PropTypes.array.isRequired,
};

export default InfoFiscaleView;
