import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Remove from "@mui/icons-material/Remove";

import Button from "components/Button/Button";

import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";

class CheckableTree extends Component {
  constructor(props) {
    super(props);
    this.defaultExpanded = props.defaultExpanded?.map(String) ?? [];

    this.state = {
      tree: null,
      checkList: [],
      selectedNodes: [],
      parentIds: [],
      expanded: this.defaultExpanded,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get(this.props.backendUrl).then((result) => {
      this.setState({
        tree: result.data?.tree ?? [],
        parentIds: result.data?.parent_ids?.map(String) ?? [],
        checkList: this.props.initCheckList ?? [],
        selectedNodes: this.props.initCheckList?.map(String) ?? [],
      });
    });
  }

  handleToggle = (event, nodeIds) => {
    this.setState({ expanded: nodeIds });
  };

  handleExpandClick = () => {
    this.setState((prevState) => {
      return {
        expanded:
          prevState.expanded.length <= this.defaultExpanded.length
            ? this.state.parentIds
            : [...this.defaultExpanded],
      };
    });
  };

  handleChangeCheckbox = (event, node) => {
    let updatedList = [];

    if (event.target.checked) {
      updatedList = [...this.state.checkList, node.id];
    } else {
      updatedList = [...this.state.checkList];
      const index = updatedList.indexOf(node.id);
      if (index !== -1) {
        updatedList.splice(index, 1);
      }
    }

    this.setState({
      checkList: updatedList,
      selectedNodes: this.toggleSelect(node),
    });

    this.props.onChange(updatedList);
  };

  toggleSelect = (node) => {
    let currentArray = [...this.state.selectedNodes];
    const nodeId = node.id.toString();

    if (currentArray.includes(nodeId)) {
      const index = currentArray.indexOf(nodeId);
      if (index !== -1) {
        currentArray.splice(index, 1);
      }
    } else {
      currentArray.push(nodeId);
    }

    return currentArray;
  };

  renderTree = (node) => {
    const checked = this.state.checkList.includes(node.id) || false;

    if (node) {
      return (
        <TreeItem
          sx={{
            "& .MuiTreeItem-group": {
              borderLeft: "1px dashed black",
            },
          }}
          key={node.id}
          nodeId={node.id.toString()}
          label={
            !Array.isArray(node.children) ? (
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => this.handleChangeCheckbox(e, node)}
                  />
                }
                label={node.value}
              />
            ) : (
              <Typography sx={{ p: 1 }}>{node.value}</Typography>
            )
          }
        >
          {Array.isArray(node.children)
            ? node.children.map((child) => this.renderTree(child))
            : null}
        </TreeItem>
      );
    }
  };

  render() {
    const { label, defaultExpanded } = this.props;
    const { tree, expanded, selectedNodes } = this.state;

    return (
      tree?.length > 0 && (
        <>
          <Typography sx={{ mb: 1 }}>
            {label && <strong>{label} : </strong>}
            {defaultExpanded && (
              <Button
                onClick={this.handleExpandClick}
                sx={{ fontSize: 10 }}
                size="small"
                startIcon={
                  expanded?.length <= defaultExpanded.length ? (
                    <Add />
                  ) : (
                    <Remove />
                  )
                }
              >
                {expanded?.length <= defaultExpanded.length
                  ? "Tout ouvrir"
                  : "Fermer"}
              </Button>
            )}
          </Typography>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={selectedNodes}
            expanded={expanded}
            onNodeToggle={this.handleToggle}
          >
            {tree && tree.map((item) => this.renderTree(item))}
          </TreeView>
        </>
      )
    );
  }
}

CheckableTree.propTypes = {
  classes: PropTypes.any,
  backendUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  initCheckList: PropTypes.array,
  defaultExpanded: PropTypes.array,
  label: PropTypes.string,
};

export default CheckableTree;
