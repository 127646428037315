import React from "react";
import Launch from "@mui/icons-material/Launch";

export default class Render {
  static boolean = (boolean) => {
    if (boolean === null) {
      return "";
    }

    return boolean ? "Oui" : "Non";
  };

  static configList = (items) => {
    return items?.map((item) => item.value).join(", ");
  };

  static date = (date, prefix = "", suffix = "") => {
    return date
      ? prefix + new Date(date).toLocaleDateString("fr-FR") + suffix
      : "";
  };

  static dateTime = (date, prefix = "", suffix = "") => {
    return date ? prefix + new Date(date).toLocaleString("fr-FR") + suffix : "";
  };

  static dayMonth = (date, prefix = "", suffix = "") => {
    return date
      ? prefix +
          new Intl.DateTimeFormat("fr-FR", {
            day: "numeric",
            month: "long",
          }).format(new Date(date)) +
          suffix
      : "";
  };

  static monthYear = (date, prefix = "", suffix = "") => {
    return date
      ? prefix +
          new Intl.DateTimeFormat("fr-FR", {
            month: "long",
            year: "numeric",
          }).format(new Date(date)) +
          suffix
      : "";
  };

  static euro = (value, prefix = "", suffix = "") => {
    return value != null && value !== "" && !isNaN(value)
      ? prefix +
          new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          })
            .format(value)
            .replace(/^-0/, "0") + // let's convert -0.00 into 0.00
          suffix
      : "";
  };

  static number = (value, precision = 2, noneValue = "") => {
    return value != null && value !== "" && !isNaN(value)
      ? new Intl.NumberFormat("fr-FR", {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        })
          .format(value)
          .replace(/^-0/, "0") // let's convert -0.00 into 0.00
      : noneValue;
  };

  static telephone = (telephone) => {
    let tel = "" + (telephone ?? "");
    return tel.replace(
      /\+?(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
      "+$1 $2 $3 $4 $5 $6",
    );
  };

  static telephoneAsLink = (telephone) => {
    let tel = this.telephone(telephone);
    return <a href={"tel:" + tel}>{tel}</a>;
  };

  static shortTelephone = (telephone) => {
    let tel = "" + (telephone ?? "");
    tel = tel.replace(/^\+33/, "0");
    return tel;
  };

  static email = (email) => {
    return <a href={"mailto:" + email}>{email}</a>;
  };

  static externalLink = (url) => {
    if (url != null && url != "") {
      return (
        <a target="_blank" rel="noopener noreferrer" href={url}>
          {url}
          <Launch fontSize="inherit" />
        </a>
      );
    }
    return "";
  };

  static amount = (count, single = "", multiple = single, zero = single) => {
    const aCount = Math.abs(count);
    let word = zero;
    if (aCount > 1) {
      word = multiple;
    } else if (aCount == 1) {
      word = single;
    }
    return count + " " + word;
  };

  static ifNotEmpty = (value, prefix = "", suffix = "") => {
    return value != null && value !== "" ? prefix + value + suffix : "";
  };

  static carre = (value, suffix = "") => {
    return value != null && value !== "" && !isNaN(value)
      ? value + " m²" + suffix
      : "";
  };

  static percent = (value, prefix = "", suffix = "", decimal = 2) => {
    return value != null && value !== "" && !isNaN(value)
      ? prefix + Number(value).toFixed(decimal) + " %" + suffix
      : "";
  };

  static ageInt = (date) => {
    if (!date) {
      return null;
    }
    const dob = new Date(date);
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);
    const age = age_dt.getUTCFullYear() - 1970;

    return age;
  };

  static age = (date, prefix = "", suffix = "") => {
    if (!date) {
      return "";
    }
    const age = this.ageInt(date);

    return prefix + age + suffix;
  };

  static nir = (nir) => {
    let n = "" + (nir ?? "");
    n = n.replace(/[. ,]/g, "");

    return n.replace(
      /(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})?/,
      "$1 $2 $3 $4 $5 $6 $7",
    );
  };

  static iban = (iban) => {
    let i = "" + (iban ?? "");
    i = i.replace(/[. ,]/g, "");

    return i.match(/.{1,4}/g)?.join(" ") ?? "";
  };

  static fullName = (user) => {
    return (
      (user?.first_name ?? "") + " " + (user?.last_name ?? "").toUpperCase()
    );
  };

  static shortName = (user) => {
    if (user) {
      const firstName = user.first_name
        ? user.first_name.substring(0, 1) + "."
        : "";
      return firstName + " " + (user.last_name ?? "");
    }
    return "";
  };

  static address = (data) => {
    if (!data) {
      return "";
    }

    let address = "";
    if (data.numero_voie) address += data.numero_voie;
    if (data.type_voie) address += " " + data.type_voie;
    if (data.nom_voie) address += " " + data.nom_voie;

    return address.trim();
  };

  static fullAddress = (data) => {
    if (!data) {
      return "";
    }

    let address = Render.address(data);
    if (data.ville?.cp) address += " " + data.ville?.cp;
    if (data.ville?.ville) address += " " + data.ville?.ville;

    return address.trim();
  };

  static siret = (siret) => {
    let s = "" + (siret ?? "");
    s = s.replace(/[. ,]/g, "");

    return s.replace(/(\d{3})(\d{3})(\d{3})(\d{5})?/, "$1 $2 $3 $4");
  };

  static CRUDAction = (action) => {
    let text = "";
    switch (action) {
      case "CREATE":
        text = "Créer";
        break;
      case "UPDATE":
        text = "Éditer";
        break;
      case "DELETE":
        text = "Archiver";
        break;
      case "RESTORE":
        text = "Restorer";
        break;
      case "FORCE_DELETE":
        text = "Supprimer";
        break;
      default:
        break;
    }
    return text;
  };

  static modelPath = (model, space = false) => {
    if (!model) {
      return "";
    }

    const modelStr = model.replace("App\\Models\\", "");

    if (space) {
      return modelStr.replace(/([A-Z])/g, " $1").trim();
    }

    return modelStr;
  };

  static modelPathArray = (models, space = false) => {
    return models
      .sort((a, b) => a.value.localeCompare(b.value))
      .map((m) => Render.modelPath(m.value, space))
      .join(", ");
  };

  static stringToFloat = (string) => {
    const num = parseFloat(string);
    return isNaN(num) ? 0 : num;
  };

  static removeFileExtension = (filename) => {
    // Remove filename extension
    return filename.split(".").slice(0, -1).join(".");
  };

  static documentPlaceholder = (key) => {
    return "{{ " + key + " }}";
  };

  static templateName = (value) => {
    const templates = {
      ct_sous_loc: "Contrat: Sous-location",
      ct_mg: "Contrat: Mandat de gestion",
      ct_mggs: "Contrat: MGGS",
      autorisation_prelevement: "Document d'autorisation de prélèvement",
      att_fiscale: "Attestation fiscale",
    };

    return templates[value] ?? "Aucun";
  };

  static fullCadastre = (data) => {
    let cadastre = "";
    cadastre =
      data.prefixe_cadastrale +
      data.section_cadastrale +
      data.numero_parcelle_cadastrale;

    return cadastre;
  };

  static normalizeString = (str) => {
    return str
      .normalize("NFD") // Normaliser sous forme décomposée
      .replace(/[\u0300-\u036f]/g, "") // Supprimer les diacritiques
      .replace(/[\s-]/g, "") // Supprimer les espaces et les tirets
      .toLowerCase(); // Convertir en minuscules
  };
}
