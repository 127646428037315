import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";

// @mui/material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import debounce from "@mui/material/utils/debounce";

// @mui/icons-material

// core components
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import SearchBar from "components/SearchBar/SearchBar";

class CheckBoxTable extends Component {
  constructor(props) {
    super(props);
    this.gridApi = null;

    this.state = {
      nbSelected: 0,
      columnDefs: props.colDef,
      defaultColDef: {
        resizable: true,
        sortable: true,
      },
      data: null,
      rowClassRules: {},
      quickFilterText: "",
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const { backendUrl } = this.props;

    axiosApiBackend.get(backendUrl).then((result) => {
      this.setState({ data: result.data }, () => {
        setTimeout(() => {
          this.initSelected();
        }, 1);
      });
    });
  }

  initSelected = () => {
    const { selectedIds } = this.props;

    if (this.gridApi == null) {
      return;
    }

    this.gridApi.forEachNode((node) => {
      node.setSelected(selectedIds.includes(node.data.id));
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetSearchValue = () => {
    this.setState({ quickFilterText: "" });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  handleSelectionChanged = debounce(() => {
    const { onSelectionChanged } = this.props;
    const selectedRows = this.gridApi.getSelectedRows();

    if (onSelectionChanged) {
      onSelectionChanged(selectedRows.map((row) => row.id));
    }

    this.setState({
      nbSelected: selectedRows.length,
    });
  }, 50);

  render() {
    const {
      columnDefs,
      defaultColDef,
      data,
      quickFilterText,
      rowClassRules,
      nbSelected,
    } = this.state;

    return (
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SearchBar
              label="Rechercher"
              name="quickFilterText"
              value={quickFilterText}
              onChange={this.onChange}
              resetSearchValue={this.resetSearchValue}
            />
          </Grid>
          <Grid item>
            <Typography>Lignes sélectionnées : {nbSelected}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className="ag-theme-material">
          <AgGridReact
            onGridReady={this.onGridReady}
            enableFilter={false}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={data}
            quickFilterText={quickFilterText}
            pagination
            rowClassRules={rowClassRules}
            rowSelection="multiple"
            rowMultiSelectWithClick
            domLayout="autoHeight"
            paginationPageSize="10"
            onSelectionChanged={this.handleSelectionChanged}
            localeText={AG_GRID_LOCALE}
            suppressDragLeaveHidesColumns
          />
        </Grid>
      </Grid>
    );
  }
}

CheckBoxTable.propTypes = {
  backendUrl: PropTypes.string.isRequired,
  colDef: PropTypes.array.isRequired,
  selectedIds: PropTypes.array,
  onSelectionChanged: PropTypes.func,
};

export default CheckBoxTable;
