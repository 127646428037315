import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Place from "@mui/icons-material/Place";

import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import SelectSearch from "components/CustomSelect/SelectSearch";
// core components
import SearchBar from "components/SearchBar/SearchBar.jsx";

import SearchBarService from "services/SearchBarService";

import { withUiCustomizationContext } from "context/UiCustomizationContext";

class VilleConfig extends Component {
  constructor(props) {
    super(props);

    this.BACKEND_URL = "/villes";
    this.FRONT_URL = "/configuration/communes";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.gridApi = null;
    const uiCustomization = props.uiCustomization;

    this.state = {
      depCode: uiCustomization?.dep_code ?? "",
      data: [],
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: [
        {
          headerName: "Code Postal",
          field: "cp",
          flex: 1,
        },
        {
          headerName: "Ville",
          field: "ville",
          flex: 1,
        },
        {
          headerName: "ANAH",
          field: "zonesanah",
          flex: 1,
          valueGetter: (params) =>
            params.data?.zonesanah?.map((anah) => anah.value).join(", "),
        },
        {
          headerName: "EPCI",
          field: "epci",
          flex: 1,
          valueGetter: (params) =>
            params.data?.epci?.map((epci) => epci.value).join(", "),
        },
      ],
      getRowStyle: null,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const { depCode } = this.state;

    let params = {
      departement: depCode,
      dispositif: 1,
    };

    axiosApiBackend.get(this.BACKEND_URL, { params }).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onChangeSelect = (name, value) => {
    this.setState({ depCode: value }, this.loadAsyncData);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    const {
      columnDefs,
      defaultColDef,
      data,
      quickFilterText,
      getRowStyle,
      depCode,
    } = this.state;

    return (
      <Card>
        <CardHeader
          avatar={
            <CardAvatar color="config">
              <Place />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              Communes
            </Typography>
          }
        />
        <CardContent>
          <Grid container columnSpacing={2}>
            <Grid item>
              <SearchBar
                label="Rechercher"
                name="quickFilterText"
                value={quickFilterText}
                onChange={this.onChange}
                onBlur={this.onBlur}
                resetSearchValue={this.resetSearchValue}
              />
            </Grid>
            <Grid item sx={{ width: 300 }}>
              <SelectSearch
                size="small"
                margin="dense"
                name="search_select"
                label="Département"
                apiUrl="/departements"
                onChange={this.onChangeSelect}
                value={depCode}
                buildOptionValue={(data) => data.code}
                buildOptionLabel={(data) => data.code + " " + data.nom}
              />
            </Grid>
            <Grid item xs={12} className="ag-theme-material">
              <AgGridReact
                overlayNoRowsTemplate="Aucune donnée à afficher."
                enableCellTextSelection
                animateRows
                onGridReady={this.onGridReady}
                rowSelection="multiple"
                enableFilter={false}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={data}
                quickFilterText={quickFilterText}
                pagination
                getRowStyle={getRowStyle}
                domLayout="autoHeight"
                paginationPageSize="30"
                localeText={AG_GRID_LOCALE}
                suppressDragLeaveHidesColumns
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

VilleConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  uiCustomization: PropTypes.object,
};

export default withUiCustomizationContext(VilleConfig);
