import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CameraIndoor from "@mui/icons-material/CameraIndoor";
import Description from "@mui/icons-material/Description";
import ElectricMeter from "@mui/icons-material/ElectricMeter";
import Key from "@mui/icons-material/Key";
import List from "@mui/icons-material/List";
import MeetingRoom from "@mui/icons-material/MeetingRoom";

import Button from "components/Button/Button";
import MuiButton from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import DisableLink from "components/DisableLink/DisableLink";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import Image from "components/Image/Image";
import MenuBottom from "components/MenuBottom/MenuBottom";

import SearchBarService from "services/SearchBarService";

import Render from "Utils/RenderUtils";

import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

class EdlsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    // this.CAN_DELETE = this.USER.can("delete.edl");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    // Ged Edl permissions
    this.GED_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.edl"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.edl"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.edl"),
    };

    this.BACKEND_URL = "/edl";
    this.FRONT_URL = "/tiers/edls";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      edl_pieces: [],
      edl_energies: [],
      edl_clefs: [],
      // DATA FORM
      user_id: "",
      logement_id: "",
      edlable_id: "",
      edlable_type: "",
      type: "",
      readable_type: "",
      etat_general_logement: "",
      date: "",
      date_entree: "",
      contrat_locataire_id: "",
      contrat_proprietaire_id: "",
      // END DATA FORM
      with_signed_url: false,
      errors: null,
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id, {
        params: {
          with_signed_url: this.state.with_signed_url ? "1" : "0",
        },
      })
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  showPhoto = () => {
    this.setState({ with_signed_url: true }, () => this.loadAsyncData());
  };

  renderShowPhoto = () => {
    return (
      !this.state.with_signed_url && (
        <MuiButton onClick={() => this.showPhoto()}>
          Afficher les photos
        </MuiButton>
      )
    );
  };

  //Remove Edl
  // clearAlert = () => {
  //   this.setState({ alert: null });
  // };
  // handleDeleteAlert = () => {
  //   axiosApiBackend.delete(this.BACKEND_URL + "/" + this.state.id).then(() => {
  //     this.props.history.push(this.FRONT_URL);
  //   });
  //   this.clearAlert();
  // };

  // onClickDeleteButton = () => {
  //   this.setState({
  //     alert: (
  //       <AlertDialog
  //         title="Voulez-vous supprimer cet État des lieux ?"
  //         onConfirm={this.handleDeleteAlert}
  //         confirmLabel="Supprimer"
  //         confirmColor="error"
  //         onCancel={this.clearAlert}
  //         cancelLabel="Annuler"
  //         cancelColor="info"
  //         fullWidth
  //       />
  //     ),
  //   });
  // };

  render() {
    const {
      id,
      contrat_locataire_id,
      contrat_proprietaire_id,
      readable_type,
      etat_general_logement,
      date,
      date_entree,
      edl_pieces,
      edl_energies,
      edl_clefs,
    } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <CameraIndoor />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  État des lieux
                </Typography>
              }
            />
            <CardContent>
              {contrat_locataire_id && (
                <Typography>
                  <strong>Contrat Locataire : </strong>
                  <Link
                    to={"/contrats-locataires/detail/" + contrat_locataire_id}
                  >
                    CL{contrat_locataire_id}
                  </Link>
                </Typography>
              )}
              {contrat_proprietaire_id && (
                <Typography>
                  <strong>Contrat Propriétaire : </strong>
                  <Link
                    to={
                      "/contrats-proprietaires/detail/" +
                      contrat_proprietaire_id
                    }
                  >
                    CP{contrat_proprietaire_id}
                  </Link>
                </Typography>
              )}
              <Typography>
                <strong>Type : </strong>
                {readable_type}
              </Typography>
              <Typography>
                <strong>État général du logement : </strong>
                {etat_general_logement}
              </Typography>
              <Typography>
                <strong>Date : </strong>
                {Render.dateTime(date)}
              </Typography>
              <Typography>
                <strong>Date d&apos;entrée : </strong>
                {Render.dateTime(date_entree)}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ElectricMeter />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Énergies
                </Typography>
              }
            />
            <CardContent>
              {edl_energies.map((edl_energie, key) => (
                <Box key={key}>
                  <HeaderTitle label={edl_energie.energie?.nom} />
                  {Boolean(edl_energie.energie?.pdl) && (
                    <Typography>
                      <strong>Point de livraison électrique (PDL) : </strong>
                      {edl_energie.energie?.pdl}
                    </Typography>
                  )}
                  <Typography>
                    <strong>Valeur : </strong>
                    {edl_energie?.valeur} {edl_energie.energie?.unite}
                  </Typography>
                  <Typography>
                    <strong>Photo : </strong>
                  </Typography>
                  {this.renderShowPhoto()}
                  <Image
                    src={edl_energie.photo?.signed_url}
                    alt="energie photo"
                  />
                </Box>
              ))}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Key />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Clefs
                </Typography>
              }
            />
            <CardContent>
              {edl_clefs.map((edl_clef, key) => (
                <Box key={key}>
                  <HeaderTitle label={edl_clef.clef?.type} />
                  <Typography>
                    <strong>Label : </strong>
                    {edl_clef.clef?.label}
                  </Typography>
                  <Typography>
                    <strong>Nombre : </strong>
                    {edl_clef.clef?.nombre}
                  </Typography>
                  {Boolean(edl_clef.nombre_rendu) && (
                    <Typography>
                      <strong>Nombre rendu : </strong>
                      {edl_clef.nombre_rendu}
                    </Typography>
                  )}
                  <Typography>
                    <strong>Photo générale : </strong>
                  </Typography>
                  <Image
                    src={edl_clef.clef?.general_photo?.signed_url}
                    alt="clef photo general"
                  />
                  <Typography>
                    <strong>Photo : </strong>
                  </Typography>
                  {this.renderShowPhoto()}
                  <Image src={edl_clef.photo?.signed_url} alt="clef photo" />
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <MeetingRoom />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Pièces
                </Typography>
              }
            />
            <CardContent>
              {edl_pieces.map((edl_piece, key) => (
                <Box key={key}>
                  <HeaderTitle label={edl_piece.piece.nom} />
                  <Typography>
                    <strong>Description : </strong>
                    {edl_piece.piece.description}
                  </Typography>
                  <Typography>
                    <strong>État : </strong>
                    {edl_piece.etat}
                  </Typography>
                  <Typography>
                    <strong>Commentaire : </strong>
                    {edl_piece.commentaire}
                  </Typography>
                  <Typography>
                    <strong>Photo générale : </strong>
                  </Typography>
                  {this.renderShowPhoto()}
                  <Image
                    src={edl_piece.piece?.general_photo?.signed_url}
                    alt="piece photo general"
                  />
                  <Typography>
                    <strong>Photos : </strong>
                  </Typography>
                  {this.renderShowPhoto()}
                  {edl_piece.photos?.map((photo, key) => (
                    <Box key={key}>
                      <Image src={photo.signed_url} alt={"photo " + key} />
                    </Box>
                  ))}
                </Box>
              ))}
            </CardContent>
          </Card>
          {this.GED_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="edl"
              objectId={id}
              permissions={this.GED_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6} />
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {/* <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button  size="small" color="onyx" square round>
              <Edit />
            </Button>
            </Button>
          </Link> */}
          {/* {this.CAN_DELETE && this.state.id && (
            <Button size="small" 
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )} */}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Edl&id=" + this.state.id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

EdlsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EdlsView);
