import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui/material components
import TextField from "@mui/material/TextField";

const TextInput = ({
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  name,
  InputLabelProps,
  value,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      value={value ?? ""}
    />
  );
};

TextInput.propTypes = {
  fullWidth: PropTypes.any,
  margin: PropTypes.any,
  name: PropTypes.any,
  variant: PropTypes.any,
  InputLabelProps: PropTypes.any,
  value: PropTypes.any,
};

export default memo(TextInput);
