import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import ListAlt from "@mui/icons-material/ListAlt";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch.jsx";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple";
import DateTimePicker from "components/DatePicker/DateTimePicker";

import DateUtils from "Utils/DateUtils";
import FoyerUtils from "Utils/FoyerUtils";
import ProcedureUtils from "Utils/ProcedureUtils";

class ProceduresForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/procedures";
    this.FRONT_URL = "/tiers/procedures";

    this.state = {
      // NESTED DATA
      causes: [],
      // DATA FORM
      contrat_locataire_id: +this.QUERY_PARAMS.get("cl_id") || "",
      statut: "en_cours",
      type: "",
      date: new Date(),
      date_sinistre: "",
      date_declaration: "",
      expertise: false,
      date_expertise: "",
      commentaire: "",
      // END DATA FORM
      loading: false,
      errors: null,
      error_message: "",
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.date = new Date(result.data?.date);
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      contrat_locataire_id: this.state.contrat_locataire_id,
      statut: this.state.statut,
      type: this.state.type,
      date: DateUtils.toDBString(this.state.date, true),
      date_sinistre: this.state.date_sinistre,
      date_declaration: this.state.date_declaration,
      expertise: this.state.expertise,
      date_expertise: this.state.expertise ? this.state.date_expertise : "",
      commentaire: this.state.commentaire,
      causes: this.state.causes.map((c) => c.id),
    };

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      error_message: "",
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      loading: false,
      errors: error.response?.data?.errors ?? {},
      error_message:
        error.response?.data?.errors?.message ??
        "Merci de corriger les champs en rouge du formulaire.",
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      errors,
      error_message,
      contrat_locataire_id,
      statut,
      type,
      date,
      date_sinistre,
      date_declaration,
      expertise,
      date_expertise,
      commentaire,
    } = this.state;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>{error_message}</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ListAlt />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier la Procédure</>
                  ) : (
                    <>Ajouter une Procédure</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={4}>
                  <SelectSearch
                    name="contrat_locataire_id"
                    label="Choisir un contrat locataire"
                    apiUrl="/contrats-locataires"
                    value={contrat_locataire_id}
                    onChange={this.handleChangeByName}
                    buildOptionLabel={(data) =>
                      "(CL" +
                      data.id +
                      ") " +
                      FoyerUtils.referentsFullName(
                        data.foyer && data.foyer.referentsactifs,
                      )
                    }
                    shrink
                    error={Boolean(errors?.contrat_locataire_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SelectInput
                    label="Statut"
                    name="statut"
                    onChange={this.handleChange}
                    value={statut}
                    error={Boolean(errors?.statut)}
                  >
                    {ProcedureUtils.STATUTS.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DateTimePicker
                    name="date"
                    label="Date"
                    format="dd/MM/yyyy HH:mm"
                    ampm={false}
                    value={date}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.date)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextInput
                    label="Date de sinistre"
                    name="date_sinistre"
                    type="date"
                    onChange={this.handleChange}
                    value={date_sinistre}
                    error={Boolean(errors?.date_sinistre)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextInput
                    label="Date de déclaration"
                    name="date_declaration"
                    type="date"
                    onChange={this.handleChange}
                    value={date_declaration}
                    error={Boolean(errors?.date_declaration)}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SelectInput
                    label="Type"
                    name="type"
                    onChange={this.handleChange}
                    value={type}
                    error={Boolean(errors?.type)}
                  >
                    {ProcedureUtils.TYPES.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SelectSearchMultiple
                    label="Causes de la procédure"
                    apiUrl="/config-procedure-causes"
                    getOptionLabel={(data) => data.value}
                    onChange={(event, newValue) => {
                      this.setState({ causes: newValue });
                    }}
                    value={this.state.causes}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <CheckBoxInput
                    label="Expertise"
                    name="expertise"
                    value={expertise}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                {Boolean(expertise) && (
                  <Grid item xs={12} lg={4}>
                    <TextInput
                      label="Date d'expertise"
                      name="date_expertise"
                      type="date"
                      onChange={this.handleChange}
                      value={date_expertise}
                      error={Boolean(errors?.date_expertise)}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextInput
                    name="commentaire"
                    label="Commentaires"
                    multiline
                    minRows={2}
                    onChange={this.handleChange}
                    value={commentaire}
                    error={Boolean(errors?.commentaire)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

ProceduresForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default ProceduresForm;
