import OperationUtils from "Utils/OperationUtils";

const echeancierStyles = () => ({
  unsetRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  focusedRow: {
    background: "#fffbe2",
  },
  notCountedRow: {
    background: OperationUtils.NOT_COUNTED_COLOR,
  },
  fixContainerForSticky: {
    overflowX: "inherit",
  },
  header: {
    "& th": {
      position: "sticky",
      top: "0",
      zIndex: 2,
      background: "#66bb6a",
      color: "rgba(255, 255, 255, 0.95);",
    },
  },
  headerDetail: {
    background: "linear-gradient(60deg, #0a4f75, #09476a);",
    "& *": {
      color: "rgba(255, 255, 255, 0.95);",
    },
  },
  yearCell: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    borderBottom: "unset",
  },
  detailCell: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    borderBottom: "unset",
  },
  detailTableContainer: {
    marginBottom: "12px",
  },
  noWrap: {
    whiteSpace: "nowrap",
    "& > *": {
      display: "inline-block",
    },
  },
});

export default echeancierStyles;
