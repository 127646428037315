import React, { useContext } from "react";

export const UserContext = React.createContext();

// Create the withUserContext HOC
export const withUserContext = (Component) => {
  return React.forwardRef(function WithUserContext(props, ref) {
    const user = useContext(UserContext);
    return <Component {...props} ref={ref} user={user} />;
  });
};
