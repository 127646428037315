import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";

// @mui/icons-material
import Notifications from "@mui/icons-material/Notifications";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import DisableLink from "components/DisableLink/DisableLink";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.FRONT_URL = "/notifications";

    this.state = {
      open: false,
    };
  }

  render() {
    const { classes, nombreNotifications } = this.props;
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    return (
      <div>
        <div className={managerClasses}>
          <DisableLink to={this.FRONT_URL} style={{ color: "inherit" }}>
            <Notifications
              className={classes.headerLinksSvg + " " + classes.links}
            />
            {nombreNotifications > 0 && (
              <span className={classes.notifications}>
                {nombreNotifications}
              </span>
            )}
          </DisableLink>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  nombreNotifications: PropTypes.number,
};

export default withStyles(headerLinksStyle)(HeaderLinks);
