import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import Render from "Utils/RenderUtils";
import CustomTimeline from "components/Timeline/CustomTimeline";

class FinancementLogementView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  onReverseDate = (a, b) => {
    if (a.date_debut > b.date_debut) return -1;
    if (a.date_debut < b.date_debut) return 1;
    return 0;
  };

  render() {
    const { financements } = this.props;
    financements.sort(this.onReverseDate);
    const activeId = financements.length > 0 ? financements[0].id : null;

    return (
      <>
        {financements.length <= 0 ? (
          <Typography>
            <strong>Aucun financement n&apos;a été saisie.</strong>
          </Typography>
        ) : (
          <CustomTimeline
            items={financements}
            visibleItems={1}
            renderDot={(item) => (
              <TimelineDot
                color={activeId === item.id ? "success" : "primary"}
              />
            )}
            renderBody={(item) => {
              return (
                <>
                  <Typography>
                    <strong>Financeur : </strong>
                    {item.financeur?.value}
                  </Typography>
                  <Typography>
                    <strong>Convention : </strong>
                    {item.convention?.value}
                  </Typography>
                  <Typography>
                    <strong>Date de début : </strong>
                    {Render.date(item.date_debut)}
                  </Typography>
                  <Typography>
                    <strong>Date de fin : </strong>
                    {Render.date(item.date_fin)}
                  </Typography>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

FinancementLogementView.propTypes = {
  financements: PropTypes.array.isRequired,
};

export default FinancementLogementView;
