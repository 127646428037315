import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
// @mui/material components
import Typography from "@mui/material/Typography";

import AddBox from "@mui/icons-material/AddBox";
import Description from "@mui/icons-material/Description";
import FindInPage from "@mui/icons-material/FindInPage";
// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import ToggleChip from "components/CustomButtons/ToggleChip";
import MenuBottom from "components/MenuBottom/MenuBottom";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import AbortRequest from "services/AbortRequest";
import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import AuditUtils from "Utils/AuditUtils";
import DispositifUtils from "Utils/DispositifUtils";
import QueryUtils from "Utils/QueryUtils";
import Render from "Utils/RenderUtils";

import { withUserContext } from "context/UserContext";

class Audits extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.audit");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/audits";
    this.FRONT_URL = "/audits";
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.ABORT_REQUEST = new AbortRequest();
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      filters:
        this.QUERY_PARAMS.get("filters")?.split(",") ??
        AuditUtils.getStatutDefaultFilter(),
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Fait par soliha",
          field: "est_audit_par_soliha",
          cellDataType: "boolean",
          valueGetter: (params) => Boolean(params.data.est_audit_par_soliha),
          valueFormatter: (params) => Render.boolean(params.value),
        },
        {
          headerName: "Code d'identification",
          field: "code_identification",
          comparator: AgGridUtils.naturalSort,
        },
        {
          headerName: "Statut",
          field: "statut",
          valueGetter: (params) => AuditUtils.renderStatut(params.data.statut),
        },
        {
          hide: props.dense,
          headerName: "Bénéficiaire",
          field: "beneficiaire",
          valueGetter: (params) =>
            (params.data.logement?.beneficiaire?.prenom ?? "") +
            " " +
            (params.data.logement?.beneficiaire?.nom ?? ""),
        },
        {
          headerName: "Statut de propriété",
          field: "logement.beneficiaire.statut_propriete.value",
        },
        {
          headerName: "Catégorie de ressources",
          field: "logement.beneficiaire.categorie_ressource.value",
        },
        {
          hide: props.dense,
          headerName: "Date butoir",
          field: "date_butoir",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Téléphone",
          field: "tel",
          valueGetter: (params) =>
            Render.telephone(params.data.logement?.beneficiaire?.tel),
        },
        {
          headerName: "Adresse",
          field: "adresse",
          valueGetter: (params) =>
            Render.address(params.data.logement?.adresse),
        },
        {
          headerName: "Type",
          field: "type_audit",
          valueGetter: (params) => {
            const avenant = params.data.is_avenant ? "Avenant " : "";
            const type = params.data.type_audit;
            const sousType = params.data.sous_type;
            const sousTypeStr = sousType ? " : " + sousType : "";
            return avenant + type + sousTypeStr;
          },
        },
        {
          headerName: "Technicien",
          field: "technicien",
          valueGetter: (params) => Render.fullName(params.data.technicien),
        },
        {
          headerName: "Conseiller en charge",
          field: "conseiller",
          valueGetter: (params) => Render.fullName(params.data.conseiller),
        },
        {
          headerName: "Donneur d'ordres",
          field: "donneurordres.nom",
        },
        {
          headerName: "Référence Externe",
          field: "reference_externe",
        },
        {
          headerName: "Zone Anah",
          field: "logement.adresse.ville.zoneanah.value",
          valueGetter: (params) =>
            DispositifUtils.renderAnah(params.data.logement?.adresse?.ville),
        },
        {
          headerName: "EPCI",
          field: "logement.adresse.ville.epci.value",
          valueGetter: (params) =>
            DispositifUtils.renderEpci(params.data.logement?.adresse?.ville),
        },
        {
          headerName: "Rédaction",
          field: "date_redaction",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Résultat",
          field: "resultat",
        },
        {
          headerName: "Consommation Énergétique",
          field: "consommation_energetique",
          cellDataType: "number",
          valueFormatter: (params) =>
            params.value ? params.value + " kWh/m²/an" : "",
        },
        {
          hide: props.dense,
          headerName: "CP",
          field: "logement.adresse.ville.cp",
        },
        {
          hide: props.dense,
          headerName: "Ville",
          field: "logement.adresse.ville.ville",
        },
        {
          hide: props.dense,
          headerName: "Demande",
          field: "date_demande",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          hide: props.dense,
          headerName: "Visite",
          field: "date_visite",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          hide: props.dense,
          headerName: "Envoi",
          field: "date_envoi",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          hide: props.dense,
          headerName: "1er résultat estimé",
          field: "auditresultats.0.resultat",
        },
        {
          hide: props.dense,
          headerName: "Frais prévus",
          field: "frais_prevu",
          cellDataType: "number",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          hide: props.dense,
          headerName: "Date de facturation",
          field: "date_facture",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          hide: props.dense,
          headerName: "Frais facturés",
          field: "frais_facture",
          cellDataType: "number",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          hide: props.dense,
          headerName: "Date de paiement",
          field: "date_paiement",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          hide: props.dense,
          headerName: "Frais payés",
          field: "frais_paiement",
          cellDataType: "number",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Raison abandon",
          field: "raison_abandon_ad.value",
        },
      ]),
      getRowStyle: null,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    let params = {
      filters: this.state.filters,
    };
    if (this.props.logementId) {
      params.logement_id = this.props.logementId;
    } else if (this.props.dossierTravauxId) {
      params.dossier_travaux_id = this.props.dossierTravauxId;
    } else if (this.props.dense) {
      // si dense et pas d'id specifique alors on affiche rien (ne devrait pas arriver)
      this.setState({ data: [] });
      return;
    }

    axiosApiBackend
      .get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: params,
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      })
      .catch(() => {});
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  toggleFilter = (filter) => {
    let filters = this.state.filters;
    let index = filters.indexOf(filter);

    if (index !== -1) {
      filters.splice(index, 1);
    } else {
      filters.push(filter);
    }

    this.setState({ filters: filters }, () => {
      this.loadAsyncData();
      if (filters.length) {
        QueryUtils.replaceSearchParams({ filters: filters.join(",") });
      } else {
        QueryUtils.deleteSearchParams("filters");
      }
    });
  };

  hasFilter = (filter) => {
    return this.state.filters.includes(filter);
  };

  render() {
    const { dense, logementId } = this.props;

    let addUrl = this.FRONT_URL + "/ajouter";
    if (logementId) {
      const toUrl = new URL("http://localhost");
      toUrl.searchParams.append("logement_id", logementId);
      addUrl += toUrl.search;
    }

    return (
      <Grid container columnSpacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <FindInPage />
                </CardAvatar>
              }
              title={
                dense ? (
                  <>
                    <Typography variant="h6" component="h5">
                      Audits/Diagnostics
                    </Typography>
                    {this.CAN_EDIT && (
                      <Link to={addUrl} style={{ float: "right" }}>
                        <Button size="small">Ajouter</Button>
                      </Link>
                    )}
                  </>
                ) : (
                  <h3>Gestion des Audits/Diagnostics</h3>
                )
              }
            />
            <CardContent>
              {!dense && (
                <Grid
                  container
                  columnSpacing={2}
                  alignItems="center"
                  ref={this.filtersRef}
                >
                  <Grid item>
                    <SearchBar
                      label="Rechercher"
                      name="quickFilterText"
                      value={this.state.quickFilterText}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      resetSearchValue={this.resetSearchValue}
                    />
                  </Grid>
                  <Grid item>
                    <ToggleChip
                      sx={{ m: 0.5 }}
                      color="primary"
                      label="Tous"
                      on={this.state.filters.length === 0}
                      onClick={() => {
                        this.setState({ filters: [] }, () => {
                          this.loadAsyncData();
                          QueryUtils.deleteSearchParams("filters");
                        });
                      }}
                    />
                    {AuditUtils.STATUT.map((statut) => {
                      return (
                        <ToggleChip
                          sx={{ m: 0.5 }}
                          key={statut.name}
                          color="primary"
                          label={statut.label}
                          on={this.hasFilter(statut.name)}
                          onClick={() => this.toggleFilter(statut.name)}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                style={{
                  minHeight: "100px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={dense ? "" : this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {!dense && (
          <MenuBottom>
            {this.CAN_EDIT && (
              <Link to={this.FRONT_URL + "/ajouter"}>
                <Button size="small" square round>
                  <AddBox />
                </Button>
              </Link>
            )}
            <Button
              size="small"
              square
              round
              onClick={() => AgGridUtils.exportCSV(this.gridApi)}
            >
              <GetApp />
            </Button>
            {this.CAN_VIEW_LOG_ACTIVITY && (
              <Link to={this.FRONT_URL + "/logs?type=Audit"}>
                <Button size="small" square round>
                  <Description />
                </Button>
              </Link>
            )}
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

Audits.propTypes = {
  logementId: PropTypes.any,
  dossierTravauxId: PropTypes.any,
  dense: PropTypes.bool,
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(Audits);
