import styled from "@mui/system/styled";
import Box from "@mui/material/Box";

const MenuBottom = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "-1px",
  left: "290px",
  zIndex: "110",
  background: "#eeeeee",
  border: "1px solid #cfd0d2",
  borderRadius: "10px 10px 0 0",
  padding: "10px",
  "& button": {
    marginLeft: "3px",
    marginRight: "3px",
  },
  [theme.breakpoints.down("md")]: {
    left: "30px",
  },
}));

export default MenuBottom;
