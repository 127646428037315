import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";

// @mui/material components

// @mui/icons-material
import Grid from "@mui/material/Grid";

// core components
import FormCollection from "components/Form/FormCollection";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import UserSelectSearch from "components/CustomSelect/UserSelectSearch";

class ReferentsSociauxForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { users: [] };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData = () => {
    axiosApiBackend.get("/user-list/referents").then((result) => {
      this.setState({
        users: result.data,
      });
    });
  };

  render() {
    const { users } = this.state;
    const { referentsSociaux, addRow, updateRow, removeRow, errors } =
      this.props;

    return (
      <FormCollection
        items={referentsSociaux}
        rowTitle="Référent Social"
        addRow={addRow}
        removeRow={removeRow}
        tooltipAdd="Ajouter un référent social"
        tooltipDelete="Supprimer le référent social"
        dialogDeleteTitle={(rowNumber) =>
          "Voulez-vous supprimer le référent social n° " + rowNumber + " ?"
        }
        formInputs={(referentSocial, key) => {
          return (
            <>
              <Grid item xs={12} lg={6}>
                <UserSelectSearch
                  label="Référent social"
                  name="id"
                  propsData={users}
                  loadOnMount={false}
                  value={referentSocial.id}
                  onChange={(name, value) => updateRow(key, name, value)}
                  error={Boolean(errors?.[key + ".id"])}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CheckBoxInput
                  label="Référent principal"
                  name="pivot.is_principal"
                  value={referentSocial.pivot.is_principal}
                  onChange={(name, value) => updateRow(key, name, value)}
                  error={Boolean(errors?.[key + ".pivot.is_principal"])}
                />
              </Grid>
            </>
          );
        }}
      />
    );
  }
}

ReferentsSociauxForm.propTypes = {
  referentsSociaux: PropTypes.array.isRequired,
  addRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default ReferentsSociauxForm;
