import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

class CheckBoxInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (event) => {
    this.props.onChange(event.target.name, event.target.checked);
  };

  render() {
    const { label, margin, value, error, ...rest } = this.props;

    return (
      <FormControlLabel
        control={
          <Checkbox
            {...rest}
            checked={Boolean(value)}
            onChange={this.handleChange}
          />
        }
        label={label}
        margin={margin}
        style={error ? { color: "red" } : null}
      />
    );
  }
}

CheckBoxInput.defaultProps = {
  margin: "normal",
};

CheckBoxInput.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  margin: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.any,
};

export default CheckBoxInput;
