// import Pages from "layouts/Pages.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import dashboardRoutes from "routes/dashboard.jsx";
import configDashboardRoutes from "routes/configDashboard.jsx";
import LogRoutesBuilder from "services/LogRoutesBuilder";
import WaitingServerErrorPage from "views/Pages/WaitingServerErrorPage";

// Add log routes
new LogRoutesBuilder(dashboardRoutes, "info").build();
new LogRoutesBuilder(configDashboardRoutes, "config").build();

var indexRoutes = [
  {
    path: "/en-attente-des-serveurs",
    name: "En attente des serveurs",
    component: WaitingServerErrorPage,
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: Dashboard,
    componentProps: {
      routes: configDashboardRoutes,
      basePath: "/configuration",
      pathRedirect: "/configuration/home",
      color: "config",
      linkPath: "/",
      linkText: "Retour HAPY",
      configBackground: false,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Dashboard,
    componentProps: {
      routes: dashboardRoutes,
      basePath: "",
      pathRedirect: "/",
      color: "blue",
      linkPath: "/configuration/home",
      linkText: "Configuration HAPY",
      configBackground: false,
    },
  },
];

export default indexRoutes;
