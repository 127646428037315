import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";

import Build from "@mui/icons-material/Build";
import Description from "@mui/icons-material/Description";
import EventBusy from "@mui/icons-material/EventBusy";

import Button from "components/Button/Button";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import ConfigTreeContainer from "components/ConfigContainer/ConfigTreeContainer";
import MenuBottom from "components/MenuBottom/MenuBottom";
import DocumentTemplatePopup from "components/Popups/DocumentTemplatePopup";

import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

class AuditsConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    // Ged permissions
    const permission = this.USER.can("config.ged.travaux.ad");
    this.GED_AD_PERMISSIONS = {
      CAN_VIEW: permission,
      CAN_EDIT: permission,
      CAN_DELETE: permission,
    };
    this.FRONT_URL = "/configuration/audit";
    this.LABEL = "audit(s)";
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          {this.GED_AD_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="templates"
              section="travaux"
              type="AD"
              permissions={this.GED_AD_PERMISSIONS}
              title="Modèles de documents"
              iconColor="config.main"
              buttonColor="config"
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              popupComponent={DocumentTemplatePopup}
              frontUrl={this.FRONT_URL}
            />
          )}
          <ConfigContainer
            icon={<EventBusy />}
            title="Raison abandon"
            backendUrl="/config-raison-abandon-audit"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConfigTreeContainer
            icon={<Build />}
            title="Type Travaux"
            backendUrl="/config-audit-type-travaux"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigAudit"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

AuditsConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AuditsConfig);
