import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Person from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";

// core components
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import { withUserContext } from "context/UserContext";
import UserTokens from "views/Forms/User/UserTokens";

class UserView extends React.Component {
  constructor(props) {
    super(props);

    this.USER = props.user;
    this.CAN_ISSUE_TOKEN = this.USER.can("use.issuecontacttoken");

    this.state = {};
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {}

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar big>
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bonjour {this.USER.getName()}
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Role(s) : </strong>
                {this.USER.getRolesNames()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {this.CAN_ISSUE_TOKEN && (
          <Grid item xs={12} lg={6}>
            <UserTokens />
          </Grid>
        )}
      </Grid>
    );
  }
}

UserView.propTypes = {
  user: PropTypes.object,
};

export default withUserContext(UserView);
