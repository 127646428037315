import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components

// @mui/icons-material

// core components
import Button from "components/Button/Button";
import { withUserContext } from "context/UserContext";
import EcheancierComptable from "components/Echeancier/EcheancierComptable";
import EcheancierCP from "components/EcheancierCP/EcheancierCP";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import { Typography } from "@mui/material";
import Euro from "@mui/icons-material/Euro";

class EcheanciersCardCP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vueComptable: false,
    };
  }

  componentDidMount() {}

  // this is used in parent component
  // eslint-disable-next-line react/no-unused-class-component-methods
  reloadEcheanciers = () => {
    this.setState({ vueComptable: !this.state.vueComptable });
  };
  changeEcheancierVue = () => {
    this.setState({ vueComptable: !this.state.vueComptable });
  };

  render() {
    const { contractId, typePaiement, onSoldeUpdate, soldeDu, moyenPaiement } =
      this.props;

    const { vueComptable } = this.state;

    return (
      <Card>
        <CardHeader
          avatar={
            <CardAvatar color="success">
              <Euro />
            </CardAvatar>
          }
          title={
            <Typography variant="h6" component="h5">
              ÉCHÉANCIER - (CP{contractId})
            </Typography>
          }
          action={
            <Button onClick={this.changeEcheancierVue}>
              {vueComptable ? "Vue Mensuelle" : "Vue Comptable"}
            </Button>
          }
        />
        <CardContent>
          {vueComptable ? (
            <EcheancierComptable
              dataURL="/operations-proprietaires"
              contractId={contractId}
              typePaiement={typePaiement}
              onSoldeUpdate={onSoldeUpdate}
              entityLink="contrats-locataires"
              entityName="CL"
            />
          ) : (
            <EcheancierCP
              dataURL="/operations-proprietaires"
              contractId={contractId}
              typePaiement={typePaiement}
              onSoldeUpdate={onSoldeUpdate}
              soldeDu={soldeDu}
              moyenPaiement={moyenPaiement}
            />
          )}
        </CardContent>
      </Card>
    );
  }
}

EcheanciersCardCP.propTypes = {
  referentsFullName: PropTypes.any,
  contractId: PropTypes.any.isRequired,
  typePaiement: PropTypes.any,
  onSoldeUpdate: PropTypes.func.isRequired,
  soldeDu: PropTypes.any,
  moyenPaiement: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EcheanciersCardCP);
