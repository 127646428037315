import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import Evenements from "components/Evenements/Evenements.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";

class EvenementDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evenementId: props.match.params.evenementId,
      modelType: props.match.params.modelType,
      data: [],
      errors: null,
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ChatIcon />
                </CardAvatar>
              }
              title={
                <Typography variant="h5" component="h4">
                  Détail de l&apos;évènement
                </Typography>
              }
            />
            <CardContent>
              <Grid columnSpacing={2} container alignItems="center">
                <Grid item xs={12}>
                  <Evenements
                    evenementId={this.state.evenementId}
                    modelType={this.state.modelType}
                    onDelete={this.goBack}
                    displayReplyButton
                    withCountReplies
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Button size="small" square round onClick={this.goBack}>
            <ListIcon />
          </Button>
        </MenuBottom>
      </Grid>
    );
  }
}

EvenementDetailPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
};

export default EvenementDetailPage;
