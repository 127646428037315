import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Chip from "@mui/material/Chip";

// @mui/icons-material
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// core components

class ToggleChip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { sx, label, icon, on, onClick, color } = this.props;
    return (
      <Chip
        sx={sx}
        variant={on ? "default" : "outlined"}
        icon={icon}
        label={label}
        clickable={onClick !== null}
        color={color}
        onClick={onClick}
        onDelete={onClick}
        deleteIcon={on ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      />
    );
  }
}

ToggleChip.propTypes = {
  on: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  label: PropTypes.any,
  color: PropTypes.string,
  sx: PropTypes.any,
};

export default ToggleChip;
