import React, { Component } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Accessibility from "@mui/icons-material/Accessibility";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import FoyerUtils from "Utils/FoyerUtils";
import Render from "Utils/RenderUtils";

class AccompagnementsFoyer extends Component {
  constructor(props) {
    super(props);

    this.FRONT_URL = "/tiers/foyers/detail/";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "foyer.id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Représentant du foyer",
          field: "referents_name",
          valueGetter: (params) =>
            FoyerUtils.referentsFullName(params.data.foyer.referentsactifs),
        },
        {
          headerName: "Date début accompagnement",
          field: "date_debut",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Date fin accompagnement",
          field: "date_fin",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
        {
          headerName: "Type de suivi",
          field: "typesuivi.value",
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    const foyerId = event.node.data.foyer.id;
    this.props.history.push(this.FRONT_URL + foyerId);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Accessibility />
                </CardAvatar>
              }
              title={<Typography variant="h5">Foyers accompagnés</Typography>}
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                alignItems="flex-end"
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.accompagnementsfoyer}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

AccompagnementsFoyer.propTypes = {
  accompagnementsfoyer: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.any,
};

export default AccompagnementsFoyer;
