import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import Euro from "@mui/icons-material/Euro";
import List from "@mui/icons-material/List";
import Description from "@mui/icons-material/Description";
import AddBox from "@mui/icons-material/AddBox";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgGridUtils from "Utils/AgGridUtils";
import SelectInput from "components/CustomSelect/SelectInput";
import AbortRequest from "services/AbortRequest";
import FoyerUtils from "Utils/FoyerUtils";
import Render from "Utils/RenderUtils";
import { withUserContext } from "context/UserContext";

class EcheancierContratsLocataires extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.ABORT_REQUEST = new AbortRequest();
    this.BACKEND_URL = "/contrats-locataires";
    this.FRONT_URL = "/contrats-locataires/echeancier";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    const d = new Date();
    this.YEARS = [
      d.getFullYear(),
      d.getFullYear() - 1,
      d.getFullYear() - 2,
      d.getFullYear() - 3,
      d.getFullYear() - 4,
      d.getFullYear() - 5,
      d.getFullYear() - 6,
    ];
    this.MONTHS = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      year: this.YEARS[0],
      month: d.getMonth() + 1,
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Locataires",
          field: "referents_name",
          valueGetter: (params) =>
            FoyerUtils.referentsFullName(params.data.foyer?.referentsactifs),
        },
        {
          headerName: "Loyer",
          field: "echeancier_data.loyer",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Charges",
          field: "echeancier_data.charges",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Assurance",
          field: "echeancier_data.assurance",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "TOM",
          field: "echeancier_data.tom",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Loyer complet",
          field: "echeancier_data.loyer_complet",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "CAF",
          field: "echeancier_data.montant_caf",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Résiduel",
          field: "echeancier_data.residuel",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Autres operations",
          field: "echeancier_data.autres_operations",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Total à payer",
          field: "echeancier_data.total_a_payer",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Règlements",
          field: "echeancier_data.reglements",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Solde mensuel",
          field: "echeancier_data.solde_mensuel",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
        {
          headerName: "Solde",
          field: "echeancier_data.solde",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.number(params.value),
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData(newState = {}) {
    const year = newState.year ?? this.state.year;
    const month = newState.month ?? this.state.month;

    axiosApiBackend
      .get(this.BACKEND_URL, {
        signal: this.ABORT_REQUEST.abortAndGetSignal(),
        params: {
          filters: ["presents", "partis"],
          echeancierDataYear: year,
          echeancierDataMonth: month,
        },
      })
      .then((result) => {
        this.setState({ data: result.data }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onFilterChange = (event) => {
    const newState = { [event.target.name]: event.target.value };
    this.loadAsyncData(newState);
    this.setState(newState);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(
      "/contrats-locataires/detail/" + event.node.data.id,
    );
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container columnSpacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">
                  Échéanciers des Contrats Locataires
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                alignItems="center"
                columnSpacing={2}
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item lg={2} md={3} xs={12}>
                  <SelectInput
                    size="small"
                    label="Mois"
                    name="month"
                    value={this.state.month}
                    onChange={this.onFilterChange}
                    margin="dense"
                  >
                    {this.MONTHS.map((value, key) => (
                      <MenuItem key={key} value={key + 1}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item lg={2} md={3} xs={12}>
                  <SelectInput
                    size="small"
                    label="Année"
                    name="year"
                    value={this.state.year}
                    onChange={this.onFilterChange}
                    margin="dense"
                  >
                    {this.YEARS.map((value, key) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to="/contrats-locataires/ajouter">
            <Button size="small" square round>
              <AddBox />
            </Button>
          </Link>
          <Link to="/contrats-locataires">
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to="/contrats-locataires/logs?type=ContratLocataire">
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

EcheancierContratsLocataires.propTypes = {
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(EcheancierContratsLocataires);
