import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import TextInput from "./TextInput";

class WarningTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isWarning, color, value, ...props } = this.props;
    if (isWarning || Boolean(color)) {
      props.focused = true;
    }

    return (
      <TextInput
        {...props}
        color={isWarning ? "warning" : color}
        FormHelperTextProps={{
          sx: { color: isWarning ? "warning.main" : color + ".main" },
        }}
        value={value ?? ""}
      />
    );
  }
}

WarningTextField.defaultProps = {
  isWarning: false,
};

WarningTextField.propTypes = {
  isWarning: PropTypes.bool,
  color: PropTypes.string,
  value: PropTypes.any,
};

export default WarningTextField;
