import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const SelectInput = ({
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  shrink = true,
  label,
  name,
  error,
  disabled,
  InputLabelProps,
  value,
  children,
  ...rest
}) => {
  const labelId = name + "-select-id";

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      error={error}
      disabled={disabled}
    >
      <InputLabel shrink={shrink} id={labelId} {...InputLabelProps}>
        {label}
      </InputLabel>
      <Select
        notched={shrink}
        name={name}
        labelId={labelId}
        label={label}
        value={value ?? ""}
        {...rest}
      >
        {children}
      </Select>
    </FormControl>
  );
};

SelectInput.propTypes = {
  fullWidth: PropTypes.any,
  margin: PropTypes.any,
  variant: PropTypes.any,
  shrink: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  error: PropTypes.any,
  disabled: PropTypes.any,
  InputLabelProps: PropTypes.any,
  value: PropTypes.any,
  children: PropTypes.any,
};

// todo y marche pas lui a cause du children
// export default memo(SelectInput);
export default SelectInput;
