import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import styled from "@mui/system/styled";
import Popover from "@mui/material/Popover";
import Avatar from "@mui/material/Avatar";

// @mui/icons-material
import Info from "@mui/icons-material/Info";

// core components

const SmallPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: "none",
  "& .MuiPopover-paper": {
    maxWidth: "300px",
    padding: theme.spacing(1),
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: "#000000",
}));

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class InfoPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      children,
      id,
      anchorOrigin,
      transformOrigin,
      avatar,
      Icon,
      iconColor,
    } = this.props;
    const open = Boolean(this.state.anchorEl);
    const sx = {};
    if (iconColor) {
      sx.color = iconColor;
    }
    return (
      <>
        <ConditionalWrapper
          condition={avatar}
          wrapper={(children) => <SmallAvatar>{children}</SmallAvatar>}
        >
          <Icon
            sx={sx}
            aria-owns={open ? id : undefined}
            aria-haspopup="true"
            onMouseEnter={this.handlePopoverOpen}
            onMouseLeave={this.handlePopoverClose}
            style={{
              fontSize: "1.25rem",
            }}
          />
        </ConditionalWrapper>
        <SmallPopover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          {children}
        </SmallPopover>
      </>
    );
  }
}

InfoPopover.defaultProps = {
  avatar: false,
  Icon: Info,
};

InfoPopover.propTypes = {
  id: PropTypes.any.isRequired,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  avatar: PropTypes.bool,
  Icon: PropTypes.any,
  iconColor: PropTypes.any,
  children: PropTypes.any,
  classes: PropTypes.any,
};

export default InfoPopover;
