import React from "react";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";
import { Link } from "react-router-dom";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

// @mui/material components
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// @mui/icons-material
import Receipt from "@mui/icons-material/Receipt";

// core components
import Button from "components/Button/Button.jsx";
import FileUtils from "Utils/FileUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";

class ExportComptableCafBordereaux extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // FORM
      override_date: "",
      import_files: [],
      // END FORM
      open: false,
      loading: false,
      errorMessage: null,
      missingCLs: null,
      errors: null,
      success: false,
      successData: null,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeFile = (fileItems) => {
    this.setState({
      import_files: fileItems.map((fileItem) => fileItem.file),
    });
  };

  handleSubmit = () => {
    const { import_files, override_date } = this.state;
    const formData = new FormData();
    import_files.forEach((file, i) => {
      formData.append("caf_file" + i, file);
    });
    formData.append("override_date", override_date);

    this.setState({
      loading: true,
      errorMessage: null,
      missingCLs: null,
      errors: null,
      success: false,
      successData: null,
      import_files: [],
    });

    axiosApiBackendNoJson
      .post("/caf/importBordereauxCompta", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
          success: true,
          successData: res.data.success,
        });
        FileUtils.downLoadFile(
          res.data.success.file,
          res.data.success.headers,
          "export-comptable-caf.txt",
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errors: error?.response?.data?.errors ?? null,
          errorMessage:
            error?.response?.data?.error?.message ?? "Une erreur est survenue",
          missingCLs: error?.response?.data?.error?.cls ?? null,
        });
      });
  };

  render() {
    const {
      errorMessage,
      missingCLs,
      loading,
      open,
      success,
      successData,
      errors,
      override_date,
      import_files,
    } = this.state;
    const inconnu = successData?.clsToCheck?.inconnu ?? [];

    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<Receipt />}
          onClick={this.toggle}
        >
          Export Comptable - Bordereaux Caf
        </Button>
        <AlertDialog
          open={open}
          fullWidth
          outsideClickCancel
          title="Export Comptable - Bordereaux Caf"
          onConfirm={this.handleSubmit}
          confirmLabel="Envoyer"
          confirmColor="primary"
          onCancel={this.toggle}
          cancelLabel="Annuler"
          cancelColor="reversed"
          loading={loading}
        >
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          {missingCLs !== null && missingCLs.length > 0 && (
            <>
              <Typography variant="h5" color="error">
                Contrats Locataires
              </Typography>
              <ul>
                {missingCLs.map((cl, key) => (
                  <li key={key}>
                    <Link
                      to={"/contrats-locataires/modifier/" + cl.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CL{cl.id} {cl.prenom} {cl.nom}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {success && (
            <Typography component="div">
              <h3>Locataires à vérifier</h3>
              <p>
                Type de fichier : <strong>{successData?.fileType}</strong>
              </p>
              {inconnu.length > 0 ? (
                <div>
                  <h4>Inconnu(s) :</h4>
                  <ul>
                    {inconnu.map((loc) => (
                      <li key={loc.ncaf}>
                        ({loc.ncaf}) {loc.firstname} {loc.lastname} :{" "}
                        {loc.amount}€
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>
                  <strong>Aucun</strong>
                </p>
              )}
            </Typography>
          )}
          <TextField
            label="Date du Bordereau"
            name="override_date"
            type="date"
            fullWidth
            margin="normal"
            variant="outlined"
            value={override_date}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(errors?.override_date)}
          />
          <FilePond
            labelIdle="Ajoutez le bordereau Caf<br>(MAX 10Mo)"
            files={import_files}
            allowMultiple={false}
            maxTotalFileSize={10000000}
            acceptedFileTypes={["text/csv", "text/plain"]}
            onupdatefiles={this.handleChangeFile}
          />
        </AlertDialog>
      </>
    );
  }
}

export default ExportComptableCafBordereaux;
