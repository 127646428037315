import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Button from "components/Button/Button.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import EmailRounded from "@mui/icons-material/EmailRounded";
import Typography from "@mui/material/Typography";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch";

class SendAllAttestaionFiscale extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.YEARS = [
      now.getFullYear(),
      now.getFullYear() - 1,
      now.getFullYear() - 2,
      now.getFullYear() - 3,
    ];
    this.state = {
      annee: now.getFullYear() - 1,
      templateName: null,
      loading: false,
      errorMessage: null,
      errors: null,
      open: false,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
      success: false,
      errorMessage: null,
      errors: null,
    });
    const url = `/contrats-proprietaires/send-documents/attestation-fiscale`;
    const data = {
      year: this.state.annee,
      templateName: this.state.templateName,
    };

    axiosApiBackend
      .post(url, data)
      .then(() => {
        this.setState({
          loading: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage:
            error.response?.data?.error?.message || "Erreur inconnue",
          errors: error.response?.data?.errors,
        });
      });
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<EmailRounded />}
          onClick={this.toggle}
        >
          Attestations Fiscales
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth>
          <DialogTitle>
            Envoyer toutes les attestations fiscales aux propriétaires
          </DialogTitle>
          <DialogContent>
            <Typography>
              Le document envoyé par email sera mis automatiquement dans la GED.
            </Typography>
            {!errors && this.state.errorMessage && (
              <Typography color="error">{this.state.errorMessage}</Typography>
            )}
            {this.state.success && (
              <Typography color="success.main">
                Les emails seront envoyés sous quelques minutes.
              </Typography>
            )}
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={6}>
                <SelectInput
                  label="Année"
                  name="annee"
                  value={this.state.annee}
                  onChange={this.handleChange}
                >
                  {this.YEARS.map((value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectSearch
                  label="Choisir un Modèle"
                  name="templateName"
                  apiUrl="/ged/getList?rootFolder=templates&section=immobilier&type=CP"
                  filterResponseData={(data) =>
                    data.template_name === "att_fiscale"
                  }
                  value={this.state.templateName}
                  onChange={this.handleChangeByName}
                  buildOptionKey={(data) => data.filename}
                  buildOptionValue={(data) => data.filename}
                  buildOptionLabel={(data) => data.filename}
                  shrink
                  error={Boolean(errors?.templateName)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggle} color="reversed">
              Annuler
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              loading={this.state.loading}
            >
              Envoyer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

SendAllAttestaionFiscale.defaultProps = {};

SendAllAttestaionFiscale.propTypes = {};

export default SendAllAttestaionFiscale;
