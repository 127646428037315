import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import Input from "@mui/icons-material/Input";
import List from "@mui/icons-material/List";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";

import SearchBarService from "services/SearchBarService";

import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";

import LogementsCal from "views/Tables/LogementsCal";

import { withUserContext } from "context/UserContext";

class CalsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_DELETE = this.USER.can("delete.cal");
    this.CAN_USE_DOCUMENTS = this.USER.can("use.caldocuments");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/cals";
    this.FRONT_URL = "/tiers/cals";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      logements: [],
      // DATA FORM
      nom_cal: "",
      info: "",
      date_cal: "",
      // END DATA FORM
      cal: null,
      errorMessage: null,
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData = () => {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        state.cal = result.data;
        this.setState(state);
      });
  };

  //Remove CAL
  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer la CAL ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer la CAL : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.logements > 0 && (
              <li>{nbrEntity.logements} logment(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  generatePdf = () => {
    const { id } = this.state;
    this.setState({ errorMessage: null });

    axiosApiBackendNoJson
      .get("/cals/" + id + "/documents/logementsPdf", {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, "logements_en_cal.pdf");
      })
      .catch((err) => {
        const status = err.response?.status;
        let errorMessage = "Impossble de charger le document";
        if (status == 428) {
          errorMessage = "Aucun logement dans la Cal";
        }
        this.setState({ errorMessage: errorMessage });
      });
  };

  render() {
    const { logements, errorMessage } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Commission Attribution Logement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Nom : </strong>
                {this.state.nom_cal}
              </Typography>
              <Typography>
                <strong>Date : </strong>
                {Render.date(this.state.date_cal)}
              </Typography>
              <Typography>
                <strong>Info complémentaire : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {this.state.info}
              </MultilineTypography>
            </CardContent>
          </Card>
        </Grid>
        {this.CAN_USE_DOCUMENTS && (
          <Grid item xs={12} lg={4}>
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar color="gla">
                    <Input />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Actions
                  </Typography>
                }
              />
              <CardContent>
                <Button color="error" onClick={this.generatePdf}>
                  Générer document
                </Button>
                {errorMessage && (
                  <Typography color="secondary">{errorMessage}</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <LogementsCal
            calId={this.state.id}
            cal={this.state.cal}
            logements={logements}
            onDelete={this.loadAsyncData}
            onAdd={this.loadAsyncData}
          />
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Cal&id=" + this.state.id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

CalsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(CalsView);
