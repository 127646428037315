import React from "react";
import PropTypes from "prop-types";

// @mui/material components

// @mui/icons-material

// core components
import LinkCard from "./LinkCard";
import Render from "Utils/RenderUtils";
import Home from "@mui/icons-material/Home";
import HomeWork from "@mui/icons-material/HomeWork";
import BaseLinkCard from "./BaseLinkCard";
import CPUtils from "Utils/CPUtils";
import FoyerUtils from "Utils/FoyerUtils";

class ImmobilierLinkCard extends BaseLinkCard {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  isNotNull = (a) => {
    return a != null && a !== "";
  };

  buildProprietaireLink = (proprietaire) => {
    if (proprietaire == null || !proprietaire.id) {
      return null;
    }
    return {
      header: "Propriétaire : ",
      to: "/tiers/proprietaires/detail/" + proprietaire.id,
      text:
        (proprietaire.prenom ?? "") +
        " " +
        (proprietaire.nom ?? "") +
        " (" +
        proprietaire.id +
        ")",
    };
  };
  buildProprietaireFallback = () => {
    return {
      button: true,
      header: "Propriétaire : ",
      to: "/tiers/proprietaires/ajouter",
      text: "Créer un Propriétaire",
    };
  };

  buildLogementLink = (logement) => {
    if (logement == null || !logement.id) {
      return null;
    }

    const detailURL = "/tiers/logements/detail/";
    const seeAll = this.buildSeeAllData({
      entityList: "logement",
      mostRecent: logement,
      detailURL: detailURL,
      prefixBadge: "LP",
      icon: <Home />,
      bodyContent: (lp, isActive) => {
        let address = Render.fullAddress(lp.adresse);
        let addressRender =
          address === "" ? "Adresse non spécifiée" : "Adresse: " + address;

        return (
          <div style={{ fontWeight: isActive && "bold" }}>
            <p>{addressRender}</p>
            <p>Type de bien : {lp?.type}</p>
          </div>
        );
      },
    });

    return {
      header: logement.type + " : ",
      to: detailURL + logement.id,
      text: Render.fullAddress(logement.adresse) + " (LP" + logement.id + ")",
      seeAll: seeAll,
    };
  };
  buildLogementFallback = (proprietaire_id) => {
    const params = this.isNotNull(proprietaire_id)
      ? "?proprietaire_id=" + proprietaire_id
      : "";
    return {
      button: true,
      header: "Logement : ",
      to: "/tiers/logements/ajouter" + params,
      text: "Créer un logement",
    };
  };

  buildContratProprietaireLink = (cp) => {
    if (cp == null || !cp.id) {
      return null;
    }
    return {
      header: "Contrat Propriétaire : ",
      to: "/contrats-proprietaires/detail/" + cp.id,
      text: cp.nom_contrat
        ? cp.nom_contrat.value + " (" + (cp.type ?? "") + ")" + " - CP" + cp.id
        : (cp.type ?? "") + " - CP" + cp.id,
    };
  };
  buildContratProprietaireFallback = (logement_id) => {
    const params = this.isNotNull(logement_id)
      ? "?logement_id=" + logement_id
      : "";
    return {
      button: true,
      header: "Contrat Propriétaire : ",
      to: "/contrats-proprietaires/ajouter" + params,
      text: "Créer un Contrat Propriétaire",
    };
  };

  buildContratLocataireLink = (cl) => {
    if (cl == null || !cl.id) {
      return null;
    }

    const detailURL = "/contrats-locataires/detail/";
    const seeAll = this.buildSeeAllData({
      entityList: "contratLocataire",
      mostRecent: cl,
      detailURL: detailURL,
      prefixBadge: "CL",
      icon: <HomeWork />,
      bodyContent: (cl, isActive) => {
        let startDate = Render.date(cl?.date_entree) || "Aucune date";
        let endDate = Render.date(cl?.date_sortie) || "Aucune date";
        let name = FoyerUtils.referentsFullName(
          cl?.foyer?.referentsactifs || cl?.foyer?.personnesactives,
        );

        return (
          <div style={{ fontWeight: isActive && "bold" }}>
            <p>Date entrée: {startDate}</p>
            <p>Date sortie: {endDate}</p>
            {name && <p>Nom locataire: {name}</p>}
          </div>
        );
      },
    });

    const name = FoyerUtils.referentsFullName(
      cl.foyer && (cl.foyer.referentsactifs || cl.foyer.personnesactives),
    );

    return {
      header: "Contrat Locataire : ",
      to: detailURL + cl.id,
      text: name + " (CL" + cl.id + ")",
      seeAll: seeAll,
    };
  };
  buildContratLocataireFallback = (logement_id, cp) => {
    if (cp && cp.id != null) {
      const toUrl = new URL("http://localhost");
      const params = [
        "loyer_origine",
        "depot_de_garantie",
        "mensualite_loyer",
        "mensualite_charges",
        "mensualite_garage",
        "mensualite_charges_garage",
        "mensualite_ordures",
        "jour_paiement",
      ];
      params.forEach((param) => {
        if (this.isNotNull(cp[param])) {
          toUrl.searchParams.append(param, cp[param]);
        }
      });
      if (this.isNotNull(logement_id)) {
        toUrl.searchParams.append("logement_id", logement_id);
      }
      return {
        button: true,
        header: "Contrat Locataire : ",
        to: "/contrats-locataires/ajouter" + toUrl.search,
        text: "Créer un Contrat Locataire",
      };
    }
    return null;
  };

  buildLinks = () => {
    const {
      proprietaire,
      logement,
      contratProprietaire,
      contratLocataire,
      hideProprietaire,
      hideLogement,
      hideContratProprietaire,
      hideContratLocataire,
    } = this.props;
    let links = [];

    if (hideProprietaire !== true) {
      let pLink =
        this.buildProprietaireLink(proprietaire) ||
        this.buildProprietaireFallback();
      if (pLink !== null) {
        links.push(pLink);
      }
    }

    if (hideLogement !== true) {
      let lLink =
        this.buildLogementLink(logement) ||
        this.buildLogementFallback(proprietaire && proprietaire.id);
      if (lLink !== null) {
        links.push(lLink);
      }
    }
    if (hideContratProprietaire !== true) {
      let cpLink =
        this.buildContratProprietaireLink(contratProprietaire) ||
        this.buildContratProprietaireFallback(logement?.id);
      if (cpLink !== null) {
        links.push(cpLink);
      }
    }
    if (hideContratLocataire !== true) {
      let clLink =
        this.buildContratLocataireLink(contratLocataire) ||
        this.buildContratLocataireFallback(logement?.id, contratProprietaire);
      if (clLink !== null) {
        links.push(clLink);
      }
    }

    return links;
  };

  render() {
    const links = this.buildLinks();

    return <LinkCard links={links} />;
  }
}

ImmobilierLinkCard.propTypes = {
  proprietaire: PropTypes.any,
  logement: PropTypes.any,
  contratProprietaire: PropTypes.any,
  contratLocataire: PropTypes.any,
  hideProprietaire: PropTypes.bool,
  hideLogement: PropTypes.bool,
  hideContratProprietaire: PropTypes.bool,
  hideContratLocataire: PropTypes.bool,
};

export default ImmobilierLinkCard;
