import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material components

// @mui/lab components
import TimelineDot from "@mui/lab/TimelineDot";

// core components
import Render from "Utils/RenderUtils";
import CustomTimeline from "components/Timeline/CustomTimeline";

class CalListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  onReverseDate = (valA, valB) => {
    if (valA.date_cal > valB.date_cal) return -1;
    if (valA.date_cal < valB.date_cal) return 1;
    return 0;
  };

  render() {
    const { cals } = this.props;
    cals.sort(this.onReverseDate);
    const activeId = cals.length > 0 ? cals[0].id : null;

    return (
      <>
        {cals.length <= 0 ? (
          <Typography>
            <strong>Aucune cal n&apos;a été saisie.</strong>
          </Typography>
        ) : (
          <>
            <Typography>
              <strong>CAL : </strong>
            </Typography>
            <CustomTimeline
              items={cals}
              visibleItems={1}
              renderDot={(item) => (
                <TimelineDot
                  color={activeId === item.id ? "success" : "primary"}
                />
              )}
              renderTo={(item) => "/tiers/cals/detail/" + item.id}
              renderBody={(item) => (
                <Typography>
                  {Render.date(item.date_cal)} <br />
                  {item.nom_cal}
                </Typography>
              )}
            />
          </>
        )}
      </>
    );
  }
}

CalListView.propTypes = {
  cals: PropTypes.array.isRequired,
};

export default CalListView;
