import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import Button from "components/Button/Button";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

class GedPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: false,
      errorMessage: "Erreur.",
      import_files: [],
      snackbar: false,
      duplicated: null,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      error: false,
      errorMessage: null,
      duplicated: null,
    });
  };

  displayDuplicatedFiles = (duplicated) => {
    return (
      <ul>
        {duplicated.map((d) => (
          <li key={d}>{d}</li>
        ))}
      </ul>
    );
  };

  handleSubmit = () => {
    const formData = new FormData();
    this.state.import_files.forEach((file) => {
      formData.append("documents[]", file);
    });
    formData.append("rootFolder", this.props.rootFolder);
    formData.append("section", this.props.section);
    formData.append("type", this.props.type);
    if (this.props?.objectId) {
      formData.append("objectId", this.props.objectId);
    }

    axiosApiBackendNoJson
      .post("/ged/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.props.uploadSuccess();
        let duplicated = res.data.duplicated;
        if (duplicated.length > 0) {
          this.setState({
            loading: false,
            error: true,
            errorMessage:
              "Les fichiers ont bien été enregistrés mais certains existent déjà. " +
              "Veuillez renommer ces fichiers si besoin, avant de les renvoyer :",
            duplicated: this.displayDuplicatedFiles(duplicated),
          });
        } else {
          this.setState({
            loading: false,
            open: false,
            snackbar: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 400 && error.response.data.error) {
            this.setState({
              error: true,
              errorMessage: error.response.data.error,
            });
          } else {
            this.setState({
              error: true,
              errorMessage: "Une erreur est survenue coté serveur.",
            });
          }
        } else {
          this.setState({
            error: true,
            errorMessage: "Erreur inconnue.",
          });
        }
      });

    this.setState({
      loading: true,
      error: false,
      errorMessage: null,
      import_files: [],
      duplicated: null,
    });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  render() {
    return (
      <>
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={this.closeSnackbar} severity="success">
            Les fichiers ont bien été enregistrés
          </Alert>
        </Snackbar>
        <Button color="primary" size="small" onClick={this.handleOpen}>
          Ajouter
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth={true}>
          <DialogTitle>Ajouter des documents</DialogTitle>
          <DialogContent>
            {this.state.error && (
              <Typography color="error">
                <p>{this.state.errorMessage}</p>
                {this.state.duplicated}
              </Typography>
            )}
            <FormControl style={{ width: "100%" }}>
              <FilePond
                labelIdle="Ajoutez les documents<br>(MAX 10Mo)"
                files={this.state.import_files}
                allowMultiple={true}
                maxTotalFileSize={10000000}
                acceptedFileTypes={this.props.acceptedFileTypes}
                onupdatefiles={(fileItems) => {
                  this.setState({
                    import_files: fileItems.map((fileItem) => fileItem.file),
                  });
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="onyx">
              Annuler
            </Button>
            <Button
              onClick={this.handleSubmit}
              color={this.props.color || "primary"}
              loading={this.state.loading}
            >
              Envoyer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

GedPopup.propTypes = {
  rootFolder: PropTypes.oneOf(["documents", "templates"]).isRequired,
  section: PropTypes.oneOf(["immobilier", "travaux"]).isRequired,
  type: PropTypes.oneOf([
    "CL",
    "logement",
    "foyer",
    "CP",
    "proprietaire",
    "procedure",
    "apurement",
    "edl",
    "beneficiaire",
    "AD",
    "DT",
  ]).isRequired,
  objectId: PropTypes.any,
  uploadSuccess: PropTypes.any,
  color: PropTypes.string,
  acceptedFileTypes: PropTypes.array,
};

export default GedPopup;
