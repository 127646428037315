import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import MuiButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import BarChart from "@mui/icons-material/BarChart";
import Build from "@mui/icons-material/Build";
import Business from "@mui/icons-material/Business";
import Delete from "@mui/icons-material/Delete";
import FindInPage from "@mui/icons-material/FindInPage";
import Home from "@mui/icons-material/Home";
import Person from "@mui/icons-material/Person";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import ToggleChip from "components/CustomButtons/ToggleChip";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import SelectSearch from "components/CustomSelect/SelectSearch.jsx";
import UserSelectSearch from "components/CustomSelect/UserSelectSearch";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import ListTitle from "components/ListTitle/ListTitle";
import CheckableTree from "components/Tree/CheckableTree";

import AuditUtils from "Utils/AuditUtils";
import DateUtils from "Utils/DateUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import LogementTravauxUtils from "Utils/LogementTravauxUtils";
import Render from "Utils/RenderUtils";

class AuditsForm extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.EDIT_MODE = id ? true : false;
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/audits";
    this.FRONT_URL = "/audits";

    this.state = {
      // NESTED DATA
      auditresultats: [],
      listLogements: [],
      logement: null,
      dossier_travaux_avant: null,
      dossier_travaux_apres: null,
      // DATA FORM
      logement_travaux_id: +this.QUERY_PARAMS.get("logement_id") || "",
      donneur_ordres_id: "",
      date_redaction: "",
      date_demande: this.EDIT_MODE ? "" : DateUtils.toDBString(new Date()),
      date_visite: "",
      date_butoir: "",
      date_envoi: "",
      type_audit: "",
      est_audit_par_soliha: 1,
      technicien_id: "",
      conseiller_id: "",
      resultat: "",
      description: "",
      statut: "",
      reference_externe: "",
      consommation_energetique: "",
      frais_paiement: "",
      frais_prevu: "",
      frais_facture: "",
      date_facture: "",
      date_paiement: "",
      sous_type: "",
      raison_abandon_id: "",
      validated: "",
      etiquette_ges: "",
      co2: "",
      // END DATA FORM
      loading: false,
      errors: null,
      id: id,
      alert: null,
      openAlertCancelAd: false,
      openAlertOpenAd: false,
      openReplicateDialog: false,
      initialValues: {},
      modifiedFields: [],
      dtFieldsToUpdate: {
        date_visite: false,
        id: +this.QUERY_PARAMS.get("dossier_travaux_id") || "",
      },
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        state.initialValues = {
          date_visite: state.date_visite,
        };
        state.dtFieldsToUpdate = {
          id: state.dossier_travaux_avant?.id,
        };

        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeLogement = (name, value) => {
    this.setState({ [name]: value });
    this.updateInfo(this.state.listLogements, value);
  };

  updateInfo = (data, logementId) => {
    let logement = data.find((logement) => logement.id === logementId);
    if (logement) {
      this.setState({ logement: logement });
    }
  };

  getLogementTravaux = (datas) => {
    this.updateInfo(datas, this.state.logement_travaux_id);
    this.setState({
      listLogements: datas,
    });
  };

  handleChangeDossierTravaux = (name, value) => {
    this.setState((prevState) => ({
      dtFieldsToUpdate: {
        ...prevState.dtFieldsToUpdate,
        id: value,
      },
    }));
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeArray = (key, event) => {
    let newData = this.state.auditresultats;

    newData[key][event.target.name] = event.target.value;
    this.setState({ auditresultats: newData });
  };

  handleTreeArray = (key, listIds) => {
    let newData = this.state.auditresultats;

    newData[key].typetravaux.leaf_ids = listIds;
    this.setState({ auditresultats: newData });
  };

  handleClick = (key, name) => {
    let newData = this.state.auditresultats;
    let index = newData.findIndex((estimation) => estimation.validated);

    if (newData.length > 0 && index >= 0 && newData[index] != newData[key]) {
      newData[index][name] = null;
    }
    newData[key][name] = !newData[key][name];
    this.setState({ auditresultats: newData });
  };

  handleSubmit = () => {
    const {
      id,
      dossier_travaux_avant,
      dossier_travaux_apres,
      date_visite,
      initialValues,
    } = this.state;

    const modifiedFields = [];
    const editMode = id ? true : false;

    // Check if date_visite has changed in edit mode
    if (editMode && date_visite !== initialValues?.date_visite) {
      if (dossier_travaux_avant) {
        modifiedFields.push({
          name: "date_visite",
          label: "Date de visite avant travaux",
        });
      } else if (dossier_travaux_apres) {
        modifiedFields.push({
          name: "date_visite",
          label: "Date de visite après travaux",
        });
      }
    }

    if (modifiedFields.length > 0) {
      this.setState({ openReplicateDialog: true, modifiedFields });
    } else {
      this.submitForm();
    }
  };

  submitForm = (dtUpdate = true) => {
    const { id } = this.state;

    const dataIn = {
      logement_travaux_id: this.state.logement_travaux_id,
      donneur_ordres_id: this.state.donneur_ordres_id,
      date_redaction: this.state.date_redaction,
      date_demande: this.state.date_demande,
      date_visite: this.state.date_visite,
      date_butoir: this.state.date_butoir,
      date_envoi: this.state.date_envoi,
      type_audit: this.state.type_audit,
      est_audit_par_soliha: this.state.est_audit_par_soliha,
      technicien_id: this.state.technicien_id,
      conseiller_id: this.state.conseiller_id,
      resultat: this.state.resultat,
      description: this.state.description,
      auditresultats: this.state.auditresultats,
      reference_externe: this.state.reference_externe,
      consommation_energetique: this.state.consommation_energetique,
      frais_paiement: this.state.frais_paiement,
      frais_prevu: this.state.frais_prevu,
      date_facture: this.state.date_facture,
      frais_facture: this.state.frais_facture,
      date_paiement: this.state.date_paiement,
      sous_type: this.state.sous_type,
      raison_abandon_id: this.state.raison_abandon_id,
      etiquette_ges: this.state.etiquette_ges,
      co2: this.state.co2,
    };

    if (dtUpdate) {
      dataIn.dtFieldsToUpdate = this.state.dtFieldsToUpdate;
    }

    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
      openReplicateDialog: false,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  addRow = () => {
    let newData = [...this.state.auditresultats];

    newData.push({
      description: "",
      resultat: "",
      consommation_energetique: "",
      etiquette_ges: "",
      co2: "",
      validated: null,
      typetravaux: { leaf_ids: [] },
    });
    this.setState({
      auditresultats: newData,
    });
  };

  onClickDeleteButton = (event, key) => {
    this.setState({
      alert: (
        <AlertDialog
          title={"Voulez-vous supprimer l'estimation " + (key + 1) + " ?"}
          onConfirm={() => this.handleDelete(key)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDelete = (key) => {
    this.clearAlert();
    let newData = this.state.auditresultats;
    newData.splice(key, 1);
  };

  //Alert cancel dossiers travaux
  clearAlertCancelAd = () => {
    this.setState({ openAlertCancelAd: false });
  };
  handleAlertCancelAd = () => {
    if (!this.state.raison_abandon_id) {
      // check raison abandon is present
      this.setState({
        errors: { raison_abandon_id: true },
      });
    } else {
      this.handleSubmit();
      this.clearAlertCancelAd();
    }
  };
  onClickCancelAd = () => {
    this.setState({
      openAlertCancelAd: true,
    });
  };

  //Alert open dossiers travaux
  clearAlertOpenAd = () => {
    this.setState({ openAlertOpenAd: false });
  };
  handleAlertOpenAd = () => {
    this.setState(
      {
        raison_abandon_id: "",
      },
      () => {
        this.handleSubmit();
      },
    );
    this.clearAlertOpenAd();
  };
  onClickOpenAd = () => {
    this.setState({
      openAlertOpenAd: true,
    });
  };

  render() {
    const { logement, errors, est_audit_par_soliha, type_audit } = this.state;
    const beneficiaire = logement?.beneficiaire;
    const type = AuditUtils.TYPE_AUDIT.find((item) => item.name === type_audit);
    const sousTypes = type?.sous_types;
    const resultats = type?.resultats;

    return (
      <Grid container columnSpacing={2}>
        <AlertDialog
          open={this.state.openAlertCancelAd}
          title="Annuler l'audit"
          onConfirm={this.handleAlertCancelAd}
          confirmLabel="Oui"
          confirmColor="error"
          onCancel={this.clearAlertCancelAd}
          cancelLabel="Non"
          cancelColor="info"
          fullWidth
        >
          <Grid item xs={12}>
            <SelectSearch
              label="Raison abandon"
              name="raison_abandon_id"
              apiUrl="/config-raison-abandon-audit"
              onChange={this.handleChangeByName}
              value={this.state.raison_abandon_id}
              buildOptionLabel={(data) => data.value}
              shrink
              error={Boolean(errors?.raison_abandon_id)}
            />
          </Grid>
        </AlertDialog>
        <AlertDialog
          open={this.state.openAlertOpenAd}
          title="Rouvrir l'audit?"
          onConfirm={this.handleAlertOpenAd}
          confirmLabel="Oui"
          confirmColor="error"
          onCancel={this.clearAlertOpenAd}
          cancelLabel="Non"
          cancelColor="info"
          fullWidth
        />
        <AlertDialog
          open={this.state.openReplicateDialog}
          title="Mettre à jour les informations du Dossier Travaux associé ?"
          content="Veuillez cocher les champs que vous souhaitez aussi mettre à jour dans le Dossier Travaux."
          confirmLabel="Propager"
          onConfirm={this.submitForm}
          cancelLabel="Aucune"
          onCancel={() => this.submitForm(false)}
        >
          {this.state.modifiedFields.map((field) => (
            <Grid item key={field.name} xs={12}>
              <CheckBoxInput
                label={field.label}
                name={field.name}
                value={this.state.dtFieldsToUpdate[field.name]}
                onChange={(name, value) => {
                  this.setState((prevState) => ({
                    dtFieldsToUpdate: {
                      ...prevState.dtFieldsToUpdate,
                      [name]: value,
                    },
                  }));
                }}
              />
            </Grid>
          ))}
        </AlertDialog>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar
                  sx={{ bgcolor: beneficiaire?.categorie_ressource?.color_mpr }}
                >
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Bénéficiaire
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Prénom : </strong>
                {beneficiaire?.prenom}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {beneficiaire?.nom}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephone(beneficiaire?.tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(beneficiaire?.mail)}
              </Typography>
              <Typography>
                <strong>Statut de propriété : </strong>
                {beneficiaire?.statut_propriete?.value}
              </Typography>
              <Typography>
                <strong>Catégorie de ressources : </strong>
                {beneficiaire?.categorie_ressource?.value}
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <FindInPage />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier l&apos;Audit</>
                  ) : (
                    <>Ajouter un Audit</>
                  )}
                </Typography>
              }
              action={
                <>
                  {this.EDIT_MODE &&
                    this.state.statut != AuditUtils.STATUT[5] &&
                    this.state.date_demande && (
                      <Button color="error" onClick={this.onClickCancelAd}>
                        Annuler audit
                      </Button>
                    )}
                  {this.EDIT_MODE &&
                    this.state.statut === AuditUtils.STATUT[5] && (
                      <Button color="error" onClick={this.onClickOpenAd}>
                        Rouvrir audit
                      </Button>
                    )}
                </>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Fait par Soliha"
                    name="est_audit_par_soliha"
                    value={this.state.est_audit_par_soliha}
                    onChange={this.handleChange}
                    error={Boolean(errors?.est_audit_par_soliha)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Type audit"
                    name="type_audit"
                    onChange={this.handleChange}
                    value={this.state.type_audit}
                    error={Boolean(errors?.type_audit)}
                  >
                    <MenuItem value="">
                      <em>Non renseigné</em>
                    </MenuItem>
                    {AuditUtils.TYPE_AUDIT.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                {sousTypes != null && (
                  <Grid item xs={12} lg={6}>
                    <SelectInput
                      label="Sous type"
                      name="sous_type"
                      onChange={this.handleChange}
                      value={this.state.sous_type}
                      error={Boolean(errors?.sous_type)}
                    >
                      <MenuItem value="">
                        <em>Non renseigné</em>
                      </MenuItem>
                      {sousTypes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </SelectInput>
                  </Grid>
                )}
                {est_audit_par_soliha === 1 && (
                  <>
                    <Grid item xs={12}>
                      <HeaderTitle label="Suivi" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date de demande"
                        name="date_demande"
                        type="date"
                        value={this.state.date_demande}
                        onChange={this.handleChange}
                        error={Boolean(errors?.date_demande)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date de visite"
                        name="date_visite"
                        type="date"
                        value={this.state.date_visite}
                        onChange={this.handleChange}
                        error={Boolean(errors?.date_visite)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date butoir"
                        name="date_butoir"
                        type="date"
                        value={this.state.date_butoir}
                        onChange={this.handleChange}
                        error={Boolean(errors?.date_butoir)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextInput
                        label="Date d'envoi"
                        name="date_envoi"
                        type="date"
                        value={this.state.date_envoi}
                        onChange={this.handleChange}
                        error={Boolean(errors?.date_envoi)}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <HeaderTitle label="Résultat" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de rédaction"
                    name="date_redaction"
                    type="date"
                    value={this.state.date_redaction}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_redaction)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {resultats != null ? (
                    <SelectInput
                      label="Résultat"
                      name="resultat"
                      onChange={this.handleChange}
                      value={this.state.resultat}
                      error={Boolean(errors?.resultat)}
                    >
                      {resultats.map((note) => (
                        <MenuItem key={note.text} value={note.value}>
                          {note.text}
                        </MenuItem>
                      ))}
                    </SelectInput>
                  ) : (
                    <TextInput
                      label="Résultat"
                      name="resultat"
                      onChange={this.handleChange}
                      value={this.state.resultat}
                      error={Boolean(errors?.resultat)}
                    />
                  )}
                </Grid>
                {this.state.type_audit === AuditUtils.TYPE_AUDIT[1].name && (
                  <>
                    <Grid item xs={12} lg={4}>
                      <SelectInput
                        label="Étiquette GES"
                        name="etiquette_ges"
                        onChange={this.handleChange}
                        value={this.state.etiquette_ges}
                        error={Boolean(errors?.etiquette_ges)}
                      >
                        {resultats?.map((note) => (
                          <MenuItem key={note.text} value={note.value}>
                            {note.text}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextInput
                        label="Consommation énergétique"
                        name="consommation_energetique"
                        multiline
                        value={this.state.consommation_energetique}
                        onChange={this.handleChange}
                        error={Boolean(errors?.consommation_energetique)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextInput
                        label="CO2 (/m²/an)"
                        name="co2"
                        multiline
                        value={this.state.co2}
                        onChange={this.handleChange}
                        error={Boolean(errors?.co2)}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <TextInput
                    label="Informations complémentaires"
                    name="description"
                    multiline
                    value={this.state.description}
                    onChange={this.handleChange}
                    error={Boolean(errors?.description)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <SelectSearch
                    name="logement_travaux_id"
                    label="Choisir un Logement"
                    apiUrl="/logements-travaux"
                    sortResponseData={(a, b) => b.id - a.id}
                    value={this.state.logement_travaux_id}
                    onChange={this.handleChangeLogement}
                    onResponse={this.getLogementTravaux}
                    buildOptionLabel={LogementTravauxUtils.renderForList}
                    shrink
                    error={Boolean(errors?.logement_travaux_id)}
                  />
                  <Typography>
                    <strong>Adresse : </strong>
                    {Render.address(logement?.adresse)}
                  </Typography>
                  <Typography>
                    <strong>Ville : </strong>
                    {logement?.adresse?.ville?.cp}{" "}
                    {logement?.adresse?.ville?.ville}
                  </Typography>
                  <Typography>
                    <strong>Étage : </strong>
                    {logement?.adresse?.etage}
                  </Typography>
                  <Typography>
                    <strong>Complément de localisation : </strong>
                    {logement?.adresse?.complement_localisation}
                  </Typography>
                  <Typography>
                    <strong>Type : </strong>
                    {logement?.type?.value}
                  </Typography>
                  <Typography>
                    <strong>Typologie : </strong>
                    {logement?.typologie?.value}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Donneur d&apos;ordres
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <SelectSearch
                    name="donneur_ordres_id"
                    label="Donneur d'ordres"
                    apiUrl="/donneurs-ordres"
                    onChange={this.handleChangeByName}
                    value={this.state.donneur_ordres_id}
                    buildOptionLabel={(data) => data.nom}
                    shrink
                    error={Boolean(errors?.donneur_ordres_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Référence externe / BDC"
                    name="reference_externe"
                    value={this.state.reference_externe}
                    onChange={this.handleChange}
                    error={Boolean(errors?.reference_externe)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Business />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Soliha
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                {est_audit_par_soliha === 1 && (
                  <>
                    <Grid item xs={12}>
                      <UserSelectSearch
                        label="Technicien en charge"
                        name="technicien_id"
                        apiUrl="/user-list/techniciens"
                        onChange={this.handleChangeByName}
                        value={this.state.technicien_id}
                        error={Boolean(errors?.technicien_id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <UserSelectSearch
                        label="Conseiller en charge"
                        name="conseiller_id"
                        apiUrl="/user-list/conseillers"
                        onChange={this.handleChangeByName}
                        value={this.state.conseiller_id}
                        error={Boolean(errors?.conseiller_id)}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <HeaderTitle label="Facturation" />
                </Grid>
                <Grid item xs={12}>
                  <CurrencyInput
                    label="Frais prévus"
                    name="frais_prevu"
                    value={this.state.frais_prevu}
                    onChange={this.handleChange}
                    error={Boolean(errors?.frais_prevu)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de facturation"
                    name="date_facture"
                    type="date"
                    value={this.state.date_facture}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_facture)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Frais facturés"
                    name="frais_facture"
                    multiline
                    value={this.state.frais_facture}
                    onChange={this.handleChange}
                    error={Boolean(errors?.frais_facture)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de paiement"
                    name="date_paiement"
                    type="date"
                    value={this.state.date_paiement}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_paiement)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Frais payés"
                    name="frais_paiement"
                    multiline
                    margin="normal"
                    value={this.state.frais_paiement}
                    onChange={this.handleChange}
                    error={Boolean(errors?.frais_paiement)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Dossier Travaux
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <SelectSearch
                    label="Audit Avant Travaux - Choisir un Dossier Travaux"
                    name="dossier_travaux_id"
                    apiUrl={
                      !this.state.logement_travaux_id
                        ? null
                        : `/dossiers-travaux`
                    }
                    filterKey="logement_id"
                    filter={this.state.logement_travaux_id}
                    sortResponseData={(a, b) => b.id - a.id}
                    value={this.state.dtFieldsToUpdate.id}
                    onChange={this.handleChangeDossierTravaux}
                    buildOptionLabel={DossierTravauxUtils.renderForListForAudit}
                    shrink
                    error={Boolean(errors?.["dtFieldsToUpdate.id"])}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <BarChart />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Propositions d&apos;améliorations de l&apos;habitat
                </Typography>
              }
            />
            <CardContent>
              {this.state.auditresultats.map((resultat, key) => (
                <Grid container spacing={2} alignItems="center" key={key}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ paddingTop: "35px" }}
                  >
                    <Grid
                      xs={6}
                      item
                      container
                      direction="row"
                      alignItems="center"
                    >
                      <ListTitle>Estimation {key + 1} :</ListTitle>
                      <ToggleChip
                        sx={{
                          marginLeft: "10px",
                        }}
                        label="Valider"
                        color={resultat.validated ? "primary" : "default"}
                        on={Boolean(resultat.validated)}
                        onClick={() => this.handleClick(key, "validated")}
                      />
                    </Grid>
                    {!resultat.validated && (
                      <Tooltip
                        title="Supprimer cette estimation"
                        placement="left"
                      >
                        <MuiButton
                          size="large"
                          color="error"
                          onClick={(event) =>
                            this.onClickDeleteButton(event, key)
                          }
                        >
                          <Delete />
                        </MuiButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {resultats != null ? (
                      <SelectInput
                        label="Résultat"
                        name="resultat"
                        onChange={(event) => this.handleChangeArray(key, event)}
                        value={resultat.resultat ?? ""}
                        error={Boolean(
                          errors?.["auditresultats." + key + ".resultat"],
                        )}
                      >
                        {resultats?.map((note) => (
                          <MenuItem key={note.text} value={note.value}>
                            {note.text}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    ) : (
                      <TextInput
                        label="Résultat"
                        name="resultat"
                        onChange={(event) => this.handleChangeArray(key, event)}
                        value={resultat.resultat ?? ""}
                        error={Boolean(
                          errors?.["auditresultats." + key + ".resultat"],
                        )}
                      />
                    )}
                  </Grid>
                  {this.state.type_audit === AuditUtils.TYPE_AUDIT[1].name && (
                    <>
                      <Grid item xs={12} lg={4}>
                        <SelectInput
                          label="Étiquette GES"
                          name="etiquette_ges"
                          onChange={(event) =>
                            this.handleChangeArray(key, event)
                          }
                          value={resultat?.etiquette_ges ?? ""}
                          error={Boolean(
                            errors?.[
                              "auditresultats." + key + ".etiquette_ges"
                            ],
                          )}
                        >
                          {resultats.map((note) => (
                            <MenuItem key={note.text} value={note.value}>
                              {note.text}
                            </MenuItem>
                          ))}
                        </SelectInput>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <TextInput
                          label="Consommation énergétique"
                          name="consommation_energetique"
                          multiline
                          value={resultat?.consommation_energetique ?? ""}
                          onChange={(event) =>
                            this.handleChangeArray(key, event)
                          }
                          error={Boolean(
                            errors?.[
                              "auditresultats." +
                                key +
                                ".consommation_energetique"
                            ],
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <TextInput
                          label="CO2 (m²/an)"
                          name="co2"
                          multiline
                          value={resultat?.co2 ?? ""}
                          onChange={(event) =>
                            this.handleChangeArray(key, event)
                          }
                          error={Boolean(
                            errors?.["auditresultats." + key + ".co2"],
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <TextInput
                      label="Description"
                      name="description"
                      multiline
                      value={resultat.description ?? ""}
                      onChange={(event) => this.handleChangeArray(key, event)}
                      error={Boolean(
                        errors?.["auditresultats." + key + ".description"],
                      )}
                    />
                  </Grid>
                  {this.state.type_audit === AuditUtils.TYPE_AUDIT[1].name && (
                    <>
                      <Grid item xs={12}>
                        {this.state.consommation_energetique &&
                          resultat.consommation_energetique && (
                            <Typography>
                              <strong>Taux d&apos;amélioration : </strong>
                              {AuditUtils.averageConsoEnergy(
                                this.state.consommation_energetique,
                                resultat.consommation_energetique,
                              )}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.co2 && resultat.co2 && (
                          <Typography>
                            <strong>Taux d&apos;amélioration CO2 : </strong>
                            {AuditUtils.averageConsoEnergy(
                              this.state.co2,
                              resultat.co2,
                            )}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <CheckableTree
                      backendUrl="/config-audit-type-travaux"
                      initCheckList={resultat.typetravaux?.leaf_ids ?? []}
                      defaultExpanded={resultat.typetravaux?.parent_ids ?? []}
                      label="Travaux"
                      onChange={(listIds) => this.handleTreeArray(key, listIds)}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                sx={{ paddingBottom: 1 }}
              >
                <Grid item>
                  <Tooltip title="Ajouter une estimation" placement="left">
                    <MuiButton
                      size="large"
                      color="primary"
                      onClick={this.addRow}
                    >
                      <Add />
                    </MuiButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
        {this.state.alert}
      </Grid>
    );
  }
}

AuditsForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default AuditsForm;
