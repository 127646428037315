import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";

class CheckboxRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { checked: props.checked };
  }

  handleChange = () => {
    const toggle = !this.state.checked;
    this.props.onChange(toggle);
    this.setState({ checked: toggle });
  };

  render() {
    return (
      <Checkbox
        onChange={this.handleChange}
        checked={Boolean(this.state.checked)}
      />
    );
  }
}

CheckboxRenderer.propTypes = {
  onChange: PropTypes.any,
  checked: PropTypes.any,
};

export default CheckboxRenderer;
