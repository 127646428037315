import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import styled from "@mui/system/styled";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";

// @mui/icons-material
import Menu from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Render from "Utils/RenderUtils";

// core components

const HistoryList = styled(List)({
  paddingTop: "0",
  maxWidth: "700px",
});

class DetailsPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: "",
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { operation, entityLink, entityName } = this.props;

    return (
      <>
        <IconButton size="small" onClick={this.handleClick}>
          <Menu />
        </IconButton>
        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <List>
            <ListItem>
              <ListItemText>
                <strong>Date Comptable : </strong>
                {Render.date(operation.date)}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <strong>Date : </strong>
                {Render.date(operation.date_ae)}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <strong>Pris en compte dans le calcul du solde : </strong>
                {Render.boolean(operation.active_pour_solde)}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Opération créée{" "}
                {operation.genere_auto ? "automatiquement" : "manuellement"}.
              </ListItemText>
            </ListItem>
            {operation.id_paiement && (
              <ListItem>
                <ListItemText>
                  <strong>Identifiant de paiement : </strong>
                  {operation.id_paiement}
                </ListItemText>
              </ListItem>
            )}
            {operation.operation && (
              <ListItem>
                <ListItemText>
                  Une opération equivalente est lié sur l&apos;échéancier du{" "}
                  <Link
                    to={`/${entityLink}/detail/${operation.operation.id_contrat}`}
                  >
                    {entityName}
                    {operation.operation.id_contrat}
                  </Link>
                  .
                </ListItemText>
              </ListItem>
            )}
            {operation.cl_id && (
              <ListItem>
                <ListItemText>
                  Cette opération est en rapport avec le{" "}
                  <Link to={"/contrats-locataires/detail/" + operation.cl_id}>
                    CL
                    {operation.cl_id}
                  </Link>
                  .
                </ListItemText>
              </ListItem>
            )}
            {operation.creee_par && (
              <ListItem>
                <ListItemText>
                  <strong>Crée par : </strong>
                  {operation.creee_par}
                </ListItemText>
              </ListItem>
            )}
            <ListItem>
              <ListItemText>
                <strong>Historique : </strong>
              </ListItemText>
            </ListItem>
            <ListItem>
              <HistoryList>
                {operation.historique.split("ØÅ").map((op, key) => (
                  <ListItem key={key}>
                    <ListItemText primary={op} />
                  </ListItem>
                ))}
              </HistoryList>
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }
}

DetailsPopover.propTypes = {
  operation: PropTypes.any,
  entityLink: PropTypes.any,
  entityName: PropTypes.any,
};

export default DetailsPopover;
