import styled from "@mui/system/styled";
import MuiCardHeader from "@mui/material/CardHeader";

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: 0,
  paddingTop: "0px",
  "& .MuiCardHeader-avatar": {
    marginTop: theme.spacing(-2),
    alignSelf: "start",
  },
  "& .MuiCardHeader-content ": {
    alignSelf: "start",
  },
  "& .MuiCardHeader-action": {
    marginTop: theme.spacing(0.5),
  },
}));

export default CardHeader;
