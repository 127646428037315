export default class SearchBarService {
  constructor(url) {
    this.URL = url;
    this.SEARCH_VALUE_KEY = `searchBar${url}`;
    this.SEARCH_HISTORY_KEY = `historySearch${url}`;
  }

  retrieveSearchValue() {
    return sessionStorage.getItem(this.SEARCH_VALUE_KEY) || "";
  }

  retrieveSearchHistory() {
    let historySearch = sessionStorage.getItem(this.SEARCH_HISTORY_KEY) || "";
    return historySearch.split(",");
  }

  storeSearch(searchValue, gridApi, bypassFilter = false) {
    this.storeSearchValue(searchValue);
    this.storeSearchHistory(gridApi, bypassFilter);
  }

  storeSearchValue(searchValue) {
    if (searchValue !== null && searchValue !== undefined) {
      sessionStorage.setItem(this.SEARCH_VALUE_KEY, searchValue);
    } else {
      sessionStorage.removeItem(this.SEARCH_VALUE_KEY);
    }
  }

  storeSearchHistory(gridApi, bypassFilter = false) {
    setTimeout(() => {
      let searchHistory = [];
      let fn = (node) => {
        searchHistory.push(node.data.id);
      };
      if (null != gridApi) {
        if (bypassFilter) {
          gridApi.forEachNode(fn);
        } else {
          gridApi.forEachNodeAfterFilter(fn);
        }
      }
      sessionStorage.setItem(this.SEARCH_HISTORY_KEY, searchHistory);
    }, 1);
  }

  getOffsetUrl(currentId, offset = 1, path = "/detail/") {
    let ids = this.retrieveSearchHistory();
    let nextId = this.constructor.getIdOffset(ids, currentId, offset);
    return nextId ? this.URL + path + nextId : null;
  }

  static getIdOffset(idArr, currentId, offset) {
    let posIndex = idArr.indexOf(currentId + "");
    return idArr[posIndex + offset] || null;
  }
}
