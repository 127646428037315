import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import AddRounded from "@mui/icons-material/AddRounded";

import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((/*theme*/) => ({
  root: {
    height: "calc(100% - 46px)",
    minHeight: "250px",
  },
  centeredGrid: {
    height: "100%",
    width: "100%",
  },
}));

function AddCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader />
      <Button
        className={classes.centeredGrid}
        variant="text"
        aria-label="add"
        size="medium"
        onClick={props.onClick}
      >
        <Grid
          container
          columnSpacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <AddRounded fontSize="large" />
          </Grid>
        </Grid>
      </Button>
    </Card>
  );
}

AddCard.propTypes = {
  onClick: PropTypes.func,
};

export default AddCard;
