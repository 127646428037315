import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import GetApp from "@mui/icons-material/GetApp";
import AddBox from "@mui/icons-material/AddBox";
import Description from "@mui/icons-material/Description";
import TrendingUp from "@mui/icons-material/TrendingUp";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgGridUtils from "Utils/AgGridUtils";
import Render from "Utils/RenderUtils";
import { withUserContext } from "context/UserContext";

class IRLs extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/irls";
    this.FRONT_URL = "/tiers/irls";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          cellDataType: "number",
        },
        {
          headerName: "Année",
          field: "annee",
          cellDataType: "number",
          sort: "desc",
          sortIndex: 0,
        },
        {
          headerName: "Trimestre",
          field: "trimestre",
          valueFormatter: (params) => "T" + params.value,
          sort: "desc",
          sortIndex: 1,
        },
        {
          headerName: "Valeur",
          field: "valeur",
          cellDataType: "number",
        },
        {
          headerName: "Publication",
          field: "publication",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.date(params.value),
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      this.setState({ data: result.data });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container columnSpacing={2} sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <TrendingUp />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">
                  Gestion des Indices de Référence des Loyers
                </Typography>
              }
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                alignItems="center"
                ref={this.filtersRef}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <div>
                    <strong>Source des IRL :</strong>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.anil.org/outils/indices-et-plafonds/tableau-de-lirl/"
                    >
                      https://www.anil.org/outils/indices-et-plafonds/tableau-de-lirl/
                    </a>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL + "/ajouter"}>
            <Button size="small" square round>
              <AddBox />
            </Button>
          </Link>
          <Button
            size="small"
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=IRL"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

IRLs.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(IRLs);
