import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";

class CalsForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/cals";
    this.FRONT_URL = "/tiers/cals";

    this.state = {
      // NESTED
      logements: [],
      // DATA FORM
      nom_cal: "",
      info: "",
      date_cal: "",
      // END DATA FORM
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom_cal: this.state.nom_cal,
      info: this.state.info,
      date_cal: this.state.date_cal,
      logements: this.state.logements.map((c) => c.id),
    };
    if (id) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + id, dataIn)
        .then(() => {
          this.props.history.push(this.FRONT_URL + "/detail/" + id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, dataIn)
        .then((res) => {
          this.props.history.push(this.FRONT_URL + "/detail/" + res.data.id);
        })
        .catch((err) => {
          this.handleError(err);
        });
    }

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleError = (error) => {
    this.setState({
      errors: error.response?.data?.errors ?? {},
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors } = this.state;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <RecordVoiceOver />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? <>Modifier la CAL</> : <>Ajouter une CAL</>}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nom"
                    name="nom_cal"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.nom_cal}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.nom_cal)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Date"
                    name="date_cal"
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.date_cal}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.date_cal)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Info complémentaire"
                    name="info"
                    multiline
                    maxRows={8}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.info}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.info)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

CalsForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default CalsForm;
