import React from "react";
import { PropTypes } from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// @mui/icons-material
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";

function CheckBoxView(props) {
  const { label, value } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography>
        <strong>{label} :&nbsp;</strong>
      </Typography>
      {value ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank />}
    </Box>
  );
}

CheckBoxView.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
};

export default CheckBoxView;
