export default class QueryUtils {
  static replaceSearchParams = (queryFilters = {}) => {
    const pathname = window.location.pathname;
    let queryParams = new URLSearchParams(queryFilters).toString();

    if (queryParams) {
      queryParams = "?" + queryParams;
    }

    window.history.replaceState(null, null, pathname + queryParams);
  };

  static deleteSearchParams = (key) => {
    // Get the query parameters as a URLSearchParams object
    const params = new URLSearchParams(window.location.search);

    // Delete the parameter with the specified key
    params.delete(key);

    // Build the new URL
    let newUrl = window.location.pathname;
    const restParams = params.toString();

    if (restParams !== "") {
      newUrl += "?" + restParams;
    }

    window.history.replaceState({}, "", newUrl);
  };
}
