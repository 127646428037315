/* eslint-disable react/prop-types */
import React from "react";
import { IMaskInput } from "react-imask";

// prop-types does not work with React.forwardRef
export default React.forwardRef(function IbanIMaskInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#### #### #### #### #### #### #### #### #### ####"
      definitions={{
        "#": /[a-zA-Z0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});
