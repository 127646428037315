import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material

// core components
import Render from "Utils/RenderUtils";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";

class AdresseView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { adresse, hideAdresseDetails, hideRegion } = this.props;

    return (
      <>
        <Typography>
          <strong>Adresse : </strong>
          {Render.address(adresse)}
        </Typography>
        <Typography>
          <strong>Ville : </strong>
          {adresse?.ville?.cp} {adresse?.ville?.ville}
        </Typography>
        {!hideRegion && (
          <Typography>
            <strong>Région : </strong>
            {adresse?.region}
          </Typography>
        )}
        <Typography>
          <strong>Latitude : </strong>
          {adresse?.latitude}
        </Typography>
        <Typography>
          <strong>Longitude : </strong>
          {adresse?.longitude}
        </Typography>
        {!hideAdresseDetails && (
          <>
            <Typography>
              <strong>Numéro logement : </strong>
              {adresse?.numero_logement}
            </Typography>
            <Typography>
              <strong>Étage : </strong>
              {adresse?.etage}
            </Typography>
            <Typography>
              <strong>Complément de localisation : </strong>
            </Typography>
            <MultilineTypography sx={{ px: 1 }}>
              {adresse?.complement_localisation}
            </MultilineTypography>
          </>
        )}
      </>
    );
  }
}

AdresseView.defaultProps = {
  hideAdresseDetails: false,
  hideRegion: false,
};

AdresseView.propTypes = {
  adresse: PropTypes.any,
  hideAdresseDetails: PropTypes.bool,
  hideRegion: PropTypes.bool,
};

export default AdresseView;
