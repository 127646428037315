import React from "react";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import Divider from "@mui/material/Divider";

const ColoredDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&::before, &::after": {
    borderColor: theme.palette.primary.main,
    alignSelf: "center",
    transform: "none",
  },
}));

function HeaderTitle(props) {
  const { label } = props;

  return (
    <ColoredDivider>
      <Typography variant="subtitle1">{label}</Typography>
    </ColoredDivider>
  );
}

HeaderTitle.propTypes = {
  label: PropTypes.any,
};

export default HeaderTitle;
