import React, { Component } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Handyman from "@mui/icons-material/Handyman";

import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import StarsRatingRender from "components/CustomAgEditor/StarsRatingRender";
import SearchBar from "components/SearchBar/SearchBar.jsx";

import SearchBarService from "services/SearchBarService";

import AgGridUtils from "Utils/AgGridUtils";
import BeneficiaireUtils from "Utils/BeneficiaireUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";
import Render from "Utils/RenderUtils";

class DevisTravaux extends Component {
  constructor(props) {
    super(props);

    this.FRONT_URL = "/dossiers-travaux/detail/";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.NO_YES = ["Non", "Oui"];

    this.state = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "Évaluation",
          children: [
            {
              headerName: "Note",
              field: "note",
              minWidth: 100,
              cellRenderer: StarsRatingRender,
              cellRendererParams: {
                readOnly: true,
                precision: 0.5,
                fontSize: "13px",
                size: "small",
              },
            },
          ],
        },
        {
          headerName: "Dossier Travaux",
          children: [
            {
              headerName: "Dossier Travaux",
              valueGetter: (params) =>
                DossierTravauxUtils.renderIdStatut(params.data.devisable),
              comparator: AgGridUtils.naturalSort,
            },
            {
              headerName: "Beneficiaire",
              field: "information",
              valueGetter: (params) =>
                BeneficiaireUtils.renderNomPrenom(
                  params.data.devisable.logement.beneficiaire,
                ),
            },
          ],
        },
        {
          headerName: "Devis",
          children: [
            {
              headerName: "Date",
              field: "reception",
              cellDataType: "dateString",
              valueFormatter: (params) => Render.date(params.value),
            },
            {
              headerName: "Montant HT",
              field: "montant_ht",
              cellDataType: "number",
              type: "numericColumn",
              valueFormatter: (params) => Render.euro(params.value),
            },
            {
              headerName: "Montant TTC",
              field: "montant_ttc",
              cellDataType: "number",
              type: "numericColumn",
              valueFormatter: (params) => Render.euro(params.value),
            },
            {
              headerName: "Validé",
              field: "valide",
              valueFormatter: (params) => this.NO_YES[params.value] ?? "",
            },
          ],
        },
        {
          headerName: "Facture",
          children: [
            {
              headerName: "Date",
              field: "date_facture",
              cellDataType: "dateString",
              valueFormatter: (params) => Render.date(params.value),
            },
            {
              headerName: "Montant HT",
              field: "montant_facture_ht",
              cellDataType: "number",
              type: "numericColumn",
              valueFormatter: (params) => Render.euro(params.value),
            },
            {
              headerName: "Montant TTC",
              field: "montant_facture_ttc",
              cellDataType: "number",
              type: "numericColumn",
              valueFormatter: (params) => Render.euro(params.value),
            },
            {
              headerName: "Description",
              field: "description",
            },
          ],
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    const dtId = event.node.data.devisable.id;
    this.props.history.push(this.FRONT_URL + dtId);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Handyman />
                </CardAvatar>
              }
              title={<Typography variant="h5">Devis</Typography>}
            />
            <CardContent>
              <Grid
                container
                columnSpacing={2}
                alignItems="flex-end"
                ref={this.filtersRef}
              >
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.props.devis}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    onRowDoubleClicked={this.goToView}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

DevisTravaux.propTypes = {
  devis: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.any,
};

export default DevisTravaux;
