import React from "react";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";
import Button from "components/Button/Button.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Typography from "@mui/material/Typography";
import FileUtils from "Utils/FileUtils";
import CircularLoading from "../Loading/CircularLoading";
import Autorenew from "@mui/icons-material/Autorenew";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

class CafAnnualReviewPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: false,
      errorMessage: "Erreur.",
      import_files: [],
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const formData = new FormData();
    this.state.import_files.forEach((file, i) => {
      formData.append("caf_file" + i, file);
    });

    axiosApiBackendNoJson
      .post("/caf/annualReview", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.handleClose();
        FileUtils.downLoadFile(
          res.data,
          res.headers,
          "REVISION-ANNUELLE-CAF.txt",
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 400 && error.response.data.error) {
            this.setState({
              error: true,
              errorMessage: error.response.data.error.message,
            });
          } else {
            this.setState({
              error: true,
              errorMessage: "Une erreur est survenue coté serveur.",
            });
          }
        } else {
          this.setState({
            error: true,
            errorMessage: "Erreur inconnu.",
          });
        }
      });
    this.setState({
      loading: true,
      error: false,
      errorMessage: "Erreur",
      import_files: [],
    });
  };

  render() {
    return (
      <>
        <Button
          fullWidth
          color="primary"
          size="small"
          startIcon={<Autorenew />}
          onClick={this.handleOpen}
        >
          Caf - Révision Annuelle
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth={true}>
          <DialogTitle>Caf - Révision Annuelle</DialogTitle>
          <DialogContent>
            {this.state.error && (
              <Typography color="error">
                <p>{this.state.errorMessage}</p>
              </Typography>
            )}
            <CircularLoading display={this.state.loading} />
            <FormControl style={{ width: "100%" }}>
              <FilePond
                labelIdle="Ajoutez le fichier vierge Caf<br>(MAX 10Mo)"
                files={this.state.import_files}
                allowMultiple={false}
                maxTotalFileSize={10000000}
                acceptedFileTypes={["text/csv", "text/plain"]}
                onupdatefiles={(fileItems) => {
                  this.setState({
                    import_files: fileItems.map((fileItem) => fileItem.file),
                  });
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="reversed">
              Annuler
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Envoyer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CafAnnualReviewPopup;
