import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material
import AttachFile from "@mui/icons-material/AttachFile";
import Euro from "@mui/icons-material/Euro";
import Description from "@mui/icons-material/Description";

// core components
import Ged from "views/Tables/Ged";
import DocumentTemplatePopup from "components/Popups/DocumentTemplatePopup";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import MenuBottom from "components/MenuBottom/MenuBottom";
import Button from "components/Button/Button";

// context
import { withUserContext } from "context/UserContext";

class ContratLocataireConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    // Ged permissions
    const permission = this.USER.can("config.ged.immobilier.cl");
    this.GED_CL_PERMISSIONS = {
      CAN_VIEW: permission,
      CAN_EDIT: permission,
      CAN_DELETE: permission,
    };
    this.FRONT_URL = "/configuration/contrats-locataires";
    this.LABEL = "contrat(s) locataire(s)";

    this.state = {};
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<AttachFile />}
            title="Origine fin de contrat"
            backendUrl="/config-origine_fin-contrat-cl"
            label={this.LABEL}
          />
          <ConfigContainer
            icon={<Euro />}
            title="Type de paiement"
            backendUrl="/config-type-paiement-cl"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {this.GED_CL_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="templates"
              section="immobilier"
              type="CL"
              permissions={this.GED_CL_PERMISSIONS}
              title="Modèles de documents"
              iconColor="config.main"
              buttonColor="config"
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/pdf",
              ]}
              popupComponent={DocumentTemplatePopup}
              docTypes={[
                { value: "ct_sous_loc", text: "Contrat: Sous-location" },
                { value: "ct_mg", text: "Contrat: Mandat de gestion" },
                { value: "ct_mggs", text: "Contrat: MGGS" },
                {
                  value: "autorisation_prelevement",
                  text: "Document d'autorisation de prélèvement",
                },
              ]}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigContratLocataire"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

ContratLocataireConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ContratLocataireConfig);
