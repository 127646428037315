import { axiosApiBackend } from "variables/axiosConfigs";

export default class User {
  loading = false;
  updated_at = null;
  access_token = null;
  id = null;
  firstname = null;
  lastname = null;
  permissions = null;
  roles = null;
  roleSlugs = null;

  constructor() {
    this.loadData();
  }

  loadData() {
    let data = JSON.parse(localStorage.getItem("user"));
    this.setData(data);
  }

  setData(data) {
    this.access_token = data?.access_token ?? this.access_token; // ensure to keep current token if not provided
    this.id = data?.user?.id;
    this.firstname = data?.user?.first_name;
    this.lastname = data?.user?.last_name;
    this.permissions = data?.permissions ?? [];
    this.roles = data?.roles ?? [];
    this.roleSlugs = this.roles.map((role) => role.slug);
    this.updated_at = data?.user?.updated_at;
  }

  storeData(data) {
    this.setData(data);
    localStorage.setItem("user", JSON.stringify(data) ?? "");
    // remove after mep
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("roles");
    localStorage.removeItem("permissions");
    // todo remove after mep
    localStorage.removeItem("name");
    localStorage.removeItem("role_name");
    localStorage.removeItem("role_slug");
    localStorage.removeItem("role_level");
    localStorage.removeItem("config");
    localStorage.removeItem("config_expiry");
    localStorage.removeItem("config_updated_at");
  }

  cleanData() {
    this.setData({});
    this.access_token = null;
    localStorage.removeItem("user");
  }

  isOutdated(headers) {
    const updatedAt = headers["x-user-updated-at"] ?? null;
    if (updatedAt == null) {
      // we skip, there is no data
      return;
    }

    return this.updated_at == null || updatedAt > this.updated_at;
  }

  getRefreshUrl() {
    return "/refresh-user";
  }

  refresh() {
    if (this.loading == false) {
      this.loading = true;

      axiosApiBackend
        .get(this.getRefreshUrl())
        .then((result) => {
          this.storeData(result.data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }

  getName() {
    return (this.firstname ?? "") + " " + (this.lastname ?? "");
  }

  getRolesNames() {
    return this.roles.map((role) => role.name).join(", ");
  }

  is(role) {
    if (role == null) {
      return true;
    }

    return this.roleSlugs.includes(role);
  }
  isOne(roles) {
    if (roles == null) {
      return true;
    }
    if (!Array.isArray(roles)) {
      roles = [roles];
    }
    if (roles.length === 0) {
      return true;
    }

    return roles.some((role) => this.is(role));
  }
  isChef() {
    const chefRoles = [
      "chefgla",
      "cheftravaux",
      "chefcesf",
      "direction",
      "admin",
    ];

    return this.roleSlugs.some((role) => chefRoles.includes(role));
  }

  can(permission) {
    if (permission == null) {
      return true;
    }
    return this.permissions.includes(permission);
  }
  canOne(permissions) {
    if (permissions == null) {
      return true;
    }
    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }
    if (permissions.length === 0) {
      return true;
    }

    return permissions.some((permission) => this.can(permission));
  }
}
