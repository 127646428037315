import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import MuiButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";
import Info from "@mui/icons-material/Info";

import AlertDialog from "components/AlertDialog/AlertDialog";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import LightTooltip from "components/Tooltip/LightTooltip";

import Render from "Utils/RenderUtils";

import AdresseForm from "views/Forms/Adresse/AdresseForm";

class CreateLogementForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/logements";

    this.state = {
      logement_id: props.logement_id ?? "",
      logement: props.logement ?? null,
      logement_key: 0,
      logements: [],

      openCreateLogementPopup: false,
      adresse: null,
      surface_principale: "",
      infos: "",
      typologie_logement_id: "",
      errors: {},
    };
  }

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  findLogement = (id, logements = null) => {
    logements = logements ?? this.state.logements;
    return logements?.find((l) => l.id === id) ?? null;
  };

  handleOnResponseLogement = (data) => {
    this.setState({
      logements: data,
    });
  };

  handleChangeLogement = (name, value) => {
    const selectedLogement = this.findLogement(value) ?? null;
    this.setState({
      logement: selectedLogement,
      logement_id: value,
    });

    if (this.props.onLogementChange) {
      this.props.onLogementChange(value);
    }
  };

  handleLogementCreated = (data) => {
    this.setState((prevState) => ({
      logement: data,
      logement_id: data.id,
      logement_key: prevState.logement_key + 1,
    }));

    if (this.props.onLogementChange) {
      this.props.onLogementChange(data.id);
    }
  };

  handleOpenCreateLogementPopup = () => {
    this.setState({ openCreateLogementPopup: true });
  };

  handleCloseCreateLogementPopup = () => {
    this.setState({
      openCreateLogementPopup: false,
      surface_principale: "",
      infos: "",
      adresse: null,
      typologie_logement_id: "",
      errors: {},
    });
  };

  handleSubmitCreateLogement = () => {
    const dataIn = {
      adresse: this.state.adresse,
      type: "Logement",
      typologie_logement_id: this.state.typologie_logement_id,
      surface_principale: this.state.surface_principale,
      infos: this.state.infos,
      has_cave: false,
      has_balcon: false,
      has_terrasse: false,
      has_meuble: false,
      hors_gestion_immobiliere: true,
    };

    // create logement
    axiosApiBackend
      .post(this.BACKEND_URL, dataIn)
      .then((res) => {
        this.handleLogementCreated(res.data);
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
        });
      });

    this.handleCloseCreateLogementPopup();
  };

  render() {
    const { errors, label, apiUrl } = this.props;
    const {
      logement_key,
      logement_id,
      logement,
      openCreateLogementPopup,
      adresse,
    } = this.state;

    return (
      <>
        <Grid item xs={12} lg={10}>
          <SelectSearch
            // Réinitialise le composant SelectSearch avec le logement crée
            key={logement_key}
            name="logement_hors_gestion_id"
            label={
              <LightTooltip title="Les logements affichés sont hors gestion immobilière et ne sont rattachés à aucun foyer.">
                <span>
                  {label} <Info fontSize="small" />
                </span>
              </LightTooltip>
            }
            apiUrl={apiUrl}
            value={logement_id}
            onChange={this.handleChangeLogement}
            onResponse={this.handleOnResponseLogement}
            sortOptions={(a, b) => b.key - a.key}
            buildOptionLabel={(data) =>
              Render.fullAddress(data.adresse) + " LP" + data.id
            }
            shrink
            error={Boolean(errors?.logement_id)}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          {!logement_id && (
            <Tooltip title="Ajouter un logement" placement="right">
              <MuiButton
                color="primary"
                onClick={this.handleOpenCreateLogementPopup}
              >
                <Add />
              </MuiButton>
            </Tooltip>
          )}
        </Grid>
        {logement_id && (
          <Grid item xs={12}>
            <Typography>
              <strong>Adresse : </strong>
              {Render.address(logement?.adresse)}
            </Typography>
            <Typography>
              <strong>Ville : </strong>
              {logement?.adresse?.ville?.cp} {logement?.adresse?.ville?.ville}
            </Typography>
            <Typography>
              <strong>Étage : </strong>
              {logement?.adresse?.etage}
            </Typography>
            <Typography>
              <strong>Complément de localisation : </strong>
              {logement?.adresse?.complement_localisation}
            </Typography>
            <Typography>
              <strong>Typologie : </strong>
              {logement?.typologie?.value}
            </Typography>
            <Typography>
              <strong>Surface habitable : </strong>
              {Render.carre(logement?.surface_principale)}
            </Typography>
            <Typography>
              <strong>Informations complémentaires : </strong>
            </Typography>
            <MultilineTypography sx={{ px: 1 }}>
              {logement?.infos}
            </MultilineTypography>
          </Grid>
        )}

        <AlertDialog
          title="Créer un logement hors gestion"
          open={openCreateLogementPopup}
          maxWidth="md"
          fullWidth={true}
          onConfirm={this.handleSubmitCreateLogement}
          confirmLabel="Enregistrer"
          confirmColor="primary"
          onCancel={this.handleCloseCreateLogementPopup}
          cancelLabel="Annuler"
          cancelColor="reversed"
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <SelectSearch
                name="typologie_logement_id"
                label="Typologie du logement"
                apiUrl="/config-typologies"
                onChange={(name, value) =>
                  this.setState({ typologie_logement_id: value })
                }
                value={this.state.typologie_logement_id}
                buildOptionLabel={(data) => data.value}
                shrink
                error={Boolean(errors?.typologie_logement_id)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Surface habitable"
                name="surface_principale"
                onChange={(event) =>
                  this.setState({ surface_principale: event.target.value })
                }
                value={this.state.surface_principale}
                error={Boolean(errors?.surface_principale)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Informations complémentaires"
                name="infos"
                multiline
                minRows={2}
                maxRows={8}
                onChange={(event) =>
                  this.setState({ infos: event.target.value })
                }
                value={this.state.infos}
                error={Boolean(errors?.infos)}
              />
            </Grid>
            <AdresseForm
              adresse={adresse}
              onChange={this.handleChangeAdresse}
              errors={errors}
              hideRegion
            />
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

CreateLogementForm.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  apiUrl: PropTypes.string.isRequired,
  logement_id: PropTypes.string,
  logement: PropTypes.object,
  onLogementChange: PropTypes.func,
};

export default CreateLogementForm;
