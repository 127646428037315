import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircle from "@mui/icons-material/CheckCircle";
import WarningRounded from "@mui/icons-material/WarningRounded";

const SectionCadastraleInput = ({
  fullWidth = true,
  margin = "normal",
  variant = "outlined",
  name,
  onChange,
  InputLabelProps,
  value,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(null);

  const handleChangeSectionCadastrale = (event) => {
    const inputValue = event.target.value;
    const characterOnly = inputValue.replace(/[^A-Za-z0]/g, "");
    event.target.value = characterOnly;
    onChange(event);

    if (characterOnly.length === 2) {
      setIsValid(true);
    } else if (characterOnly.length === 1 || characterOnly.length > 2) {
      setIsValid(false);
    } else {
      setIsValid(null);
    }
  };

  return (
    <TextField
      {...rest}
      name={name}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      onChange={handleChangeSectionCadastrale}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      value={value ?? ""}
      InputProps={{
        endAdornment: isValid !== null && (
          <InputAdornment position="end">
            {isValid ? (
              <CheckCircle color="success" />
            ) : (
              <WarningRounded color="error" />
            )}
          </InputAdornment>
        ),
        ...rest.InputProps,
      }}
    />
  );
};

SectionCadastraleInput.propTypes = {
  fullWidth: PropTypes.any,
  margin: PropTypes.any,
  variant: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  InputLabelProps: PropTypes.any,
  value: PropTypes.any,
};

export default memo(SectionCadastraleInput);
