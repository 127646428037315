import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";

class DelayButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: props?.delay > 0 ? true : false,
      countdown: props.delay,
    };

    this.interval = setInterval(() => {
      this.setState((prevState) => {
        let disabled = true;
        let countdown = prevState.countdown - 1;

        if (countdown <= 0) {
          disabled = false;
          clearTimeout(this.interval);
        }

        return { isDisabled: disabled, countdown: countdown };
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  renderCountdown = () => {
    const { countdown } = this.state;
    return countdown > 0 ? " (" + countdown + ")" : "";
  };

  render() {
    const { isDisabled } = this.state;
    const { label, disabled, ...rest } = this.props;

    return (
      <LoadingButton {...rest} disabled={isDisabled || disabled}>
        {label + this.renderCountdown()}
      </LoadingButton>
    );
  }
}

DelayButton.defaultProps = {
  delay: 0,
  disabled: false,
};

DelayButton.propTypes = {
  delay: PropTypes.number,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default DelayButton;
