import React, { Component } from "react";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";

// @mui/material components
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import CloudDownload from "@mui/icons-material/CloudDownload";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";

// core components
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import FileUtils from "Utils/FileUtils";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import GedPopup from "components/Popups/GedPopup";
import Render from "Utils/RenderUtils";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";
import SearchBar from "components/SearchBar/SearchBar";
import SearchBarService from "services/SearchBarService";
import Search from "@mui/icons-material/Search";
import Button from "components/Button/Button";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

class Ged extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/ged";
    this.SEARCH_BAR_SERVICE = new SearchBarService(
      `ged/${props.section}/${props.type}`,
    );

    this.gridApi = null;
    // this.gridColumnApi = null;

    this.state = {
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
      showSearch: false,
      alert: null,
      deleteDialog: null,
      downloading: false,
      snackbar: false,
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: [
        {
          flex: 3,
          headerName: "Fichier",
          field: "filename",
          sort: "asc",
          cellStyle: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            padding: 0,
          },
        },
        ...(props.docTypes?.length > 0
          ? [
              {
                flex: 3,
                headerName: "Type de modèle",
                field: "template_name",
                valueFormatter: (params) => Render.templateName(params.value),
              },
            ]
          : []),
        {
          flex: 1,
          cellStyle: {
            textAlign: "right",
          },
          headerName: "",
          field: "buttons",
          editable: false,
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            buttons: [
              {
                label: <CloudDownload />,
                color: "primary",
                loading: this.state.downloading,
                hide: !this.props.permissions.CAN_VIEW,
                onClick: () => this.downloadFile(params),
              },
              {
                label: <Delete />,
                color: "error",
                hide: !this.props.permissions.CAN_DELETE,
                onClick: () => {
                  this.openDeleteDialog(params.data.filename);
                },
              },
            ],
          }),
        },
      ],
      getRowStyle: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    let params = {
      rootFolder: this.props.rootFolder,
      section: this.props.section,
      type: this.props.type,
      objectId: this.props.objectId,
    };
    axiosApiBackend
      .get(this.BACKEND_URL + "/getList", { params })
      .then((result) => {
        this.setState({ data: result.data, deleteDialog: null }, () => {
          this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
        });
      });
  }

  handleUpload = () => {
    this.loadAsyncData();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;
  };

  updateDownloadingState = (downloading, row) => {
    this.setState({ downloading }, () => {
      const nodesToRedraw = [];

      this.gridApi.forEachNode((node) => {
        if (node.data.filename === row.data.filename) {
          nodesToRedraw.push(node);
        }
      });

      if (nodesToRedraw.length > 0) {
        this.gridApi.redrawRows({ rowNodes: nodesToRedraw });
      }
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  alertError = (title, errorMessage) => {
    return (
      <AlertDialog
        title={title}
        onCancel={this.clearAlert}
        cancelLabel="OK"
        cancelColor="error"
      >
        {errorMessage}
      </AlertDialog>
    );
  };

  closeDeleteDialog = () => {
    this.setState({ deleteDialog: null });
  };

  deleteConfirmation = (filename) => {
    this.openDeleteDialog(filename, true);
    let params = {
      rootFolder: this.props.rootFolder,
      section: this.props.section,
      type: this.props.type,
      objectId: this.props.objectId,
      filename: filename,
    };
    axiosApiBackend
      .delete(this.BACKEND_URL + "/delete", { params })
      .then(() => {
        this.loadAsyncData();
        this.setState({
          snackbar: true,
        });
      })
      .catch((error) => {
        const titleError = "Erreur de suppression";
        if (error.response.status === 404) {
          this.setState({
            deleteDialog: null,
            alert: this.alertError(
              titleError,
              "Ce fichier a déjà été supprimé",
            ),
          });
          this.loadAsyncData();
        } else {
          this.setState({
            deleteDialog: null,
            alert: this.alertError(
              titleError,
              "Une erreur est survenue côté serveur",
            ),
          });
        }
      });
  };

  openDeleteDialog = (filename, loading = false) => {
    this.setState({
      deleteDialog: (
        <AlertDialog
          title="Supprimer un document"
          onConfirm={() => this.deleteConfirmation(filename)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.closeDeleteDialog}
          cancelLabel="Annuler"
          loading={loading}
        >
          Voulez vous vraiment supprimer le document <b>{filename}</b> ?
        </AlertDialog>
      ),
    });
  };

  downloadFile = (row) => {
    this.updateDownloadingState(true, row);
    let config = {
      params: {
        rootFolder: this.props.rootFolder,
        section: this.props.section,
        type: this.props.type,
        objectId: this.props.objectId,
        filename: row.data.filename,
      },
      responseType: "blob",
    };
    this.setState({ downloading: true });
    axiosApiBackendNoJson
      .get(this.BACKEND_URL + "/download", config)
      .then((res) => {
        this.setState({ downloading: false });
        FileUtils.downLoadFile(res.data, res.headers, row.data.filename);
      })
      .catch((error) => {
        this.setState({ downloading: false });
        const titleError = "Erreur de téléchargement du fichier";
        if (error.response.status === 404) {
          this.setState({
            alert: this.alertError(titleError, "Le fichier est introuvable"),
          });
          this.loadAsyncData();
        } else {
          this.setState({
            alert: this.alertError(
              titleError,
              "Une erreur est survenue côté serveur",
            ),
          });
        }
      })
      .finally(() => {
        this.updateDownloadingState(false, row);
      });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onBlur = (/*event*/) => {
    // this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    // this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    const { alert, deleteDialog } = this.state;
    const AddPopup = this.props.popupComponent || GedPopup;

    return (
      <>
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={this.closeSnackbar} severity="success">
            Le fichier a bien été supprimé
          </Alert>
        </Snackbar>
        <Card>
          {alert}
          {deleteDialog}
          <CardHeader
            avatar={
              <CardAvatar sx={{ bgcolor: this.props.iconColor }}>
                <Description />
              </CardAvatar>
            }
            title={
              <Typography variant="h6" component="h5">
                {this.props.title ?? "Documents"}
              </Typography>
            }
            action={
              <>
                <Button
                  sx={{ mx: "1px" }}
                  square
                  onClick={() =>
                    this.setState({ showSearch: !this.state.showSearch })
                  }
                  size="small"
                >
                  <Search />
                </Button>
                {this.props.permissions.CAN_EDIT && (
                  <AddPopup
                    rootFolder={this.props.rootFolder}
                    section={this.props.section}
                    type={this.props.type}
                    objectId={this.props.objectId}
                    uploadSuccess={this.handleUpload}
                    color={this.props.buttonColor}
                    acceptedFileTypes={this.props.acceptedFileTypes}
                    docTypes={this.props.docTypes}
                    frontUrl={this.props.frontUrl}
                  />
                )}
              </>
            }
          />
          <CardContent>
            <Grid
              container
              style={{
                minHeight: "100px",
              }}
            >
              <Grid item xs={12}>
                <Collapse in={!!this.state.showSearch}>
                  <Box>
                    <SearchBar
                      label="Rechercher"
                      name="quickFilterText"
                      value={this.state.quickFilterText}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      resetSearchValue={this.resetSearchValue}
                    />
                  </Box>
                </Collapse>
              </Grid>
              <Grid item xs={12} className="ag-theme-material">
                <AgGridReact
                  overlayNoRowsTemplate="Aucune donnée à afficher."
                  enableCellTextSelection={true}
                  animateRows={true}
                  {...(this.props.docTypes?.length > 0
                    ? {}
                    : { headerHeight: 0 })}
                  onGridReady={this.onGridReady}
                  rowSelection="single"
                  enableFilter={false}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.data}
                  quickFilterText={this.state.quickFilterText}
                  pagination={true}
                  getRowStyle={this.state.getRowStyle}
                  domLayout="autoHeight"
                  paginationPageSize="5"
                  rowMultiSelectWithClick={true}
                  localeText={AG_GRID_LOCALE}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

Ged.defaultProps = {
  iconColor: "info",
  buttonColor: "info",
};

Ged.propTypes = {
  title: PropTypes.string,
  iconColor: PropTypes.string,
  buttonColor: PropTypes.string,
  rootFolder: PropTypes.oneOf(["documents", "templates"]).isRequired,
  section: PropTypes.oneOf(["immobilier", "travaux"]).isRequired,
  type: PropTypes.oneOf([
    "CL",
    "logement",
    "foyer",
    "CP",
    "proprietaire",
    "procedure",
    "apurement",
    "edl",
    "beneficiaire",
    "AD",
    "DT",
  ]).isRequired,
  objectId: PropTypes.any,
  permissions: PropTypes.shape({
    CAN_VIEW: PropTypes.bool,
    CAN_EDIT: PropTypes.bool,
    CAN_DELETE: PropTypes.bool,
  }),
  acceptedFileTypes: PropTypes.array,
  popupComponent: PropTypes.any,
  docTypes: PropTypes.array,
  frontUrl: PropTypes.string,
};

export default Ged;
