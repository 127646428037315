import React from "react";
import DisableLink from "components/DisableLink/DisableLink";

export default class AuditUtils {
  static TYPE_AUDIT = [
    {
      name: "Décence",
      sous_types: ["Visite", "Contre-visite"],
      resultats: [
        { text: <em>Non renseigné</em>, value: "" },
        { text: "Décent", value: "Décent" },
        { text: "Non décent", value: "Non décent" },
      ],
    },
    {
      name: "Audit Énergétique",
      sous_types: null,
      resultats: [
        { text: <em>Non renseigné</em>, value: "" },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
        { text: "D", value: "D" },
        { text: "E", value: "E" },
        { text: "F", value: "F" },
        { text: "G", value: "G" },
      ],
    },
    {
      name: "LHI",
      sous_types: null,
      resultats: [
        { text: <em>Non renseigné</em>, value: "" },
        { text: "Remédiable", value: "Remédiable" },
        { text: "Irrémédiable", value: "Irrémédiable" },
      ],
    },
    {
      name: "Adaptation",
      sous_types: null,
      resultats: [
        { text: <em>Non renseigné</em>, value: "" },
        { text: "Adapté", value: "Adapté" },
        { text: "Non adapté", value: "Non adapté" },
      ],
    },
    {
      name: "Autre",
      sous_types: null,
      resultats: null,
    },
  ];

  static STATUT = [
    { name: "ouvert", label: "Ouvert", default_filter: true },
    { name: "realise", label: "Réalisé", default_filter: true },
    { name: "envoye", label: "Envoyé", default_filter: true },
    { name: "facture", label: "Facturé", default_filter: true },
    { name: "cloture", label: "Clôturé", default_filter: false },
    { name: "annule", label: "Annulé", default_filter: false },
  ];

  static renderStatut = (name) => {
    return this.getStatut(name)?.label ?? "";
  };

  static getStatut = (name) => {
    return this.STATUT.find((statut) => statut.name === name);
  };

  static averageConsoEnergy = (consoActuelle, consoEstime, unit = "%") => {
    if (consoActuelle && consoEstime) {
      return (
        (((consoActuelle - consoEstime) / consoActuelle) * 100).toFixed(2) +
        unit
      );
    }

    return null;
  };

  static renderType = (typeId) => {
    return this.TYPE_AUDIT[typeId]?.name ?? "";
  };

  static getStatutDefaultFilter = () => {
    return this.STATUT.filter((statut) => statut.default_filter === true).map(
      (statut) => statut.name,
    );
  };

  static renderInfo = (ad, withLink = true) => {
    if (ad == null) {
      return;
    }

    const avenant = ad.audit_parent_id ? "Avenant " : "";
    let apresAvant = "";
    if (ad.isApres) {
      apresAvant = "après ";
    }
    if (ad.isAvant) {
      apresAvant = "avant ";
    }

    const url = withLink ? "/audits/detail/" + ad.id : null;

    return (
      <DisableLink to={url}>
        {avenant}AD {apresAvant}
        {ad.id} - {ad.type_audit} ({AuditUtils.renderStatut(ad.statut)})
      </DisableLink>
    );
  };
}
