import React from "react";
import PropTypes from "prop-types";

// material-ui components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// @mui/icons-material
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

// core components
import Button from "components/Button/Button.jsx";
import LightTooltip from "components/Tooltip/LightTooltip";

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderCaret = (open) => {
    const { dropup, caret } = this.props;
    if (!caret) {
      return null;
    }
    let caretProps = { transition: "all 150ms ease-in" };
    if (open) {
      caretProps.transform = "rotate(180deg)";
    }
    return dropup ? (
      <KeyboardArrowUp sx={caretProps} />
    ) : (
      <KeyboardArrowDown sx={caretProps} />
    );
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const {
      tooltipText,
      buttonText,
      buttonIcon,
      buttonProps,
      dropdownHeader,
      dropdownList,
      onItemClick,
      dropup,
    } = this.props;

    const ButtonIcon = buttonIcon;
    return (
      <>
        <LightTooltip title={tooltipText}>
          <span>
            <Button onClick={this.handleClick} {...buttonProps}>
              {buttonIcon != undefined ? <ButtonIcon /> : null}
              {buttonText != undefined ? buttonText : null}
              {this.renderCaret(open)}
            </Button>
          </span>
        </LightTooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: dropup ? "top" : "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: dropup ? "bottom" : "top",
            horizontal: "center",
          }}
        >
          {dropdownHeader != null && <MenuItem>{dropdownHeader}</MenuItem>}
          {dropdownList.map((item, key) => {
            if (item.divider) {
              return <Divider key={key} />;
            }
            return (
              <MenuItem key={key} onClick={onItemClick} data-value={item.value}>
                {item.child}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

CustomDropdown.defaultProps = {
  caret: true,
  dropup: false,
};

CustomDropdown.propTypes = {
  tooltipText: PropTypes.node,
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.elementType,
  buttonProps: PropTypes.object,
  dropdownHeader: PropTypes.node,
  dropdownList: PropTypes.array,
  onItemClick: PropTypes.func,
  dropup: PropTypes.bool,
  caret: PropTypes.bool,
};

export default CustomDropdown;
