import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import List from "@mui/icons-material/List";
import ListAlt from "@mui/icons-material/ListAlt";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card.jsx";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import DisableLink from "components/DisableLink/DisableLink";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";

import SearchBarService from "services/SearchBarService";

import FoyerUtils from "Utils/FoyerUtils";
import ProcedureUtils from "Utils/ProcedureUtils";
import Render from "Utils/RenderUtils";

import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

import DevisListForm from "./DevisListForm";

class ProceduresView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT_PROCEDURES = this.USER.can("edit.procedure");
    this.CAN_DELETE = this.USER.can("delete.procedure");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.GED_PROCEDURE_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.procedure"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.procedure"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.procedure"),
    };

    this.BACKEND_URL = "/procedures";
    this.FRONT_URL = "/tiers/procedures";

    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      contrat_locataire: null,
      causes: [],
      user: null,
      // DATA FORM
      statut: "",
      type: "",
      date: new Date(),
      date_sinistre: "",
      date_declaration: "",
      expertise: false,
      date_expertise: "",
      commentaire: "",
      // END DATA FORM
      errors: null,
      id: props.match.params.id,
      alert: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });
        this.setState(state);
      });
  }

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend.delete(this.BACKEND_URL + "/" + this.state.id).then(() => {
      this.props.history.push(this.FRONT_URL);
    });
    this.clearAlert();
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer cette procédure ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  render() {
    const {
      id,
      contrat_locataire,
      causes,
      statut,
      type,
      date,
      date_sinistre,
      date_declaration,
      expertise,
      date_expertise,
      commentaire,
      user,
    } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <ListAlt />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Procédure
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Contrat Locataire : </strong>
                {contrat_locataire && (
                  <Link
                    to={"/contrats-locataires/detail/" + contrat_locataire.id}
                  >
                    (CL {contrat_locataire.id}){" "}
                    {FoyerUtils.referentsFullName(
                      contrat_locataire?.foyer?.referentsactifs,
                    )}
                  </Link>
                )}
              </Typography>
              <Typography>
                <strong>Créée par : </strong>
                {Render.fullName(user)}
              </Typography>
              <Typography>
                <strong>Statut : </strong>
                {ProcedureUtils.renderStatut(statut)}
              </Typography>
              <Typography>
                <strong>Date : </strong>
                {Render.dateTime(date)}
              </Typography>
              <Typography>
                <strong>Type de procédure : </strong>
                {ProcedureUtils.renderType(type)}
              </Typography>
              <Typography>
                <strong>Date de sinistre : </strong>
                {Render.date(date_sinistre)}
              </Typography>
              <Typography>
                <strong>Date de déclaration : </strong>
                {Render.date(date_declaration)}
              </Typography>
              <Typography component="div">
                <strong>Causes de la procédure : </strong>
                {causes.length > 0 && (
                  <ul style={{ margin: "0" }}>
                    {causes.map((cause) => {
                      return <li key={cause.id}>{cause.value}</li>;
                    })}
                  </ul>
                )}
              </Typography>
              <Typography>
                <strong>Expertise : </strong>
                {Render.boolean(expertise)}
              </Typography>
              {Boolean(expertise) && (
                <Typography>
                  <strong>Date d&apos;expertise : </strong>
                  {Render.date(date_expertise)}
                </Typography>
              )}
              <Typography>
                <strong>Commentaires : </strong>
              </Typography>
              <MultilineTypography sx={{ px: 1 }}>
                {commentaire}
              </MultilineTypography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          {this.GED_PROCEDURE_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="procedure"
              objectId={id}
              permissions={this.GED_PROCEDURE_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <DevisListForm
            modelId={id}
            modelType="procedure"
            section="immobilier"
            canEdit={this.CAN_EDIT_PROCEDURES}
            title="Devis"
          />
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={this.FRONT_URL + "/logs?type=Procedure&id=" + this.state.id}
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

ProceduresView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ProceduresView);
