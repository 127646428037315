import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Accessibility from "@mui/icons-material/Accessibility";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Description from "@mui/icons-material/Description";
import Edit from "@mui/icons-material/Edit";
import FamilyRestroom from "@mui/icons-material/FamilyRestroom";
import LaptopChromebook from "@mui/icons-material/LaptopChromebook";
import List from "@mui/icons-material/List";
import Today from "@mui/icons-material/Today";

import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import CardHeader from "components/CardV2/CardHeader";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import PersonneCard from "components/CustomCard/PersonneCard";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Evenements from "components/Evenements/Evenements";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import InfoPopover from "components/InfoPopover/InfoPopover";
import ImmobilierLinkCard from "components/LinkCard/ImmobilierLinkCard";
import ListTitle from "components/ListTitle/ListTitle";
import MenuBottom from "components/MenuBottom/MenuBottom";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";
import DocumentTemplate from "components/Table/DocumentTemplate";

import SearchBarService from "services/SearchBarService";

import Render from "Utils/RenderUtils";

import Ged from "views/Tables/Ged";

import { withUserContext } from "context/UserContext";

import AccompagnementFoyerView from "./AccompagnementFoyer/AccompagnementFoyerView";
import InfoFiscaleView from "./InfoFiscale/InfoFiscaleView";

class FoyersView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_USE_FOYER = this.USER.can("use.foyer");
    this.CAN_DELETE = this.USER.can("delete.foyer");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/foyers";
    this.FRONT_URL = "/tiers/foyers";
    this.NO_YES = ["Non", "Oui"];
    this.FSL_DECISION = ["Refusé", "Accepté"];
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    // Ged Foyer permissions
    this.GED_FOYER_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.immobilier.foyer"),
      CAN_EDIT: this.USER.can("edit.ged.immobilier.foyer"),
      CAN_DELETE: this.USER.can("delete.ged.immobilier.foyer"),
    };

    this.state = {
      // NESTED DATA
      contratslocataires: null,
      personnesactives: [],
      ressources_principales: [],
      situation_familiale: [],
      orienteur: [],
      situation_menage_entree: [],
      situation_menage_sortie: [],
      partenaires: [],
      ville: [],
      accompagnements: [],
      referents_sociaux: [],
      infos_fiscales: [],
      logement_hors_gestion: null,
      logement_apres: null,
      // DATA FORM
      ncaf: "",
      situation_handicap: "",
      situation_familiale_id: "",
      orienteur_id: "",
      orienteur_nom: "",
      orienteur_email: "",
      orienteur_tel: "",
      orienteur_mesures_accompagnement: "",
      situation_menage_entree_id: "",
      situation_menage_sortie_id: "",
      conventionnement: "",
      dalo_demande: "",
      dalo_decision: "",
      dalo_date: "",
      dalo_date_depot: "",
      date_depot_rg: "",
      dalo_date_decision: "",
      date_depot_ta: "",
      dls_demande: "",
      dls_numero: "",
      dls_date_depot: "",
      dls_date_renouvellement: "",
      dls_secteur: "",
      action_logement: "",
      fsl_date: "",
      fsl_avance: "",
      fsl_accorde: "",
      fsl_demande: "",
      fsl_decision: "",
      fsl_maintien: "",
      fsl_garantie_bailleur: "",
      fluide: "",
      cheque_energie: "",
      is_asso: "",
      montant_total_revenus_decembre: "",
      revenus: "",
      colocation: "",
      msa: "",
      hors_gestion_immobiliere: "",
      // END DATA FORM
      mostRecentCL: null,
      alert: null,
      id: Number(props.match.params.id),
      entityLists: null,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData = () => {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        this.getMostRecentContratLocataire(result.data);
        let state = {};
        Object.entries(result.data).forEach(([key, value]) => {
          state[key] = value !== null ? value : "";
        });

        if (result.data.contratslocataires !== null) {
          state.entityLists = {
            contratLocataire: result.data.contratslocataires,
          };
        }

        this.setState(state);
      });
  };

  getMostRecentContratLocataire = (data) => {
    const cls = data.contratslocataires;

    let mostRecentCL = null;
    for (let cl of cls) {
      if (cl.date_sortie == null) {
        mostRecentCL = cl;
        break;
      } else if (
        mostRecentCL == null ||
        mostRecentCL.date_sortie < cl.date_sortie
      ) {
        mostRecentCL = cl;
      }
    }
    if (mostRecentCL == null) {
      return null;
    }

    axiosApiBackend
      .get("/contrats-locataires/" + mostRecentCL.id)
      .then((result) => {
        this.setState({
          mostRecentCL: result.data,
        });
      });
  };

  // Remove Alert
  clearAlert = () => {
    this.setState({ alert: null });
  };
  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const nbrEntity = err.response.data;
          this.onError(nbrEntity);
        }
      });
  };
  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le foyer ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };
  onError = (nbrEntity) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce foyer : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {nbrEntity.cl > 0 && (
              <li>{nbrEntity.cl} contrat(s) locataire(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  render() {
    const {
      id,
      personnesactives,
      mostRecentCL,
      montant_total_revenus_decembre,
      situation_familiale,
      revenus,
      orienteur,
      situation_menage_entree,
      situation_menage_sortie,
      referents_sociaux,
      partenaires,
      accompagnements,
      infos_fiscales,
      hors_gestion_immobiliere,
      logement_hors_gestion,
      logement_apres,
    } = this.state;

    let logement = mostRecentCL ? mostRecentCL.logement : null;
    if (!logement) logement = {};
    if (!logement.ville) logement.ville = {};
    if (!logement.proprietaire) logement.proprietaire = {};
    if (!logement.contratproprietaire) logement.contratproprietaire = {};

    const displayLinkCard = Boolean(mostRecentCL && mostRecentCL.id);
    const evenementsLink = "/evenements/immobilier/foyer/" + id;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <FamilyRestroom />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Ménage FO{id}
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Situation familiale : </strong>
                {situation_familiale?.value}
              </Typography>
              <HeaderTitle label="Ressources" />
              <Typography>
                <strong>
                  Revenus actuels
                  <InfoPopover
                    id="indicatif-popover"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <Typography>Montant mensuel actuel.</Typography>
                  </InfoPopover>{" "}
                  :{" "}
                </strong>
                {Render.euro(revenus)}
              </Typography>
              <Typography>
                <strong>
                  Montant total des revenus hors APL (décembre N-1)
                  <InfoPopover
                    id="indicatif-popover"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <Typography>Montant mensuel.</Typography>
                  </InfoPopover>{" "}
                  :{" "}
                </strong>
                {Render.euro(montant_total_revenus_decembre)}
              </Typography>
              <Typography component="div">
                <strong>Nature des ressources principales : </strong>
                {this.state.ressources_principales.length > 0 && (
                  <ul>
                    {this.state.ressources_principales.map((ressource) => {
                      return <li key={ressource.id}>{ressource.value}</li>;
                    })}
                  </ul>
                )}
              </Typography>
              <InfoFiscaleView infosFiscales={infos_fiscales} />
              <CheckBoxView
                label="Reconnaissance handicap"
                value={this.state.situation_handicap}
              />
              <CheckBoxView
                value={this.state.is_asso}
                label="Ce foyer est une association ou une entreprise"
              />
              <CheckBoxView value={this.state.colocation} label="Colocation" />
              <Typography>
                <strong>Foyer hors gestion immobilière : </strong>
                {this.NO_YES[hors_gestion_immobiliere]}
              </Typography>
              {Boolean(hors_gestion_immobiliere) && logement_hors_gestion && (
                <>
                  <HeaderTitle label="Logement Hors gestion immobilière" />
                  <Typography>
                    <strong>Adresse : </strong>
                    {Render.address(logement_hors_gestion?.adresse)}
                  </Typography>
                  <Typography>
                    <strong>Ville : </strong>
                    {logement_hors_gestion?.adresse?.ville?.cp}{" "}
                    {logement_hors_gestion?.adresse?.ville?.ville}
                  </Typography>
                  <Typography>
                    <strong>Étage : </strong>
                    {logement_hors_gestion?.adresse?.etage}
                  </Typography>
                  <Typography>
                    <strong>Complément de localisation : </strong>
                    {logement_hors_gestion?.adresse?.complement_localisation}
                  </Typography>
                  <Typography>
                    <strong>Typologie : </strong>
                    {logement_hors_gestion?.typologie?.value}
                  </Typography>
                  <Typography>
                    <strong>Surface habitable : </strong>
                    {Render.carre(logement_hors_gestion?.surface_principale)}
                  </Typography>
                  <Typography>
                    <strong>Informations complémentaires : </strong>
                  </Typography>
                  <MultilineTypography sx={{ px: 1 }}>
                    {logement_hors_gestion?.infos}
                  </MultilineTypography>
                </>
              )}
              <MultilineTypography sx={{ px: 1 }}>
                {this.state.infos}
              </MultilineTypography>
              <HeaderTitle label="Situation à l'entrée" />
              <Typography>
                <strong>
                  Situation du ménage avant l’entrée en logement :{" "}
                </strong>
                {situation_menage_entree?.value}
              </Typography>
              <HeaderTitle label="Situation à la sortie" />
              <Typography>
                <strong>Situation du ménage à la sortie : </strong>
                {situation_menage_sortie?.value}
              </Typography>
              {Boolean(situation_menage_sortie) && logement_apres && (
                <>
                  <Typography>
                    <strong>Adresse : </strong>
                    {Render.address(logement_apres?.adresse)}
                  </Typography>
                  <Typography>
                    <strong>Ville : </strong>
                    {logement_apres?.adresse?.ville?.cp}{" "}
                    {logement_apres?.adresse?.ville?.ville}
                  </Typography>
                  <Typography>
                    <strong>Étage : </strong>
                    {logement_apres?.adresse?.etage}
                  </Typography>
                  <Typography>
                    <strong>Complément de localisation : </strong>
                    {logement_apres?.adresse?.complement_localisation}
                  </Typography>
                  <Typography>
                    <strong>Typologie de logement : </strong>
                    {logement_apres?.typologie?.value}
                  </Typography>
                  <Typography>
                    <strong>Surface habitable du logement : </strong>
                    {Render.carre(logement_apres?.surface_principale)}
                  </Typography>
                  <Typography>
                    <strong>Informations complémentaires : </strong>
                  </Typography>
                  <MultilineTypography sx={{ px: 1 }}>
                    {logement_apres?.infos}
                  </MultilineTypography>
                </>
              )}
              <HeaderTitle label="Informations complémentaires" />
              <Typography>
                <strong>Informations complémentaires : </strong>
              </Typography>
            </CardContent>
          </Card>
          {this.GED_FOYER_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="documents"
              section="immobilier"
              type="foyer"
              objectId={id}
              permissions={this.GED_FOYER_PERMISSIONS}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Accessibility />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Accompagnements Sociaux
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Référents sociaux : </strong>
                {referents_sociaux
                  .map((rs) => {
                    return rs.pivot.is_principal
                      ? Render.fullName(rs) + " (Principal)"
                      : Render.fullName(rs);
                  })
                  .join(", ")}
              </Typography>
              <HeaderTitle label="Accompagnements" />
              <AccompagnementFoyerView accompagnements={accompagnements} />
              {/* Orienteur */}
              <HeaderTitle label="Orienteur" />
              <Typography>
                <strong>Orienteur : </strong>
                {orienteur?.value}
              </Typography>
              <Typography>
                <strong>Nom : </strong>
                {this.state.orienteur_nom}
              </Typography>
              <Typography>
                <strong>Mesures d&apos;accompagnement : </strong>
                {this.state.orienteur_mesures_accompagnement}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(this.state.orienteur_tel)}
              </Typography>
              <Typography>
                <strong>Email : </strong>
                {Render.email(this.state.orienteur_email)}
              </Typography>
              {/* Partenaire */}
              <HeaderTitle label="Partenaires" />
              {partenaires.length > 0 ? (
                partenaires.map((partenaire, key) => (
                  <Grid
                    container
                    columnSpacing={2}
                    direction="column"
                    key={key}
                  >
                    <Grid item>
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <ListTitle>Partenaire n° {key + 1} :</ListTitle>
                        </Grid>
                      </Grid>
                      <Typography>
                        <strong>Organisme : </strong>
                        {partenaire.organisme}
                      </Typography>
                      <Typography>
                        <strong>Nom : </strong>
                        {partenaire.nom}
                      </Typography>
                      <Typography>
                        <strong>Téléphone : </strong>
                        {Render.telephoneAsLink(partenaire.tel)}
                      </Typography>
                      <Typography>
                        <strong>Email : </strong>
                        {Render.email(partenaire.email)}
                      </Typography>
                      <Typography>
                        <strong>Mesures d&apos;accompagnement : </strong>
                        {partenaire.mesures_accompagnement}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography>
                  <strong>Aucun partenaire n&apos;a été saisie.</strong>
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LaptopChromebook />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Démarches administratives/sociales
                </Typography>
              }
            />
            <CardContent>
              <HeaderTitle label="CAF - Caisse d'allocations familiales" />
              <Typography>
                <strong>Numéro : </strong>
                {this.state.ncaf}
              </Typography>
              <HeaderTitle label="MSA - Mutualité sociale agricole" />
              <Typography>
                <strong>Numéro : </strong>
                {this.state.msa}
              </Typography>
              <HeaderTitle label="DALO - Droit au logement opposable" />
              <CheckBoxView label="Demande" value={this.state.dalo_demande} />
              {this.state.dalo_demande ? (
                <>
                  <Typography>
                    <strong>Date de dépôt : </strong>
                    {Render.date(this.state.dalo_date_depot)}
                  </Typography>
                  <Typography>
                    <strong>Date de décision : </strong>
                    {Render.date(this.state.dalo_date_decision)}
                  </Typography>
                  <Typography>
                    <strong>Décision : </strong>
                    {this.state.dalo_decision}
                  </Typography>
                  <Typography>
                    <strong>Date de dépôt du Recours Gracieux : </strong>
                    {Render.date(this.state.date_depot_rg)}
                  </Typography>
                  <Typography>
                    <strong>Date de dépôt du Tribunal Administratif : </strong>
                    {Render.date(this.state.date_depot_ta)}
                  </Typography>
                </>
              ) : null}
              <HeaderTitle label="DLS - Demande de logement Social" />
              <CheckBoxView label="Demande" value={this.state.dls_demande} />
              {this.state.dls_demande ? (
                <>
                  <Typography>
                    <strong>Numéro : </strong>
                    {this.state.dls_numero}
                  </Typography>
                  <Typography>
                    <strong>Secteur : </strong>
                    {this.state.dls_secteur}
                  </Typography>
                  <Typography>
                    <strong>Date de dépôt : </strong>
                    {Render.date(this.state.dls_date_depot)}
                  </Typography>
                  <Typography>
                    <strong>Date de renouvellement : </strong>
                    {Render.date(this.state.dls_date_renouvellement)}
                  </Typography>
                  <Grid container columnSpacing={2} justifyContent="flex-start">
                    <CheckBoxView
                      label="Action logement"
                      value={this.state.action_logement}
                    />
                  </Grid>
                </>
              ) : null}
              <HeaderTitle label="FSL - Fonds de solidarité logement" />
              <CheckBoxView label="Demande" value={this.state.fsl_demande} />
              {this.state.fsl_demande ? (
                <>
                  <Typography>
                    <strong>Date : </strong>
                    {Render.date(this.state.fsl_date)}
                  </Typography>
                  <Typography>
                    <strong>Décision : </strong>
                    {this.FSL_DECISION[this.state.fsl_decision]}
                  </Typography>
                  <Typography>
                    <strong>Montant avancé : </strong>
                    {Render.euro(this.state.fsl_avance)}
                  </Typography>
                  <Typography>
                    <strong>Montant accordé : </strong>
                    {Render.euro(this.state.fsl_accorde)}
                  </Typography>
                  <Grid container columnSpacing={2} justifyContent="flex-start">
                    <CheckBoxView
                      label="Maintien"
                      value={this.state.fsl_maintien}
                    />
                    <CheckBoxView
                      label="Mise en jeu garantie bailleur"
                      value={this.state.fsl_garantie_bailleur}
                    />
                    <CheckBoxView label="Fluide" value={this.state.fluide} />
                  </Grid>
                </>
              ) : null}
              <HeaderTitle label="Divers" />
              <Grid container columnSpacing={2} justifyContent="flex-start">
                <CheckBoxView
                  label="Chèque énergie"
                  value={this.state.cheque_energie}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          {displayLinkCard && (
            <ImmobilierLinkCard
              proprietaire={logement.proprietaire}
              logement={logement}
              contratProprietaire={logement.contratproprietaire}
              contratLocataire={mostRecentCL}
              entityLists={this.state.entityLists}
            />
          )}
          <Card>
            <CardHeader
              avatar={
                <CardAvatar color="primary">
                  <Today />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Évènements
                </Typography>
              }
              action={
                <Link to={evenementsLink + "?form=open"}>
                  <Button size="small">Ajouter</Button>
                </Link>
              }
            />
            <CardContent>
              <Link to={evenementsLink}>
                <Evenements modelId={id} modelType="foyer" dense limit={5} />
              </Link>
            </CardContent>
          </Card>
          {this.CAN_USE_FOYER && (
            <DocumentTemplate
              baseURL="/foyer"
              objectId={id}
              callback={this.loadAsyncData}
              color="gla"
            />
          )}
        </Grid>

        {/* Personnes */}
        <Grid item xs={12}>
          <Grid container columnSpacing={2} spacing={1} alignItems="stretch">
            {personnesactives.map((personne, key) => (
              <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                <PersonneCard personne={personne} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button size="small" square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button size="small" square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button size="small" square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
            <Button size="small" color="onyx" square round>
              <Edit />
            </Button>
          </Link>
          {this.CAN_DELETE && this.state.id && (
            <Button
              size="small"
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Foyer&id=" + this.state.id}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

FoyersView.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(FoyersView);
