import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { axiosApiBackend } from "variables/axiosConfigs";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";

// material-ui icons
import Menu from "@mui/icons-material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import ViewList from "@mui/icons-material/ViewList";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.miniActive = this.props.miniActive;
    this.sidebarMinimize = this.props.sidebarMinimize;
    this.handleDrawerToggle = this.props.handleDrawerToggle;
    this.BACKEND_URL = "/notifications/internal/countunread";
    this.state = {
      nombreNotifications: null,
    };
  }
  componentDidMount() {
    this.loadAsyncData();
  }
  loadAsyncData() {
    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      this.setState({ nombreNotifications: result.data });
    });
  }
  componentDidUpdate(prevprops) {
    if (this.props.currentRoute != prevprops.currentRoute) {
      this.loadAsyncData();
    }
  }

  render() {
    const { classes, color, currentRoute } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color,
    });
    const sidebarMinimize = classes.sidebarMinimize;
    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden mdDown implementation="css">
            <div className={sidebarMinimize}>
              {this.miniActive ? (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={this.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={this.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
            </div>
          </Hidden>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button className={classes.title} color="transparent">
              {currentRoute && currentRoute.name}
            </Button>
          </div>
          <HeaderLinks nombreNotifications={this.state.nombreNotifications} />
          <Hidden mdUp implementation="css">
            <Button
              className={classes.appResponsive}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  currentRoute: PropTypes.any,
  location: PropTypes.any,
  miniActive: PropTypes.any,
  sidebarMinimize: PropTypes.any,
  handleDrawerToggle: PropTypes.func,
};

export default withStyles(headerStyle)(Header);
