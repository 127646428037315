import React from "react";
import PropTypes from "prop-types";

export default class BaseLinkCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  buildSeeAllData = (p) => {
    let data = [];
    if (this.props.entityLists && this.props.entityLists[p.entityList]) {
      for (const entity of this.props.entityLists[p.entityList]) {
        // Boolean to get the current used entity
        const isMostRecent = p.mostRecent.id === entity.id;

        // Populate data for the Timeline component
        data.push({
          to: p.detailURL + entity.id,
          textBadge: (
            <span style={{ fontWeight: isMostRecent && "bold" }}>
              {p.prefixBadge + entity.id}
            </span>
          ),
          icon: p.icon,
          bodyContent: p.bodyContent(entity, isMostRecent),
          active: isMostRecent,
        });
      }
    }
    return data.length > 1 ? data : [];
  };
}

BaseLinkCard.propTypes = {
  entityLists: PropTypes.any,
};
