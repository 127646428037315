import React, { Component } from "react";

import MuiButton from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import FilterVintage from "@mui/icons-material/FilterVintage";

import AlertDialog from "components/AlertDialog/AlertDialog";
import TextInput from "components/CustomInput/TextInput";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";

class ConfigLogementConventionnement extends Component {
  constructor(props) {
    super(props);
    this.LABEL = "logement(s)";

    this.state = {
      // NESTED DATA
      types_conventionnements: this.getTypeConventionnementDefault(),
      // DATA FORM
      id: "",
      value: "",
      // END DATA FORM
      errors: null,
      alert: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getTypeConventionnementDefault = () => {
    return [{ value: "", financements_count: 0 }];
  };

  handleAddTypeConventionnement = () => {
    let newData = [
      ...this.state.types_conventionnements,
      ...this.getTypeConventionnementDefault(),
    ];

    this.setState({
      types_conventionnements: newData,
    });
  };

  handleChangeTypeConventionnement = (key, event) => {
    let newData = this.state.types_conventionnements;

    newData[key][event.target.name] = event.target.value;
    this.setState({ types_conventionnements: newData });
  };

  handleDeleteTypeConventionnement = (key) => {
    this.clearAlert();
    let newData = this.state.types_conventionnements;

    newData.splice(key, 1);
    if (newData.length === 0) {
      newData = this.getTypeConventionnementDefault();
    }
    this.setState({
      types_conventionnements: [...newData],
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  onClickDeleteButton = (event, key, typeConventionnement) => {
    this.setState({
      alert: (
        <AlertDialog
          title={
            "Voulez-vous supprimer le type de conventionnement " +
            typeConventionnement.value +
            " ?"
          }
          onConfirm={() => this.handleDeleteTypeConventionnement(key)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="primary"
        />
      ),
    });
  };

  render() {
    const { errors, types_conventionnements } = this.state;

    return (
      <>
        <TablePopUpContainer
          forceLoadAsyncRender
          icon={<FilterVintage />}
          title="Conventionnements Anah"
          backendUrl="/config-logement-conventionnement"
          autoSize={false}
          colDef={[
            { field: "id", editable: false, hide: true },
            {
              headerName: "Libellé",
              field: "value",
            },
            {
              headerName: "Types de conventionnements",
              field: "types_conventionnements",
              valueGetter: (params) => {
                return params.data.types_conventionnements
                  .map((typeConv) => typeConv.value)
                  .join(", ");
              },
            },
          ]}
          formInitData={() => {
            this.setState({
              errors: null,
              id: "",
              value: "",
              types_conventionnements: this.getTypeConventionnementDefault(),
            });
          }}
          formSetData={(data) => {
            this.setState({
              errors: null,
              id: data.id ?? "",
              value: data.value ?? "",
              types_conventionnements:
                [...data.types_conventionnements] ||
                this.getTypeConventionnementDefault(),
            });
          }}
          formGetData={() => ({
            id: this.state.id,
            value: this.state.value,
            types_conventionnements: types_conventionnements,
          })}
          formSetErrors={(errors, status) => {
            if (status == 428) {
              let errorData = errors || [];
              let typesConventionnements = Object.values(errorData).map(
                (typeConv) =>
                  `${typeConv.value} est associé à ${typeConv.logements_count} ${this.LABEL}`,
              );
              this.setState({
                alert: (
                  <AlertDialog
                    title="Vous ne pouvez pas supprimer ces types de conventionnements :"
                    content={
                      <ul>
                        {typesConventionnements.map((typeConv, key) => (
                          <li key={key}>{typeConv}</li>
                        ))}
                      </ul>
                    }
                    onCancel={this.clearAlert}
                    cancelLabel="Ok"
                    cancelColor="info"
                  />
                ),
              });
            }

            this.setState({
              errors: errors,
            });
          }}
          getDeleteContent={(data) =>
            data.value +
            (data.logements_count > 0
              ? ` : Lié à ${data.logements_count} ${this.LABEL}`
              : "")
          }
          getDeleteError={(data, error) => {
            if (error.errors) {
              return (
                (data.value ?? "") +
                " est associé à " +
                (error.errors.logements ||
                  error.errors.types_conventionnements ||
                  "") +
                ` ${this.LABEL}.`
              );
            } else {
              return "Impossible de supprimer " + (data.value ?? "");
            }
          }}
          formTitle={
            this.state.id
              ? "Modifier le conventionnement"
              : "Ajouter un conventionnement"
          }
          formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
          formContent={
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <TextInput
                  label="Libellé"
                  name="value"
                  value={this.state.value}
                  onChange={this.handleChange}
                  error={Boolean(errors?.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <HeaderTitle label="Types de conventionnements" />
              </Grid>
              {types_conventionnements.map((typeConventionnement, key) => (
                <Grid item xs={12} key={key}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <TextInput
                        label="Type de conventionnement"
                        name="value"
                        multiline
                        value={typeConventionnement.value}
                        onChange={(event) =>
                          this.handleChangeTypeConventionnement(key, event)
                        }
                        error={
                          errors &&
                          Boolean(
                            errors["types_conventionnements." + key + ".value"],
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {typeConventionnement.logements_count > 0 ? (
                        <>
                          Lié à {typeConventionnement.logements_count} Logements
                        </>
                      ) : (
                        <Tooltip
                          title="Supprimer ce type de conventionnement"
                          placement="left"
                        >
                          <MuiButton
                            color="error"
                            onClick={(event) =>
                              this.onClickDeleteButton(
                                event,
                                key,
                                typeConventionnement,
                              )
                            }
                          >
                            <Delete />
                          </MuiButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Tooltip
                  title="Ajouter un type de conventionnement"
                  placement="left"
                >
                  <MuiButton
                    color="primary"
                    onClick={this.handleAddTypeConventionnement}
                  >
                    <Add />
                  </MuiButton>
                </Tooltip>
              </Grid>
            </Grid>
          }
        />
        {this.state.alert}
      </>
    );
  }
}

export default ConfigLogementConventionnement;
