import React, { Component } from "react";
import PropTypes from "prop-types";

import Info from "@mui/icons-material/Info";
import LightTooltip from "components/Tooltip/LightTooltip";

class InfoLightTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, ...rest } = this.props;

    return (
      <LightTooltip title={title} {...rest}>
        <Info fontSize="small" />
      </LightTooltip>
    );
  }
}

InfoLightTooltip.propTypes = {
  title: PropTypes.any,
};

export default InfoLightTooltip;
