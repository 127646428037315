import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import Home from "@mui/icons-material/Home";
import PanTool from "@mui/icons-material/PanTool";

// core components
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import Button from "components/Button/Button";
import Grid from "@mui/material/Grid";
import MenuBottom from "components/MenuBottom/MenuBottom";

// context
import { withUserContext } from "context/UserContext";

class ProprietaireConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/proprietaires";
    this.LABEL = "propriétaire(s)";
  }

  render() {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<PanTool />}
            title="Type"
            backendUrl="/config-type-proprietaires"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Home />}
            title="Parc de logements estimé"
            backendUrl="/config-estime-parc-proprietaires"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigProprietaire"}>
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

ProprietaireConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(ProprietaireConfig);
