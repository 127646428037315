import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FileCopy from "@mui/icons-material/FileCopy";
import AlertDialog from "components/AlertDialog/AlertDialog";
import FileUtils from "Utils/FileUtils";
import SelectInput from "components/CustomSelect/SelectInput";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";

class GenerateAttestationFiscal extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.YEARS = [
      now.getFullYear(),
      now.getFullYear() - 1,
      now.getFullYear() - 2,
      now.getFullYear() - 3,
    ];
    this.state = {
      annee: now.getFullYear() - 1,
      asPdf: false,
      loading: false,
      errorMessage: null,
      open: false,
      alert: null,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleAlert = () => {
    this.setState({
      loading: true,
      errorMessage: null,
    });
    const url =
      "/contrats-proprietaires/" +
      this.props.cpId +
      "/documents/attestation-fiscale/" +
      this.state.annee +
      "/" +
      this.props.fileName;

    axiosApiBackendNoJson
      .get(url, {
        responseType: "arraybuffer",
        params: {
          asPdf: this.state.asPdf ? 1 : 0,
        },
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.toggle();
        FileUtils.downLoadFile(
          res.data,
          res.headers,
          "attestation-fiscale.docx",
        );
      })
      .catch((error) => {
        let errorMessage = null;
        if (error.response && error.response.status === 400) {
          const res = FileUtils.getDataFromArrayBuffer(error.response.data);
          errorMessage = res && res.error && res.error.message;
        }
        this.setState({
          loading: false,
          errorMessage: errorMessage || "Erreur inconnue",
        });
      });
  };

  render() {
    return (
      <>
        <Button square size="small" onClick={this.toggle}>
          <FileCopy />
        </Button>
        <AlertDialog
          open={this.state.open}
          title="Choisir une période"
          onConfirm={this.handleAlert}
          confirmLabel="Générer"
          confirmColor="primary"
          onCancel={this.toggle}
          cancelLabel="Annuler"
          cancelColor="gray"
          loading={this.state.loading}
          fullWidth
        >
          {this.state.errorMessage && (
            <Typography color="error">
              <p>{this.state.errorMessage}</p>
            </Typography>
          )}
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <SelectInput
                label="Année"
                name="annee"
                value={this.state.annee}
                onChange={this.handleChange}
              >
                {this.YEARS.map((value, key) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckBoxInput
                label="Version pdf"
                name="asPdf"
                value={this.state.asPdf}
                onChange={this.handleChangeByName}
              />
            </Grid>
          </Grid>
        </AlertDialog>
      </>
    );
  }
}

GenerateAttestationFiscal.propTypes = {
  cpId: PropTypes.any,
  fileName: PropTypes.string.isRequired,
};

export default GenerateAttestationFiscal;
