import DisableLink from "components/DisableLink/DisableLink";
import React from "react";

export default class LogementTravauxUtils {
  static PREFIXE_CADASTRALE =
    "Le préfixe cadastral doit être composé de trois chiffres par défaut rempli avec la valeur « 000 ».";
  static SECTION_CADASTRALE =
    "La section cadastrale doit être composée de deux caractères, soit deux lettres ou une lettre précédée d'un 0. Exemple: AA ou 0A.";
  static PARCELLE_CADASTRALE =
    "La parcelle cadastrale doit être composée de quatre chiffres. Si plusieurs parcelles, séparez-les par /. Exemple: 0012 ou 0012/0013.";

  static renderCadastre = (logement) => {
    const prefixe = logement.beneficiaire?.prenom ?? "";
    const section = logement.beneficiaire?.nom ?? "";
    const parcelle = logement.adresse?.numero_voie ?? "";

    return `${prefixe} ${section} ${parcelle}`;
  };

  static renderForList = (logement) => {
    const prenom = logement.beneficiaire?.prenom ?? "";
    const nom = logement.beneficiaire?.nom ?? "";
    const numero_voie = logement.adresse?.numero_voie ?? "";
    const type_voie = logement.adresse?.type_voie ?? "";
    const nom_voie = logement.adresse?.nom_voie ?? "";
    const cp = logement.adresse?.ville?.cp ?? "";
    const ville = logement.adresse?.ville?.ville ?? "";

    return `${prenom} ${nom} - ${numero_voie} ${type_voie} ${nom_voie} ${cp} ${ville} (${logement.id})`;
  };

  static buildRowsForLinks = (audits, dossiers_travaux) => {
    let rows = [];
    audits = audits ?? [];
    dossiers_travaux = dossiers_travaux ?? [];
    let ids = [];
    const dtForLinks = dossiers_travaux.map((dt) => {
      let span = 0;
      let adAp = audits.find((a) => a.id === dt.audit_apres_id);
      if (adAp) {
        adAp.isApres = true;
        ids.push(adAp.id);
        dt.audit_apres = adAp;
        span++;
      }
      let adAv = audits.find((a) => a.id === dt.audit_avant_id);
      if (adAv) {
        adAv.isAvant = adAp != null; // we display avant only if there is an apres
        ids.push(adAv.id);
        dt.audit_avant = adAv;
        span++;
      }
      dt.span = span > 0 ? span : 1;
      return dt;
    });
    const adForLinks = audits.filter((a) => !ids.includes(a.id));

    dtForLinks.forEach((dt) => {
      rows.push({
        dossier_travaux: dt,
        audit: dt.audit_avant ?? dt.audit_apres ?? null,
      });
      if (dt.audit_avant != null && dt.audit_apres != null) {
        rows.push({
          dossier_travaux: null,
          audit: dt.audit_apres,
        });
      }
    });
    adForLinks.forEach((ad) => {
      rows.push({
        dossier_travaux: null,
        audit: ad,
      });
    });

    return rows;
  };

  static renderInfo = (logement, withLink = true) => {
    if (logement == null) {
      return;
    }

    const url = withLink ? "/logements-travaux/detail/" + logement.id : null;

    return (
      <DisableLink to={url}>
        {logement.adresse?.numero_voie} {logement.adresse?.type_voie}{" "}
        {logement.adresse?.nom_voie} {logement.adresse?.ville?.cp}{" "}
        {logement.adresse?.ville?.ville} ({logement.id})
      </DisableLink>
    );
  };
}
