import React from "react";
import PropTypes from "prop-types";

import styled from "@mui/system/styled";
import Box from "@mui/material/Box";

const CenterBox = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
}));

class BoxColorRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { color, size } = this.props;

    return (
      Boolean(color) && (
        <CenterBox>
          <Box
            sx={{
              width: size,
              height: size,
              borderRadius: 1,
              backgroundColor: color,
            }}
          />
        </CenterBox>
      )
    );
  }
}

BoxColorRenderer.defaultProps = {
  size: 30,
};

BoxColorRenderer.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default BoxColorRenderer;
