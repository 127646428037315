import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui/material components
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const useStyles = () => ({
  bgGreen: {
    backgroundColor: "#4caf50",
  },
  bgBlue: {
    backgroundColor: "#0a4f75",
  },
  denseTimeline: {
    padding: "0",
    "& .MuiTimelineItem-root": {
      minHeight: "65px",
    },
    "& .MuiTimelineDot-root": {
      marginBottom: "4px",
      alignSelf: "center",
    },
    "& .MuiTimelineContent-positionRight": {
      paddingRight: "4px",
    },
    "& .MuiTimelineContent-positionLeft": {
      paddingLeft: "4px",
    },
    "& .MuiTimelineOppositeContent-root": {
      flex: 0,
      padding: 0,
    },
    "& .MuiPaper-root": {
      paddingRight: "10px",
      paddingLeft: "10px",
      paddingTop: "3px",
      paddingBottom: "3px",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      "-moz-box-orient": "vertical",
      overflow: "hidden",
    },
    "& .MuiPaper-root:hover": {
      backgroundColor: "#e7e9eb",
    },
    "& svg": {
      fontSize: "1rem",
    },
  },
});

class TimelineLinkCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Timeline align="left" className={classes.denseTimeline}>
        {this.props.items.map((item, key) => (
          <TimelineItem key={key}>
            <TimelineOppositeContent className={classes.smallFlex} />
            <TimelineSeparator>
              <TimelineDot
                className={item.active ? classes.bgGreen : classes.bgBlue}
              >
                {item.icon}
              </TimelineDot>
              <Typography variant="body2" color="textPrimary">
                {item.textBadge}
              </Typography>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Link to={item.to}>
                <Paper elevation={3} className={classes.paper}>
                  {item.bodyContent}
                </Paper>
              </Link>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  }
}

TimelineLinkCard.propTypes = {
  items: PropTypes.array.isRequired,
  classes: PropTypes.any,
};

export default withStyles(useStyles)(TimelineLinkCard);
