import Assessment from "@mui/icons-material/Assessment";
import Assignment from "@mui/icons-material/Assignment";
import Build from "@mui/icons-material/Build";
import BusinessCenter from "@mui/icons-material/BusinessCenter";
import CameraIndoor from "@mui/icons-material/CameraIndoor";
import ChatIcon from "@mui/icons-material/Chat";
import Description from "@mui/icons-material/Description";
import EmojiPeople from "@mui/icons-material/EmojiPeople";
import Euro from "@mui/icons-material/Euro";
import EventNote from "@mui/icons-material/EventNote";
import Explore from "@mui/icons-material/Explore";
import FamilyRestroom from "@mui/icons-material/FamilyRestroom";
import FindInPage from "@mui/icons-material/FindInPage";
import Home from "@mui/icons-material/Home";
import HomeWork from "@mui/icons-material/HomeWork";
import HowToReg from "@mui/icons-material/HowToReg";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ListAlt from "@mui/icons-material/ListAlt";
import Mail from "@mui/icons-material/Mail";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Person from "@mui/icons-material/Person";
import PersonAdd from "@mui/icons-material/PersonAdd";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import Room from "@mui/icons-material/Room";
import Savings from "@mui/icons-material/Savings";
import Speed from "@mui/icons-material/Speed";
import TrendingUp from "@mui/icons-material/TrendingUp";

import EvenementDetailPage from "components/Evenements/EvenementDetailPage";
import EvenementsPage from "components/Evenements/EvenementsPage";
import EvenementUpdatePage from "components/Evenements/EvenementUpdatePage";
import OrdresPaiementsCL from "components/OrdresPaiements/OrdresPaiementsCL";
import OrdresPaiementsCP from "components/OrdresPaiements/OrdresPaiementsCP";

import Dashboard from "views/Dashboard/Dashboard";
import EmbeddedDashboard from "views/EmbeddedDashboard/EmbeddedDashboard";
import AccompagnementsForm from "views/Forms/AccompagnementsForm";
import AccompagnementsView from "views/Forms/AccompagnementsView";
import ApurementsForm from "views/Forms/ApurementsForm";
import ApurementsView from "views/Forms/ApurementsView";
import AuditsForm from "views/Forms/AuditsForm";
import AuditsView from "views/Forms/AuditsView";
import BeneficiairesForm from "views/Forms/BeneficiairesForm";
import BeneficiairesView from "views/Forms/BeneficiairesView";
import CalsForm from "views/Forms/CalsForm";
import CalsView from "views/Forms/CalsView";
import ContratsLocatairesForm from "views/Forms/ContratsLocatairesForm";
import ContratsLocatairesView from "views/Forms/ContratsLocatairesView";
import ContratsProprietairesForm from "views/Forms/ContratsProprietairesForm";
import ContratsProprietairesView from "views/Forms/ContratsProprietairesView";
import Courriel from "views/Forms/Courriel";
import DonneurDordresTravauxForm from "views/Forms/DonneurDordresTravauxForm";
import DonneurDordresTravauxView from "views/Forms/DonneurDordresTravauxView";
import DossiersTravauxForm from "views/Forms/DossiersTravauxForm";
import DossiersTravauxView from "views/Forms/DossiersTravauxView";
import EdlsView from "views/Forms/EdlsView";
import EntreprisesForm from "views/Forms/EntreprisesForm";
import EntreprisesView from "views/Forms/EntreprisesView";
import FoyersForm from "views/Forms/FoyersForm";
import FoyersView from "views/Forms/FoyersView";
import IRLsForm from "views/Forms/IRLsForm";
import IRLsView from "views/Forms/IRLsView";
import LogementsForm from "views/Forms/LogementsForm";
import LogementsTravauxForm from "views/Forms/LogementsTravauxForm";
import LogementsTravauxView from "views/Forms/LogementsTravauxView";
import LogementsView from "views/Forms/LogementsView";
import PersonnesForm from "views/Forms/PersonnesForm";
import PersonnesView from "views/Forms/PersonnesView";
import PrimoContactForm from "views/Forms/PrimoContactForm";
import ProceduresForm from "views/Forms/ProceduresForm";
import ProceduresView from "views/Forms/ProceduresView";
import ProprietairesForm from "views/Forms/ProprietairesForm";
import ProprietairesView from "views/Forms/ProprietairesView";
import UserView from "views/Forms/User/UserView";
import MapGoogle from "views/Maps/MapGoogle.jsx";
import Accompagnements from "views/Tables/Accompagnements";
import Apurements from "views/Tables/Apurements";
import Audits from "views/Tables/Audits";
import Beneficiaires from "views/Tables/Beneficiaires";
import Cals from "views/Tables/Cals";
import ContratsLocataires from "views/Tables/ContratsLocataires";
import ContratsProprietaires from "views/Tables/ContratsProprietaires";
import DonneurDordres from "views/Tables/DonneurDordres";
import DossiersTravaux from "views/Tables/DossiersTravaux";
import EcheancierContratsLocataires from "views/Tables/EcheancierContratsLocataires";
import EcheancierContratsProprietaires from "views/Tables/EcheancierContratsProprietaires";
import Edls from "views/Tables/Edls";
import Entreprises from "views/Tables/Entreprises";
import EvenementsList from "views/Tables/EvenementsList";
import Foyers from "views/Tables/Foyers";
import IRLs from "views/Tables/IRLs";
import Logements from "views/Tables/Logements";
import LogementsTravaux from "views/Tables/LogementsTravaux";
import UnreadNotifications from "views/Tables/Notifications";
import OperationsLocataire from "views/Tables/OperationsLocataire";
import OperationsProprietaire from "views/Tables/OperationsProprietaire";
import Personnes from "views/Tables/Personnes";
import Procedures from "views/Tables/Procedures";
import Proprietaires from "views/Tables/Proprietaires";

import User from "context/User";

const isBeneficiaire = new User().is("beneficiaire");

var dashRoutes = [
  {
    sidebar: false,
    path: "/mon-compte",
    name: "Mon compte",
    icon: Speed,
    component: UserView,
  },
  {
    permission: ["view.dashboard"],
    path: "/dashboard",
    name: "Tableau de bord",
    icon: Speed,
    component: Dashboard,
  },
  {
    permission: ["view.embeddeddashboard.direction"],
    path: "/bi-direction",
    name: "BI Direction",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "direction",
    },
  },
  {
    permission: ["view.embeddeddashboard.cesf"],
    path: "/bi-cesf",
    name: "BI CESF",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "chefcesf",
    },
  },
  {
    permission: ["view.embeddeddashboard.gla"],
    path: "/bi-gla",
    name: "BI GLA",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "chefgla",
    },
  },
  {
    permission: ["view.embeddeddashboard.travaux"],
    path: "/bi-travaux",
    name: "BI Travaux",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "cheftravaux",
    },
  },
  {
    permission: ["view.embeddeddashboard.donneurordres"],
    path: "/bi-donneur-ordres",
    name: "BI Donneur d'Ordres",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "donneurordres",
    },
  },
  {
    sidebar: false,
    permission: ["use.internalnotification"],
    path: "/notifications",
    name: "Notifications",
    component: UnreadNotifications,
    fullscreen: true,
  },
  {
    sidebar: false,
    permission: ["view.evenement.immobilier", "edit.evenement.immobilier"],
    path: "/evenements/immobilier/:modelType/:modelId",
    name: "Évènements",
    component: EvenementsPage,
  },
  {
    sidebar: false,
    permission: ["view.evenement.travaux", "edit.evenement.travaux"],
    path: "/evenements/travaux/:modelType/:modelId",
    name: "Évènements",
    component: EvenementsPage,
  },
  {
    sidebar: false,
    permission: ["view.evenement", "edit.evenement"],
    path: "/evenements/:modelType/detail/:evenementId",
    name: "Évènements",
    component: EvenementDetailPage,
  },
  {
    sidebar: false,
    permission: ["edit.evenement"],
    path: "/evenements/:modelType/modifier/:evenementId",
    name: "Évènements",
    component: EvenementUpdatePage,
  },
  {
    sidebar: false,
    path: "/banque/ordres/cp",
    name: "Ordres Paiements CP",
    component: OrdresPaiementsCP,
  },
  {
    sidebar: false,
    path: "/banque/ordres/cl",
    name: "Ordres Prélèvements CL",
    component: OrdresPaiementsCL,
  },
  {
    divider: true,
    permission: ["edit.evenement.immobilier"], // todo
    name: "Immobilier",
  },
  {
    sidebar: false,
    permission: ["view.contratlocataire", "edit.contratlocataire"],
    path: "/contrats-locataires/detail/:id",
    name: "Contrats Locataires",
    component: ContratsLocatairesView,
  },
  {
    sidebar: false,
    permission: ["edit.contratlocataire"],
    path: "/contrats-locataires/modifier/:id",
    name: "Contrats Locataires",
    component: ContratsLocatairesForm,
  },
  {
    sidebar: false,
    permission: ["edit.contratlocataire"],
    path: "/contrats-locataires/ajouter",
    name: "Contrats Locataires",
    component: ContratsLocatairesForm,
  },
  {
    permission: ["view.contratlocataire", "edit.contratlocataire"],
    path: "/contrats-locataires",
    name: "Contrats Locataires",
    icon: HomeWork,
    component: ContratsLocataires,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.contratlocataire", "edit.contratlocataire"],
    path: "/contrats-locataires/echeancier",
    name: "Échéanciers Mensuels des Contrats Locataires",
    component: EcheancierContratsLocataires,
    fullscreen: true,
  },
  {
    sidebar: false,
    permission: ["view.contratproprietaire", "edit.contratproprietaire"],
    path: "/contrats-proprietaires/detail/:id",
    name: "Contrats Propriétaires",
    component: ContratsProprietairesView,
  },
  {
    sidebar: false,
    permission: ["edit.contratproprietaire"],
    path: "/contrats-proprietaires/modifier/:id",
    name: "Contrats Propriétaires",
    component: ContratsProprietairesForm,
  },
  {
    sidebar: false,
    permission: ["edit.contratproprietaire"],
    path: "/contrats-proprietaires/ajouter",
    name: "Contrats Propriétaires",
    component: ContratsProprietairesForm,
  },
  {
    permission: ["view.contratproprietaire", "edit.contratproprietaire"],
    path: "/contrats-proprietaires",
    name: "Contrats Propriétaires",
    icon: Description,
    component: ContratsProprietaires,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.contratproprietaire", "edit.contratproprietaire"],
    path: "/contrats-proprietaires/echeancier",
    name: "Échéanciers Mensuels des Contrats Propriétaires",
    component: EcheancierContratsProprietaires,
    fullscreen: true,
  },
  {
    permission: ["view.vuescomptablecl", "view.vuescomptablecp"],
    collapse: true,
    path: "/comptabilite",
    name: "Comptabilité",
    icon: LibraryBooksIcon,
    state: "openComptabilite",
    views: [
      {
        icon: ReadMoreIcon,
        permission: ["view.vuescomptablecl"],
        path: "/comptabilite/operations-locataire",
        name: "Opérations CL",
        component: OperationsLocataire,
        fullscreen: true,
        // logs: true,
      },
      {
        icon: ReadMoreIcon,
        permission: ["view.vuescomptablecp"],
        path: "/comptabilite/operations-proprietaire",
        name: "Opérations CP",
        component: OperationsProprietaire,
        fullscreen: true,
        // logs: true,
      },
      {
        icon: Euro,
        permission: ["view.vuescomptablecl"],
        path: "/comptabilite/echeancier-locataire",
        name: "Échéanciers CL",
        component: EcheancierContratsLocataires,
        fullscreen: true,
      },
      {
        icon: Euro,
        permission: ["view.vuescomptablecp"],
        path: "/comptabilite/echeancier-proprietaire",
        name: "Échéanciers CP",
        component: EcheancierContratsProprietaires,
        fullscreen: true,
      },
    ],
  },
  {
    permission: ["edit.evenement.immobilier"], // todo
    collapse: true,
    path: "/tiers",
    name: "Gestion des tiers",
    state: "openTiers",
    icon: Assignment,
    views: [
      {
        sidebar: false,
        permission: ["view.logement", "edit.logement"],
        path: "/tiers/logements/detail/:id",
        name: "Logements",
        component: LogementsView,
      },
      {
        sidebar: false,
        permission: ["edit.logement"],
        path: "/tiers/logements/modifier/:id",
        name: "Logements",
        component: LogementsForm,
      },
      {
        sidebar: false,
        permission: ["edit.logement"],
        path: "/tiers/logements/ajouter",
        name: "Logements",
        component: LogementsForm,
      },
      {
        icon: Home,
        permission: ["view.logement", "edit.logement"],
        path: "/tiers/logements",
        name: "Logements",
        component: Logements,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.cal", "edit.cal"],
        path: "/tiers/cals/detail/:id",
        name: "Commission Attribution Logement",
        component: CalsView,
      },
      {
        sidebar: false,
        permission: ["edit.cal"],
        path: "/tiers/cals/modifier/:id",
        name: "Commission Attribution Logement",
        component: CalsForm,
      },
      {
        sidebar: false,
        permission: ["edit.cal"],
        path: "/tiers/cals/ajouter",
        name: "Commission Attribution Logement",
        component: CalsForm,
      },
      {
        icon: EventNote,
        permission: ["view.cal", "edit.cal"],
        path: "/tiers/cals",
        name: "CAL",
        component: Cals,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.proprietaire", "edit.proprietaire"],
        path: "/tiers/proprietaires/detail/:id",
        name: "Propriétaires",
        component: ProprietairesView,
      },
      {
        sidebar: false,
        permission: ["edit.proprietaire"],
        path: "/tiers/proprietaires/modifier/:id",
        name: "Propriétaires",
        component: ProprietairesForm,
      },
      {
        sidebar: false,
        permission: ["edit.proprietaire"],
        path: "/tiers/proprietaires/ajouter",
        name: "Propriétaires",
        component: ProprietairesForm,
      },
      {
        icon: HowToReg,
        permission: ["view.proprietaire", "edit.proprietaire"],
        path: "/tiers/proprietaires",
        name: "Propriétaires",
        component: Proprietaires,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.personne", "edit.personne"],
        path: "/tiers/locataires/detail/:id",
        name: "Personnes/Locataires",
        component: PersonnesView,
      },
      {
        sidebar: false,
        permission: ["edit.personne"],
        path: "/tiers/locataires/modifier/:id",
        name: "Personnes/Locataires",
        component: PersonnesForm,
      },
      {
        sidebar: false,
        permission: ["edit.personne"],
        path: "/tiers/locataires/ajouter",
        name: "Personnes/Locataires",
        component: PersonnesForm,
      },
      {
        icon: Person,
        permission: ["view.personne", "edit.personne"],
        path: "/tiers/locataires",
        name: "Personnes/Locataires",
        component: Personnes,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.foyer", "edit.foyer"],
        path: "/tiers/foyers/detail/:id",
        name: "Foyers",
        component: FoyersView,
      },
      {
        sidebar: false,
        permission: ["edit.foyer"],
        path: "/tiers/foyers/modifier/:id",
        name: "Foyers",
        component: FoyersForm,
      },
      {
        sidebar: false,
        permission: ["edit.foyer"],
        path: "/tiers/foyers/ajouter",
        name: "Foyers",
        component: FoyersForm,
      },
      {
        icon: FamilyRestroom,
        permission: ["view.foyer", "edit.foyer"],
        path: "/tiers/foyers",
        name: "Foyers",
        component: Foyers,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.accompagnement", "edit.accompagnement"],
        path: "/tiers/accompagnements/detail/:id",
        name: "Accompagnements",
        component: AccompagnementsView,
      },
      {
        sidebar: false,
        permission: ["edit.accompagnement"],
        path: "/tiers/accompagnements/modifier/:id",
        name: "Accompagnements",
        component: AccompagnementsForm,
      },
      {
        sidebar: false,
        permission: ["edit.accompagnement"],
        path: "/tiers/accompagnements/ajouter",
        name: "Accompagnements",
        component: AccompagnementsForm,
      },
      {
        icon: EmojiPeople,
        permission: ["view.accompagnement", "edit.accompagnement"],
        path: "/tiers/accompagnements",
        name: "Accompagnements",
        component: Accompagnements,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.apurement", "edit.apurement"],
        path: "/tiers/apurements/detail/:id",
        name: "Apurements",
        component: ApurementsView,
      },
      {
        sidebar: false,
        permission: ["edit.apurement"],
        path: "/tiers/apurements/modifier/:id",
        name: "Apurements",
        component: ApurementsForm,
      },
      {
        sidebar: false,
        permission: ["edit.apurement"],
        path: "/tiers/apurements/ajouter",
        name: "Apurements",
        component: ApurementsForm,
      },
      {
        icon: Savings,
        permission: ["view.apurement", "edit.apurement"],
        path: "/tiers/apurements",
        name: "Apurements",
        component: Apurements,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: ["view.procedure", "edit.procedure"],
        path: "/tiers/procedures/detail/:id",
        name: "Procédures",
        component: ProceduresView,
      },
      {
        sidebar: false,
        permission: ["edit.procedure"],
        path: "/tiers/procedures/modifier/:id",
        name: "Procédures",
        component: ProceduresForm,
      },
      {
        sidebar: false,
        permission: ["edit.procedure"],
        path: "/tiers/procedures/ajouter",
        name: "Procédures",
        component: ProceduresForm,
      },
      {
        icon: ListAlt,
        permission: ["view.procedure", "edit.procedure"],
        path: "/tiers/procedures",
        name: "Procédures",
        component: Procedures,
        fullscreen: true,
        logs: true,
      },
      {
        sidebar: false,
        permission: [
          "view.entreprise.immobilier",
          "edit.entreprise.immobilier",
        ],
        path: "/tiers/entreprises/immobilier/detail/:id",
        name: "Entreprises",
        component: EntreprisesView,
        componentProps: {
          section: "immobilier",
          urlPrefix: "/tiers",
        },
      },
      {
        sidebar: false,
        permission: ["edit.entreprise.immobilier"],
        path: "/tiers/entreprises/immobilier/modifier/:id",
        name: "Entreprises",
        component: EntreprisesForm,
        componentProps: {
          section: "immobilier",
          urlPrefix: "/tiers",
        },
      },
      {
        sidebar: false,
        permission: ["edit.entreprise.immobilier"],
        path: "/tiers/entreprises/immobilier/ajouter",
        name: "Entreprises",
        component: EntreprisesForm,
        componentProps: {
          section: "immobilier",
          urlPrefix: "/tiers",
        },
      },
      {
        permission: [
          "view.entreprise.immobilier",
          "edit.entreprise.immobilier",
        ],
        path: "/tiers/entreprises/immobilier",
        name: "Entreprises",
        icon: BusinessCenter,
        component: Entreprises,
        fullscreen: true,
        logs: true,
        componentProps: {
          section: "immobilier",
          urlPrefix: "/tiers",
        },
      },
      {
        sidebar: false,
        permission: ["view.edl", "edit.edl"],
        path: "/tiers/edls/detail/:id",
        name: "États des lieux",
        component: EdlsView,
      },
      // {
      //   sidebar: false,
      //   permission: ["edit.edl"],
      //   path: "/tiers/edls/modifier/:id",
      //   name: "États des lieux",
      //   component: EdlsForm,
      // },
      // {
      //   sidebar: false,
      //   permission: ["edit.edl"],
      //   path: "/tiers/edls/ajouter",
      //   name: "États des lieux",
      //   component: EdlsForm,
      // },
      {
        icon: CameraIndoor,
        permission: ["view.edl", "edit.edl"],
        path: "/tiers/edls",
        name: "États des lieux",
        component: Edls,
        fullscreen: true,
        logs: true,
      },

      {
        sidebar: false,
        permission: ["view.irl", "edit.irl"],
        path: "/tiers/irls/detail/:id",
        name: "IRL",
        component: IRLsView,
        componentProps: {
          readOnly: true,
        },
      },
      {
        sidebar: false,
        permission: ["edit.irl"],
        path: "/tiers/irls/modifier/:id",
        name: "IRL",
        component: IRLsForm,
      },
      {
        sidebar: false,
        permission: ["edit.irl"],
        path: "/tiers/irls/ajouter",
        name: "IRL",
        component: IRLsForm,
      },
      {
        icon: TrendingUp,
        permission: ["view.irl", "edit.irl"],
        path: "/tiers/irls",
        name: "IRL",
        component: IRLs,
        fullscreen: true,
        logs: true,
      },
    ],
  },
  {
    permission: ["view.evenement.immobilier", "edit.evenement.immobilier"],
    path: "/evenements/immobilier",
    name: "Évènements",
    icon: ChatIcon,
    component: EvenementsList,
    componentProps: {
      section: "immobilier",
    },
  },
  {
    collapse: true,
    permission: ["view.contratlocataire", "edit.contratlocataire"],
    path: "/cartes",
    name: "Cartes",
    state: "openMaps",
    icon: Room,
    views: [
      {
        icon: Explore,
        permission: ["view.contratlocataire", "edit.contratlocataire"],
        path: "/cartes-contrats-locataires",
        name: "Contrats Locataires",
        fullscreen: true,
        component: MapGoogle,
      },
    ],
  },
  {
    permission: ["use.email.immobilier"],
    path: "/courriel/immobilier",
    name: "Courriel",
    icon: Mail,
    component: Courriel,
    componentProps: {
      section: "immobilier",
    },
  },
  {
    divider: true,
    permission: ["edit.evenement.travaux"], // todo
    name: "Travaux",
  },
  {
    permission: ["use.primocontact"],
    path: "/primo-contact",
    name: "Primo Contact",
    icon: PersonAdd,
    component: PrimoContactForm,
  },
  {
    sidebar: false,
    permission: ["view.beneficiaire", "edit.beneficiaire"],
    path: "/beneficiaires/detail/:id",
    name: "Bénéficiaires",
    component: BeneficiairesView,
  },
  {
    sidebar: false,
    permission: ["edit.beneficiaire"],
    path: "/beneficiaires/modifier/:id",
    name: "Bénéficiaires",
    component: BeneficiairesForm,
  },
  {
    sidebar: false,
    permission: ["edit.beneficiaire"],
    path: "/beneficiaires/ajouter",
    name: "Bénéficiaires",
    component: BeneficiairesForm,
  },
  {
    permission: ["view.beneficiaire", "edit.beneficiaire"],
    path: "/beneficiaires",
    name: isBeneficiaire ? "Mon Compte" : "Bénéficiaires",
    icon: PeopleAlt,
    component: Beneficiaires,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.logementtravaux", "edit.logementtravaux"],
    path: "/logements-travaux/detail/:id",
    name: "Logements",
    component: LogementsTravauxView,
  },
  {
    sidebar: false,
    permission: ["edit.logementtravaux"],
    path: "/logements-travaux/modifier/:id",
    name: "Logements",
    component: LogementsTravauxForm,
  },
  {
    sidebar: false,
    permission: ["edit.logementtravaux"],
    path: "/logements-travaux/ajouter",
    name: "Logements",
    component: LogementsTravauxForm,
  },
  {
    permission: ["view.logementtravaux", "edit.logementtravaux"],
    path: "/logements-travaux",
    name: isBeneficiaire ? "Mon Logement" : "Logements",
    icon: Home,
    component: LogementsTravaux,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.dossiertravaux", "edit.dossiertravaux"],
    path: "/dossiers-travaux/detail/:id",
    name: "Dossiers Travaux",
    component: DossiersTravauxView,
  },
  {
    sidebar: false,
    permission: ["edit.dossiertravaux"],
    path: "/dossiers-travaux/modifier/:id",
    name: "Dossiers Travaux",
    component: DossiersTravauxForm,
  },
  {
    sidebar: false,
    permission: ["edit.dossiertravaux"],
    path: "/dossiers-travaux/ajouter",
    name: "Dossiers Travaux",
    component: DossiersTravauxForm,
  },
  {
    permission: ["view.dossiertravaux", "edit.dossiertravaux"],
    path: "/dossiers-travaux",
    name: "Dossiers Travaux",
    icon: Build,
    component: DossiersTravaux,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.audit", "edit.audit"],
    path: "/audits/detail/:id",
    name: "Audits/Diagnostics",
    component: AuditsView,
  },
  {
    sidebar: false,
    permission: ["edit.audit"],
    path: "/audits/modifier/:id",
    name: "Audits/Diagnostics",
    component: AuditsForm,
  },
  {
    sidebar: false,
    permission: ["edit.audit"],
    path: "/audits/ajouter",
    name: "Audits/Diagnostics",
    component: AuditsForm,
  },
  {
    permission: ["view.audit", "edit.audit"],
    path: "/audits",
    name: "Audits/Diagnostics",
    icon: FindInPage,
    component: Audits,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.entreprise.travaux", "edit.entreprise.travaux"],
    path: "/entreprises/travaux/detail/:id",
    name: "Entreprises",
    component: EntreprisesView,
    componentProps: {
      section: "travaux",
    },
  },
  {
    sidebar: false,
    permission: ["edit.entreprise.travaux"],
    path: "/entreprises/travaux/modifier/:id",
    name: "Entreprises",
    component: EntreprisesForm,
    componentProps: {
      section: "travaux",
    },
  },
  {
    sidebar: false,
    permission: ["edit.entreprise.travaux"],
    path: "/entreprises/travaux/ajouter",
    name: "Entreprises",
    component: EntreprisesForm,
    componentProps: {
      section: "travaux",
    },
  },
  {
    permission: ["view.entreprise.travaux", "edit.entreprise.travaux"],
    path: "/entreprises/travaux",
    name: "Entreprises",
    icon: BusinessCenter,
    component: Entreprises,
    fullscreen: true,
    logs: true,
    componentProps: {
      section: "travaux",
    },
  },
  {
    sidebar: false,
    permission: ["view.donneurordres", "edit.donneurordres"],
    path: "/donneurs-ordres/detail/:id",
    name: "Donneur d'Ordres",
    component: DonneurDordresTravauxView,
  },
  {
    sidebar: false,
    permission: ["edit.donneurordres"],
    path: "/donneurs-ordres/ajouter",
    name: "Donneur d'Ordres",
    component: DonneurDordresTravauxForm,
  },
  {
    sidebar: false,
    permission: ["edit.donneurordres"],
    path: "/donneurs-ordres/modifier/:id",
    name: "Donneur d'Ordres",
    component: DonneurDordresTravauxForm,
  },
  {
    permission: ["view.donneurordres", "edit.donneurordres"],
    path: "/donneurs-ordres",
    name: "Donneur d'Ordres",
    icon: RecordVoiceOver,
    component: DonneurDordres,
    fullscreen: true,
    logs: true,
  },
  {
    permission: ["view.evenement.travaux", "edit.evenement.travaux"],
    path: "/evenements/travaux",
    name: "Évènements",
    icon: ChatIcon,
    component: EvenementsList,
    componentProps: {
      section: "travaux",
    },
  },
  {
    permission: ["use.email.travaux"],
    path: "/courriel/travaux",
    name: "Courriel",
    icon: Mail,
    component: Courriel,
    componentProps: {
      section: "travaux",
    },
  },
  // redirects
  {
    redirect: true,
    permission: ["view.contratlocataire", "edit.contratlocataire"],
    path: "/contrats-locataires",
    pathTo: "/contrats-locataires",
  },
  {
    redirect: true,
    permission: ["view.contratproprietaire", "edit.contratproprietaire"],
    path: "/contrats-proprietaires",
    pathTo: "/contrats-proprietaires",
  },
  {
    redirect: true,
    permission: ["view.logement", "edit.logement"],
    path: "/tiers/logements",
    pathTo: "/tiers/logements",
  },
  {
    redirect: true,
    permission: ["view.contratproprietaire", "edit.contratproprietaire"],
    path: "/tiers/proprietaires",
    pathTo: "/tiers/proprietaires",
  },
  {
    redirect: true,
    permission: ["view.personne", "edit.personne"],
    path: "/tiers/locataires",
    pathTo: "/tiers/locataires",
  },
  {
    redirect: true,
    permission: ["view.foyer", "edit.foyer"],
    path: "/tiers/foyers",
    pathTo: "/tiers/foyers",
  },
  {
    redirect: true,
    permission: ["view.accompagnement", "edit.accompagnement"],
    path: "/tiers/accompagnements",
    pathTo: "/tiers/accompagnements",
  },
  {
    redirect: true,
    permission: ["view.apurement", "edit.apurement"],
    path: "/tiers/apurements",
    pathTo: "/tiers/apurements",
  },
  {
    redirect: true,
    permission: ["view.edl", "edit.edl"],
    path: "/tiers/edls",
    pathTo: "/tiers/edls",
  },
  {
    redirect: true,
    permission: ["view.procedure", "edit.procedure"],
    path: "/tiers/procedures",
    pathTo: "/tiers/procedures",
  },
  {
    redirect: true,
    permission: ["view.entreprise.immobilier", "edit.entreprise.immobilier"],
    path: "/tiers/entreprises/immobilier",
    pathTo: "/tiers/entreprises/immobilier",
  },
  {
    redirect: true,
    permission: ["view.edl", "edit.edl"],
    path: "/tiers/edls",
    pathTo: "/tiers/edls",
  },
  {
    redirect: true,
    permission: ["view.irl", "edit.irl"],
    path: "/tiers/irls",
    pathTo: "/tiers/irls",
  },
  {
    redirect: true,
    permission: ["view.beneficiaire", "edit.beneficiaire"],
    path: "/beneficiaires",
    pathTo: "/beneficiaires",
  },
  {
    redirect: true,
    permission: ["view.logementtravaux", "edit.logementtravaux"],
    path: "/logements-travaux",
    pathTo: "/logements-travaux",
  },
  {
    redirect: true,
    permission: ["view.dossiertravaux", "edit.dossiertravaux"],
    path: "/dossiers-travaux",
    pathTo: "/dossiers-travaux",
  },
  {
    redirect: true,
    permission: ["view.audit", "edit.audit"],
    path: "/audits",
    pathTo: "/audits",
  },
  {
    redirect: true,
    permission: ["view.entreprise.travaux", "edit.entreprise.travaux"],
    path: "/entreprises/travaux",
    pathTo: "/entreprises/travaux",
  },
  {
    redirect: true,
    permission: ["edit.evenement.immobilier"], // todo une permission globale immo
    path: "/",
    pathTo: "/dashboard",
  },
  {
    redirect: true,
    permission: ["view.beneficiaire", "edit.beneficiaire"], // todo une permission globale travaux
    path: "/",
    pathTo: "/beneficiaires",
  },
];

export default dashRoutes;
