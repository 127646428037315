import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui/material components

const Image = ({ src, alt, style, ...rest }) => {
  return (
    <>
      {src != null && src != "" ? (
        <img
          src={src}
          alt={alt}
          style={{
            objectFit: "scale-down",
            width: "100%",
            maxHeight: "300px",
            ...style,
          }}
          {...rest}
        />
      ) : null}
    </>
  );
};

Image.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.any,
  style: PropTypes.any,
};

export default memo(Image);
