export default class AdresseUtils {
  static clearAddress() {
    return {
      ban_id: null,
      region: null,
      numero_voie: null,
      type_voie: null,
      nom_voie: null,
      residence: null,
      batiment: null,
      etage: null,
      ville_id: null,
      latitude: null,
      longitude: null,
      complement_localisation: null,
      numero_logement: null,
    };
  }
}
